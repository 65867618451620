import { tx } from '@instantdb/react';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useNotifications } from '@/context/Notifications/useNotifications';
import { useUser } from '@/context/User/useUser';
import { NotificationBase } from '@/ui/Notifications/NotificationBase';
import { batchTransact } from '@/utils/db/db';
import { UniverseInvite } from '@/utils/kosmik/universe';
import { getRelativeTimeString } from '@/utils/relativeDate';

interface NotificationProps {
  invite: UniverseInvite;
}

export const UniverseInviteNotification = ({ invite }: NotificationProps) => {
  const currentUser = useUser();
  const navigate = useNavigate();
  const { dismissNotification } = useNotifications();

  const universe = invite.universes?.[0];

  const handleOnClick = useCallback(async () => {
    if (currentUser && universe) {
      await batchTransact([
        tx.universes?.[universe.id]?.link({ users: [currentUser.id] }),
        tx.universe_invite?.[invite.id]?.delete(),
      ]);
      await navigate({ to: `/${universe.id}` });
      dismissNotification(invite.id);
    }
  }, [currentUser, dismissNotification, invite.id, navigate, universe]);

  const handleClose = useCallback(async () => {
    await batchTransact(tx.universe_invite?.[invite.id]?.delete());
    dismissNotification(invite.id);
  }, [dismissNotification, invite.id]);

  return (
    <NotificationBase
      id={invite.id}
      title={'Universe Invite'}
      description={`${invite.sender?.[0]?.email} invited you to ${universe?.name}`}
      action={{
        label: 'Join',
        onClick: handleOnClick,
      }}
      cancel={{
        label: 'Dismiss',
        onClick: handleClose,
      }}
      footer={
        <TextLabelRegular style={{ color: 'var(--label-tertiary)' }}>
          {getRelativeTimeString(new Date(invite.created_at))}
        </TextLabelRegular>
      }
    />
  );
};
