import { StickerFile } from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';

import { useFileImport } from '@/hooks/useFileImport';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const CreateFileStickerMenuItem = ({ type }: { type: MenuType }) => {
  const { universeId } = useParams({ strict: false });
  const { openFilePicker } = useFileImport();

  const { Item } = getPolymorphicMenuComponents(type);

  return (
    <Item
      onClick={openFilePicker}
      prefix={<StickerFile />}
      disabled={universeId === null}
    >
      File
    </Item>
  );
};
