import * as PopoverPrimitive from '@radix-ui/react-popover';
import React from 'react';

import { MenuContent } from '@/ui/MenuPrimitive/MenuContent';

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  PopoverPrimitive.PopoverContentProps
>(({ children, ...props }, ref) => {
  return (
    <PopoverPrimitive.Content asChild {...props}>
      <MenuContent ref={ref}>{children}</MenuContent>
    </PopoverPrimitive.Content>
  );
});

PopoverContent.displayName = 'PopoverContent';
