/**
 * Generates a new "copy" name based on the input name.
 *
 * @param name - The original name to generate a copy name from.
 * @returns A new name with the appropriate "copy" suffix.
 *
 * @example
 * getCopyName("Vacation") // Returns "Vacation copy"
 * getCopyName("Vacation copy") // Returns "Vacation copy 1"
 * getCopyName("Vacation copy 1") // Returns "Vacation copy 2"
 */
export const getCopyName = (name: string): string => {
  const copyRegex = /^(.*?)(?: copy(?: (\d+))?)?$/;
  const match = name.match(copyRegex);

  if (!match) {
    return `${name} copy`;
  }

  const [, baseName, numberStr] = match;

  if (numberStr) {
    const nextNumber = parseInt(numberStr, 10) + 1;
    return `${baseName} copy ${nextNumber}`;
  }

  if (baseName?.endsWith(' copy') || name.endsWith(' copy')) {
    return `${name} 1`;
  }

  return `${name} copy`;
};
