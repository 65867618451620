import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Bold,
  Italic,
  Strike,
  Underline,
} from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';
import { useCallback, useRef } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { stickerQuillAttributesFamily } from '@/atoms/sticker';
import { EditDockColorPickerItem } from '@/components/EditDock/components/EditDockColorPickerItem';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { EditDockItemWithChevron } from '@/components/EditDock/components/EditDockItemWithChevron';
import { StickerSourceEditDockItem } from '@/components/EditDock/FileEditDock/FileEditDock';
import {
  getCurrentFontSize,
  getCurrentTextColor,
} from '@/components/EditDock/TextEditDock/utils/textEditDock';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { TextEditorFormat } from '@/components/Quill/QuillEditor';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { DropdownSelect } from '@/ui/Dropdown/DropdownSelect';
import { MenuSeparator } from '@/ui/MenuPrimitive/MenuSeparator';
import {
  AlignmentStyle,
  FontSizeStyle,
  fontSizeValues,
} from '@/utils/css/types';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';
import { MaybeNull } from '@/utils/types';

import styles from '../EditDock.module.css';

const FontSizeIcon = ({ size }: { size: FontSizeStyle }) => {
  return (
    <TextLabelRegular className={styles.fontSizeIcon}>{size}</TextLabelRegular>
  );
};

const AlignmentIcon = ({
  alignment,
  ...props
}: {
  alignment: AlignmentStyle;
}) => {
  switch (alignment) {
    case 'left':
      return <AlignLeft {...props} />;
    case 'right':
      return <AlignRight {...props} />;
    case 'center':
      return <AlignCenter {...props} />;
    case 'justify':
      return <AlignJustify {...props} />;
  }
};

export const TextEditDock = ({
  stickerId,
  attributes,
  setSticker,
}: {
  stickerId: string;
  attributes: KosmikTextSticker['attributes'];
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}) => {
  const [quillAttributes, setQuillAttributes] = useRecoilState(
    stickerQuillAttributesFamily(stickerId)
  );
  const setAttributes = useSetStickerAttributes<typeof attributes>(setSticker);
  const { universeId } = useParams({ strict: false });
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const currentFillColorRef = useRef<string | undefined>(attributes.fill_color);

  const handleBackgroundColorChange = useCallback(
    (color: string) => setAttributes({ fill_color: color }, false),
    [setAttributes]
  );

  const handleBackgroundColorCommit = useCallback(
    (color: string) => {
      const preCommitValue = currentFillColorRef.current;
      const fillColorChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ fill_color: color }, true);
          currentFillColorRef.current = color;
        },
        undo: () => {
          setAttributes({ fill_color: preCommitValue }, true);
        },
      };
      fillColorChangeAction.do();
      addUndoRedoAction(fillColorChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  const handleTextColorChange = useCallback(
    (color: string) =>
      setQuillAttributes((prev) => {
        return { ...prev, color };
      }),
    [setQuillAttributes]
  );

  const handleTextSizeChange = useCallback(
    (size: FontSizeStyle) => {
      const value = fontSizeValues[size];
      setQuillAttributes((prev) => {
        return { ...prev, size: value };
      });
    },
    [setQuillAttributes]
  );

  const handleTextAlignChange = useCallback(
    (align: string) =>
      setQuillAttributes((prev) => {
        return { ...prev, align };
      }),
    [setQuillAttributes]
  );

  const changeSelectedStyle = (format: TextEditorFormat) => {
    setQuillAttributes((prev) => {
      return { ...prev, [format]: !prev[format] };
    });
  };

  const currentBackgroundColor =
    attributes.fill_color ?? 'var(--color-primary)';
  const currentTextColor =
    getCurrentTextColor(quillAttributes) ?? '--label-primary';
  const currentTextAlignment = (quillAttributes.align ??
    'left') as AlignmentStyle;
  const currentFontSize = getCurrentFontSize(quillAttributes);
  return (
    <>
      <StickerSourceEditDockItem attributes={attributes} />
      <DropdownSelect<FontSizeStyle>
        trigger={
          <EditDockItemWithChevron>
            <FontSizeIcon size={currentFontSize} />
          </EditDockItemWithChevron>
        }
        value={currentFontSize}
        options={[
          { label: <FontSizeIcon size={'small'} />, value: 'small' },
          { label: <FontSizeIcon size={'medium'} />, value: 'medium' },
          { label: <FontSizeIcon size={'large'} />, value: 'large' },
          { label: <FontSizeIcon size={'extraLarge'} />, value: 'extraLarge' },
          { label: <FontSizeIcon size={'huge'} />, value: 'huge' },
        ]}
        onValueChange={handleTextSizeChange}
      />
      <MenuSeparator orientation={'vertical'} />
      <EditDockItem
        active={quillAttributes?.bold}
        onClick={() => changeSelectedStyle('bold')}
      >
        <Bold />
      </EditDockItem>
      <EditDockItem
        active={quillAttributes?.italic}
        onClick={() => changeSelectedStyle('italic')}
      >
        <Italic />
      </EditDockItem>
      <EditDockItem
        active={quillAttributes?.underline}
        onClick={() => changeSelectedStyle('underline')}
      >
        <Underline />
      </EditDockItem>
      <EditDockItem
        active={quillAttributes?.strike}
        onClick={() => changeSelectedStyle('strike')}
      >
        <Strike />
      </EditDockItem>
      <MenuSeparator orientation={'vertical'} />
      <DropdownSelect<AlignmentStyle>
        trigger={
          <EditDockItemWithChevron>
            <AlignmentIcon
              alignment={currentTextAlignment}
              data-testid={'textalignment-icon'}
            />
          </EditDockItemWithChevron>
        }
        value={currentTextAlignment}
        options={[
          { label: <AlignLeft />, value: 'left' },
          { label: <AlignRight />, value: 'right' },
          { label: <AlignJustify />, value: 'justify' },
          { label: <AlignCenter />, value: 'center' },
        ]}
        onValueChange={handleTextAlignChange}
      />
      <MenuSeparator orientation={'vertical'} />
      <EditDockColorPickerItem
        currentColor={currentBackgroundColor}
        onColorChange={handleBackgroundColorChange}
        onColorCommit={handleBackgroundColorCommit}
      />
      <EditDockColorPickerItem
        currentColor={currentTextColor}
        onColorChange={handleTextColorChange}
        onColorCommit={() => undefined}
      />
    </>
  );
};
