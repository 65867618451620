import { useEffect, useState } from 'react';

import { MaybeUndefined } from '@/utils/types';

export const useSearchValue = (url: MaybeUndefined<string>) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue(url ?? '');
  }, [url]);

  return { searchValue, setSearchValue };
};
