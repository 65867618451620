import { StickerWeb } from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useSetPinnedSticker } from '@/hooks/pinPanel/useSetPinnedSticker';
import { useCreateLocalWebSticker } from '@/hooks/sticker/create/useCreateLocalWebSticker';
import { Tooltip } from '@/ui/Tooltip/Tooltip';

export const CreateWebStickerButton = () => {
  const { universeId } = useParams({ strict: false });
  const { setPinnedSticker } = useSetPinnedSticker();
  const createLocalWebSticker = useCreateLocalWebSticker();

  return (
    <Tooltip content={'Create web'}>
      <EditDockItem
        onClick={() => {
          const newWebStickerId = createLocalWebSticker();
          setPinnedSticker(newWebStickerId, true);
        }}
        disabled={universeId === null}
        data-testid={'create-web-sticker-button'}
      >
        <StickerWeb />
      </EditDockItem>
    </Tooltip>
  );
};
