import { atom } from 'recoil';

import { MaybeNull } from '@/utils/types';

/**
 * Holds the current open state of the canvas context menu
 */
export const canvasContextMenuOpenAtom = atom({
  key: 'canvasContextMenuOpenAtom',
  default: false,
});

/**
 * When performing context actions, we default to using the selected stickers,
 * but it's sometimes necessary to 'force' the context stickers, which you can
 * do by setting this atom to the set of ids you want the context actions to be
 * performed on.
 */
export const contextActionStickerIdsAtom = atom<MaybeNull<Set<string>>>({
  key: 'contextActionStickerIdsAtom',
  default: null,
});
