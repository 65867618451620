import { StickerPostit } from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useCreateDefaultStickySticker } from '@/hooks/sticker/create/useCreateDefaultStickySticker';
import { Tooltip } from '@/ui/Tooltip/Tooltip';

export const CreateStickyStickerButton = () => {
  const { universeId } = useParams({ strict: false });
  const { handleCreateDefaultSticky } = useCreateDefaultStickySticker();

  return (
    <Tooltip content={'Create sticky note'}>
      <EditDockItem
        onClick={(event) => handleCreateDefaultSticky(event.shiftKey)}
        disabled={universeId === null}
        data-testid={'create-sticky-sticker-button'}
      >
        <StickerPostit />
      </EditDockItem>
    </Tooltip>
  );
};
