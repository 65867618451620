import { useParams } from '@tanstack/react-router';
import { useLayoutEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { cameraAtom, DEFAULT_CAMERA } from '@/atoms/camera';
import { LoadingView } from '@/components/loading/LoadingView';
import { NoUniverseView } from '@/components/placeholders/NoUniverseView';
import { LoadedUniverseStage } from '@/components/Universe/LoadedUniverseStage';
import { useSyncLocalStorageCamera } from '@/hooks/camera/useSyncLocalStorageCamera';
import { useCurrentUniverse } from '@/hooks/useCurrentUniverse';
import { useIsUniverseMember } from '@/hooks/useIsUniverseMember';
import {
  getCurrentCameraValues,
  getZoomToFitTargetCamera,
} from '@/utils/camera/camera';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { isWeb } from '@/utils/platform/isWeb';
import { MaybeNull } from '@/utils/types';

export const UNIVERSE_TINT_VARIABLE = '--universe-tint';

export interface UniverseStageWrapperProps {
  isPublic?: boolean;
}

export const UniverseStageWrapper = ({
  isPublic,
}: UniverseStageWrapperProps) => {
  const { universeId } = useParams({ strict: false });
  const { isSynced, hasLocalStorageCamera, setLocalStorageCamera } =
    useSyncLocalStorageCamera(universeId);
  const privateSetCamera = useSetRecoilState(cameraAtom);
  const { isLoading, error, universe } = useCurrentUniverse();
  const isUniverseMember = useIsUniverseMember(universe);
  const universeColorRef = useRef<MaybeNull<string>>(null);
  // Fit universe if no local storage camera was found
  useLayoutEffect(() => {
    if (!hasLocalStorageCamera && universe?.id && universe.id === universeId) {
      if (universe.stickers && universe.stickers.length > 0) {
        const allStickers = universe.stickers ?? [];
        const allStickersBoundingBox = getBoundingBox(allStickers);
        if (allStickersBoundingBox) {
          const camera = getCurrentCameraValues();
          const targetCamera = getZoomToFitTargetCamera(
            camera,
            allStickersBoundingBox
          );
          setLocalStorageCamera(targetCamera);
          privateSetCamera(targetCamera);
        }
      } else {
        setLocalStorageCamera(DEFAULT_CAMERA);
        privateSetCamera(DEFAULT_CAMERA);
      }
    }
    if (universe?.color) {
      if (universeColorRef.current !== universe.color) {
        universeColorRef.current = universe.color;
        document
          .getElementById('root')
          ?.style.setProperty(UNIVERSE_TINT_VARIABLE, `var(${universe.color})`);
      }
    } else {
      if (isWeb) {
        universeColorRef.current = null;
        document
          .getElementById('root')
          ?.style.setProperty(
            UNIVERSE_TINT_VARIABLE,
            'var(--universe-primary)'
          );
      } else {
        universeColorRef.current = null;
        document
          .getElementById('root')
          ?.style.removeProperty(UNIVERSE_TINT_VARIABLE);
      }
    }
  }, [
    hasLocalStorageCamera,
    privateSetCamera,
    setLocalStorageCamera,
    universeId,
    universe,
  ]);

  if (!universeId) {
    return <NoUniverseView />;
  }

  if (isLoading || !isSynced) {
    return <LoadingView />;
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        Uh oh! {error.message}
      </div>
    );
  }

  if (!universe) {
    return <NoUniverseView />;
  }

  return (
    <LoadedUniverseStage
      universe={universe}
      isUniverseMember={isUniverseMember}
      isPublic={isPublic}
    />
  );
};
