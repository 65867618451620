import { Dispatch, SetStateAction, useContext, useLayoutEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';

import {
  isDrawingSelectionAtom,
  isMultiSelectionAtom,
  isSingleSelectionAtom,
  selectedStickerIdsAtom,
} from '@/atoms/selection';
import { localStickerFamily } from '@/atoms/sticker';
import { useStickerSelection } from '@/components/selection/useStickerSelection';
import { Transformer } from '@/components/Universe/Selection/Transformer';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { Dimension } from '@/utils/geometry/dimension';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeUndefined } from '@/utils/types';

export const BoundingBox = ({
  boundingBox,
  setBoundingBox,
  stickers,
  universeId,
}: {
  boundingBox: MaybeUndefined<Dimension>;
  setBoundingBox: Dispatch<SetStateAction<MaybeUndefined<Dimension>>>;
  stickers: KosmikSticker[];
  universeId: string;
}) => {
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const isDrawingSelection = useRecoilValue(isDrawingSelectionAtom);
  const isSingleSelection = useRecoilValue(isSingleSelectionAtom);
  const isMultiSelection = useRecoilValue(isMultiSelectionAtom);
  const { isUniverseMember } = useContext(UniverseContext);
  const { unselect } = useStickerSelection();

  /**
   * Recompute bounding box when necessary
   */
  useLayoutEffect(() => {
    if (selectedStickerIds.size === 0) {
      setBoundingBox(undefined);
    } else {
      const selectedStickers = [...selectedStickerIds.values()]
        .map((stickerId) => getRecoil(localStickerFamily(stickerId)))
        .filter(Boolean) as KosmikSticker[];
      const boundingBox = getBoundingBox(selectedStickers);
      if (boundingBox) {
        setBoundingBox(boundingBox);
      }
    }
  }, [selectedStickerIds, setBoundingBox, stickers]);

  /**
   * Clear bounding box upon changing universe. Make sure that the unselect
   * callback identity is stable enough and doesn't react to universe changes.
   * Otherwise, the stickers will be deselected every time you move them.
   */
  useLayoutEffect(() => {
    unselect();
    setBoundingBox(undefined);
  }, [setBoundingBox, universeId, unselect]);

  /**
   * Once the bounding box has been updated, we can clear the css offset variables
   */
  useLayoutEffect(() => {
    const cameraElement = document.querySelector('[data-camera]');

    if (!(cameraElement instanceof HTMLElement)) {
      return;
    }

    cameraElement.style.removeProperty('--bounding-box-offset-x');
    cameraElement.style.removeProperty('--bounding-box-offset-y');
  }, [boundingBox]);

  const firstSelectedStickerId = [...selectedStickerIds][0] ?? '';
  const firstSelectedSticker = getRecoil(
    localStickerFamily(firstSelectedStickerId)
  );
  const isSingleResizeableSelection =
    isSingleSelection && firstSelectedSticker?.resizeable !== false;
  const isResizeable =
    isUniverseMember && (isMultiSelection || isSingleResizeableSelection);
  const showBoundingBox = isResizeable && !isDrawingSelection;

  return boundingBox && showBoundingBox ? (
    <Transformer boundingBox={boundingBox} setBoundingBox={setBoundingBox} />
  ) : null;
};
