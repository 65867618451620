import { useState } from 'react';
import { useEventListener } from 'usehooks-ts';

/**
 * Returns the navigator.onLine status and listens to changes
 */
export const useOnlineStatus = () => {
  const [online, setOnline] = useState(navigator.onLine);
  useEventListener('online', () => setOnline(navigator.onLine));
  useEventListener('offline', () => setOnline(navigator.onLine));
  return online;
};
