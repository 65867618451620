import { useMemo, useState } from 'react';

import { Email } from '@/components/Onboarding/Email';
import { MagicCode } from '@/components/Onboarding/MagicCode';
import { loginParams, Route } from '@/routes/login';

import backgroundAVIF from '../../assets/Kosmik-CPT2_optimized.avif';
import styles from './Login.module.css';

export type LoginStepType = 'email' | 'magic-code' | 'finish';

export function Login() {
  const { duplicateId }: loginParams = Route.useSearch();
  const url = duplicateId ? `/duplicate/${duplicateId}` : '/';
  const [sentEmail, setSentEmail] = useState('');
  const [step, setStep] = useState<LoginStepType>('email');

  const content = useMemo(() => {
    switch (step) {
      case 'email':
        return <Email setSentEmail={setSentEmail} setStep={setStep} />;
      case 'magic-code':
        return (
          <MagicCode
            sentEmail={sentEmail}
            setStep={setStep}
            redirectUrl={url}
          />
        );
      default:
        return null;
    }
  }, [sentEmail, step, url]);

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginPanel}>{content}</div>
      <div
        className={styles.loginBackground}
        style={{ backgroundImage: ` url(${backgroundAVIF}` }}
      ></div>
    </div>
  );
}
