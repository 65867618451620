import { id, tx } from '@instantdb/react';

import { db } from '@/db/databaseInit';
import { getEntityColors } from '@/utils/color/colors';
import { batchTransact } from '@/utils/db/db';
import { KosmikTeam, TeamRole } from '@/utils/kosmik/team';
import { KosmikUser } from '@/utils/kosmik/user';
import { MaybeNull } from '@/utils/types';
import { deleteUniverse } from '@/utils/universe/universe';

/**
 * Create and send an invitation for the given team
 * @param team the team to invite to
 * @param invitedUserId the invited (recipient) user's id
 */
export const inviteToTeam = (team: KosmikTeam, invitedUserId: string) => {
  // Create invite linking currentTeam and invitedUser
  batchTransact(
    tx.team_invite?.[id()]
      ?.update({
        created_at: new Date().toISOString(),
      })
      .link({
        teams: [team.id],
        sender: [team.id],
        receiver: [invitedUserId],
      })
  );
};

/**
 * Renames an existing team by updating its name.
 *
 * @param id - The unique identifier of the team to be renamed.
 * @param newName - The new name to be assigned to the team.
 */
export const renameTeam = (id: string, newName: string) => {
  batchTransact(tx.teams?.[id]?.update({ name: newName }));
};

/**
 * Updates the color of a team identified by its ID.
 *
 * @param id - The unique identifier of the team to update.
 * @param color - The new color to set for the team.
 */
export const updateColorTeam = (id: string, color: string) => {
  batchTransact(tx.teams?.[id]?.update({ color: color }));
};

/**
 * Deletes the specified team and all associated universes.
 *
 * This function iterates over each universe associated with
 * the provided team and deletes them. After all the universes
 * are deleted, it deletes the team in a batch transaction.
 *
 * @param team - The team object that needs to be deleted.
 */
export const deleteTeam = (team: KosmikTeam) => {
  team.universes?.map((universe) => {
    deleteUniverse(universe);
  });
  batchTransact([
    tx.teams?.[team.id]?.delete(),
    tx.team_ownerships?.[team.id]?.delete(),
  ]);
};

/**
 * Function to remove a user from a specified team.
 *
 * @param userId - The unique identifier of the user to be removed.
 * @param teamId - The unique identifier of the team from which the user will be removed.
 */
export const leaveFromTeam = (userId: string, teamId: string) => {
  batchTransact(tx.teams?.[teamId]?.unlink({ users: userId }));
};

export const getUniversesFromTeam = async (teamId: string) => {
  const { data } = await db.queryOnce({
    teams: {
      $: {
        where: {
          id: teamId,
        },
      },
      universes: {},
    },
  });
  return data?.teams?.[0]?.universes;
};

/**
 * Returns the given team color and contrasting color
 */
export const getTeamColors = (team: KosmikTeam) => {
  return getEntityColors(team.name, team.color);
};

/**
 * Returns the role for the given user within the given team;
 */
export const getTeamUserRole = (
  team: KosmikTeam,
  user: MaybeNull<KosmikUser>
) => {
  let role: TeamRole = TeamRole.admin;

  const isTeamOwner = !!team.team_ownerships?.[0]?.users?.some(
    (owner) => owner.id === user?.id
  );

  if (isTeamOwner) {
    role = TeamRole.owner;
  }

  /**
   * Migration: auto-promote first user to team ownership
   * Because we launched without team ownership, we auto promote the first
   * team user to team ownership
   */
  const hasNoTeamOwnership =
    !team.team_ownerships || !team.team_ownerships.length;
  if (hasNoTeamOwnership && team.users.length >= 1) {
    const [newOwner] = team.users;
    if (newOwner) {
      batchTransact([
        tx.team_ownerships?.[team.id]?.update({}).link({
          users: [newOwner.id],
          teams: [team.id],
        }),
        tx.teams?.[team.id]?.unlink({ users: [newOwner.id] }),
      ]);
    }
  }
  // end migration

  return role;
};

/**
 * Get all team users, including owners
 */
export const getAllTeamUsers = (team: KosmikTeam) => {
  const availableUsers = [
    ...(team.team_ownerships?.flatMap((ownership) => ownership.users) ?? []),
    ...(team.users ?? []),
  ];

  // In case the same user is both in team.team_ownerships and team.users
  // we build a map of the available users, so we can dedupe
  const availableUsersMap = new Map(
    availableUsers.map((user) => [user.id, user])
  );

  return Array.from(availableUsersMap.values());
};
