import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { kpiStackAtom } from '@/atoms/kpi';
import { useUser } from '@/context/User/useUser';
import { db } from '@/db/databaseInit';
import { sendKPI } from '@/utils/kpi/sendKPI';

export const useKPIConsumer = () => {
  const { user } = db.useAuth();
  const userContext = useUser();
  const kpiStack = useRecoilValue(kpiStackAtom);
  const resetKPIStack = useResetRecoilState(kpiStackAtom);

  useEffect(() => {
    if (!user || !userContext || kpiStack.length === 0) {
      return;
    }

    kpiStack.forEach((kpi) => {
      sendKPI(kpi, userContext, user.refresh_token);
    });
    resetKPIStack();
  }, [kpiStack, resetKPIStack, user, userContext]);
};
