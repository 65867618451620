import { Button, ButtonShortcut } from '@lithium/spectrum';

import { DialogClose } from '@/ui/Dialog/Dialog';
import { DialogFooter } from '@/ui/Dialog/DialogFooter';

export type TeamGeneralSettingsFooterProps = {
  onSave: () => void;
};

export const TeamGeneralSettingsFooter = ({
  onSave,
}: TeamGeneralSettingsFooterProps) => {
  return (
    <DialogFooter>
      <DialogClose asChild>
        <Button variant={'default'} shortcut={ButtonShortcut.Esc}>
          Cancel
        </Button>
      </DialogClose>
      <Button
        variant={'positive'}
        shortcut={ButtonShortcut.Enter}
        onAction={onSave}
      >
        Save
      </Button>
    </DialogFooter>
  );
};
