import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';

import { ExtractButton } from '@/components/HTMLCanvas/PinPanel/Extract/ExtractButton';
import {
  TransformHandle,
  TransformHandleProps,
  TransformPayload,
} from '@/components/Universe/Selection/TransformHandle';
import { Dimension } from '@/utils/geometry/dimension';
import { getNewBoundingBoxAndScale } from '@/utils/transformer/getNewBoundingBoxAndScale';
import { MaybeUndefined } from '@/utils/types';

export type ExtractTransformerProps = {
  boundingBox: Dimension;
  isDrawing: boolean;
  setBoundingBox: Dispatch<SetStateAction<MaybeUndefined<Dimension>>>;
  onExtract: () => void;
};

export const ExtractTransformer = ({
  boundingBox,
  isDrawing,
  setBoundingBox,
  onExtract,
}: ExtractTransformerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isTransforming, setIsTransforming] = useState(false);

  const transformCSS = useCallback(
    (payload: TransformPayload) => {
      const wrapper = ref.current;
      if (!wrapper) {
        return;
      }

      const { newBoundingBox } = getNewBoundingBoxAndScale(
        boundingBox,
        payload
      );
      wrapper.style.setProperty('width', `${newBoundingBox.width}px`);
      wrapper.style.setProperty('height', `${newBoundingBox.height}px`);
      wrapper.style.setProperty(
        'transform',
        `translate3d(
              ${newBoundingBox.x}px,
              ${newBoundingBox.y}px,
              0
          )`
      );
    },
    [boundingBox]
  );

  const handleOnTransformStart = useCallback(() => {
    setIsTransforming(true);
  }, []);

  const handleOnTransform = useCallback(
    (payload: TransformPayload) => {
      transformCSS(payload);
    },
    [transformCSS]
  );

  const handleOnTransformEnd = useCallback(
    (payload: TransformPayload) => {
      const { newBoundingBox } = getNewBoundingBoxAndScale(
        boundingBox,
        payload
      );

      setBoundingBox(newBoundingBox);
      setIsTransforming(false);
    },
    [boundingBox, setBoundingBox]
  );

  const handleProps: Omit<TransformHandleProps, 'direction'> = {
    onTransformStart: handleOnTransformStart,
    onTransform: handleOnTransform,
    onTransformEnd: handleOnTransformEnd,
    ratio: {
      horizontal: boundingBox.width / boundingBox.height,
      vertical: boundingBox.height / boundingBox.width,
    },
    scaleFactor: 1,
  };

  return (
    <div
      ref={ref}
      style={
        {
          position: 'absolute',
          pointerEvents: 'none',
          borderRadius: '6px',
          outline:
            'var(--transformer-outline-size) solid var(--accent-blue-primary)',
          width: boundingBox.width,
          height: boundingBox.height,
          transform: `translate3d(
              ${boundingBox.x}px,
              ${boundingBox.y}px,
              0
          )`,
          contain: 'layout size',
          ['--transformer-outline-size']: '2px',
        } as CSSProperties
      }
    >
      {!isDrawing ? (
        <>
          <TransformHandle direction={'left'} {...handleProps} />
          <TransformHandle direction={'topLeft'} {...handleProps} />
          <TransformHandle direction={'top'} {...handleProps} />
          <TransformHandle direction={'topRight'} {...handleProps} />
          <TransformHandle direction={'right'} {...handleProps} />
          <TransformHandle direction={'bottomLeft'} {...handleProps} />
          <TransformHandle direction={'bottom'} {...handleProps} />
          <TransformHandle direction={'bottomRight'} {...handleProps} />
        </>
      ) : null}
      {!isDrawing && !isTransforming ? (
        <ExtractButton
          extractRef={ref}
          boundingBox={boundingBox}
          onExtract={onExtract}
        />
      ) : null}
    </div>
  );
};
