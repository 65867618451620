import { id, tx } from '@instantdb/react';
import normalizeText from 'normalize-text';
import { useCallback, useContext } from 'react';

import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext.ts';
import { db } from '@/db/databaseInit.ts';
import { usePushKPI } from '@/hooks/kpi/usePushKPI.ts';
import { analyzeAndSave } from '@/utils/ai/files.ts';
import { batchTransact } from '@/utils/db/db.ts';

export const useDocumentAnalyzer = (universeId: string) => {
  const user = useContext(MultiplayerUserContext);
  const auth = db.useAuth();
  const pushKPI = usePushKPI();

  const username = user?.name;
  const userId = user?.id;
  const refreshToken = auth.user?.refresh_token;

  return useCallback(
    async (stickerId: string, file?: File, url?: string) => {
      if (!username || !refreshToken || !userId) {
        throw new Error('User not logged in');
      }

      const { analyzerResponse: result, usageMetadata } = await analyzeAndSave(
        stickerId,
        file,
        url,
        universeId,
        username,
        refreshToken
      );

      pushKPI({
        type: 'document_analyzed',
        properties: {
          ...usageMetadata,
        },
      });

      const handleTags = async () => {
        const { data } = await db.queryOnce({
          stickers: {
            $: {
              where: {
                id: stickerId,
              },
            },
          },
        });

        if (data.stickers.length === 0) {
          setTimeout(handleTags, 500);
          return;
        }

        const txs = [
          tx.stickers?.[stickerId]?.update({
            description: result.document_description.summary,
          }),
          ...(
            await Promise.all(
              result.document_description.tags.map(async (tag) => {
                const { data } = await db.queryOnce({
                  tags: {
                    $: {
                      where: {
                        user: userId,
                        normalizedName: normalizeText(tag.tag_name),
                      },
                    },
                  },
                });

                const tagId = data.tags[0]?.id ?? id();

                return [
                  tx.tags?.[tagId]?.update({
                    normalizedName: normalizeText(tag.tag_name),
                    name: tag.tag_name,
                    aiGenerated: true,
                  }),
                  tx.stickers?.[stickerId]?.link({ tags: [tagId] }),
                  tx.users?.[userId]?.link({ tags: [tagId] }),
                ];
              })
            )
          ).flat(),
        ];

        batchTransact(txs);
      };

      handleTags();
    },
    [username, refreshToken, userId, universeId, pushKPI]
  );
};
