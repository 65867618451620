import { Ellipse } from '@/utils/geometry/ellipse';
import { Position, PositionTuple } from '@/utils/geometry/position';
import { rotateAroundEllipse } from '@/utils/geometry/rotate';

/**
 * Create a star polygon based on the given ellipses, the ellipses can have
 * different centers and shapes, use createRegularStar to create a regular
 * geometric star instead
 * @param numBranches the number of branches the star should have
 * @param innerEllipse the inner ellipse for the base of the branches
 * @param outerEllipse the outer ellipse for the tip of the branchs
 * @param position the position for the polygon
 */
export const createStar = (
  numBranches: number,
  innerEllipse: Ellipse,
  outerEllipse: Ellipse,
  position: Position = { x: 0, y: 0 }
) => {
  const angle = Math.PI / numBranches;
  const points: Array<PositionTuple> = [];

  for (let i = 0; i < numBranches * 2; i++) {
    const ellipse = i % 2 === 1 ? innerEllipse : outerEllipse;
    const rotatedPoint = rotateAroundEllipse(ellipse, i * angle + Math.PI / 2);
    const coordinates: PositionTuple = [
      rotatedPoint.x + position.x,
      rotatedPoint.y + position.y,
    ];
    points.push(coordinates);
  }

  return points;
};

/**
 * Create a regular star polygon (with the inner ellipse being a scaled down
 * copy of the outer ellipse)
 * @param numBranches the number of branches the star should have
 * @param width the width of the star polygon
 * @param height the height of the star polygon
 * @param innerRadiusRatio the inner radius ratio against the outer radius
 * @param position the position for the polygon
 */
export const createRegularStar = (
  numBranches: number,
  width: number,
  height: number,
  innerRadiusRatio: number,
  position: Position = { x: 0, y: 0 }
) => {
  const outerEllipse = {
    center: { x: width / 2, y: height / 2 },
    rx: width / 2,
    ry: height / 2,
  };
  const innerEllipse = {
    center: outerEllipse.center,
    rx: outerEllipse.rx * innerRadiusRatio,
    ry: outerEllipse.ry * innerRadiusRatio,
  };
  return createStar(numBranches, innerEllipse, outerEllipse, position);
};
