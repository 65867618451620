import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useSetDefaultFileResizable } from '@/components/HTMLCanvas/stickers/hooks/useSetDefaultResizable';
import { HtmlPdfExtractSticker } from '@/components/HTMLCanvas/stickers/HtmlPdfExtractSticker';
import { useDelayedCameraZ } from '@/hooks/camera/useDelayedCameraZ';
import { PAGEVIEW, usePdf } from '@/hooks/pdf/usePdf';
import { usePdfPage } from '@/hooks/pdf/usePdfPage';
import { pdfPageToCanvas } from '@/hooks/pdf/utils/pdfPageToCanvas';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { useSetStickerProperties } from '@/hooks/sticker/useSetStickerProperties';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';

import styles from './HtmlPdfSticker.module.css';

export const HtmlPdfSticker = ({
  sticker,
  refreshCachedUrl,
}: {
  sticker: KosmikFileSticker;
  refreshCachedUrl: () => void;
}) => {
  const pdfStickerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerAttributes =
    useSetStickerAttributes<FileStickerAttributes>(setSticker);
  const setStickerProperties =
    useSetStickerProperties<KosmikFileSticker>(setSticker);
  useSetDefaultFileResizable(sticker);

  const { width } = sticker;
  const file = sticker.files?.[0];
  const { cached_url: url, file_name: fileName } = file ?? sticker.attributes;
  const { extract } = sticker.attributes;
  const { ratio, page = 1 } = sticker.attributes;
  const { pdf } = usePdf(url ?? null, refreshCachedUrl);
  const pdfPage = usePdfPage({ pdf, pageNumber: page });
  const cameraZ = useDelayedCameraZ(pdfStickerRef);

  useEffect(() => {
    if (!pdfPage) {
      return;
    }
    pdfPageToCanvas({
      page: pdfPage,
      size: { width },
      scale: 2 * cameraZ,
    }).then((canvas) => {
      if (canvas) {
        canvas.className = styles.canvas;
        canvasWrapperRef.current?.replaceChildren(canvas);
      }
    });
  }, [cameraZ, pdfPage, width]);

  useEffect(() => {
    if (
      pdfPage &&
      pdfPage.view[PAGEVIEW.width] &&
      pdfPage.view[PAGEVIEW.height]
    ) {
      const viewportRatio =
        pdfPage.view[PAGEVIEW.width] / pdfPage.view[PAGEVIEW.height];
      if (viewportRatio !== ratio) {
        setStickerProperties(
          {
            width,
            height:
              width / viewportRatio + (headerRef.current?.offsetHeight ?? 0),
          },
          true
        );
        setStickerAttributes({ ratio: viewportRatio }, true);
      }
    }
  }, [pdfPage, ratio, setStickerAttributes, setStickerProperties, width]);

  if (extract) {
    return <HtmlPdfExtractSticker sticker={sticker} extract={extract} />;
  }

  return (
    <div
      ref={pdfStickerRef}
      className={styles.pdfSticker}
      data-testid={'pdf-sticker'}
    >
      <header ref={headerRef} className={styles.header}>
        <TextLabelRegular className={styles.filename}>
          {fileName}
        </TextLabelRegular>
        <TextLabelRegular className={styles.info}>
          {pdf?.numPages ? `${page}/${pdf.numPages}` : ''}
        </TextLabelRegular>
      </header>
      <div className={styles.canvasWrapper} ref={canvasWrapperRef}></div>
    </div>
  );
};
