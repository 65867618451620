import { RefObject, useCallback } from 'react';

import { getAngleBetweenCoord } from '@/components/multiplayer/MultiplayerCursors/Utils/getAngleBetweenCoord';
import { getDistance } from '@/components/multiplayer/MultiplayerCursors/Utils/getDistance';
import { getMiddlePosition } from '@/components/multiplayer/MultiplayerCursors/Utils/getMiddlePosition';
import { getTopLeftPosition } from '@/components/multiplayer/MultiplayerCursors/Utils/getTopLeftPosition';
import { getVisibleUniverseSize } from '@/components/multiplayer/MultiplayerCursors/Utils/getVisibleUniverseSize';
import { Camera, isPositionInVisibleCanvas } from '@/utils/camera/camera';
import { isDefined } from '@/utils/isDefined';
import { MaybeUndefined } from '@/utils/types';
import { getUiVisibleDimensions } from '@/utils/ui/ui';

interface CursorRefs {
  cursorContainerRef: RefObject<HTMLDivElement>;
  cursorPointerRef: MaybeUndefined<RefObject<HTMLDivElement>>;
  cursorLabelRef: MaybeUndefined<RefObject<HTMLDivElement>>;
}

export const useUpdateCursorPosition = (
  x: MaybeUndefined<number>,
  y: MaybeUndefined<number>,
  camera: Camera,
  refs: CursorRefs
) => {
  return useCallback(() => {
    if (!isDefined(x) || !isDefined(y)) {
      return;
    }

    const { cursorContainerRef, cursorPointerRef, cursorLabelRef } = refs;
    const uiDimensions = getUiVisibleDimensions();
    const cursorContainer = cursorContainerRef.current;
    const cursorPointer = cursorPointerRef?.current;
    const cursorLabel = cursorLabelRef?.current;

    if (!isPositionInVisibleCanvas({ x, y }, camera)) {
      const topLeft = getTopLeftPosition(
        camera,
        uiDimensions.sidePanelWidth,
        uiDimensions.toolbarHeight
      );

      const size = getVisibleUniverseSize(uiDimensions.canvas, camera);
      const mid = getMiddlePosition(topLeft, size);

      const angle = getAngleBetweenCoord(
        { x: 0, y: 0 },
        {
          x: (x - mid.x) / size.width,
          y: (y - mid.y) / size.height,
        }
      );

      cursorPointer?.style.setProperty(
        'transform',
        `rotate(${angle + (128 / 180) * Math.PI}rad)`
      );

      const circleToSquareMagnitude =
        1 / Math.max(Math.abs(Math.sin(angle)), Math.abs(Math.cos(angle)));

      const edge = {
        x:
          mid.x +
          ((Math.cos(angle) * size.width) / 2) * circleToSquareMagnitude,
        y:
          mid.y +
          ((Math.sin(angle) * size.height) / 2) * circleToSquareMagnitude,
      };

      const distance = getDistance(edge, { x, y });

      cursorLabel?.style.setProperty(
        'transform-origin',
        `${50 + Math.cos(angle) * circleToSquareMagnitude * 50}% ${
          50 + Math.sin(angle) * circleToSquareMagnitude * 50
        }%`
      );

      const cursorScale = Math.max(1 / (1 + (distance / 700) * camera.z), 0.6);
      cursorLabel?.style.setProperty(
        'transform',
        `translate3d(calc(-10px - 50% + ${
          Math.cos(angle) * circleToSquareMagnitude * -50
        }% + ${Math.cos(angle) * -18}px), calc(-10px - 50% + ${
          Math.sin(angle) * circleToSquareMagnitude * -50
        }% + ${Math.sin(angle) * -18}px), 0) scale(${cursorScale})`
      );

      const transform = `translate3d(${edge.x}px, ${edge.y}px, 0) scale(${1 / camera.z})`;
      cursorContainer?.style.setProperty('transform', transform);
    } else {
      cursorPointer?.style.setProperty('transform', 'rotate(0rad)');
      cursorLabel?.style.setProperty('transform', '');
      const transform = `translate3d(${x}px, ${y}px, 0) rotate(0deg) scale(${1 / camera.z})`;
      cursorContainer?.style.setProperty('transform', transform);
    }
  }, [x, y, camera, refs]);
};
