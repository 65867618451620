import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { useTeam } from '@/context/Team/useTeam';
import { Avatar } from '@/ui/Avatar/Avatar';
import { getTeamColors } from '@/utils/team/team';

export type TeamNameSectionProps = {
  teamName: string;
  setTeamName: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
};

export const TeamNameSection = ({
  teamName,
  setTeamName,
  disabled = true,
}: TeamNameSectionProps) => {
  const { team } = useTeam();
  const { color, contrastColor } = getTeamColors(team);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTeamName(event.target.value);
    },
    [setTeamName]
  );

  return (
    <div className={styles.settingsPageFieldRow}>
      <Avatar
        name={team.name}
        color={color}
        contrastColor={contrastColor}
        id={team.id}
        size={52}
        style={'square'}
        shadow
      />
      <div className={styles.column} style={{ flex: 1, gap: 4 }}>
        <TextLabelRegular>Workspace name</TextLabelRegular>
        <input
          className={'label'}
          value={teamName}
          onChange={handleChange}
          style={{ width: '230px' }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
