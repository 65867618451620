import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { CSSProperties } from 'react';

import { Tooltip } from '@/ui/Tooltip/Tooltip';
import { getInitials } from '@/utils/names/names';

import styles from './Avatar.module.css';

interface AvatarProps {
  id?: string;
  name: string;
  src?: string;
  color?: string;
  contrastColor?: string;
  size?: number;
  style?: 'round' | 'square';
  shadow?: boolean;
  tooltip?: string;
  onClick?: () => void;
}

export const Avatar = ({
  id,
  name,
  src,
  color,
  contrastColor,
  size = 30,
  style = 'round',
  shadow = false,
  tooltip,
  onClick,
}: AvatarProps) => {
  const initials = getInitials(name);
  const avatar = (
    <AvatarPrimitive.Root
      className={styles.root}
      data-testid={`avatar-${id ?? name}`}
      data-style={style}
      style={
        {
          boxShadow: shadow ? '0px 0.85px 1.69px 0px #00000066' : 'none',
          fontSize: `${size}px`,
          ['--text-color']: contrastColor,
          ['--user-color']: color,
        } as CSSProperties
      }
      onClick={onClick}
    >
      {src ? (
        <AvatarPrimitive.Image
          className={styles.image}
          src={
            'https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?&w=128&h=128&dpr=2&q=80'
          }
          alt={name}
        />
      ) : null}
      <AvatarPrimitive.Fallback className={styles.fallback}>
        {initials}
      </AvatarPrimitive.Fallback>
    </AvatarPrimitive.Root>
  );
  return tooltip ? <Tooltip content={tooltip}>{avatar}</Tooltip> : avatar;
};

Avatar.displayName = 'Avatar';
