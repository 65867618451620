import { useCallback, useRef } from 'react';

import { usePointerPositionRef } from '@/hooks/pointer/usePointerPositionRef';
import { getCurrentCameraValues } from '@/utils/camera/camera';
import { Point } from '@/utils/geometry/point';
import { Position } from '@/utils/geometry/position';
import { Maybe } from '@/utils/types';

export type DraggingState = {
  dragging: boolean;
  alt: boolean;
  shift: boolean;
  startPosition: Position;
  commitId: string;
  pointedDownAtStickerId: Maybe<string>;
};

export const DEFAULT_DRAGGING_STATE: DraggingState = {
  dragging: false,
  alt: false,
  shift: false,
  startPosition: { x: 0, y: 0 },
  commitId: '',
  pointedDownAtStickerId: null,
};

export const useDraggingState = () => {
  const pointerPositionRef = usePointerPositionRef();
  const draggingState = useRef<DraggingState>(DEFAULT_DRAGGING_STATE);

  /**
   * Force movement to be horizontal or vertical (when doing shift-drag)
   */
  const constrainMovement = useCallback((offset: Position) => {
    const newOffset = { ...offset };
    if (draggingState.current.shift) {
      const isHorizontal = Math.abs(newOffset.x) >= Math.abs(newOffset.y);
      if (isHorizontal) {
        newOffset.y = 0;
      } else {
        newOffset.x = 0;
      }
    }
    return newOffset;
  }, []);

  /**
   * Returns the screen movement in pixels (scale independent)
   */
  const getPointerMovement = useCallback(() => {
    const { startPosition } = draggingState.current;
    return {
      x: pointerPositionRef.current.x - startPosition.x,
      y: pointerPositionRef.current.y - startPosition.y,
    };
  }, [pointerPositionRef]);

  /**
   * Returns the movement offset, which is the x / y delta at scale 1
   */
  const getMovementOffset = useCallback(
    (movement: Point) => {
      const camera = getCurrentCameraValues();
      return constrainMovement({
        x: movement.x / camera.z,
        y: movement.y / camera.z,
      });
    },
    [constrainMovement]
  );

  /**
   * Clears the key modifier states
   */
  const resetModifierState = useCallback(() => {
    draggingState.current.alt = false;
    draggingState.current.shift = false;
  }, []);

  return {
    draggingState,
    constrainMovement,
    getPointerMovement,
    getMovementOffset,
    resetModifierState,
  };
};
