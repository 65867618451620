import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { MaybeUndefined } from '@/utils/types';

/**
 * Because the identity of the multiplayer users change with each room update
 * we sometimes need to lookup by user.peerId instead of using set.has(user)
 */
export const findUserInMultiplayerUserSet = (
  multiplayerUserSet: Set<MultiplayerUser>,
  user: MultiplayerUser
) => {
  return Array.from(multiplayerUserSet.values()).find(
    (selectionUser: MultiplayerUser) => {
      return selectionUser.peerId === user.peerId;
    }
  );
};

/**
 * Returns the various computed properties for a given multiplayer state set
 * @param state - the multiplayer user set on which to compute the properties
 * @param user - the current multiplayer user
 */
export const getMultiplayerProperties = (
  state: Set<MultiplayerUser>,
  user: MaybeUndefined<MultiplayerUser>
) => {
  const stateArray = [...state];
  const isUser = !!(
    user && stateArray.some((stateUser) => stateUser.id === user.id)
  );
  const isRemote = state.size > (isUser ? 1 : 0);
  const isExclusivelyRemote = !isUser && !!state.size;
  const remoteUsers = isRemote
    ? stateArray.filter((stateUser) => stateUser != user)
    : [];
  const [firstUniverseMember] = remoteUsers.filter(
    (selectionUser) => selectionUser.isUniverseMember
  );
  const [firstNonUniverseMember] = remoteUsers.filter(
    (selectionUser) => !selectionUser.isUniverseMember
  );
  const activeUser = isUser
    ? !user?.isUniverseMember
      ? (firstUniverseMember ?? user)
      : user
    : (firstUniverseMember ?? firstNonUniverseMember);

  return { isUser, isRemote, isExclusivelyRemote, remoteUsers, activeUser };
};
