import { MutableRefObject, useEffect, useRef } from 'react';

import { hideTextLayers } from '@/components/HTMLCanvas/PinPanel/PdfViewer/utils/hideTextLayers';

export const useResizePdf = (
  scrollingAreaRef: MutableRefObject<HTMLDivElement | null>
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const resizingRef = useRef(false);
  const scrollRatioRef = useRef(0);

  // Update scroll ratio on scroll
  useEffect(() => {
    const viewer = scrollingAreaRef.current;
    if (!viewer) {
      return;
    }
    const handleScroll = () => {
      const viewer = scrollingAreaRef.current;
      if (viewer && !resizingRef.current) {
        scrollRatioRef.current = viewer.scrollTop / viewer.scrollHeight;
      }
    };

    viewer.addEventListener('scroll', handleScroll);
    return () => {
      viewer.removeEventListener('scroll', handleScroll);
    };
  });

  // Auto-hide on resize
  useEffect(() => {
    const viewer = scrollingAreaRef.current;

    if (!viewer) {
      return;
    }

    let lastWidth = 0;
    let showTextLayers: () => void;

    const observer = new ResizeObserver(() => {
      const scrollRatio = scrollRatioRef.current;

      if (lastWidth === viewer.clientWidth) {
        return;
      }

      lastWidth = viewer.clientWidth;

      if (!resizingRef.current) {
        showTextLayers = hideTextLayers();
        resizingRef.current = true;
      }

      const scrollTo = scrollRatio * viewer.scrollHeight;
      viewer.scrollTo({ top: scrollTo });

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        resizingRef.current = false;
        showTextLayers();
        viewer.dispatchEvent(new Event('resize'));
      }, 200);
    });

    observer.observe(viewer);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      observer.unobserve(viewer);
      showTextLayers?.();
    };
  }, [scrollingAreaRef]);

  return resizingRef;
};
