import { RenderableStickerType } from '@/utils/kosmik/stickers/type';

export const DEFAULT_RESIZABLE_STICKER = {
  image: true,
  video: true,
  audio: false,
  pdf: true,
  file: false,
  sticky: true,
  text: true,
  rectangle: true,
  ellipse: true,
  polygon: true,
  star: true,
  prompt: false,
  web: true,
  webEmbed: true,
} as const satisfies Record<RenderableStickerType, boolean>;
