import { useEffect } from 'react';

import { TextHeader3 } from '@/components/fontSystem/Header3';
import { CreateFileStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateFileSticker/CreateFileStickerButton';
import { CreateStarShapeStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateStarShape/CreateStarShapeStickerButton';
import { CreateStickyStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateStickySticker/CreateStickyStickerButton';
import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';

import styles from './EmptyUniverseView.module.css';

export const EmptyUniverseView = () => {
  const { zoomToFitHtmlContent } = useUniverseContextActions();

  useEffect(() => {
    zoomToFitHtmlContent();
  }, [zoomToFitHtmlContent]);

  return (
    <div data-testid={'empty-universe-view'} className={styles.emptyUniverse}>
      <TextHeader3>This universe is empty</TextHeader3>
      <div className={styles.emptyUniverseDescription}>
        Click <CreateStarShapeStickerButton />
        <CreateStickyStickerButton /> <CreateFileStickerButton /> to create your
        first sticker
      </div>
    </div>
  );
};
