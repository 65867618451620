import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';

import { MenuLabel } from '@/components/ui/MenuPrimitive/MenuLabel';

export const DropdownLabel = React.forwardRef<
  HTMLDivElement,
  DropdownPrimitive.DropdownMenuLabelProps
>(({ children, ...props }, ref) => {
  return (
    <DropdownPrimitive.Label asChild {...props}>
      <MenuLabel ref={ref}>{children}</MenuLabel>
    </DropdownPrimitive.Label>
  );
});

DropdownLabel.displayName = 'DropdownLabel';
