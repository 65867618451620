import { z } from 'zod';

import { Box } from '@/utils/camera/camera';
import { Point } from '@/utils/geometry/point';
import { Rectangle } from '@/utils/geometry/rectangle';
import { getArea, Size } from '@/utils/geometry/size';
import { MaybeUndefined } from '@/utils/types';

export const Dimension = z.intersection(Point, Size);

export type Dimension = z.infer<typeof Dimension>;

/**
 * Converts two points to a rectangle dimension object.
 * @param p1 First point
 * @param p2 Second point
 * @returns Dimension object representing the area between the two points
 */
export const pointsToDimension = (p1: Point, p2: Point): Dimension => {
  const left = Math.min(p1.x, p2.x);
  const right = Math.max(p1.x, p2.x);
  const top = Math.min(p1.y, p2.y);
  const bottom = Math.max(p1.y, p2.y);

  return {
    x: left,
    y: top,
    width: right - left,
    height: bottom - top,
  };
};

/**
 * Converts a dimension object to a rectangle described by its top left and bottom right corners.
 * @param dimension Dimension object
 * @returns Rectangle object
 */
export const dimensionToRectangle = (dimension: Dimension): Rectangle => {
  return {
    x1: dimension.x,
    y1: dimension.y,
    x2: dimension.x + dimension.width,
    y2: dimension.y + dimension.height,
  };
};

/**
 * Converts a dimension object to a box object.
 * @param dimension Dimension object
 * @returns Box object
 */
export const dimensionToBox = (dimension: Dimension): Box => {
  return {
    minX: dimension.x,
    minY: dimension.y,
    maxX: dimension.x + dimension.width,
    maxY: dimension.y + dimension.height,
    width: dimension.width,
    height: dimension.height,
  };
};

/**
 * Checks if two rectangles intersect.
 * @param rectA First rectangle
 * @param rectB Second rectangle
 * @returns True if rectangles intersect, false otherwise
 */
export const isIntersecting = (rectA: Dimension, rectB: Dimension) => {
  return !(
    rectB.x > rectA.x + rectA.width ||
    rectB.x + rectB.width < rectA.x ||
    rectB.y > rectA.y + rectA.height ||
    rectB.y + rectB.height < rectA.y
  );
};

/**
 * Calculates the intersection of two rectangles.
 * @param rectA First rectangle
 * @param rectB Second rectangle
 * @returns Dimension object representing the intersection, or undefined if no intersection
 */
export const getIntersection = (
  rectA: Dimension,
  rectB: Dimension
): undefined | Dimension => {
  if (isIntersecting(rectA, rectB)) {
    const x = Math.max(rectA.x, rectB.x);
    const y = Math.max(rectA.y, rectB.y);
    const x2 = Math.min(rectA.x + rectA.width, rectB.x + rectB.width);
    const y2 = Math.min(rectA.y + rectA.height, rectB.y + rectB.height);
    return {
      x,
      y,
      width: x2 - x,
      height: y2 - y,
    };
  }
  return undefined;
};

/**
 * Get the coordinates of the center of the given rect
 */
export const getCenter = (rect: Dimension): Point => {
  return {
    x: rect.x + rect.width / 2,
    y: rect.y + rect.height / 2,
  };
};

/**
 * Returns the largest rect (by area) in the given set of rects
 */
export const getBiggestRect = <T extends Dimension>(
  rects: T[]
): MaybeUndefined<T> => {
  const [firstBox, ...remainingBoxes] = rects;
  let biggestBox = firstBox;
  let maxArea = biggestBox ? getArea(biggestBox) : 0;
  for (const box of remainingBoxes) {
    const area = box.width * box.height;
    if (area > maxArea) {
      biggestBox = box;
      maxArea = area;
    }
  }
  return biggestBox;
};
