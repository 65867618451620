import { useParams } from '@tanstack/react-router';
import { useCallback } from 'react';

import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { getCurrentCameraValues } from '@/utils/camera/camera';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { createPromptSticker } from '@/utils/sticker/create/createPromptSticker';
import { undoableDuplicateStickers } from '@/utils/sticker/sticker';

export const useCreateDefaultPromptSticker = () => {
  const { universeId } = useParams({ strict: false });
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const { deleteStickers } = useDeleteStickers();

  const handleCreateDefaultPrompt = useCallback(
    async (isShiftKeyPressed = false) => {
      if (!universeId) {
        return;
      }
      const camera = getCurrentCameraValues();
      let createdStickers: KosmikSticker[] = createPromptSticker({
        camera,
        universeId,
        isShiftKeyPressed,
      });
      const createAction: UndoRedoActionType = {
        do: () => {
          createdStickers = undoableDuplicateStickers(
            createdStickers,
            universeId
          );
        },
        undo: () => {
          deleteStickers(createdStickers);
        },
      };
      addUndoRedoAction(createAction);
    },
    [addUndoRedoAction, deleteStickers, universeId]
  );

  return { handleCreateDefaultPrompt };
};
