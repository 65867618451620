import { useParams } from '@tanstack/react-router';
import { useCallback } from 'react';
import { getRecoil } from 'recoil-nexus';

import { localStickerFamily } from '@/atoms/sticker';
import { useAddRemoveTemporaryStickers } from '@/hooks/sticker/useAddRemoveTemporaryStickers';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { useUploadFiles } from '@/hooks/universe/useUploadFiles';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { Position } from '@/utils/geometry/position';
import { KosmikFile } from '@/utils/kosmik/file';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { undoableDuplicateStickers } from '@/utils/sticker/sticker';

export const useUndoableUploadNewFiles = () => {
  const { universeId: maybeUniverseId } = useParams({ strict: false });
  const universeId = maybeUniverseId ?? '';
  const { uploadNewFiles } = useUploadFiles(universeId);
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const { addTemporaryStickers } = useAddRemoveTemporaryStickers(universeId);
  const { deleteStickers } = useDeleteStickers();

  const handleUndoableUploadNewFiles = useCallback(
    (files: FileList, position: Position) => {
      let createdStickers: KosmikSticker[] = uploadNewFiles(files, position);
      let stickerFiles = new Map<string, KosmikFile[]>();
      const createAction: UndoRedoActionType = {
        do: () => {
          createdStickers = createdStickers.map((sticker) => {
            const copy = structuredClone(sticker);
            if (copy.type === 'file') {
              copy.files = stickerFiles.get(sticker.id);
            }
            return copy;
          });
          createdStickers = undoableDuplicateStickers(
            createdStickers,
            universeId
          );
          addTemporaryStickers(createdStickers);
        },
        undo: () => {
          stickerFiles = new Map<string, KosmikFile[]>();
          createdStickers.forEach(({ id }) => {
            const sticker = getRecoil(localStickerFamily(id));
            if (sticker && sticker.type === 'file' && sticker.files) {
              stickerFiles.set(id, sticker.files);
            }
          });
          deleteStickers(createdStickers, true);
        },
      };
      addUndoRedoAction(createAction);
    },
    [
      addTemporaryStickers,
      addUndoRedoAction,
      deleteStickers,
      universeId,
      uploadNewFiles,
    ]
  );

  return { handleUndoableUploadNewFiles };
};
