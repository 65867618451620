import { useNavigate } from '@tanstack/react-router';
import equal from 'fast-deep-equal';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';

import {
  isDrawingSelectionAtom,
  selectedStickerIdsAtom,
  selectionAreaAtom,
} from '@/atoms/selection';
import { localStickerFamily } from '@/atoms/sticker';
import { useStickerSelection } from '@/components/selection/useStickerSelection';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { usePointerPositionRef } from '@/hooks/pointer/usePointerPositionRef';
import { getCurrentCameraValues, screenToCanvas } from '@/utils/camera/camera';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { isIntersecting, pointsToDimension } from '@/utils/geometry/dimension';
import { Point } from '@/utils/geometry/point';
import { KosmikSticker } from '@/utils/kosmik/sticker';

export const useSelectionArea = (
  canvasRef: RefObject<HTMLDivElement | null>
) => {
  const selectionStateStart = useRef<Point | null>();
  const pointerPositionRef = usePointerPositionRef();
  const [selectionArea, setSelectionArea] = useRecoilState(selectionAreaAtom);
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const setIsDrawingSelection = useSetRecoilState(isDrawingSelectionAtom);
  const { select, setNewSelection, unselect } = useStickerSelection();
  const { stickers } = useUniverseContext();
  const navigate = useNavigate();

  const isPointerWithinSelectedBoundingBox = useCallback(() => {
    const selectedStickers = Array.from(selectedStickerIds)
      .map((stickerId) => getRecoil(localStickerFamily(stickerId)))
      .filter(Boolean) as KosmikSticker[];
    const boundingBox = getBoundingBox(selectedStickers);
    return boundingBox
      ? isIntersecting(boundingBox, {
          ...pointerPositionRef.current.camera,
          width: 0,
          height: 0,
        })
      : false;
  }, [pointerPositionRef, selectedStickerIds]);

  const handlePointerDown = useCallback(
    (event: PointerEvent) => {
      const isLeftClick = event.button === 0;
      const isRightClick = event.button === 2;
      const hasNoSelectionArea = selectionArea === null;
      const isCanvasBackgroundTarget = event.target === canvasRef.current;
      const pointerWithinSelection = isPointerWithinSelectedBoundingBox();
      if (
        (isLeftClick || isRightClick) &&
        hasNoSelectionArea &&
        !isHotkeyPressed('space')
      ) {
        // clicking on the background clears selected stickers, unless clicking
        // within the bounding box of the selected stickers
        if (isCanvasBackgroundTarget) {
          navigate({ search: undefined });
          if (!pointerWithinSelection) {
            unselect();
          }
        } else {
          const target = event.target instanceof Element ? event.target : null;
          if (target) {
            const withId = target.closest('[data-id]');
            const id = withId instanceof HTMLElement ? withId.dataset.id : null;
            if (id) {
              const isAlreadySelected = selectedStickerIds.has(id);
              if (event.shiftKey) {
                const newSelection = new Set(selectedStickerIds);
                if (isAlreadySelected) {
                  newSelection.delete(id);
                } else {
                  newSelection.add(id);
                }
                setNewSelection(newSelection);
              } else {
                if (!isAlreadySelected) {
                  select(id);
                }
              }
            }
          }
        }
      }

      if (!isCanvasBackgroundTarget || pointerWithinSelection) {
        return;
      }

      // Heads-up! This can prevent the context menu from working properly when
      // right-clicking on the canvas itself multiple times
      if (isLeftClick) {
        event.stopPropagation();
        (event.currentTarget as HTMLDivElement)?.setPointerCapture(
          event.pointerId
        );
      }

      const point = {
        x: event.clientX,
        y: event.clientY,
      };

      const camera = getCurrentCameraValues();
      selectionStateStart.current = screenToCanvas(point, camera);
      setIsDrawingSelection(true);
    },
    [
      canvasRef,
      isPointerWithinSelectedBoundingBox,
      navigate,
      select,
      selectedStickerIds,
      selectionArea,
      setIsDrawingSelection,
      setNewSelection,
      unselect,
    ]
  );

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      const selection = selectionStateStart.current;
      if (!selection || isHotkeyPressed('space')) {
        // See previous comment about the context menu
        (event.currentTarget as HTMLDivElement)?.releasePointerCapture(
          event.pointerId
        );
        return;
      }
      event.stopPropagation();
      const point = {
        x: event.clientX,
        y: event.clientY,
      };

      const camera = getCurrentCameraValues();
      const point2 = screenToCanvas(point, camera);
      const newSelection = pointsToDimension(selection ?? point, point2);

      // early exit if area is same size
      if (equal(newSelection, selectionArea)) {
        return;
      }

      const newSelections = new Set<string>();
      stickers?.forEach((sticker) => {
        if (isIntersecting(newSelection, sticker)) {
          newSelections.add(sticker.id);
        }
      });

      setNewSelection(newSelections);
      setSelectionArea(newSelection);
    },
    [selectionArea, setNewSelection, setSelectionArea, stickers]
  );

  const handlePointerUp = useCallback(
    (event: PointerEvent) => {
      // See previous comment about the context menu
      (event.currentTarget as HTMLDivElement)?.releasePointerCapture(
        event.pointerId
      );
      selectionStateStart.current = null;
      setSelectionArea(null);
      setIsDrawingSelection(false);
    },
    [setIsDrawingSelection, setSelectionArea]
  );

  useEffect(() => {
    const canvas = canvasRef.current;

    const opt = { passive: true };
    canvas?.addEventListener('pointerdown', handlePointerDown, opt);
    canvas?.addEventListener('pointermove', handlePointerMove, opt);
    canvas?.addEventListener('pointerup', handlePointerUp, opt);
    return () => {
      canvas?.removeEventListener('pointerdown', handlePointerDown);
      canvas?.removeEventListener('pointermove', handlePointerMove);
      canvas?.removeEventListener('pointerup', handlePointerUp);
    };
  }, [canvasRef, handlePointerDown, handlePointerMove, handlePointerUp]);

  return { selectionArea };
};
