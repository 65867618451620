import { id, tx } from '@instantdb/react';

import { batchTransact } from '@/utils/db/db';
import { Dimension } from '@/utils/geometry/dimension';
import { Position } from '@/utils/geometry/position';
import { ExtractData } from '@/utils/kosmik/stickers/extract';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import {
  getCurrentFileLink,
  getTransactableStickerProperties,
} from '@/utils/sticker/sticker';

export type CreateExtractStickerProps = {
  sourceSticker: KosmikFileSticker;
  position: Position;
  size: Dimension;
  extractData: ExtractData;
  universeId: string;
};

export const createExtractSticker = ({
  sourceSticker,
  position,
  size,
  extractData,
  universeId,
}: CreateExtractStickerProps) => {
  const { id: _id, ...newSticker } = sourceSticker;
  const fileLink = getCurrentFileLink(sourceSticker);
  const toBePersistedSticker = getTransactableStickerProperties(newSticker);
  toBePersistedSticker.created_at = new Date().toISOString();
  toBePersistedSticker.width = size.width;
  toBePersistedSticker.height = size.height;
  toBePersistedSticker.x = position.x;
  toBePersistedSticker.y = position.y;
  toBePersistedSticker.v = 0;
  toBePersistedSticker.attributes = {
    ...toBePersistedSticker.attributes,
    extract: extractData,
    source: sourceSticker.id,
  };
  const newId = id();
  const txs = [
    tx.stickers?.[newId]
      ?.update(toBePersistedSticker)
      .link({ universes: [universeId], files: fileLink }),
  ];
  batchTransact(txs);
};
