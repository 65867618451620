import { z } from 'zod';

import { OEmbed } from '@/utils/embed/OEmbed';
import { KosmikStickerBase } from '@/utils/kosmik/stickers/base';
import { StickerType } from '@/utils/kosmik/stickers/type';

export const WebStickerAttributes = z.object({
  url: z.string().url('Must be a url'),
  history: z.array(z.string().url('Must be a url')).optional(),
  image: z.string().url('Must be a url').optional(),
  favicon: z.string().url('Must be a url').optional(),
  description: z.string().optional(),
  title: z.string().optional(),
  sized: z.boolean().optional(),
  embed: OEmbed.optional(),
});

export type WebStickerAttributes = z.infer<typeof WebStickerAttributes>;

export const KosmikWebSticker = z.intersection(
  KosmikStickerBase,
  z.object({
    type: z.literal(StickerType.enum.web),
    attributes: WebStickerAttributes,
  })
);

export type KosmikWebSticker = z.infer<typeof KosmikWebSticker>;
