import { MouseEvent, ReactNode } from 'react';

export type ButtonWithUnderlineProps = {
  children: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  underline?: boolean;
};

export const ButtonLabel = ({
  onClick,
  children,
  underline = false,
}: ButtonWithUnderlineProps) => {
  return (
    <button
      onClick={onClick}
      style={{
        background: 'none',
        border: 'none',
        textDecoration: underline ? 'underline' : 'none',
        cursor: 'pointer',
        color: 'var(--accent-orange-primary)',
      }}
    >
      {children}
    </button>
  );
};
