import { z } from 'zod';

import { AlignmentStyle, FontSizeStyle } from '@/utils/css/types';
import { KosmikStickerBase } from '@/utils/kosmik/stickers/base';
import { StickerType } from '@/utils/kosmik/stickers/type';

export const TextStickerAttributes = z.object({
  text_content: z.string().optional(),
  fill_color: z.string().optional(),
  text_color: z.string().optional(),
  text_alignment: AlignmentStyle.optional(),
  text_size: FontSizeStyle.optional(),
  source: z.string().optional(),
});

export type TextStickerAttributes = z.infer<typeof TextStickerAttributes>;

export const KosmikTextSticker = z.intersection(
  KosmikStickerBase,
  z.object({
    type: z.literal(StickerType.enum.text),
    attributes: TextStickerAttributes,
  })
);

export type KosmikTextSticker = z.infer<typeof KosmikTextSticker>;
