import { id, tx } from '@instantdb/react';

import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { Camera, getCenterPoint } from '@/utils/camera/camera';
import { batchTransact } from '@/utils/db/db';
import { KosmikStickySticker } from '@/utils/kosmik/stickers/sticky';

export type CreateDefaultStickyStickerProps = {
  universeId: string;
  camera: Camera;
  isShiftKeyPressed: boolean;
};

export const createStickySticker = ({
  camera,
  universeId,
  isShiftKeyPressed,
}: CreateDefaultStickyStickerProps): KosmikStickySticker[] => {
  if (!universeId) {
    return [];
  }
  const txs = [];
  const size = DEFAULT_STICKER_SIZE.sticky;
  const created_at = new Date().toISOString();
  const amount = isShiftKeyPressed ? 100 : 1;
  const stickers: KosmikStickySticker[] = [];

  for (let i = 0; i < amount; i++) {
    const stickerId = id();

    const centerOfScreen = getCenterPoint(camera);
    const position = {
      x: centerOfScreen.x - size.width / 2,
      y: centerOfScreen.y - size.height / 2,
    };
    const point = {
      x: position.x + (50 + size.width) * (i % 10),
      y: position.y + (50 + size.height) * (Math.floor(i / 10) % 10),
    };
    const sticker: Omit<KosmikStickySticker, 'id'> = {
      v: 0,
      created_at: created_at,
      type: 'sticky',
      x: point.x,
      y: point.y,
      width: size.width,
      height: size.height,
      resizeable: true,
      attributes: {
        text_content: '',
      },
    };

    txs.push(
      tx.stickers?.[stickerId]
        ?.update(sticker)
        .link({ universes: [universeId] })
    );
    stickers.push({ id: stickerId, ...sticker });
  }

  batchTransact(txs);
  return stickers;
};
