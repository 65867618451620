import './index.css';

import * as Sentry from '@sentry/react';
import Quill, { Parchment } from 'quill';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from '@/App';
import { MOTD } from '@/utils/MOTD';

MOTD();

if (window.electron) {
  window.electron.ipcRenderer.on('auth-code', (_, args) => {
    window.dispatchEvent(new CustomEvent('auth-code', { detail: args }));
  });

  window.electron.ipcRenderer.on('pns-on', () => {
    window.dispatchEvent(new Event('pns-on'));
  });

  window.electron.ipcRenderer.on('pns-off', () => {
    window.dispatchEvent(new Event('pns-off'));
  });

  window.electron.ipcRenderer.on('bookmark', () => {
    window.dispatchEvent(new Event('bookmark'));
  });
}

Sentry.init({
  dsn: 'https://0be2b2c65e01347f8291f96f00fc7eba@sentry.lithium.paris/2',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const size = Quill.import(
  'attributors/style/size'
) as Parchment.StyleAttributor;
// put whitelist to undefined allow the use of any size
size.whitelist = undefined;
Quill.register(size, true);

const root = document.getElementById('root');
if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  throw new Error('No react root found');
}
