import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { selectedStickerIdsAtom } from '@/atoms/selection';
import { temporaryStickersFamily } from '@/atoms/sticker';

/**
 * We use a ref to access both the selected and temporary stickers because
 * the state isn't up-to-date when running callbacks.
 */
export const useSelectedAndTemporaryStickerIdsRef = (universeId: string) => {
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const temporaryStickers = useRecoilValue(temporaryStickersFamily(universeId));
  const selectedStickerIdsRef = useRef(selectedStickerIds);
  const temporaryStickerIdsRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    selectedStickerIdsRef.current = selectedStickerIds;
  }, [selectedStickerIds]);

  useEffect(() => {
    temporaryStickerIdsRef.current = new Set(
      temporaryStickers.map((sticker) => sticker.id)
    );
  }, [temporaryStickers]);

  return { selectedStickerIdsRef, temporaryStickerIdsRef };
};
