import React, { ComponentPropsWithoutRef, ForwardedRef, JSX } from 'react';

import styles from './Text.module.css';

export type TextVariant =
  | 'header-1'
  | 'header-2'
  | 'header-3'
  | 'section'
  | 'body-semibold'
  | 'body-regular'
  | 'label-regular'
  | 'label-medium'
  | 'tag';

export type TextProps = ComponentPropsWithoutRef<
  'span' | 'h1' | 'h2' | 'h3'
> & {
  variant: TextVariant;
};

export const Text = React.forwardRef<
  HTMLSpanElement | HTMLHeadingElement,
  TextProps
>(({ variant, className, ...props }, ref) => {
  let Component: keyof JSX.IntrinsicElements = 'span';

  switch (variant) {
    case 'header-1':
      Component = 'h1';
      break;
    case 'header-2':
      Component = 'h2';
      break;
    case 'header-3':
      Component = 'h3';
      break;
  }

  return Component === 'span' ? (
    <Component
      ref={ref as ForwardedRef<HTMLSpanElement>}
      data-variant={variant}
      className={`${styles.text} ${className}`}
      {...props}
    />
  ) : (
    <Component
      ref={ref as ForwardedRef<HTMLHeadingElement>}
      data-variant={variant}
      className={`${styles.text} ${className}`}
      {...props}
    />
  );
});

Text.displayName = 'Text';
