import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { useLayoutEffect, useRef, useState } from 'react';

import { DialogContent } from '@/components/ui/Dialog/DialogContent';
import { DialogOverlay } from '@/components/ui/Dialog/DialogOverlay';

import styles from './Dialog.module.css';

type BaseDialogProps = DialogPrimitive.DialogProps &
  React.PropsWithChildren<{
    noOverlay?: boolean;
    contentProps?: DialogPrimitive.DialogContentProps;
  }>;

type DialogWithTriggerProps = BaseDialogProps & {
  trigger: React.ReactNode;
  triggerAsChild?: boolean;
};

type DialogWithoutTriggerProps = BaseDialogProps & {
  trigger?: never;
  triggerAsChild?: never;
};

export type DialogProps = DialogWithTriggerProps | DialogWithoutTriggerProps;

export const Dialog = ({
  children,
  open,
  onOpenChange,
  trigger,
  triggerAsChild,
  contentProps,
  noOverlay,
  ...rootProps
}: DialogProps) => {
  const [forceMount, setForceMount] = useState(false);
  const lastOpenRef = useRef(open);

  useLayoutEffect(() => {
    if (open !== lastOpenRef.current) {
      if (!open) {
        setForceMount(true);
      }
    }
    lastOpenRef.current = open;
  }, [open]);

  const handleAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    contentProps?.onAnimationEnd?.(event);
    setForceMount(false);
  };

  const internalOnOpenChange = (newOpen: boolean) => {
    if (!open) {
      setForceMount(true);
    }
    onOpenChange?.(newOpen);
  };

  return (
    <DialogPrimitive.Root
      open={open}
      onOpenChange={internalOnOpenChange}
      {...rootProps}
    >
      {trigger ? (
        <DialogPrimitive.Trigger asChild={triggerAsChild}>
          {trigger}
        </DialogPrimitive.Trigger>
      ) : null}
      <DialogPrimitive.Portal forceMount={forceMount || undefined}>
        <div className={styles.dialogContainer}>
          {!noOverlay ? <DialogOverlay /> : null}
          <DialogContent {...contentProps} onAnimationEnd={handleAnimationEnd}>
            {children}
          </DialogContent>
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export const DialogTitle = DialogPrimitive.Title;

export const DialogDescription = DialogPrimitive.Description;

export const DialogClose = DialogPrimitive.Close;
