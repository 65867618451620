import { MultiplayerUserPresence } from '@/components/selection/MultiplayerUserPresence';
import { useRoom } from '@/context/Room/useRoom';

export const MultiplayerUsers = () => {
  const { room } = useRoom();
  const { peers } = room.usePresence({
    keys: [],
    user: false,
  });

  return Object.keys(peers).map((id) => {
    return <MultiplayerUserPresence key={id} peerId={id} />;
  });
};
