import { ComponentPropsWithoutRef } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { Keys } from 'react-hotkeys-hook';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { isReadonlyArray } from '@/utils/array/array';

import styles from './Shortcut.module.css';

export type ShortcutProps = ComponentPropsWithoutRef<
  typeof TextLabelRegular
> & {
  keys: Keys;
};

export const Shortcut = ({ keys, className, ...props }: ShortcutProps) => {
  const firstShortcut = isReadonlyArray(keys)
    ? keys[0]
    : keys.split(',')[0]?.trim();
  const splitList = firstShortcut?.split('+');
  const list = splitList?.map((item) => item.trim().toLowerCase()) ?? [];

  let bind: Record<string, string> = {
    mod: 'ctrl',
    shift: '⇧',
    backspace: '⌫',
  };

  if (isMacOs || isIOS) {
    bind = {
      mod: '⌘',
      ctrl: '⌃',
      shift: '⇧',
      alt: '⌥',
      backspace: '⌫',
      space: '␣',
    };
  }

  const mapped = list.map((item, i) => {
    const bound = bind[item];
    return bound ? (
      // eslint-disable-next-line react/no-array-index-key
      <small key={i} className={styles.specialKey}>
        {bound}
      </small>
    ) : (
      // eslint-disable-next-line react/no-array-index-key
      <span key={i} className={styles.normalKey}>
        {item}
      </span>
    );
  });

  return (
    <TextLabelRegular
      className={`${styles.shortcutContainer} ${className ?? ''} `}
      {...props}
    >
      {mapped}
    </TextLabelRegular>
  );
};
