import { Camera } from '@/utils/camera/camera';
import { Size } from '@/utils/geometry/size';

/**
 * Return the size of the visible universe.
 * @param canvasSize
 * @param camera
 */
export const getVisibleUniverseSize = (canvasSize: Size, camera: Camera) => {
  return {
    width: canvasSize.width / camera.z,
    height: canvasSize.height / camera.z,
  };
};
