import { Button } from '@lithium/spectrum';
import * as Form from '@radix-ui/react-form';
import React, { Dispatch, FormEvent, SetStateAction, useState } from 'react';

import { TextHeader2 } from '@/components/fontSystem/Header2';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { TextTag } from '@/components/fontSystem/TextTag';
import { GoogleLoginButton } from '@/components/Onboarding/GoogleLoginButton';
import { LoginStepType } from '@/components/Onboarding/Login';
import { OfflineMessage } from '@/components/Onboarding/OfflineMessage';
import formFieldStyles from '@/components/ui/Form/FormField.module.css';
import { db } from '@/db/databaseInit';
import { useOnlineStatus } from '@/hooks/online/useOnlineStatus';
import { EmailFormField } from '@/ui/Form/EmailFormField';

import styles from './Onboarding.module.css';

export const Email = ({
  setSentEmail,
  setStep,
}: {
  setSentEmail: React.Dispatch<React.SetStateAction<string>>;
  setStep: Dispatch<SetStateAction<LoginStepType>>;
}) => {
  const online = useOnlineStatus();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    if (online) {
      db.auth
        .sendMagicCode({ email })
        .then(() => {
          setSentEmail(email);
          setStep('magic-code');
        })
        .catch((err) => {
          setError('Error: ' + err.body?.message);
          setSentEmail('');
        });
    }
  };

  return (
    <div className={styles.formContainer}>
      <Form.Root onSubmit={handleSubmit} className={styles.form}>
        <TextHeader2>Sign-up and log-in</TextHeader2>
        <TextTag>
          Welcome! Enter your email to get started. We&apos;ll set up an account
          if you&apos;re new here!
        </TextTag>
        <EmailFormField
          showLabel
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {error ? (
          <div className={formFieldStyles.message}>
            <TextLabelRegular>{error}</TextLabelRegular>
          </div>
        ) : null}
        <OfflineMessage />
        <Button variant={'positive'} disabled={!online}>
          Sign in
        </Button>
      </Form.Root>
      <GoogleLoginButton />
    </div>
  );
};
