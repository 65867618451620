import { SelectionArea } from '@/components/selection/SelectionArea';
import { useMultiplayerPinnedSticker } from '@/components/selection/useMultiplayerPinnedSticker';
import { useMultiplayerStickerSelection } from '@/components/selection/useMultiplayerStickerSelection';
import { useRoom } from '@/context/Room/useRoom';

interface MultiplayerUserPresenceProps {
  peerId: string;
}

export const MultiplayerUserPresence = ({
  peerId,
}: MultiplayerUserPresenceProps) => {
  const { room } = useRoom();
  useMultiplayerStickerSelection({ peerId, room });
  useMultiplayerPinnedSticker({ peerId, room });
  const { peers, isLoading } = room.usePresence({
    keys: ['user', 'selectionArea'],
    peers: [peerId],
    user: false,
  });

  if (isLoading) {
    return null;
  }

  const peer = Object.values(peers)?.[0];

  return peer?.user ? (
    <SelectionArea color={peer?.user.color} area={peer?.selectionArea} />
  ) : null;
};
