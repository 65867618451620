import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import LogoKosmik from '@/assets/kosmik/kosmik-instant-beta.svg?react';
import { TextBodyRegular } from '@/components/fontSystem/BodyRegular';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import aboutInfoStyle from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/AboutInfo/AboutInfo.module.css';
import { DialogTitle } from '@/ui/Dialog/Dialog';
import { DialogSection } from '@/ui/Dialog/DialogSection';
import { getAppVersion } from '@/utils/getAppVersion';

export const AboutInfo = () => {
  return (
    <div className={styles.tabsContentContainer}>
      <VisuallyHidden>
        <DialogTitle>About Kosmik</DialogTitle>
      </VisuallyHidden>
      <DialogSection>
        <div className={aboutInfoStyle.container}>
          <LogoKosmik className={aboutInfoStyle.kosmikLogo} />
          <div className={styles.settingsPageFieldRow}>
            <TextBodyRegular>Kosmik</TextBodyRegular>
            <TextBodyRegular className={aboutInfoStyle.textInfo}>
              v{getAppVersion()}
            </TextBodyRegular>
          </div>
        </div>
      </DialogSection>
    </div>
  );
};
