import { Fragment, ReactNode, useCallback, useState } from 'react';

import styles from '@/components/AvatarRow.module.css';
import { TextTag } from '@/components/fontSystem/TextTag';

interface AvatarRowProps<T> {
  peers: [string, T][];
  avatar: (value: [string, T]) => ReactNode;
  limit?: number;
}

export function AvatarRow<Type>({
  peers,
  avatar,
  limit = 3,
}: AvatarRowProps<Type>) {
  const [showOthers, setShowOthers] = useState(false);

  const handleClick = useCallback(() => {
    setShowOthers((showOthers) => !showOthers);
  }, []);

  const users = [...peers];
  const limitedPeers = users.slice(0, limit);
  const others = users.slice(limit);

  return (
    <div className={styles.row}>
      {limitedPeers.length > 0 && (
        <div className={styles.instantAvatarStack} onClick={handleClick}>
          {limitedPeers.map((peer) => {
            return <Fragment key={peer[0]}>{avatar(peer)}</Fragment>;
          })}

          {others.length > 0 &&
            showOthers &&
            others.map((peer) => {
              return <Fragment key={peer[0]}>{avatar(peer)}</Fragment>;
            })}
        </div>
      )}

      {others.length > 0 ? (
        <TextTag onClick={handleClick}>
          {!showOthers ? `+${others.length}` : null}
        </TextTag>
      ) : null}
    </div>
  );
}
