import debounce from 'debounce';
import { CSSProperties, ReactElement, useMemo, useRef } from 'react';

import RoundCheck from '@/assets/themesModeAssets/round-check.svg?react';
import styles from '@/components/EditDock/components/EditDockColorItem.module.css';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useColorInputOpener } from '@/components/EditDock/hooks/useColorInputOpener';
import { wrapCSSVar } from '@/utils/css/variable';
import { AnyStringWithAutocomplete } from '@/utils/types';

export interface EditDockColorItemProps {
  currentColor: string;
  onColorChange: SetColorFunction;
  onColorCommit: SetColorFunction;
  notSelectable?: boolean;
  stroke?: boolean;
  withCheck?: boolean;
  color?: AnyStringWithAutocomplete<'auto'>;
}

export type SetColorFunction = (color: string) => void;

export const EditDockColorItem = ({
  color,
  onColorChange,
  onColorCommit,
  currentColor,
  stroke = false,
  withCheck = false,
}: EditDockColorItemProps): ReactElement => {
  const debouncedOnColorChange = useMemo(
    () =>
      debounce(onColorChange, 100, {
        immediate: true,
      }),
    [onColorChange]
  );
  const debouncedOnColorCommit = useMemo(
    () => debounce(onColorCommit, 250),
    [onColorCommit]
  );
  const buttonRef = useRef<HTMLButtonElement>(null);
  const colorInputRef = useRef<HTMLInputElement>(null);
  const formattedColor = useMemo(() => color?.toLowerCase(), [color]);
  const formattedCurrentColor = useMemo(
    () => currentColor?.toLowerCase(),
    [currentColor]
  );
  const isCurrentColor = formattedCurrentColor === formattedColor;

  useColorInputOpener(buttonRef, colorInputRef);

  const handleSingleColorClick = () => {
    if (formattedColor) {
      debouncedOnColorChange.clear();
      debouncedOnColorCommit.clear();
      debouncedOnColorChange(formattedColor);
      debouncedOnColorCommit(formattedColor);
    }
  };

  const colorOrCurrentColor = formattedColor || formattedCurrentColor;

  return (
    <EditDockItem
      ref={buttonRef}
      onClick={handleSingleColorClick}
      active={!withCheck ? isCurrentColor : false}
      data-stroke-color-picker={stroke ? '' : undefined}
      data-color-wheel={color === 'auto' ? '' : undefined}
      className={styles.editDockItem}
      data-testid={'editdock-colorswatch'}
      style={
        {
          ['--current-color']: colorOrCurrentColor
            ? wrapCSSVar(colorOrCurrentColor)
            : undefined,
        } as CSSProperties
      }
    >
      <div className={styles.stack}>
        <div className={styles.colorCircle} />
        <div className={styles.colorBorder} />
        {withCheck && isCurrentColor ? (
          <RoundCheck className={styles.check} />
        ) : null}
      </div>
    </EditDockItem>
  );
};
