import { Component, ReactNode } from 'react';

import { StickerErrorBoundaryUI } from '@/components/stickerErrorBoundary/StickerErrorBoundaryUI';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeNull } from '@/utils/types';

interface StickerErrorBoundaryProps {
  children?: ReactNode;
  sticker: KosmikSticker;
}

interface StickerErrorBoundaryState {
  hasError: MaybeNull<Error>;
}

export class StickerErrorBoundary extends Component<
  StickerErrorBoundaryProps,
  StickerErrorBoundaryState
> {
  public state: StickerErrorBoundaryState = {
    hasError: null,
  };

  public static getDerivedStateFromError(
    error: Error
  ): StickerErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <StickerErrorBoundaryUI
          error={this.state.hasError}
          sticker={this.props.sticker}
        />
      );
    }

    return this.props.children;
  }
}
