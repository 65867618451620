import { Button, ButtonShortcut } from '@lithium/spectrum';

import { DialogClose } from '@/ui/Dialog/Dialog';
import { DialogFooter } from '@/ui/Dialog/DialogFooter';

export const UserSettingsFooter = () => {
  return (
    <DialogFooter>
      <DialogClose asChild>
        <Button variant={'default'} shortcut={ButtonShortcut.Esc}>
          Close
        </Button>
      </DialogClose>
    </DialogFooter>
  );
};
