import { ReactElement, ReactNode } from 'react';

import { Dropdown, DropdownProps } from '@/ui/Dropdown/Dropdown';
import { DropdownRadioGroup } from '@/ui/Dropdown/DropdownRadioGroup';
import { DropdownRadioItem } from '@/ui/Dropdown/DropdownRadioItem';

interface DropdownSelectOption<Value extends string> {
  label: ReactNode;
  value: Value;
}

interface DropdownSelectProps<Value extends string> extends DropdownProps {
  options: DropdownSelectOption<Value>[];
  value?: NoInfer<Value>;
  onValueChange: (value: Value) => void;
}

export const DropdownSelect = <Value extends string>({
  trigger,
  contentProps,
  options,
  value,
  onValueChange,
  ...props
}: DropdownSelectProps<Value>): ReactElement => {
  const uniqueOptions = new Set(options.map((option) => option.value));
  if (uniqueOptions.size !== options.length) {
    console.warn('Each option of a DropdownSelect must have a unique value');
  }

  return (
    <Dropdown
      modal={false}
      contentProps={{
        style: { minWidth: 10 },
        align: 'center',
        sideOffset: 5,
        side: 'top',
        avoidCollisions: true,
        ...contentProps,
      }}
      trigger={trigger}
      {...props}
    >
      <DropdownRadioGroup
        value={value}
        onValueChange={onValueChange as (value: string) => void}
      >
        {options.map((element) => {
          return (
            <DropdownRadioItem
              value={element.value}
              key={element.value}
              onMouseOver={(event) => {
                event.currentTarget
                  .closest('[role=menu]')
                  ?.querySelectorAll('[data-current]')
                  .forEach((element) => {
                    element.removeAttribute('data-current');
                  });
              }}
              {...{
                'data-current': element.value === value ? '' : undefined,
              }}
            >
              {element.label}
            </DropdownRadioItem>
          );
        })}
      </DropdownRadioGroup>
    </Dropdown>
  );
};
