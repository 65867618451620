import { ComponentProps } from 'react';

import { TextHeader2 } from '@/components/fontSystem/Header2';
import { DialogTitle } from '@/ui/Dialog/Dialog';

type SettingsPageTitleProps = ComponentProps<typeof DialogTitle>;

export const SettingsPageTitle = ({
  children,
  ...props
}: SettingsPageTitleProps) => {
  return (
    <DialogTitle {...props} asChild>
      <TextHeader2>{children}</TextHeader2>
    </DialogTitle>
  );
};
