import styles from './PdfContentTable.module.css';

export type TableOfContentEntry = { title: string; pageNumber: number };

interface PdfContentTableProps {
  table: TableOfContentEntry[];
  setCurrentPage: (p: number) => void;
}

export const PdfContentTable = ({
  table,
  setCurrentPage,
}: PdfContentTableProps) => {
  return (
    <div className={styles.contentTableWrapper}>
      {table.map((entry) => (
        <div
          className={styles.indexPageContainer}
          key={entry.pageNumber}
          onClick={() => setCurrentPage(entry.pageNumber)}
        >
          &#x2022; <span>{entry.title}</span>
        </div>
      ))}
    </div>
  );
};
