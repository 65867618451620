import { z } from 'zod';

import { LineStyle } from '@/utils/css/types';
import { KosmikStickerBase } from '@/utils/kosmik/stickers/base';
import { StickerType } from '@/utils/kosmik/stickers/type';

export const Shape = z.enum(['star', 'polygon', 'rectangle', 'ellipse']);

export type Shape = z.infer<typeof Shape>;

export const shapeStickerBaseAttributes = {
  fill_color: z.string(),
  stroke_color: z.string(),
  stroke_width: z.number(),
  stroke_style: LineStyle.optional(),
};

const ShapeStickerBaseAttributes = z.object(shapeStickerBaseAttributes);

export type ShapeStickerBaseAttributes = z.infer<
  typeof ShapeStickerBaseAttributes
>;

export const StarShapeAttributes = z.object({
  ...shapeStickerBaseAttributes,
  shape: z.literal(Shape.enum.star),
  num_points: z
    .number()
    .min(3, 'A star cannot have less than 3 points')
    .max(16, 'A star cannot have more than 16 points'),
  inner_radius_ratio: z
    .number()
    .min(0, 'The minimum inner radius ratio for a star is 0')
    .max(1, 'The maximum inner radius ratio for a star is 1'),
  corner_radius_ratio: z
    .number()
    .min(0, 'The corner radius ratio for a star cannot be lower than 0')
    .max(1, 'The corner radius ratio for a star cannot be more than 1'),
});

export type StarShapeAttributes = z.infer<typeof StarShapeAttributes>;

export const PolygonShapeAttributes = z.object({
  ...shapeStickerBaseAttributes,
  shape: z.literal(Shape.enum.polygon),
  num_points: z
    .number()
    .min(3, 'A polygon cannot have less than 3 points')
    .max(16, 'A polygon cannot have more than 16 points'),
  corner_radius_ratio: z
    .number()
    .min(0, 'The corner radius ratio for a polygon cannot be lower than 0')
    .max(1, 'The corner radius ratio for a polygon cannot be more than 1'),
});

export type PolygonShapeAttributes = z.infer<typeof PolygonShapeAttributes>;

export const RectangleShapeAttributes = z.object({
  ...shapeStickerBaseAttributes,
  shape: z.literal(Shape.enum.rectangle),
  corner_radius_ratio: z
    .number()
    .min(0, 'The corner radius ratio for a rectangle cannot be lower than 0')
    .max(1, 'The corner radius ratio for a rectangle cannot be more than 1'),
});

export type RectangleShapeAttributes = z.infer<typeof RectangleShapeAttributes>;

export const EllipseShapeAttributes = z.object({
  ...shapeStickerBaseAttributes,
  shape: z.literal(Shape.enum.ellipse),
});

export type EllipseShapeAttributes = z.infer<typeof EllipseShapeAttributes>;

export const KosmikShapeSticker = z.intersection(
  KosmikStickerBase,
  z.object({
    type: z.literal(StickerType.enum.shape),
    attributes: z.discriminatedUnion('shape', [
      StarShapeAttributes,
      PolygonShapeAttributes,
      RectangleShapeAttributes,
      EllipseShapeAttributes,
    ]),
  })
);

export type KosmikShapeSticker = z.infer<typeof KosmikShapeSticker>;
