import { forwardRef, PropsWithChildren } from 'react';

import { TextTag } from '@/components/fontSystem/TextTag';

import styles from './MenuLabel.module.css';

export const MenuLabel = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => {
    return (
      <div ref={ref} className={styles.menuLabel}>
        <TextTag className={styles.subtitle}>{children}</TextTag>
      </div>
    );
  }
);

MenuLabel.displayName = 'MenuLabel';
