import { Button, ButtonShortcut } from '@lithium/spectrum';
import * as Form from '@radix-ui/react-form';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import formFieldStyles from '@/components/ui/Form/FormField.module.css';
import { useTeam } from '@/context/Team/useTeam';
import { db } from '@/db/databaseInit';
import { getAllTeamUsers, inviteToTeam } from '@/utils/team/team';

export const InviteTeamMemberForm = () => {
  const { team } = useTeam();
  const [emailToInvite, setEmailToInvite] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const { data } = db.useQuery({
    users: {},
  });
  const allUsers = data?.users;
  const allTeamUsers = getAllTeamUsers(team);
  const allUsersNotInTeam = allUsers?.filter(
    (user) => !allTeamUsers.some((teamUser) => teamUser.id === user.id)
  );

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmailToInvite(event.target.value);
    setError(null);
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const invitableUser = allUsersNotInTeam?.find(
        (user) => user.email === emailToInvite
      );
      if (invitableUser) {
        inviteToTeam(team, invitableUser.id);
        setEmailToInvite('');
      } else {
        const isAlreadyInTeam = allTeamUsers.some(
          (user) => user.email === emailToInvite
        );
        setError(
          isAlreadyInTeam
            ? 'This user is already a member of this workspace'
            : 'User not found'
        );
      }
    },
    [allTeamUsers, allUsersNotInTeam, emailToInvite, team]
  );

  return (
    <Form.Root onSubmit={handleSubmit}>
      <Form.Field name={'email'} asChild>
        <>
          {error ? (
            <Form.Message className={formFieldStyles.message} asChild>
              <TextLabelRegular
                style={{ display: 'inline-block', marginBottom: 8 }}
              >
                {error}
              </TextLabelRegular>
            </Form.Message>
          ) : (
            <Form.Label asChild>
              <TextLabelRegular
                style={{ display: 'inline-block', marginBottom: 8 }}
              >
                Type in the email of the user to invite
              </TextLabelRegular>
            </Form.Label>
          )}
          <div className={styles.rowInput}>
            <Form.Control asChild>
              <input
                name={'email'}
                list={'users'}
                value={emailToInvite}
                placeholder={'Add or invite people...'}
                onChange={handleChange}
                autoComplete={'off'}
                style={{ height: 29 }}
              />
            </Form.Control>
            <datalist id={'users'}>
              {allUsersNotInTeam?.map((user) => (
                <option key={user.id} value={user.email} />
              ))}
            </datalist>
            <Button variant={'positive'} shortcut={ButtonShortcut.Enter}>
              Invite
            </Button>
          </div>
        </>
      </Form.Field>
    </Form.Root>
  );
};
