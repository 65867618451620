import { useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { DEFAULT_RESIZABLE_STICKER } from '@/constants/resizable';
import { useSetStickerProperties } from '@/hooks/sticker/useSetStickerProperties';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { getRenderableFileType } from '@/utils/sticker/file';

export const useSetDefaultFileResizable = (
  sticker: KosmikFileSticker,
  skip?: boolean
) => {
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerProperties =
    useSetStickerProperties<KosmikFileSticker>(setSticker);
  const { resizeable } = sticker;
  const { type } = sticker.files?.[0] ?? sticker.attributes;

  useLayoutEffect(() => {
    if (!skip) {
      const renderableFileType = type ? getRenderableFileType(type) : 'file';
      const defaultResizable = DEFAULT_RESIZABLE_STICKER[renderableFileType];
      if (resizeable !== defaultResizable) {
        setStickerProperties({ resizeable: defaultResizable }, true);
      }
    }
  }, [resizeable, setStickerProperties, skip, type]);
};
