/**
 * As we need more types, we can look into a package like csstype
 */
import { z } from 'zod';

export const LineStyle = z.enum([
  'dashed',
  'dotted',
  'double',
  'groove',
  'hidden',
  'inset',
  'none',
  'outset',
  'ridge',
  'solid',
]);

export type LineStyle = z.infer<typeof LineStyle>;

export const AlignmentStyle = z.enum(['left', 'right', 'center', 'justify']);

export type AlignmentStyle = z.infer<typeof AlignmentStyle>;

export const FontSizeStyle = z.enum([
  'small',
  'medium',
  'large',
  'extraLarge',
  'huge',
]);

export type FontSizeStyle = z.infer<typeof FontSizeStyle>;

export const fontSizeValues: Record<FontSizeStyle, string> = {
  small: '16px',
  medium: '28px',
  large: '40px',
  extraLarge: '64px',
  huge: '188px',
} as const;

export const reverseFontSizeValues = Object.fromEntries(
  Object.entries(fontSizeValues).map(([key, value]) => [value, key])
) as Record<string, FontSizeStyle>;
