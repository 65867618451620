/**
 * Returns the visible dimensions of the main ui elements
 */
export const getUiVisibleDimensions = () => {
  const toolbar = document.getElementById('toolbar');
  const sidePanel = document.getElementById('side-panel');
  const toolbarHeight = toolbar?.offsetHeight ?? 0;
  const sidePanelState = sidePanel?.dataset.state ?? 'closed';
  const sidePanelWidth =
    sidePanelState === 'open' ? (sidePanel?.offsetWidth ?? 0) : 0;
  return {
    canvas: {
      width: window.innerWidth - sidePanelWidth,
      height: window.innerHeight - toolbarHeight,
    },
    sidePanelWidth,
    toolbarHeight,
    toolbar,
    sidePanel,
  };
};
