import { createContext } from 'react';

import { KosmikUser } from '@/utils/kosmik/user';
import { MaybeUndefined } from '@/utils/types';

export interface MultiplayerUser
  extends Pick<KosmikUser, 'id' | 'email' | 'name'> {
  color: string;
  contrastColor: string;
  peerId: string;
  anonymous?: boolean;
  isUniverseMember?: boolean;
}

export const MultiplayerUserContext =
  createContext<MaybeUndefined<MultiplayerUser>>(undefined);
