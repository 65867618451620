import { useEffect, useLayoutEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRecoilValue } from 'recoil';

import {
  isPinPanelFullscreenAtom,
  isPinPanelOpenedAtom,
  pinnedStickerIdAtom,
} from '@/atoms/pinpanel';
import { localStickerFamily } from '@/atoms/sticker';
import { EditDock } from '@/components/EditDock/EditDock';
import { PinPanelContent } from '@/components/HTMLCanvas/PinPanel/PinPanelContent';
import { PinPanelContext } from '@/context/PinPanel/PinPanelContext';
import { useClosePinPanel } from '@/hooks/pinPanel/useClosePinPanel';
import { useSetPinnedSticker } from '@/hooks/pinPanel/useSetPinnedSticker';

import styles from './PinPanel.module.css';

export const PinPanel = () => {
  const isPinPanelOpened = useRecoilValue(isPinPanelOpenedAtom);
  const isPinPanelFullScreen = useRecoilValue(isPinPanelFullscreenAtom);
  const pinnedStickerId = useRecoilValue(pinnedStickerIdAtom);
  const { resetPinnedSticker } = useSetPinnedSticker();
  const localSticker = useRecoilValue(
    localStickerFamily(pinnedStickerId ?? '')
  );
  const pinPanelWrapperRef = useRef<HTMLDivElement>(null);
  const { handleClosePinPanel } = useClosePinPanel();

  useHotkeys(['Escape'], () => {
    if (isPinPanelOpened && pinnedStickerId) {
      handleClosePinPanel();
    }
  });

  useEffect(() => {
    const pinPanelWrapper = pinPanelWrapperRef.current;
    if (!pinPanelWrapper) {
      return;
    }
    if (isPinPanelOpened) {
      setTimeout(() => (pinPanelWrapper.style.transform = 'translateX(0)'));
    } else {
      pinPanelWrapper.style.transform = 'translateX(-100%)';
    }
  }, [isPinPanelOpened]);

  // when a new sticker is pinned, we want to refocus the pin panel
  useLayoutEffect(() => {
    if (localSticker?.id) {
      pinPanelWrapperRef.current?.focus();
    }
  }, [localSticker?.id]);

  if (!localSticker) {
    return null;
  }

  return (
    <PinPanelContext.Provider value={true}>
      <div
        className={styles.pinPanelWrapper}
        ref={pinPanelWrapperRef}
        style={{ width: isPinPanelFullScreen ? '100%' : 'fit-content' }}
        onTransitionEnd={(event) => {
          if (event.target !== event.currentTarget) {
            return;
          }
          if (!isPinPanelOpened) {
            resetPinnedSticker();
          }
        }}
        tabIndex={-1}
      >
        <EditDock sticker={localSticker} />
        <PinPanelContent sticker={localSticker} />
      </div>
    </PinPanelContext.Provider>
  );
};
