import { PDFPageProxy } from 'pdfjs-dist';
import { useMemo, useRef } from 'react';

import { useRenderPreviewPageWhenVisible } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useRenderPreviewPageWhenVisible';
import { useSelectedPreviewPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useSelectedPreviewPage';
import { PdfPreviewPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPreviewPage';
import { useIterableRefs } from '@/hooks/useIterableRefs';
import { ScrollArea } from '@/ui/ScrollArea/ScrollArea';

interface PdfPreviewProps {
  pageProxies: PDFPageProxy[];
  currentPage: number;
  setCurrentPage: (p: number) => void;
}

export const PdfPreview = ({
  pageProxies,
  currentPage,
  setCurrentPage,
}: PdfPreviewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { refs: pageRefs, getRef: getPageRef } =
    useIterableRefs<HTMLButtonElement>();

  useSelectedPreviewPage(ref, currentPage);
  useRenderPreviewPageWhenVisible(ref, pageRefs, pageProxies);

  return useMemo(
    () => (
      <ScrollArea ref={ref} type={'hover'}>
        {pageProxies.map((pageProxy) => {
          return (
            <PdfPreviewPage
              key={pageProxy.pageNumber}
              ref={getPageRef(pageProxy.pageNumber)}
              pageProxy={pageProxy}
              onClick={() => setCurrentPage(pageProxy.pageNumber)}
            />
          );
        })}
      </ScrollArea>
    ),
    [getPageRef, pageProxies, setCurrentPage]
  );
};
