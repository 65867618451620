import { Button, Close } from '@lithium/spectrum';
import { ReactNode, useCallback } from 'react';

import { TextLabelMedium } from '@/components/fontSystem/LabelMedium';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useNotifications } from '@/context/Notifications/useNotifications';
import styles from '@/ui/Notifications/NotificationBase.module.css';

interface NotificationAction {
  label?: string;
  onClick?: () => void;
}

export interface NotificationBaseProps {
  id: string;
  title?: string;
  description: string | ReactNode;
  action?: NotificationAction;
  cancel?: NotificationAction;
  dismiss?: () => void;
  footer?: ReactNode;
}

export const NotificationBase = ({
  id,
  title,
  description,
  action,
  cancel,
  dismiss,
  footer,
}: NotificationBaseProps) => {
  const { dismissNotification } = useNotifications();

  const handleClose = useCallback(() => {
    dismiss?.();
    dismissNotification(id);
  }, [dismiss, dismissNotification, id]);

  return (
    <div className={styles.notification}>
      <div
        className={styles.closeButton}
        onClick={handleClose}
        data-testid={'close-button'}
      >
        <Close />
      </div>
      <div className={styles.column} style={{ gap: '4px' }}>
        <div className={styles.column} style={{ gap: '8px' }}>
          {title ? <TextLabelMedium>{title}</TextLabelMedium> : null}
          <TextLabelRegular className={styles.description}>
            {description}
          </TextLabelRegular>
        </div>
        {footer ? <footer>{footer}</footer> : null}
      </div>

      <div className={styles.column} style={{ gap: '4px', textWrap: 'nowrap' }}>
        {action && action?.label ? (
          <Button
            variant={'positive'}
            onAction={action?.onClick}
            data-testid={'action-button'}
          >
            {action.label}
          </Button>
        ) : null}

        {cancel && cancel?.label ? (
          <Button onAction={cancel?.onClick} data-testid={'cancel-button'}>
            {cancel.label}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
