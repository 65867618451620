import { DATA_ATTRIBUTE_PAGE } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';
import { Dimension } from '@/utils/geometry/dimension';
import { isDefined } from '@/utils/isDefined';

export const extractPdf = (
  extractSelection: Dimension,
  contentDiv: HTMLDivElement,
  width: number
) => {
  const pdfViewport = contentDiv.querySelector(
    '.pdfViewport'
  ) as HTMLDivElement;
  if (!pdfViewport?.children) {
    return undefined;
  }
  /**
   * filter the two below div that correspond to the scrollbar, in that way we get all pages.
   */
  const pages = Array.from(
    pdfViewport.querySelectorAll(`[data-${DATA_ATTRIBUTE_PAGE}]`)
  ) as HTMLDivElement[];

  if (!pages.length) {
    return undefined;
  }

  const currentScrollValue = pdfViewport.scrollTop;
  const top = currentScrollValue + extractSelection.y;
  const bottom = top + extractSelection.height;

  /**
   * recalculate the visibles pages which is the new extract document
   */
  const visiblePages = pages
    .map((page, index) => {
      if (
        !(top > page.offsetTop + page.clientHeight || bottom < page.offsetTop)
      ) {
        return index;
      }
      return null;
    })
    .filter(isDefined);

  const firstPageIndex = visiblePages[0];
  const lastPageIndex = visiblePages[visiblePages.length - 1];
  if (!isDefined(firstPageIndex) || !isDefined(lastPageIndex)) {
    return undefined;
  }

  const firstPage = pages[firstPageIndex];
  const lastPage = pages[lastPageIndex];
  if (!isDefined(firstPage) || !isDefined(lastPage)) {
    return undefined;
  }

  /**
   * the size of the image of only the visible pages which is generated when we load the extract with the poly.
   */
  const newContentSize = {
    width: firstPage.offsetWidth,
    height: lastPage.offsetTop + lastPage.offsetHeight - firstPage.offsetTop,
  };

  /**
   * offset to substract to be align with ou for know imaginary extract image and calculate the ratio in this coordinate of space.
   */
  const yToSubstract = firstPage.offsetTop - pdfViewport.scrollTop;
  const xToSubstract = firstPage.offsetLeft;
  const zoomScale = newContentSize.width / width;

  return {
    cropTop: (extractSelection.y - yToSubstract) / newContentSize.height,
    cropLeft: (extractSelection.x - xToSubstract) / newContentSize.width,
    cropBottom:
      1 -
      (extractSelection.y + extractSelection.height - yToSubstract) /
        newContentSize.height,
    cropRight:
      1 -
      (extractSelection.x + extractSelection.width - xToSubstract) /
        newContentSize.width,
    contentOffset: { x: 0, y: pdfViewport.scrollTop },
    zoomScale,
    visiblePages,
  };
};
