import { KosmikPreferences } from '@/utils/kosmik/preferences';
import { SearchEngine } from '@/utils/web/search';

export const getDefaultPreferences = (): KosmikPreferences => {
  return {
    id: crypto.randomUUID(),
    homepage: undefined,
    search_engine: SearchEngine.enum.google,
    color_mode: 'system',
  };
};
