import { ReactElement, useCallback } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { InlineColorPicker } from '@/components/InlineColorPicker.tsx';
import { useAddModal } from '@/components/modal/hooks/useAddModal.ts';
import { useTeam } from '@/context/Team/useTeam';
import { useUser } from '@/context/User/useUser';
import { useIsDev } from '@/hooks/env/useIsDev';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import { useUniverseActions } from '@/utils/universe/hooks/useUniverseActions';

export interface UniverseMenuContentProps {
  type: MenuType;
  universe: KosmikUniverse;
}

export const UniverseMenuContent = ({
  universe,
  type,
}: UniverseMenuContentProps): ReactElement => {
  const user = useUser();
  const { team } = useTeam();
  const addModal = useAddModal();
  const isDev = useIsDev();
  const {
    handleDeleteUniverse,
    handleUpdateColorUniverse,
    handleDuplicateUniverse,
  } = useUniverseActions();
  const { undoableDeleteStickers } = useDeleteStickers();

  const handleUniverseRename = useCallback(() => {
    addModal('rename-universe', {
      universeId: universe.id,
      universeName: universe.name,
    });
  }, [addModal, universe]);

  const handleInviteSomeone = useCallback(() => {
    addModal('invite-to-universe', {
      universe: universe,
    });
  }, [addModal, universe]);

  const handleDeleteAllStickers = useCallback(() => {
    const stickers = universe?.stickers;
    if (stickers && stickers.length > 0) {
      undoableDeleteStickers(stickers);
    }
  }, [undoableDeleteStickers, universe?.stickers]);

  const handleDuplicate = useCallback(async () => {
    const isTeamMember = universe.teams?.some((team) =>
      team.users.some((teamUser) => teamUser.id === user?.id)
    );
    await handleDuplicateUniverse(universe, isTeamMember ? undefined : [team]);
  }, [handleDuplicateUniverse, team, universe, user?.id]);

  const { Item, Separator, Label } = getPolymorphicMenuComponents(type);

  return (
    <>
      <Item displayAction onClick={handleUniverseRename}>
        Rename...
      </Item>
      <Item displayAction onClick={handleDuplicate}>
        Duplicate Universe
      </Item>
      <Separator />
      <Item displayAction onClick={handleInviteSomeone}>
        Invite someone
      </Item>
      <Separator />
      <Label>
        <TextLabelRegular
          style={{
            marginBottom: '30px',
            color: 'var(--label-primary)',
          }}
        >
          Background color
        </TextLabelRegular>
        <InlineColorPicker
          colors={[
            '--color-primary',
            '--universe-primary',
            '--universe-secondary',
            '--universe-yellow',
            '--universe-green',
            '--universe-teal',
            '--universe-blue',
            '--universe-purple',
            '--universe-red',
          ]}
          onColorChange={(color) => {
            handleUpdateColorUniverse(universe.id, color);
          }}
          selectedColor={universe.color}
        />
      </Label>
      <Separator />
      {isDev ? (
        <Item displayAction onClick={handleDeleteAllStickers}>
          Delete all stickers
        </Item>
      ) : null}
      <Separator />
      <Item displayAction onClick={() => handleDeleteUniverse(universe)}>
        Delete
      </Item>
    </>
  );
};

UniverseMenuContent.displayName = 'UniverseMenuContent';
