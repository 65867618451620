import { useNavigate, useParams } from '@tanstack/react-router';
import { useCallback } from 'react';

import { KosmikTeam } from '@/utils/kosmik/team';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import {
  deleteUniverse,
  duplicateUniverse,
  inviteToUniverse,
  renameUniverse,
  updateColorUniverse,
} from '@/utils/universe/universe';

export const useUniverseActions = () => {
  const { universeId } = useParams({ strict: false });
  const navigate = useNavigate();

  /**
   * Deletes the given universe and redirect if needed
   */
  const handleDeleteUniverse = useCallback(
    async (universe: KosmikUniverse) => {
      try {
        await deleteUniverse(universe);
        // Redirect if the deleted universe is the one currently displayed
        if (universe.id === universeId) {
          navigate({ to: '/' });
        }
      } catch (error) {
        console.error('Error when deleting universe:', error);
      }
    },
    [navigate, universeId]
  );

  const handleDuplicateUniverse = useCallback(
    async (universe: KosmikUniverse, teams?: KosmikTeam[]) => {
      const newUniverseId = await duplicateUniverse(universe, teams);
      // Redirect if the deleted universe is the one currently displayed
      if (universe.id === universeId) {
        navigate({ to: '/$universeId', params: { universeId: newUniverseId } });
      }
      return newUniverseId;
    },
    [navigate, universeId]
  );

  return {
    handleDeleteUniverse,
    handleRenameUniverse: renameUniverse,
    handleInviteToUniverse: inviteToUniverse,
    handleUpdateColorUniverse: updateColorUniverse,
    handleDuplicateUniverse,
  };
};
