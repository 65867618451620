import {
  CSSProperties,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { isWindows } from 'react-device-detect';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { followingAtom } from '@/atoms/multiplayer';
import { isMultiSelectionAtom } from '@/atoms/selection';
import { DebugFollowingBounds } from '@/components/HTMLCanvas/DebugFollowingBounds';
import { useCanvasContextEvents } from '@/components/HTMLCanvas/hooks/useCanvasContextEvents';
import { useMultiplayerCSSVariables } from '@/components/HTMLCanvas/hooks/useMultiplayerCSSVariables';
import { usePanNavigation } from '@/components/HTMLCanvas/hooks/usePanNavigation';
import { useWheel } from '@/components/HTMLCanvas/hooks/useWheel';
import { MultiplayerCursors } from '@/components/multiplayer/MultiplayerCursors/MultiplayerCursors';
import { MultiplayerPresence } from '@/components/multiplayer/MultiplayerPresence';
import { LocalSelectionArea } from '@/components/selection/LocalSelectionArea';
import { MultiplayerUsers } from '@/components/selection/MultiplayerUsers';
import { ZoomDock } from '@/components/ZoomDock/ZoomDock';
import { DEBUG_FOLLOWING_BOUNDS } from '@/constants/debug';
import { MAX_ZOOM, MIN_ZOOM } from '@/constants/zoom';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { useCamera } from '@/hooks/camera/useCamera';
import { useFollowingCamera } from '@/hooks/camera/useFollowingCamera';
import { useDropFiles } from '@/hooks/universe/useDropFiles';
import {
  getZoomCenterPoint,
  panCamera,
  zoomCameraTo,
  zoomCameraToZoom,
} from '@/utils/camera/camera';
import { Position } from '@/utils/geometry/position';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import { mapToRange } from '@/utils/number/number';

import styles from './CanvasView.module.css';

interface CanvasViewProps {
  children: ReactNode;
  universe: KosmikUniverse;
}

export const CanvasView = forwardRef<HTMLDivElement, CanvasViewProps>(
  ({ children, universe }, ref) => {
    const { isUniverseMember } = useContext(UniverseContext);
    const resetFollowing = useResetRecoilState(followingAtom);
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);
    const variables = useMultiplayerCSSVariables();
    const isMultiSelection = useRecoilValue(isMultiSelectionAtom);
    const { handleDrop } = useDropFiles(universe.id);
    useFollowingCamera();

    const { camera, setCamera, animateCamera, stopCameraAnimation } =
      useCamera();

    const disableAnimation = useCallback(() => {
      stopCameraAnimation();
    }, [stopCameraAnimation]);

    const zoomIn = useCallback(() => {
      resetFollowing();
      const dz = mapToRange(camera.z, MIN_ZOOM, MAX_ZOOM, 5, 10) / 10;
      const point = getZoomCenterPoint();
      animateCamera(zoomCameraTo(camera, point, -dz));
    }, [animateCamera, camera, resetFollowing]);

    const zoomOut = useCallback(() => {
      resetFollowing();
      const dz = 0.5;
      const point = getZoomCenterPoint();
      animateCamera(zoomCameraTo(camera, point, dz));
    }, [animateCamera, camera, resetFollowing]);

    const setZoom = useCallback(
      (newZoom: number) => {
        resetFollowing();
        disableAnimation();
        setCamera((camera) => {
          const point = getZoomCenterPoint();
          return zoomCameraToZoom(camera, point, newZoom);
        });
      },
      [disableAnimation, resetFollowing, setCamera]
    );

    const zoomReset = useCallback(() => {
      resetFollowing();
      setCamera((camera) => {
        const point = getZoomCenterPoint();
        return zoomCameraToZoom(camera, point, 1);
      });
    }, [resetFollowing, setCamera]);

    const handleWheel = useCallback(
      (event: WheelEvent) => {
        event.preventDefault();
        disableAnimation();
        resetFollowing();
        const { clientX, clientY, deltaX, deltaY, ctrlKey, altKey, metaKey } =
          event;
        if (ctrlKey || altKey || metaKey) {
          setCamera((camera) =>
            zoomCameraTo(camera, { x: clientX, y: clientY }, deltaY / 100)
          );
        } else {
          setCamera((camera) =>
            isWindows && event.shiftKey
              ? panCamera(camera, deltaY, deltaX)
              : panCamera(camera, deltaX, deltaY)
          );
        }
      },
      [disableAnimation, resetFollowing, setCamera]
    );

    useWheel(containerRef, handleWheel);

    usePanNavigation(containerRef, (delta: Position) => {
      disableAnimation();
      resetFollowing();
      setCamera((camera) => panCamera(camera, -delta.x, -delta.y));
    });

    useCanvasContextEvents();

    const handleContextMenu: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        /**
         * RadixUI overrides the default system context menu.
         * Preventing default avoid to open the radix context menu if necessary.
         */
        if (!isUniverseMember) {
          event.preventDefault();
        }
      },
      [isUniverseMember]
    );

    const transform = `matrix(${camera.z}, 0, 0, ${camera.z}, ${camera.x * camera.z}, ${camera.y * camera.z})`;

    return (
      <div ref={ref} className={styles.canvasViewport}>
        <div
          ref={containerRef}
          className={styles.canvasFrame}
          onContextMenu={handleContextMenu}
          onDragOver={(event) => event.preventDefault()}
          onDrop={handleDrop}
        >
          <div
            ref={canvasRef}
            className={styles.canvas}
            data-camera={''}
            data-camera-x={camera.x}
            data-camera-y={camera.y}
            data-camera-z={camera.z}
            data-multi-select={isMultiSelection ? '' : undefined}
            style={
              {
                transform,
                '--camera-z': camera.z,
                ...Object.fromEntries(variables.entries()),
              } as CSSProperties
            }
            data-performance={camera.z < 0.5 || undefined}
            onTransitionEnd={disableAnimation}
          >
            {children}
            <MultiplayerUsers />
            <MultiplayerPresence containerRef={containerRef} />
            <LocalSelectionArea containerRef={containerRef} />
            <MultiplayerCursors />
            {DEBUG_FOLLOWING_BOUNDS ? <DebugFollowingBounds /> : null}
          </div>
          <ZoomDock
            camera={camera}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            zoomReset={zoomReset}
            setZoom={setZoom}
          />
        </div>
      </div>
    );
  }
);

CanvasView.displayName = 'CanvasView';
