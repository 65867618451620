import { isWeb } from '@/utils/platform/isWeb';
import { isValidUrl } from '@/utils/url/url';
import { SEARCH_ENGINE_SPEC, SearchEngine } from '@/utils/web/search';

interface MaybeUrlProps {
  maybeUrl: string;
  searchEngine?: SearchEngine;
}

/**
 * Returns a url from the given string, two cases:
 * - the input string is likely a url, convert it to a full qualified url
 * - the input string is likely a search term, convert it to the configured
 *   search engine url
 */
export const getUrlOrSearchUrl = ({
  maybeUrl,
  searchEngine = SearchEngine.enum.google,
}: MaybeUrlProps) => {
  const isSearch =
    !(maybeUrl.trim().indexOf(' ') === -1) || maybeUrl.indexOf('.') === -1;
  let url: string;

  if (!isSearch && isValidUrl(maybeUrl)) {
    url = maybeUrl;
  } else if (!isSearch && maybeUrl.indexOf('https://') === -1) {
    url = `https://${maybeUrl}`;
  } else {
    url = `${SEARCH_ENGINE_SPEC[searchEngine].search}${encodeURIComponent(
      maybeUrl
    )}`;
  }
  return url;
};

/**
 * Open a new tab to the relevant search query on web, return the search-engine
 * specific url otherwise
 */
export const openNewSearch = ({
  maybeUrl,
  searchEngine = SearchEngine.enum.google,
}: MaybeUrlProps) => {
  const url = getUrlOrSearchUrl({ maybeUrl, searchEngine });

  if (isWeb) {
    window.open(url, '_blank');
  }
  return url;
};
