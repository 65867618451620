import equal from 'fast-deep-equal/es6';

/**
 * Fast Compare two arrays of strings for equality
 * @param a
 * @param b
 */
export const compare = (a: string[], b: string[]) => {
  if (a.length === b.length) {
    return a.length === 0 || equal(a, b);
  }

  return false;
};
