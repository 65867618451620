import debounce from 'debounce';
import {
  CSSProperties,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import styles from '@/components/EditDock/components/EditDockColorItem.module.css';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useColorInputOpener } from '@/components/EditDock/hooks/useColorInputOpener';
import ColorPalette from '@/ui/ColorPalette/ColorPalette';
import { Popover } from '@/ui/Popover/Popover';
import { wrapCSSVar } from '@/utils/css/variable';

interface BaseProps {
  currentColor: string;
  onColorChange: SetColorFunction;
  onColorCommit: SetColorFunction;
  notSelectable?: boolean;
  stroke?: boolean;
}

interface ColorPickerProps extends BaseProps {
  colors?: string[];
  colorWheel?: boolean;
}

export type EditDockColorItemProps = ColorPickerProps;

export type SetColorFunction = (color: string) => void;

export const EditDockColorPickerItem = ({
  colors,
  colorWheel,
  onColorChange,
  onColorCommit,
  currentColor,
  stroke = false,
}: EditDockColorItemProps): ReactElement => {
  const debouncedOnColorChange = useMemo(
    () =>
      debounce(onColorChange, 100, {
        immediate: true,
      }),
    [onColorChange]
  );
  const debouncedOnColorCommit = useMemo(
    () => debounce(onColorCommit, 250),
    [onColorCommit]
  );
  const buttonRef = useRef<HTMLButtonElement>(null);
  const colorInputRef = useRef<HTMLInputElement>(null);
  const formattedCurrentColor = useMemo(
    () => currentColor?.toLowerCase(),
    [currentColor]
  );

  const isCurrentColor = !(colors?.includes(formattedCurrentColor) ?? false);

  useColorInputOpener(buttonRef, colorInputRef);

  const handleChangeColor = useCallback(
    (value: string) => {
      debouncedOnColorChange.clear();
      debouncedOnColorCommit.clear();
      debouncedOnColorChange(value);
      debouncedOnColorCommit(value);
    },
    [debouncedOnColorChange, debouncedOnColorCommit]
  );

  const trigger = (
    <EditDockItem
      ref={buttonRef}
      active={isCurrentColor}
      data-color-wheel={colorWheel ? '' : undefined}
      data-stroke-color-picker={stroke ? '' : undefined}
      className={styles.editDockItem}
      style={
        {
          ['--current-color']: formattedCurrentColor
            ? wrapCSSVar(formattedCurrentColor)
            : undefined,
        } as CSSProperties
      }
    >
      <div className={styles.stack} data-testid={'editdock-colorpicker'}>
        <div className={styles.colorCircle} />
        <div className={styles.colorBorder} />
      </div>
    </EditDockItem>
  );

  return (
    <Popover
      trigger={trigger}
      contentProps={{
        sideOffset: 8,
        style: { padding: 10 },
        side: 'top',
        avoidCollisions: true,
      }}
    >
      <ColorPalette
        currentColor={formattedCurrentColor}
        onChange={handleChangeColor}
      />
    </Popover>
  );
};
