import { Close, ExitFullscreen, Fullscreen } from '@lithium/spectrum';
import { useRecoilState } from 'recoil';

import { isPinPanelFullscreenAtom } from '@/atoms/pinpanel';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useClosePinPanel } from '@/hooks/pinPanel/useClosePinPanel';

export const PinPanelDefaultEditDockItems = () => {
  const [isPinPanelFullscreen, setIsPinPanelFullscreen] = useRecoilState(
    isPinPanelFullscreenAtom
  );
  const { handleClosePinPanel } = useClosePinPanel();

  return (
    <>
      <EditDockItem onClick={() => setIsPinPanelFullscreen((prev) => !prev)}>
        {isPinPanelFullscreen ? <ExitFullscreen /> : <Fullscreen />}
      </EditDockItem>
      <EditDockItem onClick={handleClosePinPanel}>
        <Close />
      </EditDockItem>
    </>
  );
};
