/**
 * Create a transformer function that takes an input range and output range.
 * Calling the created transform will transform any input value to the
 * relative value in the output range
 *
 * For example:
 * ```
 * const transformX = transform([0,1], [0,100])
 *
 * const newValue = transformX(0.2)
 * // newValue: 20
 * ```
 *
 * @param fromRange
 * @param toRange
 */
export const transform =
  (fromRange: [number, number], toRange: [number, number]) =>
  (inputValue: number) => {
    const [fromMin, fromMax] = fromRange;
    const [toMin, toMax] = toRange;
    const fromSize = fromMax - fromMin;
    const toSize = toMax - toMin;
    const fromPercent = (inputValue - fromMin) / fromSize;
    return fromPercent * toSize + toMin;
  };
