import { Position } from '@/utils/geometry/position';

/**
 * Get the distance between two given points
 * @param p1
 * @param p2
 */
export const getDistance = (
  p1: Position | [number, number],
  p2: Position | [number, number]
) => {
  const p1Object = Array.isArray(p1) ? { x: p1[0], y: p1[1] } : p1;
  const p2Object = Array.isArray(p2) ? { x: p2[0], y: p2[1] } : p2;
  return Math.hypot(p2Object.x - p1Object.x, p2Object.y - p1Object.y);
};
