import { Button } from '@lithium/spectrum';
import { useNavigate } from '@tanstack/react-router';
import {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';

import Error403 from '@/assets/error/error403.svg?react';
import Error404 from '@/assets/error/error404.svg?react';
import ErrorGeneric from '@/assets/error/errorGeneric.svg?react';
import { TextBodySemibold } from '@/components/fontSystem/BodySemibold';
import { TextHeader1 } from '@/components/fontSystem/Header1';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useUser } from '@/context/User/useUser';

import styles from './ErrorPage.module.css';

export interface ErrorPageBaseProps {
  variant: '404' | '403' | 'custom';
}

export interface CustomErrorPageProps extends ErrorPageBaseProps {
  variant: 'custom';
  title?: string;
  subtitle?: ReactNode;
  description?: ReactNode;
  isButtonDisplayed?: boolean;
  onClick?: () => void;
  buttonText?: string;
  style?: CSSProperties;
}

export interface GenericErrorPageProps extends ErrorPageBaseProps {
  variant: '404' | '403';
  title?: never;
  subtitle?: ReactNode;
  description?: ReactNode;
  isButtonDisplayed?: never;
  onClick?: () => never;
  buttonText?: never;
  style?: CSSProperties;
}

export type ErrorPageProps = CustomErrorPageProps | GenericErrorPageProps;

export const ErrorPage = ({
  variant,
  title,
  subtitle,
  description,
  isButtonDisplayed = true,
  onClick,
  buttonText,
  style,
}: ErrorPageProps): ReactElement => {
  const user = useUser();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      navigate({ to: '/' });
    }
  }, [navigate, onClick]);

  useEffect(() => {
    if (title !== '404') {
      return;
    }
  }, [title]);

  let Illustration = ErrorGeneric;
  switch (variant) {
    case '404':
      Illustration = Error404;
      title = '404';
      subtitle = subtitle ?? 'Page Not Found';
      description = description ?? (
        <>
          Some questions? Visit{' '}
          <a href={'https://www.kosmik.app/faq'}>Kosmik FAQ</a>
        </>
      );
      break;
    case '403':
      Illustration = Error403;
      title = '403';
      subtitle = subtitle ?? 'Looks like you don’t have access to this page';
      description = description ?? (
        <>
          Some questions? Visit{' '}
          <a href={'https://www.kosmik.app/faq'}>Kosmik FAQ</a>
        </>
      );
      break;
  }

  return (
    <div className={styles.wrapper} style={style}>
      <div className={styles.container}>
        <header className={styles.header}>
          {title ? (
            <TextHeader1 className={styles.title} data-testid={'error-title'}>
              {title}
            </TextHeader1>
          ) : null}
          {subtitle ? (
            <TextBodySemibold
              className={styles.subTitle}
              data-testid={'error-subtitle'}
            >
              {subtitle}
            </TextBodySemibold>
          ) : null}
        </header>
        <Illustration />
        {description ? (
          <TextLabelRegular
            className={styles.description}
            data-testid={'error-description'}
          >
            {description}
          </TextLabelRegular>
        ) : null}
        {isButtonDisplayed ? (
          <Button
            variant={'positive'}
            onAction={handleClick}
            data-testid={'error-button'}
          >
            {buttonText ||
              (user ? 'Go back to your content' : 'Back to log-in')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
