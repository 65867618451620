import * as SelectPrimitive from '@radix-ui/react-select';
import React from 'react';

import { MenuContent } from '@/ui/MenuPrimitive/MenuContent';

export const SelectContent = React.forwardRef<
  HTMLDivElement,
  SelectPrimitive.SelectContentProps
>(({ children, ...props }, ref) => {
  return (
    <SelectPrimitive.Content asChild {...props}>
      <MenuContent ref={ref}>{children}</MenuContent>
    </SelectPrimitive.Content>
  );
});

SelectContent.displayName = 'SelectMenuContent';
