import { useCallback, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { Modal, modalStackAtom } from '@/atoms/modal';
import {
  MODAL_SPECS,
  ModalProperties,
} from '@/components/modal/specs/MODAL_SPECS';
import { Dialog } from '@/ui/Dialog/Dialog';
import { MaybeNull } from '@/utils/types';

/**
 * Common props that are injected for all modals
 */
export interface CommonModalProps {
  // Close the current modal, optionally close all other  modals in the stack
  closeModal: (closeAll?: boolean) => void;
}

/**
 * The component responsible for rendering the modals
 */
export const Modals = () => {
  const [modalStack, setModalStack] = useRecoilState(modalStackAtom);
  const lastModalOnTopRef = useRef<Modal | null>(null);
  const modalOnTop =
    modalStack.length > 0 ? modalStack[modalStack.length - 1] : null;
  if (modalOnTop) {
    lastModalOnTopRef.current = modalOnTop;
  }
  const lastModalOnTop = lastModalOnTopRef.current;
  const modalProperties: MaybeNull<ModalProperties> = lastModalOnTop
    ? MODAL_SPECS[lastModalOnTop.type]
    : null;

  const ModalContent = modalProperties?.Component;

  const popModal = useCallback(
    (closeAll?: boolean) => {
      setModalStack((prevState) => {
        if (closeAll) {
          lastModalOnTopRef.current = null;
          return [];
        } else {
          const newModalStack = prevState.slice(0, -1);
          const modalOnTop = newModalStack[newModalStack.length - 1];
          lastModalOnTopRef.current = modalOnTop ?? null;
          return newModalStack;
        }
      });
    },
    [setModalStack]
  );

  return ModalContent ? (
    <Dialog
      open={!!modalOnTop}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          popModal();
        }
      }}
      contentProps={modalProperties?.contentProps}
    >
      <ModalContent {...lastModalOnTop?.props} closeModal={popModal} />
    </Dialog>
  ) : null;
};
