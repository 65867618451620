import { LAYOUT_GUTTER } from '@/constants/layout';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { Dimension } from '@/utils/geometry/dimension';
import { Point, sortPointByY } from '@/utils/geometry/point';
import { Size } from '@/utils/geometry/size';
import { isDefined } from '@/utils/isDefined';
import { round } from '@/utils/math/round';

export type MasonryOptions = {
  gutter?: number;
  numberOfColumns?: number;
  origin?: Point;
};

export const masonry = <T extends Size>(
  items: T[],
  options: MasonryOptions
): (T & Dimension)[] => {
  const {
    gutter = LAYOUT_GUTTER,
    numberOfColumns = round(Math.sqrt(items.length)),
    origin = { x: 0, y: 0 },
  } = options;
  const columnHeights = Array(numberOfColumns).fill(0);
  const columnWidths = Array(numberOfColumns);

  const sumWidths = (position: number) => {
    return columnWidths
      .slice(0, position)
      .reduce((partialSum, a) => partialSum + a, 0);
  };

  const results = items.map((item) => {
    // current shortest column
    const targetColumnIndex = indexOfSmallest(columnHeights);
    const previousColumnsWidth = sumWidths(targetColumnIndex);
    const xGutters = targetColumnIndex * gutter;
    const x = previousColumnsWidth + xGutters + origin.x;
    const y = columnHeights[targetColumnIndex] + origin.y;
    const columnWidth = columnWidths[targetColumnIndex];
    const width = columnWidth ? columnWidth : item.width;
    const height = columnWidth
      ? (item.height / item.width) * columnWidth
      : item.height;
    if (height) {
      columnHeights[targetColumnIndex] += height + gutter;
    }
    if (!isDefined(columnWidths[targetColumnIndex])) {
      columnWidths[targetColumnIndex] = item.width;
    }

    return {
      ...item,
      width,
      height,
      x,
      y,
    };
  });

  return results;
};

const indexOfSmallest = (array: number[]): number => {
  let lowest = 0;
  for (let index = 1; index < array.length; index++) {
    const currentItem = array[index];
    const lowestItem = array[lowest];
    if (
      isDefined(currentItem) &&
      isDefined(lowestItem) &&
      currentItem < lowestItem
    ) {
      lowest = index;
    }
  }
  return lowest;
};

export const runMasonryLayout = <T extends Dimension>(items: T[]) => {
  const boundingBox = getBoundingBox(items);
  const options: MasonryOptions = {
    origin: {
      x: boundingBox?.x ?? 0,
      y: boundingBox?.y ?? 0,
    },
  };

  // To ensure consistent sorting layouts, sort the order
  // of the boxes from left to right and top to bottom
  const sortedItems = items
    .sort(sortPointByY)
    .sort((a, b) => a.width - b.width);
  return masonry(sortedItems, options);
};
