import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { forwardRef, PropsWithChildren, ReactNode } from 'react';

import { TextTag } from '@/components/fontSystem/TextTag';

import styles from './Tooltip.module.css';

export type TooltipProps = PropsWithChildren<
  TooltipPrimitive.TooltipProps & {
    content: ReactNode;
    tooltipContentProps?: TooltipPrimitive.TooltipContentProps;
  }
>;

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ children, content, tooltipContentProps, ...props }, ref) => {
    return (
      <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root {...props}>
          <TooltipPrimitive.Trigger asChild>
            {children}
          </TooltipPrimitive.Trigger>
          <TooltipPrimitive.Portal>
            <TooltipPrimitive.Content
              ref={ref}
              sideOffset={2}
              collisionPadding={4}
              className={styles.tooltipContent}
              {...tooltipContentProps}
            >
              <TextTag>{content}</TextTag>
            </TooltipPrimitive.Content>
          </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  }
);

Tooltip.displayName = 'Tooltip';
