import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Close } from '@lithium/spectrum';
import { TanStackRouterDevtoolsPanel } from '@tanstack/router-devtools';
import { useRef, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { TextTag } from '@/components/fontSystem/TextTag';
import { useAnimationFrame } from '@/components/internal/useAnimationFrame';
import { router } from '@/router';
import { transform } from '@/utils/transform';

import styles from './InternalToolbar.module.css';

const framesToPath = (frames: number[]) => {
  const graphHeight = 10;
  const graphWidth = 200;
  const max = 60;
  const min = 0;

  const transformerY = transform([min, max], [0, graphHeight]);
  const transformerX = transform([0, frames.length], [0, graphWidth]);

  const [firstFrame] = frames;
  if (!firstFrame) {
    return '';
  }
  let path = `M${0},${transformerY(firstFrame)}`;

  frames.forEach((frame: number, index: number) => {
    path += `L${transformerX(index)},${transformerY(frame)}`;
  });

  return path;
};

const FramerateMonitor = () => {
  const averageHistoryLength = 60 * 2; // 2  sec
  const averageHistory = useRef(60);

  const historyLength = 60 * 30; // 30 sec
  const history = useRef(new Array(historyLength).fill(60));

  const [fps, setFps] = useState(60);

  useAnimationFrame((e) => {
    const frame = 1 / e.delta;
    // add missing frames when framerate drops, number of frames to add:
    const frameSupplement = Math.max(1, Math.floor(60 / frame));
    const supplementalFrames = new Array(frameSupplement).fill(frame);

    const prev = history.current;
    const newHistory = [...prev, ...supplementalFrames];
    history.current = newHistory.slice(-historyLength);
    const average = newHistory.slice(-averageHistoryLength);
    averageHistory.current =
      average.reduce((a, b) => a + b, 0) / average.length;

    // trigger refresh
    setFps(frame);
  });

  return (
    <div className={styles.framerateMonitor}>
      <TextTag className={styles.counter}>
        FPS: <span className={styles.number}>{fps && Math.floor(fps)}</span>
      </TextTag>
      <TextTag className={styles.counter}>
        (
        <span className={styles.number}>
          {averageHistory.current && Math.floor(averageHistory.current)}
        </span>{' '}
        avg)
      </TextTag>
      <svg className={styles.graph}>
        <linearGradient id={'Gradient1'}>
          <stop stopColor={'transparent'} offset={'0%'} />
          <stop stopColor={'var(--accent-orange-primary)'} offset={'5%'} />
          <stop stopColor={'var(--accent-orange-primary)'} offset={'95%'} />
          <stop stopColor={'transparent'} offset={'100%'} />
        </linearGradient>
        <path
          d={framesToPath(history.current)}
          fill={'none'}
          strokeWidth={'1px'}
          stroke={'url(#Gradient1)'}
        />
      </svg>
    </div>
  );
};

export const InternalToolbar = () => {
  const [showToolbar, setShowToolbar] = useLocalStorage(
    'showDevToolbar',
    false
  );
  const [tanStackIsOpen, setTanStackIsOpen] = useState(false);
  const [parent] = useAutoAnimate();
  return (
    <div ref={parent}>
      {showToolbar && (
        <div
          className={styles.toolbar}
          // initial={{ y: 50, scale: 0.8, opacity: 0.5 }}
          // animate={{ y: 0, scale: 1, opacity: 1 }}
        >
          <EditDockItem
            active={tanStackIsOpen}
            onClick={() => setTanStackIsOpen(!tanStackIsOpen)}
          >
            TanStack
          </EditDockItem>
          <FramerateMonitor />
          <EditDockItem>
            <Close onClick={() => setShowToolbar(!showToolbar)} />
          </EditDockItem>
        </div>
      )}
      {tanStackIsOpen && (
        <TanStackRouterDevtoolsPanel
          router={router}
          isOpen={tanStackIsOpen}
          setIsOpen={setTanStackIsOpen}
          className={styles.routerPanel}
        />
      )}
    </div>
  );
};
