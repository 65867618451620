import { atom } from 'recoil';

import { HALF_UNIVERSE_SIZE } from '@/constants/universe';
import { Camera } from '@/utils/camera/camera';

export const DEFAULT_CAMERA = {
  x: -HALF_UNIVERSE_SIZE,
  y: -HALF_UNIVERSE_SIZE,
  z: 1,
};

/**
 * Holds the camera values, be mindful about frequent re-renders when using this atom
 */
export const cameraAtom = atom<Camera>({
  key: 'camera',
  default: DEFAULT_CAMERA,
});
