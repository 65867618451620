import { Button } from '@lithium/spectrum';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

import GoogleIcon from '@/assets/google.svg?react';
import styles from '@/components/Onboarding/Onboarding.module.css';
import { db } from '@/db/databaseInit';
import { useOnlineStatus } from '@/hooks/online/useOnlineStatus';
import { Route as LoginRoute } from '@/routes/login';
import { sendKPI } from '@/utils/kpi/sendKPI';
import { getAppLocation } from '@/utils/location/getAppLocation';
import { isWeb } from '@/utils/platform/isWeb';
import { createNewUser } from '@/utils/user/create';

const ELECTRON_REDIRECT_URL = 'kosmik-instant://instant_oauth';

export const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const online = useOnlineStatus();
  const { duplicateId } = LoginRoute.useSearch();

  const handleGoogleClick = async () => {
    const duplicatePath = duplicateId ? `/duplicate/${duplicateId}` : '';

    const redirectUrl =
      (isWeb ? getAppLocation() + duplicatePath : ELECTRON_REDIRECT_URL) +
      '?sso=true';

    const url = db.auth.createAuthorizationURL({
      clientName: import.meta.env.VITE_GOOGLE_CLIENT_NAME,
      redirectURL: redirectUrl,
    });

    if (isWeb) {
      window.location.assign(url);
    } else {
      window.open(url);
    }
  };

  useEffect(() => {
    if (isWeb) {
      return;
    }

    const handleElectronAuthCode = (event: CustomEvent) => {
      const oauth_code = event.detail.replace(ELECTRON_REDIRECT_URL, '');
      const params = new URLSearchParams(oauth_code);
      const code = params.get('code');
      if (code) {
        db.auth
          .exchangeOAuthCode({ code, codeVerifier: null })
          .then(async ({ user }) => {
            await createNewUser(user);
            sendKPI({ type: 'sign_in' }, user, user.refresh_token);
            navigate({ to: '/' });
          })
          .catch((err) => console.error(err));
      }
    };

    window.addEventListener(
      'auth-code',
      handleElectronAuthCode as EventListener
    );
    return () => {
      window.removeEventListener(
        'auth-code',
        handleElectronAuthCode as EventListener
      );
    };
  }, [navigate]);

  return (
    <Button variant={'default'} disabled={!online} onAction={handleGoogleClick}>
      <GoogleIcon className={styles.googleIcon} />
      Continue with Google
    </Button>
  );
};
