import { useParams } from '@tanstack/react-router';
import { WebviewTag } from 'electron';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  localStickerFamily,
  stickerWebViewAttributesFamily,
} from '@/atoms/sticker';
import { ErrorPage } from '@/components/ErrorPage/ErrorPage';
import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { useUploadFiles } from '@/hooks/universe/useUploadFiles';
import { getCenterPoint, getCurrentCameraValues } from '@/utils/camera/camera';
import { offsetPosition } from '@/utils/geometry/position';
import {
  KosmikWebSticker,
  WebStickerAttributes,
} from '@/utils/kosmik/stickers/web';
import { PnsPayload } from '@/utils/pns/utils/pnsHelper';
import { createTextSticker } from '@/utils/sticker/create/createTextSticker/createTextSticker';
import { createWebSticker } from '@/utils/sticker/create/createWebSticker';
import { MaybeNull } from '@/utils/types';
import { getWebviewId } from '@/utils/webview';

import styles from './HtmlWebViewSticker.module.css';

export interface WebViewAttributes {
  historyIndex: number;
}

type HtmlWebViewSticker = {
  sticker: KosmikWebSticker;
};

export const HtmlWebViewSticker = ({ sticker }: HtmlWebViewSticker) => {
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setWebViewAttributes = useSetRecoilState(
    stickerWebViewAttributesFamily(sticker.id)
  );
  const setStickerAttributes =
    useSetStickerAttributes<WebStickerAttributes>(setSticker);

  const handleUrlChange = useCallback(
    (url: string) => {
      const newHistory = [
        ...(sticker.attributes.history ?? [sticker.attributes.url]),
        url,
      ];
      setWebViewAttributes(newHistory.length - 1);
      setStickerAttributes({ url: url, history: newHistory }, false);
    },
    [
      setStickerAttributes,
      setWebViewAttributes,
      sticker.attributes.history,
      sticker.attributes.url,
    ]
  );

  return (
    <WebView
      url={sticker.attributes.url}
      stickerId={sticker.id}
      onUrlChange={handleUrlChange}
    />
  );
};

export type WebViewError = { code: number; description: string };

export type WebViewProps = {
  stickerId: string;
  url?: string;
  onUrlChange: (url: string) => void;
};

export const WebView = ({ stickerId, url, onUrlChange }: WebViewProps) => {
  const { universeId } = useParams({ strict: false });
  const { uploadNewFileBlob } = useUploadFiles(universeId ?? 'todo');
  const [error, setError] = useState<MaybeNull<WebViewError>>(null);
  const webviewRef = useRef<WebviewTag>(null);
  const setWebviewUrl = (url = '') => {
    const webview = webviewRef.current;
    if (webview && webview.src !== url) {
      webview.src = url;
    }
  };
  const pushKPI = usePushKPI();

  useEffect(() => {
    setError(null);
    setWebviewUrl(url);
  }, [url]);

  useEffect(() => {
    const webview = webviewRef.current;
    if (!webview) {
      return;
    }

    const handleUrlChange = (url: string) => {
      if (webview.src !== url) {
        onUrlChange(url);
      }
    };

    const handleWillNavigate = (event: Electron.WillNavigateEvent) => {
      handleUrlChange(event.url);
    };

    const handleDidNavigateInPage = (
      event: Electron.DidNavigateInPageEvent
    ) => {
      if (event.isMainFrame) {
        handleUrlChange(event.url);
      }
    };

    const handleInternalChange = (event: Event) => {
      webview.src = (event as CustomEvent).detail;
    };

    const handleDomReady = () => {
      const code = `
        const removeBlankLinks = (mutations) => {
          const links = document.querySelectorAll("a[target='_blank']");
          for (const link of links) {
            link.target = '';
          }
        };

        removeBlankLinks();

        const observer = new MutationObserver(removeBlankLinks);
        observer.observe(document.body, { childList: true, subtree: true });
        
        window.addEventListener('dragstart', (e) => {
          event.dataTransfer.setData('kosmik-source-url', window.location.href);
        })
      `;
      webview.send('stop-pns');
      webview.executeJavaScript(code);
    };

    const forwardEvent = (event: Event) => {
      window.dispatchEvent(new Event(event.type, event));
    };

    const handleIpcMessage = async (event: Electron.IpcMessageEvent) => {
      if (event.channel === 'pns-payload' && url && universeId) {
        // exit pns
        webview.send('stop-pns');
        const items = event.args[0] as PnsPayload;

        let newPosition = getCenterPoint(getCurrentCameraValues());
        for (const text of items.text) {
          createTextSticker(text, newPosition, universeId, url);
          pushKPI({
            type: 'create',
            properties: {
              interaction: 'extract',
              coType: 'text',
              coFormat: 'html',
              coSize: text.length,
              coUniverse: universeId,
            },
          });
          newPosition = offsetPosition(newPosition);
        }

        for (const base64 of items.images) {
          const res = await fetch(base64);
          const blob = await res.blob();
          uploadNewFileBlob(blob, newPosition, url);
          pushKPI({
            type: 'create',
            properties: {
              interaction: 'extract',
              coType: 'file',
              coFormat: 'image',
              coSize: blob.size,
              coUniverse: universeId,
            },
          });
          newPosition = offsetPosition(newPosition);
        }

        for (const url of items.links) {
          createWebSticker(url, newPosition, universeId);
          pushKPI({
            type: 'create',
            properties: {
              interaction: 'extract',
              coType: 'web',
              coFormat: 'url',
              coSize: 0,
              coUniverse: universeId,
            },
          });
          newPosition = offsetPosition(newPosition);
        }
      }
    };

    const handleDidFailLoad = (event: Electron.DidFailLoadEvent) => {
      // this will be called when the url is invalid (ERR_NAME_NOT_RESOLVED)
      if (event.errorCode === -105) {
        setError({
          code: event.errorCode,
          description: event.errorDescription,
        });
        forwardEvent(event);
      }
    };

    webview.addEventListener('dom-ready', handleDomReady);
    webview.addEventListener('will-navigate', handleWillNavigate);
    webview.addEventListener('did-navigate-in-page', handleDidNavigateInPage);
    webview.addEventListener('internal-change', handleInternalChange);
    webview.addEventListener('did-finish-load', forwardEvent);
    webview.addEventListener('did-fail-load', handleDidFailLoad);
    webview.addEventListener('did-start-loading', forwardEvent);
    webview.addEventListener('did-stop-loading', forwardEvent);
    webview.addEventListener('ipc-message', handleIpcMessage);

    return () => {
      webview.removeEventListener('dom-ready', handleDomReady);
      webview.removeEventListener('will-navigate', handleWillNavigate);
      webview.removeEventListener(
        'did-navigate-in-page',
        handleDidNavigateInPage
      );
      webview.removeEventListener('internal-change', handleInternalChange);
      webview.removeEventListener('did-finish-load', forwardEvent);
      webview.removeEventListener('did-fail-load', handleDidFailLoad);
      webview.removeEventListener('did-start-loading', forwardEvent);
      webview.removeEventListener('did-stop-loading', forwardEvent);
      webview.removeEventListener('ipc-message', handleIpcMessage);
    };
  }, [onUrlChange, pushKPI, universeId, uploadNewFileBlob, url]);

  return (
    <div className={styles.wrapper}>
      {error ? (
        <>
          <ErrorPage
            variant={'custom'}
            title={'Error'}
            description={
              <>
                {error.description} ({error.code})<br />
                Try a new url, you can update the homepage URL in
                <br /> your User account &gt; Interface tab &gt; Browser
                section.
              </>
            }
            isButtonDisplayed={false}
            style={
              {
                height: '100%',
                width: '100%',
                minHeight: '100%',
                WebkitAppRegion: 'initial',
              } as CSSProperties
            }
          />
        </>
      ) : null}
      <webview
        id={getWebviewId(stickerId)}
        ref={webviewRef}
        draggable={true}
        /* TODO: investigate if it makes sense to use electron's BrowserView */
        /* eslint-disable-next-line react/no-unknown-property */
        partition={'persist:electron'}
        style={{ display: error ? 'none' : undefined }}
      />
    </div>
  );
};
