import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';

import { MenuSeparator } from '@/components/ui/MenuPrimitive/MenuSeparator';

export const DropdownSeparator = React.forwardRef<
  HTMLDivElement,
  DropdownPrimitive.DropdownMenuSeparatorProps
>((props, ref) => {
  return (
    <DropdownPrimitive.Separator asChild {...props}>
      <MenuSeparator ref={ref} />
    </DropdownPrimitive.Separator>
  );
});

DropdownSeparator.displayName = 'DropdownSeparator';
