import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import React from 'react';

import { MenuSeparator } from '@/components/ui/MenuPrimitive/MenuSeparator';

export const ContextMenuSeparator = React.forwardRef<
  HTMLDivElement,
  ContextMenuPrimitive.ContextMenuSeparatorProps
>((props, ref) => {
  return (
    <ContextMenuPrimitive.Separator asChild {...props}>
      <MenuSeparator ref={ref} />
    </ContextMenuPrimitive.Separator>
  );
});

ContextMenuSeparator.displayName = 'ContextMenuSeparator';
