import { Position } from '@/utils/geometry/position';

export type Ellipse = {
  // The center of the ellipse
  center: Position;
  // The x-axis semi-radius of the ellipse
  rx: number;
  // the y-axis semi-radius of the ellipse
  ry: number;
  // the rotation of the ellipse (in radians)
  rotation?: number;
};

/**
 * Get an ellipse point at t
 * @param t the parametric point to get (0: beginning, no rotation, 1: full rotation)
 * @param ellipse the spec of the ellipse
 */
export const getEllipsePointAt = (t: number, ellipse: Ellipse) => {
  const radians = t * Math.PI * 2;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const ellipseRotation = ellipse.rotation ?? 0;
  const x =
    ellipse.rx * cos * Math.cos(ellipseRotation) +
    ellipse.ry * sin * Math.sin(ellipseRotation) +
    ellipse.center.x;
  const y =
    ellipse.rx * cos * Math.sin(ellipseRotation) -
    ellipse.ry * sin * Math.cos(ellipseRotation) +
    ellipse.center.y;
  return { x, y };
};
