import { StickerText } from '@lithium/spectrum';

import { useCreateDefaultTextSticker } from '@/hooks/sticker/create/useCreateDefaultTextSticker';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const CreateTextStickerMenuItem = ({ type }: { type: MenuType }) => {
  const { handleCreateDefaultText } = useCreateDefaultTextSticker();
  const { Item } = getPolymorphicMenuComponents(type);

  return (
    <Item
      onClick={(event) => handleCreateDefaultText(event.shiftKey)}
      prefix={<StickerText />}
    >
      Text
    </Item>
  );
};
