import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer.mjs';

interface RenderPdfTextLayerProps {
  page?: PDFPageProxy;
  pdf?: PDFDocumentProxy;
  container: HTMLElement;
  pageNumber?: number;
  scale?: number;
}

export const renderPdfTextLayer = async ({
  page,
  pdf,
  container,
  pageNumber = 1,
  scale = 1,
}: RenderPdfTextLayerProps) => {
  const _page = page || (await pdf?.getPage(pageNumber));
  if (!_page) {
    return;
  }
  const viewport = _page.getViewport({ scale });

  for (const child of container.children) {
    if ((child as HTMLElement).classList.contains('textLayer')) {
      container.removeChild(child);
    }
  }

  const pdfTextLayer = new pdfjsViewer.TextLayerBuilder({ pdfPage: _page });
  container.style.setProperty('--scale-factor', `${scale}`);
  container.appendChild(pdfTextLayer.div);

  return await pdfTextLayer.render(viewport);
};
