import { StickerText } from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useCreateDefaultTextSticker } from '@/hooks/sticker/create/useCreateDefaultTextSticker';
import { Tooltip } from '@/ui/Tooltip/Tooltip';

export const CreateTextStickerButton = () => {
  const { universeId } = useParams({ strict: false });
  const { handleCreateDefaultText } = useCreateDefaultTextSticker();

  return (
    <Tooltip content={'Create text'}>
      <EditDockItem
        onClick={(event) => handleCreateDefaultText(event.shiftKey)}
        disabled={universeId === null}
        data-testid={'create-text-sticker-button'}
      >
        <StickerText />
      </EditDockItem>
    </Tooltip>
  );
};
