import { Minus, Plus, ViewWholeCanvas } from '@lithium/spectrum';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import styles from '@/components/ZoomDock/ZoomDock.module.css';
import { ZoomSlider } from '@/components/ZoomDock/ZoomSlider';
import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';
import { MenuContent } from '@/ui/MenuPrimitive/MenuContent';
import { Tooltip } from '@/ui/Tooltip/Tooltip';
import { Camera } from '@/utils/camera/camera';

interface ZoomButtonsProps {
  camera: Camera;
  zoomIn: () => void;
  zoomOut: () => void;
  zoomReset: () => void;
  setZoom: (zoom: number) => void;
}

export const ZoomDock = ({ zoomIn, zoomOut, setZoom }: ZoomButtonsProps) => {
  const handlers = useUniverseContextActions();
  return (
    <MenuContent className={styles.zoomDock}>
      <Tooltip content={'Zoom in'}>
        <EditDockItem onClick={zoomIn}>
          <Plus />
        </EditDockItem>
      </Tooltip>
      <ZoomSlider setZoom={setZoom} />
      <Tooltip content={'Zoom out'}>
        <EditDockItem onClick={zoomOut}>
          <Minus />
        </EditDockItem>
      </Tooltip>
      <Tooltip content={'Zoom to fit'}>
        <EditDockItem onClick={handlers.zoomToFit}>
          <ViewWholeCanvas />
        </EditDockItem>
      </Tooltip>
    </MenuContent>
  );
};
