import { Contacts, Settings } from '@lithium/spectrum';
import { ReactElement, useState } from 'react';

import { CommonModalProps } from '@/components/modal/Modals';
import styles from '@/components/modal/modals/Settings/components/SettingsTabsItem/SettingsTabs.module.css';
import { SettingsTabsItem } from '@/components/modal/modals/Settings/components/SettingsTabsItem/SettingsTabsItem';
import { TeamGeneralSettings } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamGeneralSettings/TeamGeneralSettings';
import { TeamMembersSettings } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamMembersSettings/TeamMembersSettings';
import { TabsContent } from '@/ui/Tabs/Content/TabsContent';
import { Tabs } from '@/ui/Tabs/Tabs';
import { capitalizeFirstLetter } from '@/utils/string/string';

const STYLE_MAX_WIDTH = { width: 'clamp(320px, 90vw, 600px)' };

export type TeamSettingsTab =
  | 'settings'
  | 'members'
  | 'roles'
  | 'tags'
  | 'billing';

export type TeamSettingsProps = CommonModalProps & {
  tab: TeamSettingsTab;
};

export const TeamSettings = ({ closeModal, tab }: TeamSettingsProps) => {
  const [currentTab, setCurrentTab] = useState<TeamSettingsTab>(tab);
  const getTabItemNode = (icon: ReactElement, name: string) => {
    return <SettingsTabsItem icon={icon} name={name} key={name} />;
  };

  const createTabTrigger = (
    id: TeamSettingsTab,
    icon: ReactElement,
    name: string
  ) => ({
    id,
    node: getTabItemNode(icon, name),
  });

  const tabs: {
    id: TeamSettingsTab;
    icon: ReactElement;
    content: ReactElement;
  }[] = [
    {
      id: 'settings',
      icon: <Settings />,
      content: <TeamGeneralSettings closeModal={closeModal} />,
    },
    {
      id: 'members',
      icon: <Contacts />,
      content: <TeamMembersSettings closeModal={closeModal} />,
    },
    // { id: 'roles', icon: <Key />, content: <>Roles edit</> },
    // { id: 'tags', icon: <Tag />, content: <>Tags edit</> },
    // { id: 'billing', icon: <Creditcard />, content: <>Billing edit</> },
  ];

  const tabTriggers = tabs.map((tab) =>
    createTabTrigger(tab.id, tab.icon, capitalizeFirstLetter(tab.id))
  );

  const tabContents = tabs.map((tab) => {
    return { value: tab.id, content: tab.content };
  });

  return (
    <div key={currentTab} style={STYLE_MAX_WIDTH}>
      <Tabs
        listClassName={styles.tabsList}
        itemClassName={styles.tabsTriggerItem}
        value={currentTab}
        onValueChange={setCurrentTab as (tab: string) => void}
        triggers={tabTriggers}
      >
        {tabContents.map((tab) => (
          <TabsContent key={tab.value} value={tab.value}>
            {tab.content}
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
};
