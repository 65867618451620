import { tx } from '@instantdb/react';

import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { batchTransact } from '@/utils/db/db';
import { KosmikUser } from '@/utils/kosmik/user';

export const getUserName = (user: MultiplayerUser | KosmikUser) => {
  return user.name || user.email;
};

export const renameUser = (id: string, newName: string) => {
  batchTransact(tx.users?.[id]?.update({ name: newName }));
};
