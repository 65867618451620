import { useCallback, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';

import { contextActionStickerIdsAtom } from '@/atoms/context';
import { selectedStickerIdsAtom } from '@/atoms/selection';
import { localStickerFamily } from '@/atoms/sticker';
import { duplicateOffsetAtom } from '@/atoms/transform';
import { useStickerSelection } from '@/components/selection/useStickerSelection';
import { useRoom } from '@/context/Room/useRoom';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { usePaste } from '@/hooks/clipboard/usePaste';
import { useAddRemoveTemporaryStickers } from '@/hooks/sticker/useAddRemoveTemporaryStickers';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { useUniverseStickers } from '@/hooks/universe/useUniverseStickers';
import { useUndoableAutoLayout } from '@/hooks/useUndoableAutoLayout';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { AutoLayoutType } from '@/utils/autolayout/autolayout';
import { getPasteableItems } from '@/utils/clipboard/clipboardItem';
import { isInputLike } from '@/utils/element/isInputLike';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { comparableStickerType } from '@/utils/sticker/compare';
import { duplicateStickers } from '@/utils/sticker/sticker';

import { useSetPinnedSticker } from '../pinPanel/useSetPinnedSticker';
import { useZoomToFit } from './useZoomToFit';

/**
 * Returns the handlers for the universe context actions, defaults on acting
 * on the selected stickers in the canvas, but you can optionally force the
 * context stickers by setting the `contextActionStickerIdsAtom` to the set
 * of sticker ids you want the actions to be performed on
 */
export const useUniverseContextActions = () => {
  const universe = useUniverseContext();
  const { room } = useRoom();
  const publishTemporaryStickers = room.usePublishTopic('addTemporaryStickers');
  const getSelectedStickerIds = useCallback(
    () =>
      getRecoil(contextActionStickerIdsAtom) ??
      getRecoil(selectedStickerIdsAtom),
    []
  );
  const { setNewSelection, unselect } = useStickerSelection();
  const { stickers: universeStickers } = useUniverseStickers(universe);
  const duplicateOffset = useRecoilValue(duplicateOffsetAtom);
  const { addTemporaryStickers } = useAddRemoveTemporaryStickers(universe.id);
  const { isUniverseMember } = useContext(UniverseContext);
  const { pasteFiles, pasteText, pasteUrls, pasteStickers } = usePaste();
  const { addUndoRedoAction } = useUndoRedo(universe.id);
  const { undo, redo } = useUndoRedo(universe.id);
  const { deleteStickers, undoableDeleteStickers } = useDeleteStickers();
  const { undoableAutoLayout } = useUndoableAutoLayout();
  const { zoomToFit: handleZoomToFit, zoomToFitHtmlContent } = useZoomToFit();
  const { setPinnedSticker } = useSetPinnedSticker();

  /**
   * Select all the stickers in the universe
   */
  const selectAll = useCallback(() => {
    const newSelection = new Set(
      universeStickers.map((sticker) => sticker.id) ?? []
    );
    setNewSelection(newSelection);
  }, [setNewSelection, universeStickers]);

  /**
   * Deselect all stickers
   */
  const deselectAll = useCallback(() => {
    unselect();
  }, [unselect]);

  /**
   * Delete selected stickers
   */
  const deleteSelected = useCallback(
    (keepFiles?: boolean) => {
      if (isUniverseMember) {
        const selectedStickerIds = getSelectedStickerIds();
        const targetIds = [...selectedStickerIds];
        const stickersToDelete = universeStickers.filter((sticker) =>
          targetIds.includes(sticker.id)
        );
        undoableDeleteStickers(stickersToDelete, keepFiles);
      }
    },
    [
      isUniverseMember,
      getSelectedStickerIds,
      universeStickers,
      undoableDeleteStickers,
    ]
  );

  /**
   * Duplicate selected stickers in the universe
   */
  const duplicateSelected = useCallback(() => {
    if (isUniverseMember) {
      const selectedStickerIds = getSelectedStickerIds();
      let duplicatedStickers: KosmikSticker[] = [];
      const duplicateAction: UndoRedoActionType = {
        do: () => {
          const selectedStickers =
            universeStickers.filter((sticker) =>
              selectedStickerIds.has(sticker.id)
            ) ?? [];
          const areStickersDuplicated = duplicatedStickers.length > 1;

          const stickers = areStickersDuplicated
            ? duplicatedStickers
            : selectedStickers;

          const { newIds, newStickers } = duplicateStickers(
            stickers,
            universe.id,
            { offset: duplicateOffset }
          );
          duplicatedStickers = newStickers;
          addTemporaryStickers(newStickers);
          publishTemporaryStickers(newStickers);
          setNewSelection(new Set(newIds));
        },
        undo: () => {
          deleteStickers(duplicatedStickers);
        },
      };

      duplicateAction.do();
      addUndoRedoAction(duplicateAction);
    }
  }, [
    isUniverseMember,
    getSelectedStickerIds,
    addUndoRedoAction,
    universeStickers,
    universe.id,
    duplicateOffset,
    addTemporaryStickers,
    publishTemporaryStickers,
    setNewSelection,
    deleteStickers,
  ]);

  /**
   * Zoom to fit selected stickers
   */
  const zoomToFit = useCallback(() => {
    const selectedStickerIds = getSelectedStickerIds();
    handleZoomToFit(selectedStickerIds);
  }, [handleZoomToFit, getSelectedStickerIds]);

  /**
   * Copy the selected stickers to the clipboard
   */
  const copy = useCallback(async () => {
    if (isInputLike(document.activeElement)) {
      return;
    }
    try {
      const selectedStickerIds = getSelectedStickerIds();
      const selected =
        universeStickers.filter((sticker) =>
          selectedStickerIds.has(sticker.id)
        ) ?? [];
      const jsonData = JSON.stringify(selected);
      const jsonBlob = new Blob([jsonData], { type: 'text/plain' });
      await navigator.clipboard.write([
        new ClipboardItem({
          [jsonBlob.type]: jsonBlob,
        }),
      ]);
    } catch (error) {
      console.error(error);
    }
  }, [getSelectedStickerIds, universeStickers]);

  /**
   * Paste the clipboard content
   */
  const paste = useCallback(async () => {
    if (!isUniverseMember) {
      return;
    }
    document.body.style.cursor = 'wait';
    const clipboard = await navigator.clipboard.read();
    document.body.style.cursor = 'default';
    const { files, urls, texts, stickers } = await getPasteableItems(clipboard);
    let pastedItems: KosmikSticker[] = [];
    const pasteAction: UndoRedoActionType = {
      do: async () => {
        pastedItems = [];
        if (files.length) {
          pastedItems.push(...pasteFiles(files));
        }
        if (stickers.length) {
          pastedItems.push(...pasteStickers(stickers));
        }
        if (urls.length) {
          pastedItems.push(...(await pasteUrls(urls)));
        }
        if (texts.length) {
          pastedItems.push(...(await pasteText(texts.join('\n'))));
        }
      },
      undo: () => {
        if (pastedItems.length) {
          deleteStickers(pastedItems, true);
        }
      },
    };
    pasteAction.do();
    addUndoRedoAction(pasteAction);
  }, [
    isUniverseMember,
    addUndoRedoAction,
    pasteFiles,
    pasteStickers,
    pasteUrls,
    pasteText,
    deleteStickers,
  ]);

  /**
   * Cut the selected stickers
   */
  const cut = useCallback(() => {
    if (isInputLike(document.activeElement) || !isUniverseMember) {
      return;
    }
    copy();
    deleteSelected(true);
  }, [copy, deleteSelected, isUniverseMember]);

  /**
   * Pin the selected sticker when only 1 is selected
   */
  const pin = useCallback(() => {
    const ids = [...getRecoil(selectedStickerIdsAtom)];
    const [firstId] = ids;
    if (!firstId || ids.length !== 1) {
      return;
    }

    setPinnedSticker(firstId);
  }, [setPinnedSticker]);

  /**
   * Select all the stickers of the same type of the currently selected stickers
   */
  const selectAllOfType = useCallback(() => {
    const selectedStickerIds = getSelectedStickerIds();
    const allStickersOfType: Set<string> = new Set();

    // Create a set of all the selected sticker types
    const selectedTypes = new Set(
      Array.from(selectedStickerIds).map((id) => {
        const sticker = getRecoil(localStickerFamily(id));
        return comparableStickerType(sticker);
      })
    );

    // Add all stickers matching selected types
    universeStickers.forEach((sticker) => {
      if (selectedTypes.has(comparableStickerType(sticker))) {
        allStickersOfType.add(sticker.id);
      }
    });

    // Set selection
    if (allStickersOfType.size !== 0) {
      setNewSelection(allStickersOfType);
    }
  }, [getSelectedStickerIds, setNewSelection, universeStickers]);

  /**
   * Autolayout the selected stickers
   */
  const autolayoutSelected = useCallback(
    (layoutType: AutoLayoutType) => {
      const selectedStickerIds = getSelectedStickerIds();
      const selectedStickers = Array.from(selectedStickerIds)
        .map((id) => getRecoil(localStickerFamily(id)))
        .filter(Boolean) as KosmikSticker[];

      undoableAutoLayout(layoutType, selectedStickers);
    },
    [getSelectedStickerIds, undoableAutoLayout]
  );

  return {
    autolayoutSelected,
    selectAllOfType,
    selectAll,
    deselectAll,
    deleteSelected,
    duplicateSelected,
    zoomToFit,
    zoomToFitHtmlContent,
    copy,
    paste,
    cut,
    pin,
    undo,
    redo,
  };
};
