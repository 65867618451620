import { ComponentProps, useState } from 'react';

export const AutoResizingInput = ({
  className,
  style,
  onChange,
  ...props
}: ComponentProps<'input'>) => {
  const [value, setValue] = useState<string>(
    (props?.defaultValue as string) ?? props?.value ?? ''
  );

  return (
    <div
      className={className}
      style={{ minWidth: '12ch', ...style, position: 'relative' }}
    >
      <div style={{ opacity: 0, userSelect: 'none' }}>
        {value.length == 0 ? '1' : value}
      </div>
      <input
        className={className}
        style={{
          ...style,
          position: 'absolute',
          inset: 0,
          width: '100%',
          height: '100%',
        }}
        onChange={(event) => {
          setValue(event.target.value);
          onChange?.(event);
        }}
        {...props}
      />
    </div>
  );
};
