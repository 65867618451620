import { HTMLProps } from 'react';

import styles from './FormStack.module.css';

export const FormStack = ({
  className,
  ...props
}: HTMLProps<HTMLDivElement>) => {
  return <div className={`${styles.formstack} ${className}`} {...props} />;
};
