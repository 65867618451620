import { Ellipse } from '@/utils/geometry/ellipse';
import { Position, PositionTuple } from '@/utils/geometry/position';
import { rotateAroundEllipse } from '@/utils/geometry/rotate';

/**
 * Create a polygon geometry based on specified parameters
 * @param numPoints how many points should the polygon have
 * @param width width of the polygon
 * @param height height of the polygon
 * @param position position (top left) of the polygon
 */
export const createPolygon = (
  numPoints = 3,
  width = 100,
  height = 100,
  position: Position = { x: 0, y: 0 }
) => {
  const center = {
    x: position.x + width / 2,
    y: position.y + height / 2,
  };

  const ellipse: Ellipse = {
    center,
    rx: width / 2,
    ry: height / 2,
  };

  const stepAngle = (2 * Math.PI) / numPoints;
  const points: PositionTuple[] = [];

  for (let i = 0; i < numPoints; i++) {
    const angle = stepAngle * i + Math.PI / 2;
    const pointPosition = rotateAroundEllipse(ellipse, angle);
    points.push([pointPosition.x, pointPosition.y]);
  }
  return points;
};
