import { useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { useNoControlsFocus } from '@/components/HTMLCanvas/stickers/hooks/useNoControlsFocus';
import { useSetDefaultFileResizable } from '@/components/HTMLCanvas/stickers/hooks/useSetDefaultResizable';
import { HtmlGenericFileSticker } from '@/components/HTMLCanvas/stickers/HtmlGenericFileSticker';
import { HtmlLoadingSticker } from '@/components/HTMLCanvas/stickers/HtmlLoadingSticker';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { useSetStickerProperties } from '@/hooks/sticker/useSetStickerProperties';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';
import { offscreenAudio } from '@/utils/media/offscreen';

export const HtmlAudioSticker = ({
  sticker,
  refreshCachedUrl,
}: {
  sticker: KosmikFileSticker;
  refreshCachedUrl: () => void;
}) => {
  const [error, setError] = useState<Error | MediaError | null>(null);
  const file = sticker.files?.[0];
  const { type, cached_url } = file ?? sticker.attributes;
  const { ratio } = sticker.attributes;
  const audioRef = useRef<HTMLAudioElement>(null);
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerProperties =
    useSetStickerProperties<KosmikFileSticker>(setSticker);
  const setStickerAttributes =
    useSetStickerAttributes<FileStickerAttributes>(setSticker);
  const spaceRef = useNoControlsFocus({ ref: audioRef });
  const refs = mergeRefs([audioRef, spaceRef]);
  useSetDefaultFileResizable(sticker);

  useEffect(() => {
    if (error && cached_url) {
      refreshCachedUrl();
    }
  }, [cached_url, error, refreshCachedUrl]);

  useEffect(() => {
    setError(null);
  }, [cached_url]);

  return offscreenAudio.canPlayType(type ?? '') ? (
    <HtmlLoadingSticker stickerId={sticker.id} isLoading={!cached_url}>
      {cached_url ? (
        <audio
          /* The key has to change so the video properly picks new sources */
          key={cached_url}
          ref={refs}
          controls={true}
          data-testid={'audio'}
          style={{ width: '100%' }}
          onLoadedMetadata={() => {
            const audio = audioRef.current;
            if (!audio) {
              return;
            }
            const audioWidth = audio.offsetWidth;
            const audioHeight = audio.offsetHeight;
            if (!audioWidth || !audioHeight) {
              return;
            }
            const audioRatio = audioWidth / audioHeight;

            if (audioRatio !== ratio) {
              setStickerProperties(
                {
                  width: audioWidth,
                  height: audioHeight,
                },
                true
              );
              setStickerAttributes({ ratio: audioRatio }, true);
            }
          }}
        >
          <source
            data-testid={'source'}
            src={cached_url}
            type={type}
            onError={() => {
              const error = audioRef.current?.error;
              setError(error ?? new Error('Undefined audio error'));
            }}
          />
        </audio>
      ) : null}
    </HtmlLoadingSticker>
  ) : (
    <HtmlGenericFileSticker
      sticker={sticker}
      refreshCachedUrl={refreshCachedUrl}
    />
  );
};
