import { useCallback } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { stickerWebViewAttributesFamily } from '@/atoms/sticker';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import {
  KosmikWebSticker,
  WebStickerAttributes,
} from '@/utils/kosmik/stickers/web';
import { clamp } from '@/utils/number/number';
import { MaybeNull } from '@/utils/types';

interface UseWebStickerHistoryNavigationProps {
  stickerId: string;
  attributes: KosmikWebSticker['attributes'];
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}

/**
 * Returns appropriate handlers related to the web sticker history
 */
export const useWebNavigation = ({
  stickerId,
  attributes,
  setSticker,
}: UseWebStickerHistoryNavigationProps) => {
  const setStickerAttributes =
    useSetStickerAttributes<WebStickerAttributes>(setSticker);
  const [historyIndex, setHistoryIndex] = useRecoilState(
    stickerWebViewAttributesFamily(stickerId)
  );
  const historyMax = Math.max((attributes.history?.length ?? 0) - 1, 0);

  const handleNewUrl = useCallback(
    (newUrl: string) => {
      const newIndex = Number(historyMax) + 1;
      if (newUrl) {
        setHistoryIndex(newIndex);
        setStickerAttributes({ url: newUrl }, true);
      }
    },
    [historyMax, setHistoryIndex, setStickerAttributes]
  );

  const goBack = useCallback(() => {
    const newIndex = clamp(0, historyIndex - 1, historyMax);
    const newUrl = attributes.history?.[newIndex];
    if (newUrl) {
      setHistoryIndex(newIndex);
      setStickerAttributes({ url: newUrl }, false);
    }
  }, [
    historyIndex,
    historyMax,
    attributes.history,
    setHistoryIndex,
    setStickerAttributes,
  ]);

  const goForward = useCallback(() => {
    const newIndex = clamp(0, historyIndex + 1, historyMax);
    const newUrl = attributes.history?.[newIndex];
    if (newUrl) {
      setHistoryIndex(newIndex);
      setStickerAttributes({ url: newUrl }, false);
    }
  }, [
    historyIndex,
    historyMax,
    attributes.history,
    setHistoryIndex,
    setStickerAttributes,
  ]);

  return {
    canGoBack: historyIndex === 0,
    canGoForward: historyIndex === historyMax,
    goBack,
    goForward,
    handleNewUrl,
  };
};
