import { ForwardedRef, forwardRef, HTMLAttributes } from 'react';

import { Text } from '@/components/fontSystem/Text';

export const TextHeader3 = forwardRef(
  (
    props: HTMLAttributes<HTMLHeadingElement>,
    ref: ForwardedRef<HTMLHeadingElement>
  ) => {
    return <Text ref={ref} variant={'header-3'} {...props} />;
  }
);

TextHeader3.displayName = 'TextHeader3';
