export type OS =
  | 'unknown'
  | 'macOS'
  | 'iPadOS'
  | 'iOS'
  | 'windows'
  | 'android'
  | 'linux';

export const detectOS = (
  userAgent = navigator.userAgent,
  platform = navigator?.userAgentData?.platform || navigator.platform
): OS => {
  const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os: OS = 'unknown';

  if (macosPlatforms.includes(platform)) {
    os = 'macOS';
  } else if (iosPlatforms.includes(platform)) {
    os = platform === 'iPad' ? 'iPadOS' : 'iOS';
  } else if (windowsPlatforms.includes(platform)) {
    os = 'windows';
  } else if (/Android/.test(userAgent)) {
    os = 'android';
  } else if (/Linux/.test(platform)) {
    os = 'linux';
  }

  return os;
};
