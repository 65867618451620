import { RefObject } from 'react';

import { SelectionArea } from '@/components/selection/SelectionArea';
import { useSelectionArea } from '@/components/selection/useSelectionArea';

interface LocalSelectionAreaProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const LocalSelectionArea = ({
  containerRef,
}: LocalSelectionAreaProps) => {
  const { selectionArea } = useSelectionArea(containerRef);
  return (
    <SelectionArea color={'var(--accent-blue-primary)'} area={selectionArea} />
  );
};
