import { createContext, ReactNode } from 'react';

export interface Notification {
  id: string;
  element: ReactNode;
}

interface NotificationsContext {
  visibility: boolean;
  setVisibility: (state: boolean) => void;
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  dismissNotification: (id: string) => void;
  dismissAll: () => void;
}

export const NotificationsContext = createContext<NotificationsContext>({
  visibility: false,
  setVisibility: () => {},
  notifications: [],
  addNotification: () => {},
  dismissNotification: () => {},
  dismissAll: () => {},
});
