import isUrl from 'is-url-superb';

import { MaybeNull } from '@/utils/types';
import { isUriList, parseUriList } from '@/utils/url/uri';

/**
 * Get the urls from the given dataTransfer, using text/uri-list format for
 * multiple urls
 */
export const getUrls = (dataTransfer: MaybeNull<DataTransfer>) => {
  const uriList = dataTransfer?.getData('text/uri-list');
  const parsedUriList = uriList ? parseUriList(uriList) : [];

  if (parsedUriList.length) {
    return parsedUriList;
  }

  const plain = dataTransfer?.getData('text/plain').trim();
  if (plain) {
    if (isUriList(plain)) {
      return parseUriList(plain);
    } else if (isUrl(plain) && !/\n/.test(plain)) {
      return [plain];
    }
  }

  return null;
};

/**
 * Get the text from the given dataTransfer, favoring html if any, or plain text
 */
export const getText = (dataTransfer: MaybeNull<DataTransfer>) => {
  const html = dataTransfer?.getData('text/html');
  if (html) {
    // We put the html in a template to make sure it actually contains useful text
    // for example, we dismiss the html if it's just markup
    const template = document.createElement('template');
    template.innerHTML = html;
    // eslint-disable-next-line no-control-regex
    return template.content.textContent ? html.replace(/\x00/g, '') : null;
  }

  const plain = dataTransfer?.getData('text/plain');
  if (plain) {
    // eslint-disable-next-line no-control-regex
    return plain.replace(/\x00/g, '');
  }

  return null;
};

/**
 * Get files from dataTransfer.items, see the following note from MDN:
 * > The files property of DataTransfer objects can only be accessed from within
 *   the `drop` event. For all other events, the files property will be empty
 *   because its underlying data store will be in a protected mode.
 * This is true at least in Firefox (chrome gives you dataTransfer.files also
 * in a `paste` event), so instead of using dataTransfer.files we loop through
 * items and perform .getAsFile() on the ones that are of kind `file`
 */
export const getFiles = (dataTransfer: MaybeNull<DataTransfer>): File[] => {
  if (!dataTransfer?.items) {
    return [];
  }
  const files: File[] = [];
  for (const item of dataTransfer.items) {
    if (item.kind === 'file') {
      const file = item.getAsFile();
      if (file) {
        files.push(file);
      }
    }
  }
  return files;
};
