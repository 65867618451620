import { ReactNode, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';

import { LoadingView } from '@/components/loading/LoadingView';
import { CreateTeamPage } from '@/components/modal/modals/Settings/CreateTeam/CreateTeamPage';
import { useUser } from '@/context/User/useUser';
import { KosmikTeam } from '@/utils/kosmik/team';
import { getTeamUserRole } from '@/utils/team/team';
import { MaybeNull } from '@/utils/types';

import { TeamContext } from './TeamContext';

const getTeamLocalStorageKey = (userId?: string) => {
  return `team-${userId ?? uuidv4()}`;
};

export const TeamProvider = ({ children }: { children: ReactNode }) => {
  const user = useUser();
  const [teamId, setTeamId] = useLocalStorage<MaybeNull<string>>(
    getTeamLocalStorageKey(user?.id),
    null
  );

  const availableTeams = useMemo(
    () =>
      Array.from(
        new Map(
          [
            ...(user?.team_ownerships.flatMap((ownership) => ownership.teams) ??
              []),
            ...(user?.teams ?? []),
          ].map((team) => [team.id, team])
        ).values()
      ),
    [user?.team_ownerships, user?.teams]
  );

  const selectedTeam = useMemo(() => {
    return (
      availableTeams.find((team) => team.id === teamId) ?? availableTeams[0]
    );
  }, [availableTeams, teamId]);

  const setTeam = useCallback(
    (team: KosmikTeam) => {
      setTeamId(team.id);
    },
    [setTeamId]
  );

  const currentTeam = selectedTeam;

  if (user && availableTeams.length === 0) {
    return <CreateTeamPage />;
  }

  if (currentTeam === undefined) {
    return <LoadingView />;
  }

  const role = getTeamUserRole(currentTeam, user);

  return (
    <TeamContext.Provider
      value={{
        team: currentTeam,
        teams: availableTeams,
        role,
        setTeam,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
