import { PDFPageProxy } from 'pdfjs-dist';
import React, { HTMLAttributes, useRef } from 'react';

import { useSetPdfCanvasSize } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useSetPdfCanvasSize';

import styles from './PdfPage.module.css';

interface PdfPageProps extends HTMLAttributes<HTMLDivElement> {
  pageProxy: PDFPageProxy;
}

export const DATA_ATTRIBUTE_PAGE = 'page';

export const DATA_ATTRIBUTE_VISIBLE = 'visible';

export const DATA_ATTRIBUTE_RENDERED = 'rendered';

export const DATA_ATTRIBUTE_PAGE_WRAPPER = 'page-wrapper';

export const PdfPage = React.forwardRef<HTMLDivElement, PdfPageProps>(
  ({ pageProxy, ...htmlProps }, ref) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    useSetPdfCanvasSize(canvasRef, pageProxy);

    return (
      <div
        className={styles.pageContainer}
        ref={ref}
        {...{ [`data-${DATA_ATTRIBUTE_PAGE}`]: pageProxy.pageNumber }}
        {...htmlProps}
      >
        <div
          className={styles.pageWrapper}
          {...{ [`data-${DATA_ATTRIBUTE_PAGE_WRAPPER}`]: '' }}
        >
          <canvas className={styles.pageCanvas} ref={canvasRef} />
        </div>
      </div>
    );
  }
);

PdfPage.displayName = 'PdfPage';
