import React, { ComponentProps } from 'react';

import styles from './MenuSeparator.module.css';

export type MenuSeparatorOrientation = 'horizontal' | 'vertical';

export type MenuSeparatorProps = {
  orientation?: MenuSeparatorOrientation;
} & ComponentProps<'div'>;

export const MenuSeparator = React.forwardRef<
  HTMLDivElement,
  MenuSeparatorProps
>(({ orientation = 'horizontal', ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-orientation={orientation}
      className={styles.menuSeparator}
      {...props}
    />
  );
});

MenuSeparator.displayName = 'MenuSeparator';
