import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  Capture,
  Embed,
} from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';
import { useCallback, useEffect, useState } from 'react';
import { SetterOrUpdater } from 'recoil';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useWebNavigation } from '@/components/EditDock/WebEditDock/hooks/useWebNavigation';
import { UrlField } from '@/components/EditDock/WebEditDock/UrlField';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { useIsDev } from '@/hooks/env/useIsDev';
import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { getCenterPoint, getCurrentCameraValues } from '@/utils/camera/camera';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';
import { createWebSticker } from '@/utils/sticker/create/createWebSticker';
import { MaybeNull } from '@/utils/types';
import { getWebview } from '@/utils/webview';

interface WebEditDockProps {
  stickerId: string;
  attributes: KosmikWebSticker['attributes'];
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}

export const WebEditDock = (props: WebEditDockProps) => {
  const { canGoBack, canGoForward, goBack, goForward, handleNewUrl } =
    useWebNavigation(props);
  const isPinPanel = usePinPanel();
  const { universeId } = useParams({ strict: false });
  const [pns, setPns] = useState(false);
  const pushKPI = usePushKPI();
  const isDev = useIsDev();

  const handleBookmark = useCallback(() => {
    if (universeId) {
      const position = getCenterPoint(getCurrentCameraValues());
      createWebSticker(props.attributes.url, position, universeId);
      pushKPI({
        type: 'create',
        properties: {
          interaction: 'click',
          coType: 'web',
          coFormat: 'url',
          coSize: 0,
          coUniverse: universeId,
        },
      });
    }
  }, [universeId, props.attributes.url, pushKPI]);

  useEffect(() => {
    if (isPinPanel) {
      const handlePnsOn = () => {
        setPns(true);
      };

      const handlePnsOff = () => {
        setPns(false);
      };

      window.addEventListener('pns-on', handlePnsOn);
      window.addEventListener('pns-off', handlePnsOff);
      window.addEventListener('bookmark', handleBookmark);
      window.addEventListener('navigate-backwards', goBack);
      window.addEventListener('navigate-forwards', goForward);

      return () => {
        window.removeEventListener('pns-on', handlePnsOn);
        window.removeEventListener('pns-off', handlePnsOff);
        window.removeEventListener('bookmark', handleBookmark);
        window.removeEventListener('navigate-backwards', goBack);
        window.removeEventListener('navigate-forwards', goForward);
      };
    }
  }, [
    isPinPanel,
    goBack,
    goForward,
    props.attributes.url,
    universeId,
    handleBookmark,
  ]);

  if (isPinPanel) {
    return (
      <>
        <EditDockItem
          active={pns}
          onClick={() => {
            if (pns) {
              getWebview(props.stickerId)?.send('stop-pns');
            } else {
              getWebview(props.stickerId)?.send('start-pns');
              getWebview(props.stickerId)?.focus();
            }
          }}
        >
          <Capture />
        </EditDockItem>
        {isDev ? (
          <EditDockItem
            onClick={(event) => {
              const webview = getWebview(props.stickerId);
              if (!webview) {
                return;
              }
              if (webview.isDevToolsOpened()) {
                webview.closeDevTools();
                event.currentTarget.removeAttribute('data-state');
              } else {
                webview.openDevTools();
                event.currentTarget.setAttribute('data-state', 'active');
              }
            }}
          >
            <Embed />
          </EditDockItem>
        ) : null}
        <EditDockItem
          disabled={canGoBack}
          onClick={goBack}
          data-testid={'historybutton'}
        >
          <ArrowLeft />
        </EditDockItem>
        <EditDockItem
          disabled={canGoForward}
          onClick={goForward}
          data-testid={'historybutton'}
        >
          <ArrowRight />
        </EditDockItem>
        <UrlField url={props.attributes.url} handleNewUrl={handleNewUrl} />
        <EditDockItem onClick={handleBookmark} data-testid={'bookmarkbutton'}>
          <Bookmark />
        </EditDockItem>
      </>
    );
  }

  return <UrlField url={props.attributes.url} handleNewUrl={handleNewUrl} />;
};
