import { PDFDocumentProxy } from 'pdfjs-dist';
import useSWR from 'swr';

import { MaybeUndefined } from '@/utils/types';

const fetcher = (pdf: PDFDocumentProxy) => {
  return Promise.all(
    Array.from({ length: pdf.numPages }).map((_, index) => {
      return pdf.getPage(index + 1).catch();
    })
  ).then((pages) => {
    return pages.sort((a, b) => a.pageNumber - b.pageNumber);
  });
};

const swrPageProxiesOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateIfStale: false,
  dedupingInterval: 3600 * 24,
};

export const usePageProxies = (pdf: MaybeUndefined<PDFDocumentProxy>) => {
  const { data } = useSWR(pdf ?? null, fetcher, swrPageProxiesOptions);

  return data ?? [];
};
