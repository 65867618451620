import { id, tx } from '@instantdb/react';

import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { batchTransact } from '@/utils/db/db';
import { Position } from '@/utils/geometry/position';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';

export const createTextSticker = (
  content: string,
  position: Position,
  universeId: string,
  source?: string
): KosmikTextSticker => {
  const stickerId = id();
  const size = DEFAULT_STICKER_SIZE.text;

  const sticker: Omit<KosmikTextSticker, 'id'> = {
    v: 0,
    ...position,
    width: size.width,
    height: size.height,
    type: 'text',
    resizeable: true,
    created_at: new Date().toISOString(),
    attributes: {
      text_content: content,
      source: source,
    },
  };
  batchTransact(
    tx.stickers?.[stickerId]?.update(sticker).link({ universes: [universeId] })
  );
  return { id: stickerId, ...sticker };
};
