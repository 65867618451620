import { Button, ButtonShortcut } from '@lithium/spectrum';
import debounce from 'debounce';
import { useEffect, useRef, useState } from 'react';
import { SetterOrUpdater } from 'recoil';

import { EditDockItemWithChevron } from '@/components/EditDock/components/EditDockItemWithChevron';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { useFetchStream } from '@/hooks/useFetchStream';
import { DropdownSelect } from '@/ui/Dropdown/DropdownSelect';
import { GenerateResponse } from '@/utils/kosmik/AI/api';
import { ModelType } from '@/utils/kosmik/AI/type';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikPromptSticker } from '@/utils/kosmik/stickers/prompt';
import { MaybeNull } from '@/utils/types';

export const PromptEditDock = ({
  attributes,
  setSticker,
}: {
  attributes: KosmikPromptSticker['attributes'];
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}) => {
  const [inputValue, setInputValue] = useState(attributes.prompt ?? '');

  const setAttributes = useSetStickerAttributes<typeof attributes>(setSticker);
  const { data, isLoading, start } = useFetchStream<GenerateResponse>(
    'https://serge.kosmik.app/api/generate'
  );

  const update = useRef(
    debounce((prompt) => {
      setAttributes({ prompt }, true);
    }, 200)
  );

  const handleGenerate = async () => {
    await start(
      `{\n  "model": "${currentModel}",\n  "prompt": "${inputValue}",\n  "stream": true\n}`
    );

    // clear previous response
    setAttributes({ response: '' }, true);
  };

  // sync received data with instantdb
  useEffect(() => {
    const newResponse = data
      .map((chunk) => {
        return chunk?.response;
      })
      .filter((chunk) => chunk)
      .join('');

    if (newResponse.length > 0 && newResponse !== attributes.response) {
      setAttributes({ response: newResponse }, true);
    }
  }, [attributes, data, setAttributes]);

  const currentModel = ModelType.parse(
    attributes.model ?? ModelType.enum.llama3
  );

  return (
    <>
      <DropdownSelect<ModelType>
        trigger={
          <EditDockItemWithChevron>{currentModel}</EditDockItemWithChevron>
        }
        value={currentModel}
        options={[
          { label: ModelType.enum.llama3, value: ModelType.enum.llama3 },
        ]}
        onValueChange={(value) => setAttributes({ model: value }, true)}
      />
      <input
        value={inputValue}
        style={{ width: '100%' }}
        placeholder={'Chat with Kosmo...'}
        onChange={(event) => {
          setInputValue(event.currentTarget.value);
          update.current(event.currentTarget.value);
        }}
      />
      <Button
        variant={'positive'}
        shortcut={ButtonShortcut.Enter}
        onAction={handleGenerate}
        loading={isLoading}
      >
        Generate
      </Button>
    </>
  );
};
