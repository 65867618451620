import React, { ComponentProps } from 'react';

import styles from '@/components/EditDock/components/EditDockField.module.css';

export const EditDockField = React.forwardRef<
  HTMLDivElement,
  ComponentProps<'div'>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={[styles.editDockField, className].join(' ')}
      {...props}
    />
  );
});

EditDockField.displayName = 'EditDockField';
