import { ReactNode, useEffect, useState } from 'react';

import {
  Notification,
  NotificationsContext,
} from '@/context/Notifications/NotificationsContext';

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps) => {
  const [visibility, setVisibility] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // hide list when notifications is 0
  useEffect(() => {
    if (notifications.length === 0) {
      setVisibility(false);
    }
  }, [notifications.length]);

  const addNotification = (notification: Notification) => {
    setNotifications((prev) => {
      if (prev.every((item) => item.id !== notification.id)) {
        // Open notifications when new notification is added
        if (!visibility) {
          setVisibility(true);
        }
        return [notification, ...prev];
      }
      return prev;
    });
  };

  const dismissNotification = (id: string) => {
    const newNotification = [...notifications].filter(
      (notification) => notification.id !== id
    );

    setNotifications(newNotification);
  };

  const dismissAll = () => {
    setNotifications([]);
  };

  return (
    <NotificationsContext.Provider
      value={{
        visibility,
        setVisibility,
        notifications: notifications,
        addNotification,
        dismissNotification,
        dismissAll,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
