import { PDFPageProxy } from 'pdfjs-dist';

import { PAGEVIEW } from '@/hooks/pdf/usePdf';
import { isDefined } from '@/utils/isDefined';

interface GetPageScaledViewportProps {
  page: PDFPageProxy;
  size?: { width?: number; height?: number };
  scale?: number;
}

export const getPageScaledViewport = ({
  page,
  size,
  scale = 1,
}: GetPageScaledViewportProps) => {
  if (!page.view[PAGEVIEW.width] || !page.view[PAGEVIEW.height]) {
    return {
      viewport: page.getViewport({ scale }),
      pageSize: { width: 0, height: 0 },
    };
  }

  const pdfRatio = page.view[PAGEVIEW.width] / page.view[PAGEVIEW.height];
  const _size = {
    width: isDefined(size?.width)
      ? size.width
      : isDefined(size?.height)
        ? size.height * pdfRatio
        : page.view[PAGEVIEW.width],
    height: isDefined(size?.height)
      ? size.height
      : isDefined(size?.width)
        ? size.width / pdfRatio
        : page.view[PAGEVIEW.height],
  };
  const viewport = page.getViewport({
    scale: Math.min((_size.width / page.view[PAGEVIEW.width]) * scale, 8),
  });

  return {
    viewport,
    pageSize: _size,
  };
};
