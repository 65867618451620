import Quill from 'quill';
import { useContext, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSetRecoilState } from 'recoil';

import { stickerQuillAttributesFamily } from '@/atoms/sticker';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { MaybeUndefined } from '@/utils/types';

export type UseQuillActivationProps = {
  quill: MaybeUndefined<Quill>;
  stickerId: string;
  isSelected: boolean;
};

export const useQuillActivation = ({
  quill,
  stickerId,
  isSelected,
}: UseQuillActivationProps) => {
  const setAttributes = useSetRecoilState(
    stickerQuillAttributesFamily(stickerId)
  );
  const { isUniverseMember } = useContext(UniverseContext);
  useHotkeys(
    'enter',
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      quill?.enable();
      quill?.setSelection(quill.getLength(), 0);
    },
    { enabled: isSelected },
    [quill]
  );

  useEffect(() => {
    quill?.keyboard.addBinding(
      { key: 'Escape' },
      (_: Range, { event }: { event: KeyboardEvent }) => {
        event.preventDefault();
        event.stopPropagation();
        quill.setSelection(null);
        quill.disable();
        setAttributes({});
        const focusableElement = quill.container.closest(
          '[tabindex]'
        ) as HTMLElement;
        focusableElement?.focus();
      }
    );
  }, [quill, setAttributes]);

  useEffect(() => {
    if (quill && isSelected && isUniverseMember) {
      const handlePointerDown = (event: PointerEvent) => {
        if (!quill.isEnabled()) {
          quill.enable();
        }

        if (getComputedStyle(event.target as HTMLElement).cursor === 'text') {
          event.stopPropagation();
        }
      };
      quill.container.addEventListener('pointerdown', handlePointerDown);

      return () => {
        quill.container.removeEventListener('pointerdown', handlePointerDown);
      };
    }
  }, [isSelected, isUniverseMember, quill]);

  useEffect(() => {
    if (quill) {
      if (isSelected && isUniverseMember) {
        setAttributes(quill.getFormat(0, quill.getLength()));
      } else {
        quill.setSelection(null);
        quill.disable();
        setAttributes({});
      }
    }
  }, [quill, isSelected, setAttributes, isUniverseMember]);
};
