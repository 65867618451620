export interface Position {
  x: number;
  y: number;
}

export type PositionTuple = [number, number];

/**
 * Get minimum and maximum x and y positions in a given array of position tuples
 * @param positions
 */
export const getMinMax = (positions: PositionTuple[]) => {
  const xValues: number[] = [];
  const yValues: number[] = [];
  positions.forEach(([x, y]) => {
    xValues.push(x);
    yValues.push(y);
  });
  return {
    minX: Math.min(...xValues),
    maxX: Math.max(...xValues),
    minY: Math.min(...yValues),
    maxY: Math.max(...yValues),
  };
};

/**
 * Get the offset position to center the given points within their bounding box
 * @param points
 * @param width
 * @param height
 */
export const getOffsetPosition = (
  points: PositionTuple[],
  width: number,
  height: number
) => {
  const { minX, maxX, minY, maxY } = getMinMax(points);
  return {
    x: (width - maxX - minX) / 2,
    y: (height - maxY - minY) / 2,
  };
};

/**
 * Offset the given position by the specified x and y offset
 */
export const offsetPosition = (
  position: Position,
  offsetX: number = 25,
  offsetY: number = 25
) => {
  return {
    x: position.x + offsetX,
    y: position.y + offsetY,
  };
};
