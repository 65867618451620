import { createContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { KosmikTeam, TeamRole } from '@/utils/kosmik/team';

const mockTeam: KosmikTeam = {
  id: uuidv4(),
  universes: [],
  created_at: '2024-06-13T22:00:00.000Z',
  name: 'Default Team',
  users: [],
};

export interface TeamContext {
  team: KosmikTeam;
  teams: KosmikTeam[];
  role: TeamRole;
  setTeam: (team: KosmikTeam) => void;
}

export const TeamContext = createContext<TeamContext>({
  team: mockTeam,
  teams: [mockTeam],
  role: TeamRole.viewer,
  setTeam: () => {},
});
