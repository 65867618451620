import { createContext } from 'react';

import { KosmikUniverse } from '@/utils/kosmik/universe';

interface UniverseContextType extends KosmikUniverse {
  isUniverseMember: boolean;
}

export const mockKosmikUniverse: UniverseContextType = {
  id: 'default-id',
  name: 'Demo Universe',
  isPublished: false,
  isDuplicable: false,
  isUniverseMember: true,
  created_at: '2024-06-13T22:00:00.000Z',
};

export const UniverseContext =
  createContext<UniverseContextType>(mockKosmikUniverse);
