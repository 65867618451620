import { Button } from '@lithium/spectrum';
import { useNavigate } from '@tanstack/react-router';
import { CSSProperties, useCallback } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useTeam } from '@/context/Team/useTeam';
import { useUser } from '@/context/User/useUser';
import { useCurrentUniverse } from '@/hooks/useCurrentUniverse';
import { Tooltip } from '@/ui/Tooltip/Tooltip';
import { useUniverseActions } from '@/utils/universe/hooks/useUniverseActions';

export const DuplicateButton = () => {
  const user = useUser();
  const { team } = useTeam();
  const navigate = useNavigate();
  const { universe } = useCurrentUniverse();
  const { handleDuplicateUniverse } = useUniverseActions();

  const handleDuplicate = useCallback(async () => {
    if (universe && user) {
      try {
        await handleDuplicateUniverse(universe, [team]);
      } catch (e) {
        console.error(e);
      }
    } else {
      navigate({ to: `/login?duplicateId=${universe?.id}` });
    }
  }, [handleDuplicateUniverse, navigate, team, universe, user]);

  return (
    <Tooltip content={'Duplicate universe'}>
      <span>
        <Button
          style={{ WebkitAppRegion: 'no-drag' } as CSSProperties}
          onAction={handleDuplicate}
          data-testid={'duplicate-universe-button'}
        >
          <TextLabelRegular>Duplicate</TextLabelRegular>
        </Button>
      </span>
    </Tooltip>
  );
};
