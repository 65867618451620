import React, { FocusEventHandler, KeyboardEventHandler } from 'react';

import { useSearchValue } from '@/components/EditDock/WebEditDock/hooks/useSearchValue';
import { useUrlFieldMode } from '@/components/EditDock/WebEditDock/hooks/useUrlFieldMode';
import { UrlFieldProps } from '@/components/EditDock/WebEditDock/UrlField';
import { UrlIcon } from '@/components/EditDock/WebEditDock/UrlIcon';
import { openNewSearch } from '@/components/EditDock/WebEditDock/utils/search';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { usePreferences } from '@/context/Preferences/usePreferences';

import styles from './Url.module.css';

export type BaseUrlProps = {
  faviconUrl?: string;
};

export type EditableUrlProps = BaseUrlProps & {
  url?: string;
  setMode: ReturnType<typeof useUrlFieldMode>;
  handleNewUrl: UrlFieldProps['handleNewUrl'];
};

export type SimplifiedUrlProps = BaseUrlProps & {
  simplifiedUrl?: string;
};

export const EditableUrl = React.forwardRef<HTMLDivElement, EditableUrlProps>(
  ({ url, faviconUrl, setMode, handleNewUrl }, ref) => {
    const { searchValue, setSearchValue } = useSearchValue(url);
    const preferences = usePreferences();
    const handleUrlInputFocus: FocusEventHandler<HTMLInputElement> = (
      event
    ) => {
      const target = event.currentTarget;
      setTimeout(() => {
        target.select();
      });
    };

    const handleUrlInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      event.stopPropagation();
      if (event.key === 'Enter') {
        handleNewUrl(
          openNewSearch({
            maybeUrl: searchValue,
            searchEngine: preferences.search_engine,
          })
        );
        event.currentTarget.blur();
      } else if (event.key === 'Escape') {
        if (searchValue) {
          setSearchValue('');
        } else {
          event.currentTarget.blur();
        }
      }
    };

    return (
      <div
        className={styles.url}
        ref={ref}
        onClick={() => {
          setMode('edit');
        }}
      >
        <UrlIcon imageUrl={faviconUrl} />
        <input
          placeholder={'Search the web or type a URL'}
          className={`${styles.urlInput} label`}
          onFocus={handleUrlInputFocus}
          onKeyDown={handleUrlInputKeyDown}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          onBlur={() => setMode('display')}
          value={searchValue}
        />
      </div>
    );
  }
);

EditableUrl.displayName = 'EditableUrl';

export const SimplifiedUrl = React.forwardRef<
  HTMLDivElement,
  SimplifiedUrlProps
>(({ simplifiedUrl, faviconUrl }, ref) => {
  return (
    <div className={`${styles.url} ${styles.simplifiedUrl}`}>
      <div className={styles.urlInnerWrapper} ref={ref}>
        <UrlIcon imageUrl={faviconUrl} />
        <TextLabelRegular className={styles.urlText}>
          {simplifiedUrl ?? 'Search the web or type a URL'}
        </TextLabelRegular>
      </div>
    </div>
  );
});

SimplifiedUrl.displayName = 'SimplifiedUrl';

export const UrlRow = ({ simplifiedUrl, faviconUrl }: SimplifiedUrlProps) => {
  return (
    <div className={styles.row}>
      <UrlIcon imageUrl={faviconUrl} />
      <TextLabelRegular className={styles.urlText}>
        {simplifiedUrl}
      </TextLabelRegular>
    </div>
  );
};

UrlRow.displayName = 'UrlRow';
