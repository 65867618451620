import { ChevronDown } from '@lithium/spectrum';
import React, { ComponentProps } from 'react';

import styles from '@/components/EditDock/components/EditDockItem.module.css';

export type EditDockItemProps = {
  active?: boolean;
} & ComponentProps<'button'>;

export const EditDockItemWithChevron = React.forwardRef<
  HTMLButtonElement,
  EditDockItemProps
>(({ children, active, className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={`${styles.editDockItem} ${styles.withChevron} ${className ?? ''}`}
      data-active={active ? '' : undefined}
      {...props}
    >
      {children}
      <ChevronDown />
    </button>
  );
});

EditDockItemWithChevron.displayName = 'EditDockItemWithChevron';
