import { useRecoilValue } from 'recoil';

import { canRedoSelector, canUndoSelector } from '@/atoms/undoRedo';
import { CreateStickerSubmenu } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateStickerSubmenu';
import { useAddModal } from '@/components/modal/hooks/useAddModal';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';
import { useIsDev } from '@/hooks/env/useIsDev';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { useUniverseStickers } from '@/hooks/universe/useUniverseStickers';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';
import { Shortcut } from '@/ui/Shortcut/Shortcut';
import { useUniverseActions } from '@/utils/universe/hooks/useUniverseActions';

export type EmptySelectionItemsProps = {
  type: MenuType;
};

export const EmptySelectionItems = ({ type }: EmptySelectionItemsProps) => {
  const universe = useUniverseContext();
  const { stickers } = useUniverseStickers(universe);
  const { handleDeleteUniverse } = useUniverseActions();
  const handlers = useUniverseContextActions();
  const addModal = useAddModal();
  const isUndoAvailable = useRecoilValue(canUndoSelector(universe?.id ?? ''));
  const isRedoAvailable = useRecoilValue(canRedoSelector(universe?.id ?? ''));
  const { undoableDeleteStickers } = useDeleteStickers();
  const isDev = useIsDev();

  const { Item, SubTrigger, Sub, Separator } =
    getPolymorphicMenuComponents(type);

  return (
    <>
      <Sub triggerAsChild trigger={<SubTrigger>Create</SubTrigger>}>
        <CreateStickerSubmenu type={type} />
      </Sub>
      <Item
        displayAction
        onClick={handlers.paste}
        suffix={<Shortcut keys={'mod+v'} />}
      >
        Paste
      </Item>
      <Separator />
      <Item
        displayAction
        onSelect={() => {
          addModal('rename-universe', {
            universeId: universe.id,
            universeName: universe.name,
          });
        }}
      >
        Rename...
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.selectAll}
        suffix={<Shortcut keys={'mod+a'} />}
      >
        Select All
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.zoomToFit}
        suffix={<Shortcut keys={'alt+f'} />}
      >
        Zoom to fit
      </Item>
      {isDev && (
        <>
          <Separator />
          <Item
            displayAction
            onClick={handlers.undo}
            disabled={!isUndoAvailable}
            suffix={<Shortcut keys={'mod+z'} />}
          >
            Undo
          </Item>
          <Item
            displayAction
            onClick={handlers.redo}
            disabled={!isRedoAvailable}
            suffix={<Shortcut keys={'mod+shift+z'} />}
          >
            Redo
          </Item>
        </>
      )}
      <Separator />
      {isDev ? (
        <Item
          displayAction
          onClick={() => {
            undoableDeleteStickers(stickers);
          }}
        >
          Delete all Stickers️ ({stickers.length})
        </Item>
      ) : null}
      <Item displayAction onClick={() => handleDeleteUniverse(universe)}>
        Delete Universe
      </Item>
    </>
  );
};
