import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';

import styles from './DialogOverlay.module.css';

export const DialogOverlay = React.forwardRef<
  HTMLDivElement,
  DialogPrimitive.DialogOverlayProps
>(({ children, className, ...props }, ref) => {
  return (
    <DialogPrimitive.Overlay
      ref={ref}
      data-testid={'dialog-overlay'}
      className={[styles.dialogOverlay, className].join(' ')}
      {...props}
    >
      {children}
    </DialogPrimitive.Overlay>
  );
});

DialogOverlay.displayName = 'DialogOverlay';
