import { Button, ButtonShortcut } from '@lithium/spectrum';

import { TextHeader3 } from '@/components/fontSystem/Header3';
import { CommonModalProps } from '@/components/modal/Modals';
import styles from '@/components/modal/modals/Settings/CreateTeam/CreateTeam.module.css';
import { CreateTeamForm } from '@/components/modal/modals/Settings/CreateTeam/CreateTeamForm';
import {
  DialogClose,
  DialogDescription,
  DialogTitle,
} from '@/ui/Dialog/Dialog';
import { DialogFooter } from '@/ui/Dialog/DialogFooter';

export const CreateTeamModalContent = ({ closeModal }: CommonModalProps) => {
  const formId = 'team-form';

  return (
    <>
      <div className={styles.header}></div>
      <div className={styles.section}>
        <div className={styles.centerColumn}>
          <DialogTitle asChild>
            <TextHeader3>Setup your workspace</TextHeader3>
          </DialogTitle>
          <DialogDescription style={{ margin: 0 }}>
            Give your workspace a name to get started
          </DialogDescription>
        </div>
        <CreateTeamForm closeModal={closeModal} formId={formId} />
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button shortcut={ButtonShortcut.Esc}>Cancel</Button>
        </DialogClose>
        <Button
          form={formId}
          variant={'positive'}
          shortcut={ButtonShortcut.Enter}
        >
          Create
        </Button>
      </DialogFooter>
    </>
  );
};
