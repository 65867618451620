import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import React from 'react';

import { MenuContent } from '@/components/ui/MenuPrimitive/MenuContent';

export const ContextMenuContent = React.forwardRef<
  HTMLDivElement,
  ContextMenuPrimitive.ContextMenuContentProps
>(({ children, style, ...props }, ref) => {
  return (
    <ContextMenuPrimitive.Content
      asChild
      {...props}
      style={{ ...style, zIndex: 'var(--zindex-floating-menu)' }}
    >
      <MenuContent
        ref={ref}
        style={{
          transformOrigin: 'var(--radix-context-menu-content-transform-origin)',
        }}
      >
        {children}
      </MenuContent>
    </ContextMenuPrimitive.Content>
  );
});

ContextMenuContent.displayName = 'ContextMenuContent';
