import { isWeb } from '@/utils/platform/isWeb';

export const getAppLocation = () => {
  if (isWeb) {
    if (import.meta.env.VITE_STAGE === 'development') {
      const hostname = window.location.hostname;
      const port = window.location.port;
      return `http://${hostname}:${port}`;
    }
    const urlObject = new URL(window.location.href);
    return urlObject.origin;
  } else {
    return import.meta.env.VITE_KOSMIK_PUBLIC_URL;
  }
};
