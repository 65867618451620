import {
  useIsomorphicLayoutEffect,
  useLocalStorage,
  useMediaQuery,
} from 'usehooks-ts';

export type ColorScheme = 'light' | 'dark';

export type ColorMode = 'system' | ColorScheme;
const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';

export type TernaryDarkModeReturn = {
  colorScheme: ColorScheme;
  colorMode: ColorMode;
  setColorMode: (mode: ColorMode) => void;
};

/**
 * Returns the current user-preferred color-scheme and optionally executes
 * a callback that will receive the new scheme when changing
 * @param onChange
 */
export const useColorScheme = (
  onChange?: (newColorScheme: ColorScheme) => void
): TernaryDarkModeReturn => {
  const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY, {
    initializeWithValue: true,
  });
  const [mode, setMode] = useLocalStorage<ColorMode>('color-scheme', 'system', {
    initializeWithValue: true,
  });
  const isDarkMode = mode === 'dark' || (mode === 'system' && isDarkOS);
  const resolvedColorScheme: ColorScheme = isDarkMode ? 'dark' : 'light';

  useIsomorphicLayoutEffect(() => {
    onChange?.(resolvedColorScheme);
  }, [resolvedColorScheme]);

  return {
    colorScheme: resolvedColorScheme,
    colorMode: mode,
    setColorMode: setMode,
  };
};
