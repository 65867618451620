import { init } from '@instantdb/react';

import { MovePayload } from '@/components/Universe/Selection/movePayload';
import { TransformPayload } from '@/components/Universe/Selection/TransformHandle';
import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { Camera } from '@/utils/camera/camera';
import { Dimension } from '@/utils/geometry/dimension';
import { Size } from '@/utils/geometry/size';
import { KosmikFile } from '@/utils/kosmik/file';
import { KosmikPreferences } from '@/utils/kosmik/preferences';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikTeam, TeamInvite } from '@/utils/kosmik/team';
import { KosmikUniverse, UniverseInvite } from '@/utils/kosmik/universe';
import { KosmikUser } from '@/utils/kosmik/user';
import { Maybe, MaybeUndefined } from '@/utils/types';

export type AppSchema = {
  stickers: KosmikSticker;
  users: KosmikUser;
  universes: KosmikUniverse;
  teams: KosmikTeam;
  universe_invite: UniverseInvite;
  files: KosmikFile;
  team_invite: TeamInvite;
  preferences: KosmikPreferences;
};

export interface UserMultiplayerState {
  x: MaybeUndefined<number>;
  y: MaybeUndefined<number>;
  selections: string[];
  selectionArea: Maybe<Dimension>;
  user: MaybeUndefined<MultiplayerUser>;
  camera: MaybeUndefined<Camera>;
  viewport: MaybeUndefined<Size>;
  canvas: MaybeUndefined<Size>;
  following: Maybe<string>;
  pinnedStickerId: Maybe<string>;
}

export type WithCommitId<T> = T & {
  commitId: string;
};

export type RoomsSchema = {
  'universe-room': {
    presence: UserMultiplayerState;
    topics: {
      move: WithCommitId<MovePayload>;
      transform: WithCommitId<TransformPayload>;
      commit: WithCommitId<MovePayload> | WithCommitId<TransformPayload>;
      addTemporaryStickers: KosmikSticker[];
      removeTemporaryStickers: string[];
      deleteStickers: string[];
    };
  };
};

export const db = init<AppSchema, RoomsSchema>({
  appId: import.meta.env.VITE_INSTANT_APP_ID,
  devtool: false,
});
