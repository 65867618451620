/**
 * JavaScript implementation of the Durstenfeld shuffle,
 * an optimized version of Fisher-Yates
 * @see https://stackoverflow.com/a/12646864
 */
export const shuffleArray = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // @ts-expect-error The problem seems to be based on the way TypeScript interprets the destructured assignment. You can reassure TypeScript that the indices used are valid with a little adaptation.
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
