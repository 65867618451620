import {
  ShapeStrokeDashed,
  ShapeStrokeDotted,
  ShapeStrokeLight,
  ShapeStrokeMedium,
  ShapeStrokeOptions,
  ShapeStrokeSolid,
  ShapeStrokeThick,
  ShapeStrokeThicknessOptions,
} from '@lithium/spectrum';
import { useCallback, useRef } from 'react';
import { SetterOrUpdater } from 'recoil';

import { EditDockColorPickerItem } from '@/components/EditDock/components/EditDockColorPickerItem';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { PolygonShapeEditDock } from '@/components/EditDock/ShapeEditDock/PolygonShapeEditDock';
import { RectangleShapeEditDock } from '@/components/EditDock/ShapeEditDock/RectangleShapeEditDock';
import { StarShapeEditDock } from '@/components/EditDock/ShapeEditDock/StarShapeEditDock';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { DropdownSelect } from '@/ui/Dropdown/DropdownSelect';
import { MenuSeparator } from '@/ui/MenuPrimitive/MenuSeparator';
import { LineStyle } from '@/utils/css/types';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikShapeSticker } from '@/utils/kosmik/stickers/shape';
import { MaybeNull } from '@/utils/types';

export const ShapeEditDock = ({
  attributes,
  setSticker,
}: {
  attributes: KosmikShapeSticker['attributes'];
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}) => {
  const setAttributes =
    useSetStickerAttributes<KosmikShapeSticker['attributes']>(setSticker);
  const universe = useUniverseContext();
  const { addUndoRedoAction } = useUndoRedo(universe.id);
  const currentFillColorRef = useRef<string>(attributes.fill_color);
  const currentStrokeColorRef = useRef<string>(attributes.stroke_color);
  const currentStrokeThicknessRef = useRef<number>(attributes.stroke_width);
  const currentStrokeStyleRef = useRef(attributes.stroke_style);

  const handleFillColorChange = useCallback(
    (color: string) => setAttributes({ fill_color: color }, false),
    [setAttributes]
  );

  const handleFillColorCommit = useCallback(
    (color: string) => {
      const preCommitValue = currentFillColorRef.current;
      const fillColorChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ fill_color: color }, true);
          currentFillColorRef.current = color;
        },
        undo: () => {
          setAttributes({ fill_color: preCommitValue }, true);
        },
      };
      fillColorChangeAction.do();
      addUndoRedoAction(fillColorChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  const handleStrokeColorChange = useCallback(
    (color: string) => setAttributes({ stroke_color: color }, false),
    [setAttributes]
  );

  const handleStrokeColorCommit = useCallback(
    (color: string) => {
      const preCommitValue = currentStrokeColorRef.current;
      const strokeColorChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ stroke_color: color }, true);
          currentStrokeColorRef.current = color;
        },
        undo: () => {
          setAttributes({ stroke_color: preCommitValue }, true);
        },
      };
      strokeColorChangeAction.do();
      addUndoRedoAction(strokeColorChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  const handleStrokeThicknessCommit = useCallback(
    (stroke_width: number) => {
      const preCommitValue = currentStrokeThicknessRef.current;
      const fillColorChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ stroke_width }, true);
          currentStrokeThicknessRef.current = stroke_width;
        },
        undo: () => {
          setAttributes({ stroke_width: preCommitValue }, true);
        },
      };
      fillColorChangeAction.do();
      addUndoRedoAction(fillColorChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  const handleStrokeStyleCommit = useCallback(
    (stroke_style: LineStyle) => {
      const preCommitValue = currentStrokeStyleRef.current;
      const fillColorChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ stroke_style }, true);
          currentStrokeStyleRef.current = stroke_style;
        },
        undo: () => {
          setAttributes({ stroke_style: preCommitValue }, true);
        },
      };
      fillColorChangeAction.do();
      addUndoRedoAction(fillColorChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  let shapeSpecificEditDock = null;
  switch (attributes.shape) {
    case 'star':
      shapeSpecificEditDock = (
        <StarShapeEditDock attributes={attributes} setSticker={setSticker} />
      );
      break;
    case 'polygon':
      shapeSpecificEditDock = (
        <PolygonShapeEditDock attributes={attributes} setSticker={setSticker} />
      );
      break;
    case 'ellipse':
      break;
    case 'rectangle':
      shapeSpecificEditDock = (
        <RectangleShapeEditDock
          attributes={attributes}
          setSticker={setSticker}
        />
      );
      break;
  }

  return (
    <>
      {shapeSpecificEditDock}
      {shapeSpecificEditDock ? (
        <MenuSeparator orientation={'vertical'} />
      ) : null}
      <EditDockColorPickerItem
        currentColor={attributes.fill_color}
        onColorChange={handleFillColorChange}
        onColorCommit={handleFillColorCommit}
      />
      <MenuSeparator orientation={'vertical'} />
      <DropdownSelect<string>
        trigger={
          <EditDockItem>
            <ShapeStrokeThicknessOptions />
          </EditDockItem>
        }
        value={attributes.stroke_width.toString()}
        options={[
          { label: <ShapeStrokeLight />, value: '3' },
          { label: <ShapeStrokeMedium />, value: '7' },
          { label: <ShapeStrokeThick />, value: '15' },
        ]}
        onValueChange={(value) =>
          handleStrokeThicknessCommit(parseInt(value, 10))
        }
      />
      <DropdownSelect<LineStyle>
        trigger={
          <EditDockItem>
            <ShapeStrokeOptions />
          </EditDockItem>
        }
        value={attributes.stroke_style}
        options={[
          { label: <ShapeStrokeSolid />, value: 'solid' },
          { label: <ShapeStrokeDashed />, value: 'dashed' },
          { label: <ShapeStrokeDotted />, value: 'dotted' },
        ]}
        onValueChange={(value) => handleStrokeStyleCommit(value)}
      />
      <MenuSeparator orientation={'vertical'} />
      <EditDockColorPickerItem
        stroke
        currentColor={attributes.stroke_color}
        onColorChange={handleStrokeColorChange}
        onColorCommit={handleStrokeColorCommit}
      />
    </>
  );
};
