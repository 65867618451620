import { z } from 'zod';

export const Point = z.object({
  x: z.number(),
  y: z.number(),
});

export type Point = z.infer<typeof Point>;

export const sortPointByY = (pointA: Point, pointB: Point) => {
  if (pointA.y === pointB.y) {
    return pointA?.x - pointB?.x;
  }
  return pointA?.y - pointB?.y;
};
