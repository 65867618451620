import { ComponentProps, forwardRef } from 'react';

import styles from './MenuContent.module.css';

export const MenuContent = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={[className, styles.menuContent].join(' ')}
        {...props}
      >
        {children}
      </div>
    );
  }
);

MenuContent.displayName = 'MenuContent';
