import { useRef } from 'react';

import { useAutoSizeSticker } from '@/components/HTMLCanvas/stickers/hooks/useAutoSizeSticker';
import {
  EllipseShapeAttributes,
  KosmikShapeSticker,
} from '@/utils/kosmik/stickers/shape';
import { getNumber } from '@/utils/number/number';
import { cssToSvgDasharray } from '@/utils/svg';

export const HtmlEllipseShapeSticker = ({
  sticker,
}: {
  sticker: KosmikShapeSticker;
}) => {
  const attributes = sticker.attributes;
  const ellipseAttributes = EllipseShapeAttributes.safeParse(attributes);

  if (!ellipseAttributes.success) {
    console.log('Invalid attributes for ellipse sticker', sticker);
    return null;
  }

  return <EllipseShape attributes={ellipseAttributes.data} />;
};

export type EllipseShapeProps = {
  attributes: EllipseShapeAttributes;
};

export const EllipseShape = ({ attributes }: EllipseShapeProps) => {
  const ref = useRef<SVGSVGElement>(null);
  const size = useAutoSizeSticker(ref);
  const width = getNumber(size?.width, 0);
  const height = getNumber(size?.height, 0);

  return (
    <svg
      ref={ref}
      data-testid={'shape-ellipse'}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
    >
      {width && height ? (
        <ellipse
          data-testid={'ellipse'}
          cx={width / 2}
          cy={height / 2}
          rx={width / 2}
          ry={height / 2}
          fill={attributes.fill_color}
          strokeWidth={attributes.stroke_width}
          stroke={attributes.stroke_color}
          {...cssToSvgDasharray(attributes)}
        />
      ) : null}
    </svg>
  );
};
