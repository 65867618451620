import { useRecoilValue } from 'recoil';

import { cameraAtom } from '@/atoms/camera';
import { MAX_ZOOM, MIN_ZOOM } from '@/constants/zoom';
import { Slider } from '@/ui/Slider/Slider';

export const ZoomSlider = ({
  setZoom,
}: {
  setZoom: (zoom: number) => void;
}) => {
  const camera = useRecoilValue(cameraAtom);

  const logToLinear = (logValue: number) => {
    return Math.exp(logValue);
  };

  const linearToLog = (linearValue: number) => {
    return Math.log(linearValue);
  };

  const scaleValueChange = (newValue: number[]) => {
    const [value] = newValue;
    if (value) {
      setZoom(logToLinear(value));
    }
  };

  const scaleValueCommit = (newValue: number[]) => {
    const [value] = newValue;
    if (value) {
      setZoom(logToLinear(value));
    }
  };

  return (
    <Slider
      orientation={'vertical'}
      value={[linearToLog(camera.z)]}
      min={linearToLog(MIN_ZOOM)}
      max={linearToLog(MAX_ZOOM)}
      step={0.001}
      onValueChange={scaleValueChange}
      onValueCommit={scaleValueCommit}
    />
  );
};
