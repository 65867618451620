/**
 * Returns a new set without the provided `value`, if `value` isn't found in the
 * set (remember that it's an identity-based comparison), fallbacks to filtering
 * out all the values in the set that matches the given `predicate`
 * @param set - the set to delete the value from
 * @param value - the value to delete
 * @param predicate - the predicate function for the fallback if the value isn't
 *                    found. In such case, all the values for which the predicate
 *                    returns true will be filtered out from the original set
 */
export const deleteFromSetWithFallback = <T>(
  set: Set<T>,
  value: T,
  predicate: (setValue: T) => boolean
) => {
  let newSet = new Set(set);
  if (newSet.has(value)) {
    // Try by object identity
    newSet.delete(value);
  } else {
    // Fallback by filtering out the set values matching the predicate
    newSet = new Set([...newSet].filter((setValue) => !predicate(setValue)));
  }
  return newSet.size !== set.size ? newSet : set;
};
