import { ChevronRight } from '@lithium/spectrum';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';

import { MenuItem } from '@/components/ui/MenuPrimitive/MenuItem';

export const DropdownSubTrigger = React.forwardRef<
  HTMLDivElement,
  DropdownPrimitive.DropdownMenuSubTriggerProps
>(({ children, ...props }, ref) => {
  return (
    <DropdownPrimitive.SubTrigger {...props}>
      <MenuItem ref={ref} suffix={<ChevronRight />}>
        {children}
      </MenuItem>
    </DropdownPrimitive.SubTrigger>
  );
});

DropdownSubTrigger.displayName = 'DropdownSubTrigger';
