import { z } from 'zod';

import { KosmikStickerBase } from '@/utils/kosmik/stickers/base';
import { StickerType } from '@/utils/kosmik/stickers/type';

export const PromptStickerAttributes = z.object({
  prompt: z.string().optional(),
  response: z.string().optional(),
  model: z.string().optional(),
});

export type PromptStickerAttributes = z.infer<typeof PromptStickerAttributes>;

export const KosmikPromptSticker = z.intersection(
  KosmikStickerBase,
  z.object({
    type: z.literal(StickerType.enum.prompt),
    attributes: PromptStickerAttributes,
  })
);

export type KosmikPromptSticker = z.infer<typeof KosmikPromptSticker>;
