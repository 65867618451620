import { KosmikSticker } from '@/utils/kosmik/sticker';

/**
 * Determines if a given MIME type represents a file type supported by the application.
 *
 * @param type - The MIME type string to check.
 * @returns The original MIME type if it's a supported file type, or undefined if it's not.
 *
 * @description
 * This function checks if the given MIME type represents one of the following file categories:
 * - Image files (e.g., 'image/jpeg', 'image/png')
 * - Video files (e.g., 'video/mp4', 'video/webm')
 * - Audio files (e.g., 'audio/mpeg', 'audio/wav')
 *
 * If the MIME type includes 'image', 'video', or 'audio', it's considered a supported file type,
 * and the function returns the original MIME type. Otherwise, it returns undefined.
 *
 * @example
 * isFile('image/jpeg') // returns 'image/jpeg'
 * isFile('video/mp4') // returns 'video/mp4'
 * isFile('audio/mpeg') // returns 'audio/mpeg'
 * isFile('application/pdf') // returns undefined
 */
export const isFile = (type: string) => {
  if (type.includes('image')) {
    return type;
  }

  if (type.includes('video')) {
    return type;
  }

  if (type.includes('audio')) {
    return type;
  }

  return;
};

export const isPdfSticker = (sticker: KosmikSticker) => {
  if (sticker.type !== 'file') {
    return false;
  }
  const file = sticker.files?.[0];
  const { type } = file ?? sticker.attributes ?? {};

  return type === 'application/pdf';
};

export const isImageSticker = (sticker: KosmikSticker) => {
  if (sticker.type !== 'file') {
    return false;
  }
  const file = sticker.files?.[0];
  const { type } = file ?? sticker.attributes ?? {};

  return type?.startsWith('image') ?? false;
};

export const isAudioSticker = (sticker: KosmikSticker) => {
  if (sticker.type !== 'file') {
    return false;
  }
  const file = sticker.files?.[0];
  const { type } = file ?? sticker.attributes ?? {};

  return type?.startsWith('audio') ?? false;
};

export const isVideoSticker = (sticker: KosmikSticker) => {
  if (sticker.type !== 'file') {
    return false;
  }
  const file = sticker.files?.[0];
  const { type } = file ?? sticker.attributes ?? {};

  return type?.startsWith('video') ?? false;
};

export const getStickerFormat = (sticker: KosmikSticker) => {
  if (isImageSticker(sticker)) {
    return 'image';
  }
  if (isAudioSticker(sticker)) {
    return 'audio';
  }
  if (isVideoSticker(sticker)) {
    return 'video';
  }
  if (isPdfSticker(sticker)) {
    return 'pdf';
  }
  if (sticker.type === 'text' || sticker.type === 'sticky') {
    return 'quill';
  }
  if (sticker.type === 'web') {
    return 'url';
  }
  if (sticker.type === 'shape') {
    return sticker.attributes.shape;
  }
  return 'unknown';
};
