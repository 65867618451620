import { isCSSVar, resolveCSSVariable, wrapCSSVar } from '@/utils/css/variable';
import { AnyStringWithAutocomplete } from '@/utils/types';

/**
 * Converts a string to an HSL color.
 *
 * This function generates a consistent HSL color based on the input string.
 *
 * @param string - The input string to convert to a color.
 * @param saturation - The saturation percentage of the resulting color (0-100).
 * @param lightness - The lightness percentage of the resulting color (0-100).
 * @returns An HSL color string (e.g., "hsl(180, 85%, 68%)").
 *
 * @example
 * const color = stringToHslColor("Hello, World!");
 * console.log(color); // Outputs something like "hsl(225, 85%, 68%)"
 */
export const stringToHslColor = (
  string: string | undefined,
  saturation: number = 85,
  lightness: number = 68
): string => {
  if (string === undefined || string === '') {
    return 'hsl(' + 0 + ', ' + saturation + '%, ' + lightness + '%)';
  }
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;

  return 'hsl(' + hue + ', ' + saturation + '%, ' + lightness + '%)';
};

/**
 * Converts a string to a hsl color, with a safe contrast against white
 *
 * It's useful for creating unique contrasted colors, on top of which white text
 * will be added, such as user names or categories.
 */
export const stringToContrastSafeHslColor = (
  string: string | undefined,
  saturation: number = 85,
  lightness: number = 68
): string => {
  const color = stringToHslColor(string, saturation, lightness);
  const rgb = hslToRgbArray(color);
  const contrastValue = contrast(rgb, [255, 255, 255]); // compare color to white

  // shift lightness if not enough contrast
  if (contrastValue < 2.5) {
    return stringToContrastSafeHslColor(string, saturation, lightness - 5);
  }

  return color;
};

export const luminance = (rgb: [number, number, number]) => {
  const RED = 0.2126;
  const GREEN = 0.7152;
  const BLUE = 0.0722;

  const GAMMA = 2.4;

  const toLuminance = (value: number) => {
    value /= 255;
    return value <= 0.03928
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, GAMMA);
  };

  return (
    toLuminance(rgb[0]) * RED +
    toLuminance(rgb[1]) * GREEN +
    toLuminance(rgb[2]) * BLUE
  );
};

/**
 * Calculate contrast ratio based on rgb values
 * @param rgb1
 * @param rgb2
 *
 * source: https://stackoverflow.com/a/9733420/3199999
 */
export const contrast = (
  rgb1: [number, number, number],
  rgb2: [number, number, number]
) => {
  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

export const hslToRgbArray = (hslColor: string): [number, number, number] => {
  const matches = hslColor.match(
    /hsl\(\s*(-?\d*\.?\d+)\s*,\s*(\d*\.?\d+)%\s*,\s*(\d*\.?\d+)%\s*\)/
  );
  if (!matches) {
    throw new Error(`Not an hsl color (${hslColor})`);
  }

  let h = Number(matches[1]) % 360;
  if (h < 0) {
    h = 360 + h;
  }
  // Must be fractions of 1
  const s = Number(matches[2]) / 100;
  const l = Number(matches[3]) / 100;

  if (isNaN(h) || isNaN(s) || isNaN(l)) {
    throw new Error(`Not an hsl color (${hslColor})`);
  }

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
};

/**
 * Convert a given hsl css color to rgb
 * @param hslColor
 */
export const hslToRgb = (hslColor: string) => {
  const [r, g, b] = hslToRgbArray(hslColor);
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

/**
 * Converts a hex color to an rgb array
 */
export const hexToRgbArray = (hex: string): [number, number, number] => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (_, r, g, b) => {
    return '#' + r + r + g + g + b + b;
  });

  const result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const [_, r, g, b] = result ?? [];
  if (!r || !g || !b) {
    throw new Error(`Invalid hex color (${hex}) received: (${r}, ${g}, ${b})`);
  }
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
};

/**
 * Convert a hex color to an rgb() color
 */
export const hexToRgb = (hex: string) => {
  const [r, g, b] = hexToRgbArray(hex);
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

/**
 * Returns the contrasting color for the given rgb color
 */
export function getContrastColor(
  color: string | [number, number, number],
  midLuminance: number = 0.6
): string {
  let [r, g, b] = Array.isArray(color) ? color : [NaN, NaN, NaN];
  if (typeof color === 'string') {
    const rgbColor = color.startsWith('hsl(')
      ? hslToRgb(color)
      : color.startsWith('#')
        ? hexToRgb(color)
        : color;
    const matches = rgbColor.match(
      /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3}),\s*(\d{1,3}\s*)\)/
    );
    if (!matches) {
      throw new Error(`Not an rgb/hsl color (${color})`);
    }
    r = Number(matches[1]) / 255;
    g = Number(matches[2]) / 255;
    b = Number(matches[3]) / 255;
  }

  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    throw new Error(`Not an rgb/hsl color (${color})`);
  }

  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  const d = luminance > midLuminance ? 0 : 1;

  return d === 1 ? 'var(--label-white)' : 'var(--label-black)';
}

/**
 * Get an entity's color and contrasting color
 * @param name - the name of the entity to base the automatic colors on
 * @param color - if defined and different from 'auto' the main color to user
 */
export const getEntityColors = (
  name: string,
  color?: AnyStringWithAutocomplete<'auto'>
) => {
  const cssColor = color && color !== 'auto' ? color : stringToHslColor(name);
  const contrastColor = getContrastColor(
    isCSSVar(cssColor) ? resolveCSSVariable(cssColor) : cssColor
  );

  return { color: wrapCSSVar(cssColor), contrastColor };
};
