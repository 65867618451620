import { tx } from '@instantdb/react';
import { setRecoil } from 'recoil-nexus';

import { localStickerFamily } from '@/atoms/sticker';
import { runMasonryLayout } from '@/utils/autolayout/masonry';
import { runOrbitalLayout } from '@/utils/autolayout/orbital';
import { runPotpackLayout } from '@/utils/autolayout/potpack';
import { batchTransact, TxChunk } from '@/utils/db/db';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeUndefined } from '@/utils/types';

export type AutoLayoutType = 'orbital' | 'potpack' | 'masonry';

/**
 * Runs the desired autolayout algorithm on the given stickers
 */
export const autolayout = (
  layoutType: AutoLayoutType,
  stickers: KosmikSticker[]
) => {
  let postLayoutStickers: KosmikSticker[] = [];
  switch (layoutType) {
    case 'masonry':
      postLayoutStickers = runMasonryLayout(stickers);
      break;
    case 'orbital':
      postLayoutStickers = runOrbitalLayout(stickers);
      break;
    case 'potpack':
      postLayoutStickers = runPotpackLayout(stickers);
      break;
    default:
      console.error('Unsupported layoutType');
  }
  return postLayoutStickers;
};

/**
 * Move and persist stickers to the computed layout
 * @param postLayoutStickers - the resulting stickers of the layout algorithm
 * @param preLayoutStickers - the stickers before the layout algorithm
 */
export const handlePostLayoutStickers = (
  postLayoutStickers: KosmikSticker[],
  preLayoutStickers: KosmikSticker[]
) => {
  const preLayoutStickersMap = new Map(
    preLayoutStickers.map((sticker) => [sticker.id, sticker])
  );
  const txs: MaybeUndefined<TxChunk>[] = [];
  const animationOptions: KeyframeAnimationOptions = {
    duration: 450,
    easing: 'ease-in-out',
  };
  postLayoutStickers.forEach((postLayoutSticker) => {
    const initialSticker = preLayoutStickersMap.get(postLayoutSticker.id);
    if (initialSticker) {
      animateSticker(initialSticker, postLayoutSticker, animationOptions);
    }
    const newSticker = {
      ...postLayoutSticker,
      v: (postLayoutSticker.v ?? 0) + 1,
    };
    setRecoil(localStickerFamily(newSticker.id), newSticker);
    const { x, y, width, height, v } = newSticker;
    const payload = { x, y, width, height, v };
    txs.push(tx.stickers?.[newSticker.id]?.update(payload));
  });
  animateBoundingBox(preLayoutStickers, postLayoutStickers, animationOptions);
  batchTransact(txs);
};

/**
 * Animate the sticker from its initial values to its target values
 */
const animateSticker = (
  initialSticker: KosmikSticker,
  targetSticker: KosmikSticker,
  animationOptions: KeyframeAnimationOptions
) => {
  const { id } = initialSticker;
  const htmlSticker = document.querySelector(`[data-sticker][data-id="${id}"]`);
  if (htmlSticker instanceof Element) {
    htmlSticker.animate(
      {
        transform: [
          `translate3d(${initialSticker.x}px, ${initialSticker.y}px, 0)`,
          `translate3d(${targetSticker.x}px, ${targetSticker.y}px, 0)`,
        ],
        width: [`${initialSticker.width}px`, `${targetSticker.width}px`],
        height: [`${initialSticker.height}px`, `${targetSticker.height}px`],
      },
      animationOptions
    );
  }
};

/**
 * Animate the bounding box from its pre-layout values to its post-layout values
 */
const animateBoundingBox = (
  preLayoutStickers: KosmikSticker[],
  postLayoutStickers: KosmikSticker[],
  animationOptions: KeyframeAnimationOptions
) => {
  const transformer = document.querySelector('[data-transformer]');
  const initialBoundingBox = getBoundingBox(preLayoutStickers);
  const targetBoundingBox = getBoundingBox(postLayoutStickers);
  if (
    transformer instanceof Element &&
    initialBoundingBox &&
    targetBoundingBox
  ) {
    transformer.animate(
      {
        width: [
          `${initialBoundingBox.width}px`,
          `${targetBoundingBox.width}px`,
        ],
        height: [
          `${initialBoundingBox.height}px`,
          `${targetBoundingBox.height}px`,
        ],
        transform: [
          `translate3d(${initialBoundingBox.x}px, ${initialBoundingBox.y}px, 0)`,
          `translate3d(${targetBoundingBox.x}px, ${targetBoundingBox.y}px, 0)`,
        ],
      },
      animationOptions
    );
  }
};
