import isUrl from 'is-url-superb';

/**
 * Parses a string corresponding to a text/uri-list to an array of uri
 */
export const parseUriList = (uriList: string) => {
  const lines = uriList.split(/\r?\n/);
  return lines
    .filter((line) => {
      // Remove empty lines and comments (lines starting with #)
      const trimmedLine = line.trim();
      return trimmedLine !== '' && !trimmedLine.startsWith('#');
    })
    .map((line) => {
      return line.trim();
    });
};

/**
 * Check if a given string conforms to the text/uri-list format
 */
export const isUriList = (input: string) => {
  const trimmedInput = input.trim();
  if (trimmedInput.length === 0) {
    return false;
  }

  const lines = trimmedInput.split(/\r?\n/);
  for (const line of lines) {
    const trimmedLine = line.trim();
    if (trimmedLine === '' || trimmedLine.startsWith('#')) {
      continue;
    }
    if (!isUrl(trimmedLine)) {
      return false;
    }
  }

  return true;
};
