import { ChevronRight } from '@lithium/spectrum';
import React from 'react';

import {
  MenuItem,
  MenuItemProps,
} from '@/components/ui/MenuPrimitive/MenuItem';

export const ContextMenuSubTrigger = React.forwardRef<
  HTMLDivElement,
  MenuItemProps
>(({ children, ...props }, ref) => {
  return (
    <MenuItem ref={ref} suffix={<ChevronRight />} {...props}>
      {children}
    </MenuItem>
  );
});

ContextMenuSubTrigger.displayName = 'ContextMenuSubTrigger';
