import { useRecoilValue } from 'recoil';

import { selectedStickerIdsAtom } from '@/atoms/selection';
import { AutoLayoutMenuOptions } from '@/components/HTMLCanvas/CanvasContextMenu/AutoLayoutMenuOptions';
import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';
import { Shortcut } from '@/ui/Shortcut/Shortcut';

interface MultiSelectionProps {
  type: MenuType;
}

export const MultiSelectionItems = ({ type }: MultiSelectionProps) => {
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const handlers = useUniverseContextActions();
  const { Item, Separator } = getPolymorphicMenuComponents(type);

  return (
    <>
      <AutoLayoutMenuOptions type={type} />
      <Separator />
      <Item
        displayAction
        onClick={handlers.copy}
        suffix={<Shortcut keys={'mod+c'} />}
      >
        Copy
      </Item>
      <Item
        displayAction
        onClick={handlers.cut}
        suffix={<Shortcut keys={'mod+x'} />}
      >
        Cut
      </Item>
      <Item
        displayAction
        onClick={handlers.paste}
        suffix={<Shortcut keys={'mod+v'} />}
      >
        Paste
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.duplicateSelected}
        suffix={<Shortcut keys={'mod+d'} />}
      >
        Duplicate
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.selectAll}
        suffix={<Shortcut keys={'mod+a'} />}
      >
        Select All
      </Item>
      <Item
        displayAction
        onClick={handlers.selectAllOfType}
        suffix={<Shortcut keys={'mod+shift+a'} />}
      >
        Select all of type
      </Item>
      <Item
        displayAction
        onClick={handlers.deselectAll}
        suffix={<Shortcut keys={'esc'} />}
      >
        Deselect All
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.zoomToFit}
        suffix={<Shortcut keys={'alt+f'} />}
      >
        Zoom to fit
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={() => handlers.deleteSelected()}
        suffix={<Shortcut keys={'backspace'} />}
      >
        Delete {selectedStickerIds.size} stickers️
      </Item>
    </>
  );
};
