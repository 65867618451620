import { Button } from '@lithium/spectrum';
import * as Form from '@radix-ui/react-form';
import { FormEvent, ReactNode, useCallback, useState } from 'react';

import backgroundAVIF from '@/assets/Kosmik-CPT2_optimized.avif';
import { TextHeader2 } from '@/components/fontSystem/Header2';
import { TextTag } from '@/components/fontSystem/TextTag';
import styles from '@/components/Onboarding/Login.module.css';
import { OfflineMessage } from '@/components/Onboarding/OfflineMessage';
import formStyles from '@/components/Onboarding/Onboarding.module.css';
import { db } from '@/db/databaseInit';
import { useOnlineStatus } from '@/hooks/online/useOnlineStatus';
import { NameFormField } from '@/ui/Form/NameFormField';
import { sendKPI } from '@/utils/kpi/sendKPI';
import { UserFromInstant } from '@/utils/user/create';
import { renameUser } from '@/utils/user/user';

const Link = ({ url, children }: { url: string; children: ReactNode }) => {
  return (
    <a
      href={url}
      target={'_blank'}
      rel={'noopener noreferrer'}
      style={{ textDecoration: 'underline', color: 'var(--label-primary)' }}
    >
      {children}
    </a>
  );
};

export const UsernameForm = ({ user }: { user: UserFromInstant }) => {
  const online = useOnlineStatus();
  const [name, setName] = useState('');
  const { user: authUser } = db.useAuth();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      renameUser(user.id, name);
      if (authUser) {
        sendKPI({ type: 'new_user' }, user, authUser.refresh_token);
      }
    },
    [authUser, name, user]
  );

  const content = (
    <Form.Root onSubmit={handleSubmit} className={formStyles.formContainer}>
      <TextHeader2>Finish your account</TextHeader2>
      <NameFormField
        showLabel
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <OfflineMessage />
      <Button variant={'positive'} disabled={!online}>
        Sign up
      </Button>

      <TextTag>
        By signing up, you agree to the{' '}
        <Link url={'https://www.kosmik.app/terms'}>Terms of use</Link> and{' '}
        <Link url={'https://www.kosmik.app/privacy'}>Privacy Policy</Link>
      </TextTag>
    </Form.Root>
  );

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginPanel}>{content}</div>
      <div
        className={styles.loginBackground}
        style={{ backgroundImage: ` url(${backgroundAVIF}` }}
      ></div>
    </div>
  );
};
