import { useContext } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { DefaultEditDockItems } from '@/components/EditDock/components/DefaultEditDockItems';
import { FileEditDock } from '@/components/EditDock/FileEditDock/FileEditDock';
import { PromptEditDock } from '@/components/EditDock/PromptEditDock/PromptEditDock';
import { ShapeEditDock } from '@/components/EditDock/ShapeEditDock/ShapeEditDock';
import { StickyEditDock } from '@/components/EditDock/StickyEditDock/StickyEditDock';
import { TextEditDock } from '@/components/EditDock/TextEditDock/TextEditDock';
import { WebEditDock } from '@/components/EditDock/WebEditDock/WebEditDock';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { MenuContent } from '@/ui/MenuPrimitive/MenuContent';
import { MenuSeparator } from '@/ui/MenuPrimitive/MenuSeparator';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { KosmikPromptSticker } from '@/utils/kosmik/stickers/prompt';
import { KosmikShapeSticker } from '@/utils/kosmik/stickers/shape';
import { KosmikStickySticker } from '@/utils/kosmik/stickers/sticky';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';

import styles from './EditDock.module.css';

export const EditDock = ({ sticker }: { sticker: KosmikSticker }) => {
  const { isUniverseMember } = useContext(UniverseContext);
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const isPinPanel = usePinPanel();

  let stickerSpecificEditDock = null;

  const shapeSticker = KosmikShapeSticker.safeParse(sticker);
  const textSticker = KosmikTextSticker.safeParse(sticker);
  const stickySticker = KosmikStickySticker.safeParse(sticker);
  const webSticker = KosmikWebSticker.safeParse(sticker);
  const fileSticker = KosmikFileSticker.safeParse(sticker);
  const promptSticker = KosmikPromptSticker.safeParse(sticker);
  const menuContentClassName = isPinPanel
    ? styles.pinPanelEditDock
    : styles.editDock;

  if (shapeSticker.success) {
    stickerSpecificEditDock = (
      <ShapeEditDock
        attributes={shapeSticker.data.attributes}
        setSticker={setSticker}
      />
    );
  } else if (textSticker.success) {
    stickerSpecificEditDock = (
      <TextEditDock
        key={sticker.id}
        stickerId={sticker.id}
        attributes={textSticker.data.attributes}
        setSticker={setSticker}
      />
    );
  } else if (stickySticker.success) {
    stickerSpecificEditDock = (
      <StickyEditDock
        key={sticker.id}
        stickerId={sticker.id}
        attributes={stickySticker.data.attributes}
        setSticker={setSticker}
      />
    );
  } else if (webSticker.success) {
    stickerSpecificEditDock = (
      <WebEditDock
        stickerId={sticker.id}
        attributes={webSticker.data.attributes}
        setSticker={setSticker}
      />
    );
  } else if (fileSticker.success) {
    stickerSpecificEditDock = (
      <FileEditDock
        sticker={fileSticker.data}
        attributes={fileSticker.data.attributes}
        setSticker={setSticker}
      />
    );
  } else if (promptSticker.success) {
    stickerSpecificEditDock = (
      <PromptEditDock
        attributes={promptSticker.data.attributes}
        setSticker={setSticker}
      />
    );

    return (
      <MenuContent
        onPointerDown={(event) => event.stopPropagation()}
        className={menuContentClassName}
        style={{ width: '100%' }}
        data-edit-dock={isPinPanel ? 'pin-panel' : 'canvas'}
      >
        {stickerSpecificEditDock}
      </MenuContent>
    );
  }

  return (
    <MenuContent
      onPointerDown={(event) => event.stopPropagation()}
      className={menuContentClassName}
      data-edit-dock={isPinPanel ? 'pin-panel' : 'canvas'}
    >
      {isUniverseMember ? (
        <>
          {stickerSpecificEditDock}
          <MenuSeparator orientation={'vertical'} />
        </>
      ) : null}
      <DefaultEditDockItems sticker={sticker} />
    </MenuContent>
  );
};
