import { useParams } from '@tanstack/react-router';
import React, { useCallback } from 'react';
import { Dimension } from 'src/utils/geometry/dimension';

import { extractPdf } from '@/components/HTMLCanvas/PinPanel/Extract/utils/extractPdf';
import { extractPicture } from '@/components/HTMLCanvas/PinPanel/Extract/utils/extractPicture';
import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { getCenterPoint, getCurrentCameraValues } from '@/utils/camera/camera';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { ExtractData } from '@/utils/kosmik/stickers/extract';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { createExtractSticker } from '@/utils/sticker/create/createExtractSticker';
import { isImageSticker, isPdfSticker } from '@/utils/sticker/type';
import { MaybeUndefined } from '@/utils/types';

export const useOnExtract = (
  contentRef: React.RefObject<HTMLDivElement>,
  sticker: KosmikSticker
) => {
  const { universeId } = useParams({ strict: false });
  const pushKPI = usePushKPI();

  const onExtract = useCallback(
    (extractSelection: Dimension) => {
      const contentDiv = contentRef.current;
      if (!contentDiv || !universeId) {
        return;
      }

      const fileSticker = KosmikFileSticker.safeParse(sticker);
      if (!fileSticker.success) {
        return;
      }

      let extractData: MaybeUndefined<ExtractData> = undefined;
      if (isPdfSticker(sticker)) {
        extractData = extractPdf(extractSelection, contentDiv, sticker.width);
      } else if (isImageSticker(sticker)) {
        extractData = extractPicture(
          extractSelection,
          contentDiv,
          sticker.width,
          sticker.height
        );
      }

      if (extractData) {
        if (fileSticker.data.attributes.extract) {
          const currentExtractData = fileSticker.data.attributes.extract;
          const currentWidthRatio =
            1 - (currentExtractData.cropLeft + currentExtractData.cropRight);
          const currentHeightRatio =
            1 - (currentExtractData.cropTop + currentExtractData.cropBottom);
          extractData.cropLeft =
            currentExtractData.cropLeft +
            extractData.cropLeft * currentWidthRatio;
          extractData.cropRight =
            currentExtractData.cropRight +
            extractData.cropRight * currentWidthRatio;
          extractData.cropTop =
            currentExtractData.cropTop +
            extractData.cropTop * currentHeightRatio;
          extractData.cropBottom =
            currentExtractData.cropBottom +
            extractData.cropBottom * currentHeightRatio;
        }

        const camera = getCurrentCameraValues();
        const position = getCenterPoint(camera);
        createExtractSticker({
          sourceSticker: fileSticker.data,
          position,
          size: extractSelection,
          extractData,
          universeId,
        });
        pushKPI({
          type: 'create',
          properties: {
            interaction: 'extract',
            coType: 'extract',
            coFormat: fileSticker.data.attributes.type ?? 'unknown',
            coSize: 0,
            coUniverse: universeId,
          },
        });
      }
    },
    [contentRef, pushKPI, sticker, universeId]
  );

  return onExtract;
};
