import React from 'react';

import { PdfViewer } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfViewer';
import { HtmlEllipseShapeSticker } from '@/components/HTMLCanvas/stickers/HtmlEllipseShapeSticker';
import { HtmlFileSticker } from '@/components/HTMLCanvas/stickers/HtmlFileSticker';
import { HtmlPolygonShapeSticker } from '@/components/HTMLCanvas/stickers/HtmlPolygonShapeSticker';
import { HtmlPromptSticker } from '@/components/HTMLCanvas/stickers/HtmlPromptSticker';
import { HtmlRectangleShapeSticker } from '@/components/HTMLCanvas/stickers/HtmlRectangleShapeSticker';
import { HtmlStarShapeSticker } from '@/components/HTMLCanvas/stickers/HtmlStarShapeSticker';
import { HtmlStickySticker } from '@/components/HTMLCanvas/stickers/HtmlStickySticker';
import { HtmlTextSticker } from '@/components/HTMLCanvas/stickers/HtmlTextSticker';
import { HtmlWebSticker } from '@/components/HTMLCanvas/stickers/HtmlWebSticker';
import { HtmlWebViewSticker } from '@/components/HTMLCanvas/stickers/HtmlWebViewSticker';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { useIsDev } from '@/hooks/env/useIsDev';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { KosmikPromptSticker } from '@/utils/kosmik/stickers/prompt';
import { KosmikShapeSticker } from '@/utils/kosmik/stickers/shape';
import { KosmikStickySticker } from '@/utils/kosmik/stickers/sticky';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';
import { isElectron } from '@/utils/platform/isElectron';

interface HtmlAnyStickerViewRendererProps {
  sticker: KosmikSticker;
}

export const HtmlAnyStickerViewRenderer = React.memo(
  ({ sticker }: HtmlAnyStickerViewRendererProps) => {
    const isDev = useIsDev();
    const isPinPanel = usePinPanel();
    const shapeSticker = KosmikShapeSticker.safeParse(sticker);
    if (shapeSticker.success) {
      switch (shapeSticker.data.attributes.shape) {
        case 'star':
          return <HtmlStarShapeSticker sticker={shapeSticker.data} />;
        case 'polygon':
          return <HtmlPolygonShapeSticker sticker={shapeSticker.data} />;
        case 'ellipse':
          return <HtmlEllipseShapeSticker sticker={shapeSticker.data} />;
        case 'rectangle':
          return <HtmlRectangleShapeSticker sticker={shapeSticker.data} />;
      }
    }

    const textSticker = KosmikTextSticker.safeParse(sticker);
    if (textSticker.success) {
      return <HtmlTextSticker sticker={textSticker.data} />;
    }

    const stickySticker = KosmikStickySticker.safeParse(sticker);
    if (stickySticker.success) {
      return <HtmlStickySticker sticker={stickySticker.data} />;
    }

    const webSticker = KosmikWebSticker.safeParse(sticker);
    if (webSticker.success) {
      // For pinned stickers, in electron we want to display the web view
      if (isPinPanel && isElectron) {
        return <HtmlWebViewSticker sticker={webSticker.data} />;
      } else {
        return <HtmlWebSticker sticker={webSticker.data} />;
      }
    }

    const fileSticker = KosmikFileSticker.safeParse(sticker);
    if (fileSticker.success) {
      const file = fileSticker.data.files?.[0];
      const { type } = file ?? fileSticker.data.attributes;
      if (isPinPanel && type === 'application/pdf') {
        return <PdfViewer sticker={fileSticker.data} />;
      } else {
        return <HtmlFileSticker sticker={fileSticker.data} />;
      }
    }

    const promptSticker = KosmikPromptSticker.safeParse(sticker);

    console.log(promptSticker);
    if (promptSticker.success) {
      return <HtmlPromptSticker sticker={promptSticker.data} />;
    }

    const stickerErrors = {
      shapeSticker,
      textSticker,
      stickySticker,
      webSticker,
      fileSticker,
      promptSticker,
    };

    console.log('⚠️ Unknown sticker', sticker, stickerErrors);

    // show error boundary if sticker fails to be parsed
    if (isDev) {
      throw new Error('⚠️ Unknown sticker');
    } else {
      return null;
    }
  }
);

HtmlAnyStickerViewRenderer.displayName = 'HtmlAnyStickerViewRenderer';
