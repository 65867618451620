import { isDefined } from '@/utils/isDefined';
import { getWeekNumber } from '@/utils/kpi/getWeekNumber';
import { MaybeUndefined } from '@/utils/types';

export const getDeliveryInfos = (userCreatedAt: MaybeUndefined<string>) => {
  if (!isDefined(userCreatedAt)) {
    return {
      deliveryYearMonth: 'unknown',
      deliveryWeek: 'unknown',
    };
  }
  const deliveryYearMonth = new Date(userCreatedAt);
  deliveryYearMonth.setDate(1);
  deliveryYearMonth.setHours(2, 0, 0, 0);

  const deliveryInfos = {
    deliveryYearMonth: deliveryYearMonth.toISOString(),
    deliveryWeek: `${deliveryYearMonth.getFullYear()}-W${getWeekNumber(
      new Date(userCreatedAt)
    )}`,
  };

  return deliveryInfos;
};
