import debounce from 'debounce';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { cameraAtom } from '@/atoms/camera';

/**
 *
 * @param ref - optional ref to check if the element is visible on the screen
 * @returns
 */
export const useDelayedCameraZ = (ref?: RefObject<HTMLDivElement>) => {
  const camera = useRecoilValue(cameraAtom);
  const [delayedZ, setDelayedZ] = useState(camera.z);
  const debouncedSetDelayedZ = useMemo(
    () =>
      debounce((z: number) => {
        const element = ref?.current;
        if (element) {
          const rect = element.getBoundingClientRect();
          // if the element is not visible on the screen, don't update the delayedZ
          if (
            rect.bottom < 0 ||
            rect.top > window.innerHeight ||
            rect.right < 0 ||
            rect.left > window.innerWidth
          ) {
            return;
          }
        }
        setDelayedZ(z);
      }, 100),
    [ref]
  );

  useEffect(() => {
    debouncedSetDelayedZ(camera.z);
    // effect called on camera.z change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera]);

  return delayedZ;
};
