import { Button, ButtonShortcut } from '@lithium/spectrum';

import SetupTeamSVG from '@/assets/setup-team.svg?react';
import { TextBodyRegular } from '@/components/fontSystem/BodyRegular';
import { TextHeader3 } from '@/components/fontSystem/Header3';
import styles from '@/components/modal/modals/Settings/CreateTeam/CreateTeam.module.css';
import { CreateTeamForm } from '@/components/modal/modals/Settings/CreateTeam/CreateTeamForm';

export const CreateTeamPage = () => {
  const formId = 'team-form';

  return (
    <div className={styles.pageContainer}>
      <section className={styles.section}>
        <div className={styles.centerColumn}>
          <TextHeader3>Setup your team</TextHeader3>
          <TextBodyRegular>
            Give your team a name to get started
          </TextBodyRegular>
        </div>
        <CreateTeamForm formId={formId} />
        <SetupTeamSVG className={styles.responsiveImage} />
        <Button
          form={formId}
          variant={'positive'}
          shortcut={ButtonShortcut.Enter}
        >
          Create
        </Button>
      </section>
    </div>
  );
};
