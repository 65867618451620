import { useParams } from '@tanstack/react-router';
import { useCallback, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useLocalStorage } from 'usehooks-ts';

import { cameraAtom, DEFAULT_CAMERA } from '@/atoms/camera';
import { EASINGS } from '@/constants/easings';
import { Camera, getCurrentCameraValues } from '@/utils/camera/camera';
import { MaybeNull } from '@/utils/types';
import { getUiVisibleDimensions } from '@/utils/ui/ui';

export const getCameraLocalStorageKey = (universeId?: string) => {
  return `camera-${universeId}`;
};

export const useCamera = () => {
  const rafRef = useRef<MaybeNull<number>>(null);
  const { universeId } = useParams({ strict: false });
  const [camera, privateSetCamera] = useRecoilState(cameraAtom);
  const [, setLocalStorageCamera] = useLocalStorage<Camera>(
    getCameraLocalStorageKey(universeId),
    DEFAULT_CAMERA
  );

  const setCamera = useCallback<typeof privateSetCamera>(
    (valOrUpdater) => {
      setLocalStorageCamera(valOrUpdater);
      privateSetCamera(valOrUpdater);
    },
    [privateSetCamera, setLocalStorageCamera]
  );

  const stopCameraAnimation = useCallback(() => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
  }, []);

  const animateCamera = useCallback(
    (target: Camera, duration = 250) => {
      const camera = getCurrentCameraValues();
      const animationStartTime = performance.now();
      const startValues = {
        ...camera,
      };
      const ui = getUiVisibleDimensions();
      const startRight =
        (ui.canvas.width + ui.sidePanelWidth) / camera.z + camera.x;
      const targetRight =
        (ui.canvas.width + ui.sidePanelWidth) / target.z + target.x;
      const deltas = {
        x: target.x - camera.x,
        y: target.y - camera.y,
        z: target.z - camera.z,
        right: targetRight - startRight,
      };

      const update = () => {
        if (animationStartTime) {
          const currentTime = performance.now();
          const elapsedTime = currentTime - animationStartTime;
          const progress = Math.min(elapsedTime / duration, 1);

          // You can replace this with other easing functions for different effects
          const easeProgress = EASINGS.easeInOutQuad(progress);

          const newCamera = { ...startValues };

          // Update x, y, and z independently
          newCamera.x = startValues.x + deltas.x * easeProgress;
          newCamera.y = startValues.y + deltas.y * easeProgress;
          const right = startRight + deltas.right * easeProgress;
          const width = Math.abs(right - newCamera.x);
          const ui = getUiVisibleDimensions();
          const viewportWidth = ui.canvas.width + ui.sidePanelWidth;
          newCamera.z = viewportWidth / width;
          setCamera(newCamera);

          if (progress < 1) {
            stopCameraAnimation();
            rafRef.current = requestAnimationFrame(update);
          }
        }
      };

      stopCameraAnimation();
      rafRef.current = requestAnimationFrame(update);
    },
    [setCamera, stopCameraAnimation]
  );

  return { camera, setCamera, animateCamera, stopCameraAnimation };
};
