import { useRef } from 'react';
import { useEventListener } from 'usehooks-ts';

import { usePaste } from '@/hooks/clipboard/usePaste';
import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';

export const useCanvasContextEvents = () => {
  const documentRef = useRef<Document>(document);
  const handlers = useUniverseContextActions();
  const { handleUndoablePaste } = usePaste();

  useEventListener('paste', handleUndoablePaste, documentRef, {
    passive: true,
  });
  useEventListener('copy', handlers.copy, documentRef, { passive: true });
  useEventListener('cut', handlers.cut, documentRef, { passive: true });
};
