import React, { ComponentProps, useEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

export type DangerouslySetHtmlContentProps = ComponentProps<'div'> & {
  html: string;
  allowRerender?: boolean;
};

/**
 * Renders html content AND executes inner javascript;
 * Adapted from https://github.com/christo-pr/dangerously-set-html-content
 */
export const DangerouslySetHtmlContent = React.forwardRef<
  HTMLDivElement,
  DangerouslySetHtmlContentProps
>(({ html, dangerouslySetInnerHTML: _, allowRerender, ...rest }, ref) => {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const innerRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!html || !innerRef.current) {
      throw new Error("html prop can't be null");
    }
    if (!isFirstRender.current) {
      return;
    }
    isFirstRender.current = Boolean(allowRerender);
    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    innerRef.current.innerHTML = ''; // Clear the container
    innerRef.current.appendChild(slotHtml); // Append the new content
  }, [allowRerender, html, innerRef]);

  return <div ref={mergeRefs([innerRef, ref])} {...rest} />;
});

DangerouslySetHtmlContent.displayName = 'DangerouslySetHtmlContent';
