import styles from '@/components/EditDock/EditDock.module.css';
import { CreateEllipseShapeStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateEllipseShape/CreateEllipseShapeStickerButton';
import { CreateFileStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateFileSticker/CreateFileStickerButton';
import { CreatePolygonShapeStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreatePolygonShape/CreatePolygonShapeStickerButton';
import { CreatePromptStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreatePromptSticker/CreatePromptStickerButton';
import { CreateRectangleShapeStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateRectangleShape/CreateRectangleShapeStickerButton';
import { CreateStarShapeStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateStarShape/CreateStarShapeStickerButton';
import { CreateStickyStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateStickySticker/CreateStickyStickerButton';
import { CreateTextStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateTextSticker/CreateTextStickerButton';
import { CreateWebStickerButton } from '@/components/Toolbar/EditorToolbarContent/CreateSticker/CreateWebSticker/CreateWebStickerButton';
import { useFeature } from '@/hooks/useFeature';
import { MenuContent } from '@/ui/MenuPrimitive/MenuContent';

export const UniverseEditDock = () => {
  const { flags } = useFeature();
  return (
    <MenuContent
      onPointerDown={(event) => event.stopPropagation()}
      className={styles.universeEditDock}
    >
      {flags['AI sticker'] ? <CreatePromptStickerButton /> : null}
      <CreateWebStickerButton />
      <CreateTextStickerButton />
      <CreateStarShapeStickerButton />
      <CreatePolygonShapeStickerButton />
      <CreateEllipseShapeStickerButton />
      <CreateRectangleShapeStickerButton />
      <CreateStickyStickerButton />
      <CreateFileStickerButton />
    </MenuContent>
  );
};
