import { ComponentProps, forwardRef } from 'react';

import styles from './MinimalButton.module.css';

export const MinimalButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<'button'>
>(({ className, children, ...props }, ref) => {
  return (
    <button ref={ref} className={`${styles.button} ${className}`} {...props}>
      {children}
    </button>
  );
});

// Add a display name for better debugging
MinimalButton.displayName = 'MinimalButton';
