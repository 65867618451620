import { ForwardedRef, forwardRef, HTMLAttributes } from 'react';

import { Text } from '@/components/fontSystem/Text';

export const TextHeader1 = forwardRef(
  (
    props: HTMLAttributes<HTMLHeadingElement>,
    ref: ForwardedRef<HTMLHeadingElement>
  ) => {
    return <Text ref={ref} variant={'header-1'} {...props} />;
  }
);

TextHeader1.displayName = 'TextHeader1';
