import { getKPIMetas, KPIUser } from '@/utils/kpi/getKPIMetas';
import { KPI } from '@/utils/kpi/types';

export const sendKPI = async (
  kpi: KPI,
  kpiUser: KPIUser,
  refreshToken: string
) => {
  const kpiMetas = getKPIMetas(kpiUser);
  const kpiData = {
    meta: kpiMetas,
    ...kpi.properties,
  };
  fetch(
    `${import.meta.env.VITE_KPI_URL}/api/ingest?kpi_type=${kpi.type.toUpperCase()}`,
    {
      method: 'POST',
      body: JSON.stringify(kpiData),
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};
