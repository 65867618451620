import equal from 'fast-deep-equal/es6';
import { CSSProperties, memo, useEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRecoilState, useRecoilValue } from 'recoil';

import { pinnedStickerIdAtom } from '@/atoms/pinpanel';
import { isDrawingSelectionAtom } from '@/atoms/selection';
import { localStickerFamily } from '@/atoms/sticker';
import { isMovingAtom, isPressingAtom } from '@/atoms/transform';
import { CanvasEditDock } from '@/components/EditDock/CanvasEditDock';
import { useSelectionProperties } from '@/components/HTMLCanvas/stickers/hooks/useSelectionProperties';
import { HtmlAnyStickerViewRenderer } from '@/components/HTMLCanvas/stickers/HtmlAnyStickerViewRenderer';
import { StickerWrapper } from '@/components/HTMLCanvas/StickerWrapper';
import { StickerErrorBoundary } from '@/components/stickerErrorBoundary/StickerErrorBoundary';
import { useSetPinnedSticker } from '@/hooks/pinPanel/useSetPinnedSticker';
import { KosmikSticker } from '@/utils/kosmik/sticker';

import styles from './HtmlAnyStickerView.module.css';

export interface StickerRenderingProps {
  sticker: KosmikSticker;
}

export const HtmlAnyStickerView = memo(
  (props: StickerRenderingProps) => {
    const ref = useRef<HTMLDivElement>(null);
    // We use a state to be able to update the sticker before persisting to the db
    const [localSticker, setLocalSticker] = useRecoilState(
      localStickerFamily(props.sticker.id)
    );
    const isDrawingSelection = useRecoilValue(isDrawingSelectionAtom);
    const isMoving = useRecoilValue(isMovingAtom);
    const isPressing = useRecoilValue(isPressingAtom);
    const { setPinnedSticker } = useSetPinnedSticker();
    const pinnedStickerId = useRecoilValue(pinnedStickerIdAtom);

    useEffect(() => {
      setLocalSticker((prev) => {
        // Sticker is not initialized yet
        if (!prev) {
          return props.sticker ?? prev;
        }

        // Same props, do-not trigger re-renders
        if (equal(props.sticker, prev)) {
          return prev;
        }

        // Newer or same version. We allow the same version to replace the
        // local sticker because when linking a sticker to, for example, a file
        // we'll receive the same version: one without its link, and then, one
        // with the link. Because the version won't be incremented in that case
        // we need to allow same version updates.
        if ((props.sticker.v ?? 0) >= (prev.v ?? 0)) {
          return props.sticker;
        }

        // console.log(
        //   'stale v:',
        //   props.sticker.v,
        //   `(previous was ${prev.v}), discarding update`,
        //   {
        //     local: prev,
        //     received: props.sticker,
        //   }
        // );
        return prev;
      });
    }, [props.sticker, setLocalSticker]);

    const {
      isUserSelected,
      isRemoteSelected,
      isUserSelectedOrPinned,
      isExclusivelyRemoteSelectedOrPinned,
      selectionUser,
      outlineColor,
    } = useSelectionProperties(props.sticker.id);

    const isPinPanelShortcutActive = () => {
      const { activeElement } = document;
      const isButton = activeElement instanceof HTMLButtonElement;
      const isCanvasEditDock = !!activeElement?.closest(
        '[data-edit-dock="canvas"]'
      );
      return !isButton || isCanvasEditDock;
    };

    useHotkeys(
      'space',
      () => {
        if (isUserSelected && isPinPanelShortcutActive()) {
          if (pinnedStickerId !== props.sticker.id) {
            setPinnedSticker(props.sticker.id);
            const { activeElement } = document;
            if (activeElement instanceof HTMLElement) {
              activeElement.blur();
            }
          }
        }
      },
      { preventDefault: () => isPinPanelShortcutActive() }
    );

    return useMemo(
      () =>
        localSticker ? (
          <div
            ref={ref}
            data-sticker={''}
            data-id={localSticker.id}
            data-x={localSticker.x}
            data-y={localSticker.y}
            data-testid={`sticker-${localSticker.id}`}
            data-type={localSticker.type}
            data-moving={isMoving ? '' : undefined}
            data-pressing={isPressing ? '' : undefined}
            data-selected={isUserSelectedOrPinned ? '' : undefined}
            data-remote-selected={
              isExclusivelyRemoteSelectedOrPinned ? '' : undefined
            }
            className={styles.sticker}
            style={
              selectionUser && (isUserSelected || isRemoteSelected)
                ? ({
                    outlineColor: outlineColor,
                    transform: `translate3d(
                      calc(
                        ${localSticker.x}px
                        + var(--drag-${selectionUser.peerId}-offset-x, 0px)
                        + var(--clamped-transform-result-x, 0px)
                      ),
                      calc(
                        ${localSticker.y}px
                        + var(--drag-${selectionUser.peerId}-offset-y, 0px)
                        + var(--clamped-transform-result-y, 0px)
                      ),
                      0
                    )`,
                    ['--sticker-width']:
                      localSticker.resizeable === false
                        ? `${localSticker.width}px`
                        : `calc(${localSticker.width}px * var(--transform-${selectionUser.peerId}-scale-x, 1))`,
                    ['--sticker-height']:
                      localSticker.resizeable === false
                        ? `${localSticker.height}px`
                        : `calc(${localSticker.height}px * var(--transform-${selectionUser.peerId}-scale-y, 1))`,
                    ['--transform-proportional-x']: `calc(var(--transform-${selectionUser.peerId}-new-bbox-x, 0px) + (${localSticker.x}px - var(--transform-${selectionUser.peerId}-initial-bbox-x, 0px)) * var(--transform-${selectionUser.peerId}-scale-x, 1) - ${localSticker.x}px)`,
                    ['--transform-proportional-y']: `calc(var(--transform-${selectionUser.peerId}-new-bbox-y, 0px) + (${localSticker.y}px - var(--transform-${selectionUser.peerId}-initial-bbox-y, 0px)) * var(--transform-${selectionUser.peerId}-scale-y, 1) - ${localSticker.y}px)`,
                    ['--transform-result-x']:
                      localSticker.resizeable === false
                        ? `calc(var(--transform-proportional-x, 0px) + (${localSticker.width}px * var(--transform-${selectionUser.peerId}-scale-x, 1) - ${localSticker.width}px) * (${localSticker.x} - var(--transform-${selectionUser.peerId}-initial-bbox-x-no-unit, 0)) / max(1, var(--transform-${selectionUser.peerId}-initial-bbox-width-no-unit, 0) - ${localSticker.width}) )`
                        : 'var(--transform-proportional-x, 0px)',
                    ['--transform-result-y']:
                      localSticker.resizeable === false
                        ? `calc(var(--transform-proportional-y, 0px) + (${localSticker.height}px * var(--transform-${selectionUser.peerId}-scale-y, 1) - ${localSticker.height}px) * (${localSticker.y} - var(--transform-${selectionUser.peerId}-initial-bbox-y-no-unit, 0)) / max(1, var(--transform-${selectionUser.peerId}-initial-bbox-height-no-unit, 0) - ${localSticker.height}) )`
                        : 'var(--transform-proportional-y, 0px)',
                    ['--clamped-transform-result-x']: `clamp(
                          calc(var(--transform-${selectionUser.peerId}-new-bbox-x, ${localSticker.x}px) - ${localSticker.x}px),
                          var(--transform-result-x, 0px),
                          calc(var(--transform-${selectionUser.peerId}-new-bbox-x, 0px) + var(--transform-${selectionUser.peerId}-new-bbox-width, ${localSticker.x}px) - ${localSticker.x}px)
                        )`,
                    ['--clamped-transform-result-y']: `clamp(
                          calc(var(--transform-${selectionUser.peerId}-new-bbox-y, ${localSticker.y}px) - ${localSticker.y}px),
                          var(--transform-result-y, 0px),
                          calc(var(--transform-${selectionUser.peerId}-new-bbox-y, 0px) + var(--transform-${selectionUser.peerId}-new-bbox-height, ${localSticker.y}px) - ${localSticker.y}px)
                        )`,
                  } as CSSProperties)
                : ({
                    outlineColor: outlineColor,
                    transform: `translate3d(
                      ${localSticker.x}px,
                      ${localSticker.y}px,
                      0
                    )`,
                    ['--sticker-width']: `${localSticker.width}px`,
                    ['--sticker-height']: `${localSticker.height}px`,
                  } as CSSProperties)
            }
          >
            {!isDrawingSelection && !isMoving ? (
              <CanvasEditDock sticker={localSticker} />
            ) : null}
            <StickerWrapper
              sticker={localSticker}
              data-type={localSticker.type}
            >
              <StickerErrorBoundary sticker={localSticker}>
                <HtmlAnyStickerViewRenderer sticker={localSticker} />
              </StickerErrorBoundary>
            </StickerWrapper>
          </div>
        ) : null,
      [
        localSticker,
        isMoving,
        isPressing,
        isUserSelectedOrPinned,
        isExclusivelyRemoteSelectedOrPinned,
        selectionUser,
        isUserSelected,
        isRemoteSelected,
        outlineColor,
        isDrawingSelection,
      ]
    );
  },
  (prevProps, nextProps) => equal(prevProps, nextProps)
);

HtmlAnyStickerView.displayName = 'HtmlAnyStickerView';
