import { InstantReactRoom } from '@instantdb/react/dist/InstantReact';
import { useCallback, useEffect, useRef } from 'react';
import { setRecoil } from 'recoil-nexus';

import { stickerPinnedStateFamily } from '@/atoms/sticker';
import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { AppSchema, RoomsSchema } from '@/db/databaseInit';
import { deleteFromSetWithFallback } from '@/utils/set/set';
import { Maybe, MaybeNull } from '@/utils/types';

interface UseMultiplayerPinnedStickerProps {
  peerId: string;
  room: InstantReactRoom<AppSchema, RoomsSchema, 'universe-room'>;
}

export const useMultiplayerPinnedSticker = ({
  peerId,
  room,
}: UseMultiplayerPinnedStickerProps) => {
  const lastPinnedSticker = useRef<Maybe<string>>(null);
  const lastPeerUserRef = useRef<MaybeNull<MultiplayerUser>>(null);

  const { peers, isLoading } = room.usePresence({
    keys: ['user', 'pinnedStickerId'],
    peers: [peerId],
    user: false,
  });

  const unsetPreviousPinnedSticker = useCallback((user: MultiplayerUser) => {
    const pinnedSticker = lastPinnedSticker.current;
    if (pinnedSticker) {
      setRecoil(stickerPinnedStateFamily(pinnedSticker), (prev) => {
        return deleteFromSetWithFallback(
          prev,
          user,
          (multiplayerUser) => multiplayerUser.id === user.id
        );
      });
    }
  }, []);

  const setNewPinnedSticker = useCallback(
    (user: MultiplayerUser, pinnedStickerId: Maybe<string>) => {
      const pinnedSticker = lastPinnedSticker.current;
      // avoid re-renders
      if (pinnedSticker === pinnedStickerId) {
        return;
      }

      if (pinnedStickerId) {
        setRecoil(stickerPinnedStateFamily(pinnedStickerId), (prev) => {
          const newState = new Set(prev);
          newState.add(user);
          return newState;
        });
      }
      unsetPreviousPinnedSticker(user);
      lastPinnedSticker.current = pinnedStickerId;
    },
    [unsetPreviousPinnedSticker]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const peer = Object.values(peers)[0];

    if (peer?.user && peer?.pinnedStickerId) {
      setNewPinnedSticker(peer.user, peer.pinnedStickerId);
      lastPeerUserRef.current = peer.user;
    } else if (lastPeerUserRef.current) {
      setNewPinnedSticker(lastPeerUserRef.current, null);
    }
  }, [isLoading, peerId, peers, setNewPinnedSticker]);
};
