import { useParams } from '@tanstack/react-router';
import { useCallback } from 'react';

import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { getCurrentCameraValues } from '@/utils/camera/camera';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { createStickySticker } from '@/utils/sticker/create/createStickySticker';
import { undoableDuplicateStickers } from '@/utils/sticker/sticker';

export const useCreateDefaultStickySticker = () => {
  const { universeId } = useParams({ strict: false });
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const { deleteStickers } = useDeleteStickers();
  const pushKPI = usePushKPI();

  const handleCreateDefaultSticky = useCallback(
    async (isShiftKeyPressed = false) => {
      if (!universeId) {
        return;
      }
      const camera = getCurrentCameraValues();
      let createdStickers: KosmikSticker[] = createStickySticker({
        universeId,
        camera,
        isShiftKeyPressed,
      });
      pushKPI({
        type: 'create',
        properties: {
          interaction: 'click',
          coType: 'sticky',
          coFormat: 'quill',
          coSize: 0,
          coUniverse: universeId,
        },
      });
      const createAction: UndoRedoActionType = {
        do: () => {
          createdStickers = undoableDuplicateStickers(
            createdStickers,
            universeId
          );
        },
        undo: () => {
          deleteStickers(createdStickers);
        },
      };
      addUndoRedoAction(createAction);
    },
    [addUndoRedoAction, deleteStickers, pushKPI, universeId]
  );

  return { handleCreateDefaultSticky };
};
