import { CreateEllipseShapeStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateEllipseShape/CreateEllipseShapeStickerMenuItem';
import { CreateFileStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateFileSticker/CreateFileStickerMenuItem';
import { CreatePolygonShapeStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreatePolygonShape/CreatePolygonShapeStickerMenuItem';
import { CreatePromptStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreatePromptSticker/CreatePromptStickerMenuItem';
import { CreateRectangleShapeStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateRectangleShape/CreateRectangleShapeStickerMenuItem';
import { CreateStarShapeStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateStarShape/CreateStarShapeStickerMenuItem';
import { CreateStickyStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateStickySticker/CreateStickyStickerMenuItem';
import { CreateTextStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateTextSticker/CreateTextStickerMenuItem';
import { CreateWebStickerMenuItem } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/CreateSticker/CreateWebSticker/CreateWebStickerMenuItem';
import { useIsDev } from '@/hooks/env/useIsDev';
import { MenuType } from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const CreateStickerSubmenu = ({ type }: { type: MenuType }) => {
  const isDev = useIsDev();

  return (
    <>
      <CreateWebStickerMenuItem type={type} />
      <CreateTextStickerMenuItem type={type} />
      <CreateStickyStickerMenuItem type={type} />
      <CreateFileStickerMenuItem type={type} />
      {isDev && <CreatePromptStickerMenuItem type={type} />}
      <CreateRectangleShapeStickerMenuItem type={type} />
      <CreateEllipseShapeStickerMenuItem type={type} />
      <CreatePolygonShapeStickerMenuItem type={type} />
      <CreateStarShapeStickerMenuItem type={type} />
    </>
  );
};
