import {
  PDFPageProxy,
  RenderingCancelledException,
  RenderTask,
} from 'pdfjs-dist';
import { RefCallback } from 'react';

import {
  DATA_ATTRIBUTE_PAGE_WRAPPER,
  DATA_ATTRIBUTE_RENDERED,
  DATA_ATTRIBUTE_VISIBLE,
} from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';
import { PAGEVIEW } from '@/hooks/pdf/usePdf';
import { pdfPageToCanvas } from '@/hooks/pdf/utils/pdfPageToCanvas';
import { renderPdfTextLayer } from '@/hooks/pdf/utils/renderPdfTextLayer';
import { isDefined } from '@/utils/isDefined';
import { Maybe } from '@/utils/types';

export type RenderPageAndTextLayerProps = {
  // The container must contain both a page-wrapper (marked with a data
  // attribute) and a canvas to render into
  container: HTMLElement;
  pageProxy: PDFPageProxy;
  getPdfPromise: () => Maybe<RenderTask>;
  setPdfPromise: RefCallback<RenderTask>;
};

export const getPdfElements = (container: HTMLElement) => {
  const pageWrapper = container.querySelector(
    `[data-${DATA_ATTRIBUTE_PAGE_WRAPPER}]`
  );
  const canvas = container.querySelector('canvas');
  return { pageWrapper, canvas };
};

export const renderPageAndTextLayer = ({
  container,
  pageProxy,
  getPdfPromise,
  setPdfPromise,
}: RenderPageAndTextLayerProps) => {
  const { pageWrapper, canvas } = getPdfElements(container);
  const visible = isDefined(container.dataset[DATA_ATTRIBUTE_VISIBLE]);
  const rendered = isDefined(container.dataset[DATA_ATTRIBUTE_RENDERED]);
  const isPageWrapperHtmlElement = pageWrapper instanceof HTMLElement;
  const skip = !visible || rendered || !canvas || !isPageWrapperHtmlElement;
  if (skip) {
    return;
  }

  container.dataset[DATA_ATTRIBUTE_RENDERED] = '';
  getPdfPromise()?.cancel();

  const containerWidth = pageWrapper.clientWidth;
  // Render the image version of the page and then the text layer to enable
  // selecting text
  pdfPageToCanvas({
    page: pageProxy,
    size: { width: containerWidth },
    scale: 2,
    pdfPromiseRef: setPdfPromise,
  })
    .then((offscreenCanvas) => {
      // We have to copy the new canvas on the previous canvas after
      // the new canvas is rendered to prevent flicker while rendering
      // effectively using the new canvas as an offscreen canvas
      const destinationCanvas = canvas;
      if (offscreenCanvas && destinationCanvas) {
        const context = destinationCanvas.getContext('2d');
        destinationCanvas.width = offscreenCanvas.width;
        destinationCanvas.height = offscreenCanvas.height;
        context?.drawImage(offscreenCanvas, 0, 0);
      }

      const textScale = pageProxy.view[PAGEVIEW.width]
        ? containerWidth / pageProxy.view[PAGEVIEW.width]
        : 1;
      renderPdfTextLayer({
        page: pageProxy,
        container: pageWrapper,
        scale: textScale,
      });
    })
    .catch((e: Error) => {
      if (!(e instanceof RenderingCancelledException)) {
        console.error(e);
      }
    });
};
