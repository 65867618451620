import { atom } from 'recoil';

import { MaybeNull } from '@/utils/types';

/**
 * The currently active pinned sticker id
 */
export const pinnedStickerIdAtom = atom<MaybeNull<string>>({
  key: 'pinnedSticker',
  default: null,
});

/**
 * Boolean if the current pin panel is open
 */
export const isPinPanelOpenedAtom = atom<boolean>({
  key: 'isPinPanelOpenedAtom',
  default: false,
});

/**
 * Boolean if the current pin panel is in fullscreen mode
 */
export const isPinPanelFullscreenAtom = atom<boolean>({
  key: 'isPinPanelFullscreenAtom',
  default: false,
});

export type ActiveTool = 'extract' | 'preview' | 'table' | null;

export const pinPanelActiveToolAtom = atom<ActiveTool>({
  key: 'pinPanelActiveToolAtom',
  default: null,
});
