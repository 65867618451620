import { HTMLAttributes } from 'react';

import { CSSVar, wrapCSSVar } from '@/utils/css/variable';

import styles from './InlineColorPicker.module.css';

interface InlineColorPickerProps extends HTMLAttributes<HTMLDivElement> {
  colors: CSSVar[];
  onColorChange?: (color: CSSVar) => void;
  selectedColor?: string;
}

export const InlineColorPicker = ({
  colors,
  onColorChange,
  selectedColor = '--universe-primary',
  ...props
}: InlineColorPickerProps) => {
  return (
    <div {...props} className={styles.inlineColorPicker}>
      {colors.map((color) => (
        <div
          key={color}
          data-testid={`color-${color}`}
          className={`${styles.color} ${selectedColor?.toLowerCase() === color?.toLowerCase() ? styles.selected : ''}`}
          style={{ backgroundColor: wrapCSSVar(color) }}
          onClick={() => onColorChange?.(color)}
        />
      ))}
    </div>
  );
};
