import { ChevronRightDouble } from '@lithium/spectrum';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { IterableRefsMapKey, useIterableRefs } from '@/hooks/useIterableRefs';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { ItemButton } from '@/ui/ItemButton/ItemButton';

import styles from './ButtonGroup.module.css';

export type NotCollapsibleButtonGroupButton = {
  id: string;
  button: React.ReactNode;
};

export type CollapsibleButtonGroupButton = NotCollapsibleButtonGroupButton & {
  menuItem: React.ReactNode;
};

export type BaseButtonGroupProps = {
  buttons: NotCollapsibleButtonGroupButton[];
  collapsible?: never | false;
};

export type CollapsibleButtonGroupProps = {
  buttons: CollapsibleButtonGroupButton[];
  collapsible: true;
};

export type ButtonGroupProps =
  | BaseButtonGroupProps
  | CollapsibleButtonGroupProps;

export const ButtonGroup = ({ buttons, collapsible }: ButtonGroupProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { refs: itemsRefs, getRef } = useIterableRefs<HTMLDivElement>();
  const overflowingItems = useRef<Set<IterableRefsMapKey>>(new Set());
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [overflows, setOverflows] = useState<number>(0);
  const lastContainerWidth = useRef<number | null>(null);

  // Setup resize observer
  useEffect(() => {
    const hide = (item: HTMLElement) => {
      item.style.transform = 'scale(0)';
      item.style.opacity = '0';
    };

    const show = (item: HTMLElement) => {
      item.style.transform = 'scale(1)';
      item.style.opacity = '1';
    };

    if (!collapsible) {
      [...overflowingItems.current.values()].forEach((id) => {
        const item = itemsRefs.get(id);
        if (item) {
          show(item);
        }
      });
      overflowingItems.current.clear();
      setOverflows(0);
      return;
    }

    // Mark an item as overflowing or not and update its style accordingly
    const checkAndUpdateOverflow = (
      id: IterableRefsMapKey,
      item: HTMLElement,
      xEdge: number
    ) => {
      const overflowingItemsSet = overflowingItems.current;
      if (item.offsetLeft + item.offsetWidth > xEdge) {
        overflowingItemsSet.add(id);
        hide(item);
      } else {
        overflowingItemsSet.delete(id);
        show(item);
      }
    };

    // Update overflowing items
    const update = () => {
      const container = containerRef.current;
      const overflowingItemsSet = overflowingItems.current;
      const dropdownElement = dropdownRef.current;
      if (container && dropdownElement) {
        const containerBbox = container.getBoundingClientRect();
        // Separate the last and remaining items
        const otherItems = [...itemsRefs.entries()];
        const lastItemValue = otherItems.pop();
        if (lastItemValue) {
          const [lastItemId, lastItem] = lastItemValue;
          checkAndUpdateOverflow(lastItemId, lastItem, containerBbox.width);
        }
        otherItems.forEach(([id, item]) => {
          checkAndUpdateOverflow(
            id,
            item,
            containerBbox.width - dropdownElement.offsetWidth
          );
        });
        setOverflows(overflowingItemsSet.size);
        lastContainerWidth.current = containerBbox.width;
      }
    };

    const container = containerRef.current;
    if (container) {
      const observer = new ResizeObserver(update);
      observer.observe(container);
      return () => observer.disconnect();
    }
  }, [collapsible, itemsRefs]);

  const dropdownTrigger = (
    <ItemButton>
      <ChevronRightDouble />
    </ItemButton>
  );

  const dropdownItems = overflows
    ? buttons
        .filter((button) => overflowingItems.current.has(button.id))
        .map((button) => (
          <Fragment key={button.id}>
            {(button as CollapsibleButtonGroupButton).menuItem}
          </Fragment>
        ))
    : null;

  return (
    <div className={styles.buttonGroup} ref={containerRef}>
      {buttons.map(({ id, button }) => (
        <div className={styles.buttonWrapper} key={id} ref={getRef(id)}>
          {button}
        </div>
      ))}
      {collapsible ? (
        <div
          ref={dropdownRef}
          className={styles.buttonGroupDropdown}
          data-visible={overflows ? '' : undefined}
        >
          {overflows ? (
            <Dropdown
              modal={false}
              contentProps={{
                style: { minWidth: 160 },
                align: 'start',
                sideOffset: 5,
              }}
              trigger={dropdownTrigger}
            >
              {dropdownItems}
            </Dropdown>
          ) : (
            dropdownTrigger
          )}
        </div>
      ) : null}
    </div>
  );
};
