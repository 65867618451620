import { tx } from '@instantdb/react';
import equal from 'fast-deep-equal/es6';
import { useCallback } from 'react';
import { useRecoilTransaction_UNSTABLE } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { MovePayload } from '@/components/Universe/Selection/movePayload';
import { TransformPayload } from '@/components/Universe/Selection/TransformHandle';
import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { batchTransact, TxChunk } from '@/utils/db/db';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { Dimension } from '@/utils/geometry/dimension';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import {
  getCurrentFileLink,
  getTransactableStickerProperties,
} from '@/utils/sticker/sticker';
import { getNewBoundingBoxAndScale } from '@/utils/transformer/getNewBoundingBoxAndScale';

export const useMoveTransformHooks = () => {
  /**
   * Move the local stickers only
   */
  const moveLocalStickers = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (ids: string[], payload: MovePayload) => {
        ids.forEach((id) => {
          set(localStickerFamily(id), (prevSticker) => {
            if (!prevSticker) {
              return prevSticker;
            }
            const { x, y } = prevSticker;
            const newPosition = { x: x + payload.x, y: y + payload.y };
            if (!equal({ x, y }, newPosition)) {
              return {
                ...prevSticker,
                ...newPosition,
                v: (prevSticker.v ?? 0) + 1,
              };
            } else {
              return prevSticker;
            }
          });
        });
      },
    []
  );

  /**
   * Transform the local stickers only
   */
  const transformLocalStickers = useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      (ids: string[], payload: TransformPayload) => {
        const stickers: KosmikSticker[] = [];
        ids.forEach((id) => {
          const sticker = get(localStickerFamily(id));
          if (sticker) {
            stickers.push(sticker);
          }
        });
        const initialBoundingBox = getBoundingBox(stickers);
        if (!initialBoundingBox) {
          return;
        }
        const { newBoundingBox, scaleX, scaleY } = getNewBoundingBoxAndScale(
          initialBoundingBox,
          payload
        );
        ids.forEach((id) => {
          if (id) {
            set(localStickerFamily(id), (prevSticker) => {
              if (prevSticker) {
                const resizeable = prevSticker.resizeable !== false;
                let newX =
                  newBoundingBox.x +
                  (prevSticker.x - initialBoundingBox.x) * scaleX;
                let newY =
                  newBoundingBox.y +
                  (prevSticker.y - initialBoundingBox.y) * scaleY;
                if (!resizeable) {
                  newX +=
                    ((prevSticker.width * scaleX - prevSticker.width) *
                      (prevSticker.x - initialBoundingBox.x)) /
                    Math.max(1, initialBoundingBox.width - prevSticker.width);
                  newY +=
                    ((prevSticker.height * scaleY - prevSticker.height) *
                      (prevSticker.y - initialBoundingBox.y)) /
                    Math.max(1, initialBoundingBox.height - prevSticker.height);
                  newX = Math.max(newBoundingBox.x, newX);
                  newY = Math.max(newBoundingBox.y, newY);
                }
                return {
                  ...prevSticker,
                  x: newX,
                  y: newY,
                  width: resizeable
                    ? prevSticker.width * scaleX
                    : prevSticker.width,
                  height: resizeable
                    ? prevSticker.height * scaleY
                    : prevSticker.height,
                  v: (prevSticker.v ?? 0) + 1,
                } as KosmikSticker;
              } else {
                return prevSticker;
              }
            });
          }
        });
      },
    []
  );

  /**
   * Persist local stickers
   */
  const persist = useRecoilTransaction_UNSTABLE(
    ({ get }) =>
      (
        ids: string[],
        syncFullSticker = false,
        linkToUniverseId: string = ''
      ) => {
        const chunks: TxChunk[] = [];
        ids.forEach((id) => {
          const localSticker = get(localStickerFamily(id));
          if (localSticker) {
            const { x, y, width, height, v } = localSticker;
            const { id: _, ...fullSticker } = localSticker;
            const fileLink = getCurrentFileLink(localSticker);
            const payload = syncFullSticker
              ? fullSticker
              : { x, y, width, height, v };
            const transactablePayload =
              getTransactableStickerProperties(payload);
            // We can't add the .link after the chunk is created
            const chunk = linkToUniverseId
              ? tx.stickers?.[id]
                  ?.update(transactablePayload)
                  .link({ universes: [linkToUniverseId], files: fileLink })
              : tx.stickers?.[id]
                  ?.update(transactablePayload)
                  .link({ files: fileLink });

            if (chunk) {
              chunks.push(chunk);
            }
          }
        });
        batchTransact(chunks);
      },
    []
  );

  /**
   * Get the CSS variables for the given transform payload
   */
  const getTransformCSSVariables = useCallback(
    (
      initialBoundingBox: Dimension,
      payload: TransformPayload,
      user?: MultiplayerUser
    ) => {
      const { newBoundingBox, scaleX, scaleY } = getNewBoundingBoxAndScale(
        initialBoundingBox,
        payload
      );
      const variables = new Map<string, string>();
      const id = user?.peerId;
      const prefix = `--transform-${id}`;
      const setVar = (name: string, value: string) => {
        variables.set(`${prefix}-${name}`, value);
      };
      setVar('scale-x', `${scaleX}`);
      setVar('scale-y', `${scaleY}`);
      setVar('new-bbox-x', `${newBoundingBox.x}px`);
      setVar('new-bbox-y', `${newBoundingBox.y}px`);
      setVar('new-bbox-width', `${newBoundingBox.width}px`);
      setVar('new-bbox-height', `${newBoundingBox.height}px`);
      setVar('new-bbox-x-no-unit', `${newBoundingBox.x}x`);
      setVar('new-bbox-y-no-unit', `${newBoundingBox.y}`);
      setVar('new-bbox-width-no-unit', `${newBoundingBox.width}`);
      setVar('new-bbox-height-no-unit', `${newBoundingBox.height}`);
      setVar('initial-bbox-x', `${initialBoundingBox.x}px`);
      setVar('initial-bbox-y', `${initialBoundingBox.y}px`);
      setVar('initial-bbox-width', `${initialBoundingBox.width}px`);
      setVar('initial-bbox-height', `${initialBoundingBox.height}px`);
      setVar('initial-bbox-x-no-unit', `${initialBoundingBox.x}`);
      setVar('initial-bbox-y-no-unit', `${initialBoundingBox.y}`);
      setVar('initial-bbox-width-no-unit', `${initialBoundingBox.width}`);
      setVar('initial-bbox-height-no-unit', `${initialBoundingBox.height}`);
      return { variables, initialBoundingBox, newBoundingBox };
    },
    []
  );

  return {
    moveLocalStickers,
    transformLocalStickers,
    getTransformCSSVariables,
    persist,
  };
};
