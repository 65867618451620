import { TransformPayload } from '@/components/Universe/Selection/TransformHandle';
import { Dimension } from '@/utils/geometry/dimension';
import { normalizeRect } from '@/utils/geometry/rectangle';

/**
 * Compute the new bounding box and scale for the given payload
 */
export const getNewBoundingBoxAndScale = (
  initialBoundingBox: Dimension,
  payload: TransformPayload
) => {
  const newBoundingBox = normalizeRect({
    x: initialBoundingBox.x + payload.left,
    y: initialBoundingBox.y + payload.top,
    width: initialBoundingBox.width + payload.right - payload.left,
    height: initialBoundingBox.height + payload.bottom - payload.top,
  });
  const scaleX = newBoundingBox.width / initialBoundingBox.width;
  const scaleY = newBoundingBox.height / initialBoundingBox.height;
  return { newBoundingBox, scaleX, scaleY };
};
