import { id, tx } from '@instantdb/react';
import { Plus } from '@lithium/spectrum';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { useTeam } from '@/context/Team/useTeam';
import { MinimalButton } from '@/ui/MinimalButton/MinimalButton';
import { Tooltip } from '@/ui/Tooltip/Tooltip';
import { batchTransact } from '@/utils/db/db';
import { KosmikUniverse } from '@/utils/kosmik/universe';

export const CreateUniverseButton = () => {
  const navigate = useNavigate();
  const { team } = useTeam();
  const [disabledCreateButton, setDisabledCreateButton] =
    useState<boolean>(false);

  const handleNewUniverseClick = async () => {
    setDisabledCreateButton(true);
    const universe: Partial<KosmikUniverse> = {
      name: `Universe ${team.universes.length + 1}`,
      created_at: new Date().toISOString(),
    };

    const newUniverseId = id();

    await batchTransact(
      tx.universes?.[newUniverseId]?.update(universe).link({ teams: [team.id] })
    );

    setDisabledCreateButton(false);
    navigate({ to: `/${newUniverseId}` });
  };

  // Watch out if adding a tooltip, it prevents the button onAction handler to be called
  return (
    <Tooltip content={'Create universe'}>
      <MinimalButton
        style={{ pointerEvents: 'auto' }}
        disabled={disabledCreateButton}
        onClick={() => handleNewUniverseClick()}
      >
        <Plus />
      </MinimalButton>
    </Tooltip>
  );
};
