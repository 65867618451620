import { atomFamily } from 'recoil';

import { TextAttributes } from '@/components/Quill/QuillEditor';
import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeNull } from '@/utils/types';

/**
 * Contains the set of multiplayer users that have selected the sticker matching
 * the given id. Most of the time, only one user can select a given sticker, but
 * we allow multi-user selections when browsing a published universe as an
 * anonymous or a non universe member user. This way these users can
 * select stickers and open them in the pin panel, while the universe members
 * (editors) can freely update the stickers despite them being currently
 * selected by anonymous or non universe members users.
 */
export const stickerSelectionStateFamily = atomFamily<
  Set<MultiplayerUser>,
  string
>({
  key: 'stickerSelectionStateFamily',
  default: new Set(),
});

/**
 * Contains the set of multiplayer users that have pinned the sticker matching
 * the given id. Most of the time, only one user can pin a given sticker, but
 * we allow multi-user pins when browsing a published universe as an
 * anonymous or a non universe member user. This allows anonymous or non
 * universe members to select and pin a sticker no matter if already pinned
 * by a universe member, while preventing concurrent edition for the universe
 * members.
 */
export const stickerPinnedStateFamily = atomFamily<
  Set<MultiplayerUser>,
  string
>({
  key: 'stickerPinnedStateFamily',
  default: new Set(),
});

/**
 * Contains the local copy of stickers
 */
export const localStickerFamily = atomFamily<MaybeNull<KosmikSticker>, string>({
  key: 'localStickerFamily',
  default: null,
});

/**
 * The default upload state for a given sticker when it's the only one uploading
 */
export type DefaultStickerUploadState = {
  // True if the sticker is currently related to an uploading file
  isUploading: boolean;
  uploadingDuplicates?: never;
  uploadingSticker?: never;
};

/**
 * The upload state when an uploading sticker has duplicates subsequently marked
 * as uploading too
 */
export type StickerUploadStateWithDuplicates = {
  // True if the sticker is currently related to an uploading file
  isUploading: boolean;
  // The ids of the duplicated stickers that use the same file
  uploadingDuplicates: Set<string>;
  uploadingSticker?: never;
};

/**
 * The uploading state for a sticker which is a duplicate of an actual
 * uploading sticker (only the uploading sticker is really uploading something)
 */
export type UploadingDuplicateStickerUploadState = {
  // True if the sticker is currently related to an uploading file
  isUploading: boolean;
  uploadingDuplicates?: never;
  // If the sticker is a duplicate of an uploading sticker, the id of the
  // sticker that is actually uploading the related file
  uploadingSticker: string;
};

/**
 * The possible uploading states for a given sticker
 */
export type StickerUploadState =
  | DefaultStickerUploadState
  | StickerUploadStateWithDuplicates
  | UploadingDuplicateStickerUploadState;

/**
 * Contains the upload state of this sticker
 */
export const stickerUploadStateFamily = atomFamily<StickerUploadState, string>({
  key: 'stickerUploadStateFamily',
  default: { isUploading: false },
});

/**
 * Contains the temporary stickers that may be persisted later on
 * (such as the alt-dragged stickers, or the duplicated stickers).
 * This allows us to
 * - give instant feedback without waiting for the db txs to complete
 * - display stickers that are not in the database, and might never be
 */
export const temporaryStickersFamily = atomFamily<KosmikSticker[], string>({
  key: 'temporaryStickersFamily',
  default: [],
});

/**
 * Contains the ids of the stickers that are being deleted, this allows us
 * to react instantly to deletion instead of waiting the batched transactions
 */
export const deletingStickersFamily = atomFamily<Set<string>, string>({
  key: 'deletingStickersFamily',
  default: new Set(),
});

/**
 * Contains the currently active quill text attributes of this sticker
 */
export const stickerQuillAttributesFamily = atomFamily<TextAttributes, string>({
  key: 'stickerQuillAttributesFamily',
  default: {},
});

/**
 * Contains the currently active webview attributes of this sticker
 */
export const stickerWebViewAttributesFamily = atomFamily<number, string>({
  key: 'stickerWebViewAttributesFamily',
  default: 0,
});
