import { SVGProps } from 'react';

import { Position } from '@/utils/geometry/position';
import { vector } from '@/utils/geometry/vector';
import { ShapeStickerBaseAttributes } from '@/utils/kosmik/stickers/shape';

const limitPrecision = (num: number, precision = 2) => {
  return Math.round(num * 10 ** precision) / 10 ** precision;
};

/**
 * Returns a svg path data string with the rounded polygon corresponding to the
 * given svg polygon `points` string
 * @see https://observablehq.com/@daformat/rounding-polygon-corners
 * @param points the svg polygon `points` string
 * @param radius the corner radius to use
 * @param style the rounding style (WIP)
 */
export const roundSvgPolygon = (
  points: string,
  radius: number,
  style: 'approx' | 'circle' | 'hand' = 'circle'
) => {
  if (!points) {
    return points;
  }

  // Get path coordinates as array of {x, y} objects
  const pathCoords = points
    .split(' ')
    .map((entry) => {
      const coordinates = entry.split(',');
      return { x: +(coordinates[0] ?? 0), y: +(coordinates[1] ?? 0) };
    })
    .filter((entry, i, arr) => {
      // Remove consecutive duplicates
      const prev = arr[i === 0 ? arr.length - 1 : i - 1];
      return entry.x !== prev?.x || entry.y !== prev?.y;
    });

  // Build rounded path
  const path = [];
  for (let i = 0; i < pathCoords.length; i++) {
    // Get current point and the next two (start, corner, end)
    const c2Index = (i + 1) % pathCoords.length;
    const c3Index = (i + 2) % pathCoords.length;

    const c1 = pathCoords[i];
    const c2 = pathCoords[c2Index];
    const c3 = pathCoords[c3Index];

    if (c1 && c2 && c3) {
      // Vectors from middle point (c2) to each ends
      const vC1c2 = vector(c2, c1);
      const vC3c2 = vector(c2, c3);

      const angle = Math.abs(
        Math.atan2(
          vC1c2.dx * vC3c2.dy - vC1c2.dy * vC3c2.dx, // cross product
          vC1c2.dx * vC3c2.dx + vC1c2.dy * vC3c2.dy // dot product
        )
      );

      // Limit radius to 1/2 the shortest edge length to:
      // 1. allow rounding the next corner as much as the
      //    one we're dealing with right now (the 1/2 part)
      // 2. draw part of a circle and not an ellipse, hence
      //    we keep the shortest edge of the two
      const cornerLength = Math.min(radius, vC1c2.mag / 2, vC3c2.mag / 2);

      // Find out tangential circle radius
      const bc = cornerLength;
      const bd = Math.cos(angle / 2) * bc;
      const fd = Math.sin(angle / 2) * bd;
      const bf = Math.cos(angle / 2) * bd; // simplify from abs(cos(PI - angle / 2))
      const ce = fd / (bf / bc);

      // Compute control point distance to create a circle
      // with quadratic bezier curves
      const numberOfPointsInCircle = (2 * Math.PI) / (Math.PI - angle);
      let idealControlPointDistance = 0;

      if (style === 'circle') {
        // Strictly geometric
        idealControlPointDistance =
          (4 / 3) * Math.tan(Math.PI / (2 * numberOfPointsInCircle)) * ce;
      } else if (style === 'approx') {
        // Serendipity #1 rounds the shape more naturally
        idealControlPointDistance =
          (4 / 3) *
          Math.tan(Math.PI / (2 * ((2 * Math.PI) / angle))) *
          cornerLength *
          (angle < Math.PI / 2 ? 1 + Math.cos(angle) : 2 - Math.sin(angle));
      } else if (style === 'hand') {
        // Serendipity #2 'hands free' style
        idealControlPointDistance =
          (4 / 3) *
          Math.tan(Math.PI / (2 * ((2 * Math.PI) / angle))) *
          cornerLength *
          (2 + Math.sin(angle));
      }

      // First point and control point
      const cpDistance = cornerLength - idealControlPointDistance;

      // Start of the curve
      let c1c2curvePoint = {
        x: c2.x + vC1c2.unit.x * cornerLength,
        y: c2.y + vC1c2.unit.y * cornerLength,
      };
      // First control point
      let c1c2curveCP = {
        x: c2.x + vC1c2.unit.x * cpDistance,
        y: c2.y + vC1c2.unit.y * cpDistance,
      };

      // Second point and control point
      // End of the curve
      let c3c2curvePoint = {
        x: c2.x + vC3c2.unit.x * cornerLength,
        y: c2.y + vC3c2.unit.y * cornerLength,
      };
      // Second control point
      let c3c2curveCP = {
        x: c2.x + vC3c2.unit.x * cpDistance,
        y: c2.y + vC3c2.unit.y * cpDistance,
      };

      // Limit floating point precision
      const limit = (point: Position) => ({
        x: limitPrecision(point.x, 3),
        y: limitPrecision(point.y, 3),
      });

      c1c2curvePoint = limit(c1c2curvePoint);
      c1c2curveCP = limit(c1c2curveCP);
      c3c2curvePoint = limit(c3c2curvePoint);
      c3c2curveCP = limit(c3c2curveCP);

      // If at last coordinate of polygon, use the end of the curve as
      // the polygon starting point
      if (i === pathCoords.length - 1) {
        path.unshift(`M ${c3c2curvePoint.x} ${c3c2curvePoint.y}`);
      }

      // Draw line from previous point to the start of the curve
      path.push(`L ${c1c2curvePoint.x} ${c1c2curvePoint.y}`);

      // Cubic bezier to draw the actual curve
      path.push(
        `C ${c1c2curveCP.x} ${c1c2curveCP.y}, ${c3c2curveCP.x} ${c3c2curveCP.y}, ${c3c2curvePoint.x} ${c3c2curvePoint.y}`
      );
    }
  }

  // Close path
  path.push('Z');

  return path.join(' ');
};

/**
 * Converts the css border style to corresponding svg properties
 * @param attributes
 */
export const cssToSvgDasharray = (attributes: ShapeStickerBaseAttributes) => {
  let strokeDasharray: SVGProps<SVGPathElement>['strokeDasharray'] = undefined;
  let strokeLinecap: SVGProps<SVGPathElement>['strokeLinecap'] = undefined;
  switch (attributes.stroke_style) {
    case 'dashed':
      strokeDasharray = `${attributes.stroke_width * 2}, ${attributes.stroke_width}`;
      break;
    case 'dotted':
      strokeDasharray = `1, ${attributes.stroke_width * 2}`;
      strokeLinecap = 'round';
      break;
  }
  return { strokeDasharray, strokeLinecap };
};
