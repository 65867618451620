// based on: https://gist.github.com/James1x0/8443042
export const greeting = () => {
  let currentHour = new Date().getHours();
  if (currentHour < 4) {
    // After midnight, but before 4am
    currentHour = currentHour + 24;
  }

  let greetingMessage;

  if (currentHour < 12) {
    // after 4:00AM and before 12:00PM
    greetingMessage = 'Good morning';
  } else if (currentHour < 18) {
    // after 12:00PM and before 6:00pm
    greetingMessage = 'Good afternoon';
  } else if (currentHour < 28) {
    // after 5:59pm or before 4:00AM (to accommodate night owls)
    greetingMessage = 'Good evening';
  } else {
    // if for some reason the calculation didn't work
    greetingMessage = 'Welcome';
  }

  return greetingMessage;
};
