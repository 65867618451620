import { TextAttributes } from '@/components/Quill/QuillEditor';
import { FontSizeStyle, reverseFontSizeValues } from '@/utils/css/types';

export const getCurrentTextColor = (quillAttributes: TextAttributes) => {
  const { color } = quillAttributes;
  if (Array.isArray(color)) {
    return color[0];
  } else {
    return color;
  }
};

export const getCurrentFontSize = (quillAttributes: TextAttributes) => {
  const { size } = quillAttributes;
  let currentFontSize: FontSizeStyle = 'small';
  let resolvedSize;
  if (Array.isArray(size)) {
    const key = size[0];
    resolvedSize = key ? reverseFontSizeValues[key] : 'small';
  } else if (typeof size === 'string') {
    resolvedSize = reverseFontSizeValues[size];
  }
  if (resolvedSize) {
    currentFontSize = resolvedSize;
  }
  return currentFontSize;
};
