import { Dispatch, SetStateAction, useCallback } from 'react';

import { EditDockColorItem } from '@/components/EditDock/components/EditDockColorItem';
import styles from '@/components/modal/modals/Settings/CreateTeam/TeamColorRow.module.css';
import { TEAM_COLORS } from '@/constants/colors';
import { Avatar } from '@/ui/Avatar/Avatar';
import { getEntityColors } from '@/utils/color/colors';
import { wrapCSSVar } from '@/utils/css/variable';
import { MaybeUndefined } from '@/utils/types';

type TeamColorRowProps = {
  name: MaybeUndefined<string>;
  color: string;
  setColor: Dispatch<SetStateAction<string>>;
};

export const TeamColorRow = ({
  name = 'W',
  setColor,
  color: currentColor,
}: TeamColorRowProps) => {
  const { color: teamColor, contrastColor } = getEntityColors(
    name,
    currentColor
  );
  const handleColorChange = useCallback(
    (color: string) => {
      setColor(color);
    },
    [setColor]
  );

  return (
    <div className={styles.container}>
      <Avatar
        name={name}
        color={wrapCSSVar(teamColor)}
        contrastColor={contrastColor}
        size={30}
        style={'square'}
      />
      <div className={styles.colors}>
        {TEAM_COLORS.map((color) => (
          <EditDockColorItem
            key={color}
            color={color}
            currentColor={currentColor}
            onColorChange={handleColorChange}
            onColorCommit={handleColorChange}
            withCheck
          />
        ))}
        <EditDockColorItem
          color={'auto'}
          currentColor={currentColor}
          onColorChange={handleColorChange}
          onColorCommit={handleColorChange}
          withCheck
        />
      </div>
    </div>
  );
};
