import { ContextMenuComponentMap } from '@/ui/PolymorphicMenu/ContextMenuComponentMap';
import { DropdownMenuComponentMap } from '@/ui/PolymorphicMenu/DropdownMenuComponentMap';

export type MenuType = 'dropdown' | 'contextmenu';

export type PolymorphicMenuProps = {
  menuType: MenuType;
  disabled?: boolean;
};

export type PolymorphicComponents<T extends PolymorphicMenuProps['menuType']> =
  T extends 'dropdown'
    ? typeof DropdownMenuComponentMap
    : T extends 'contextmenu'
      ? typeof ContextMenuComponentMap
      : never;

export const getPolymorphicMenuComponents = <
  T extends PolymorphicMenuProps['menuType'],
>(
  type: T
): PolymorphicComponents<T> => {
  const components =
    type === 'dropdown' ? DropdownMenuComponentMap : ContextMenuComponentMap;
  return components as PolymorphicComponents<T>;
};
