import { RefObject, useLayoutEffect, useState } from 'react';

import { Size } from '@/utils/geometry/size';
import { MaybeUndefined } from '@/utils/types';

export const useAutoSizeSticker = (
  ref: RefObject<SVGSVGElement | HTMLElement>
) => {
  const [size, setSize] = useState<MaybeUndefined<Size>>();

  useLayoutEffect(() => {
    const shape = ref.current;

    if (!shape) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        setSize((prev) => {
          const { width, height } = entry.contentRect;
          if (prev?.width !== width || prev?.height !== height) {
            return { width, height };
          }
          return prev;
        });
      }
    });

    observer.observe(shape);

    return () => observer.disconnect();
  }, [ref]);

  useLayoutEffect(() => {
    const shape = ref.current;
    if (shape) {
      const styles = getComputedStyle(shape);
      const width = parseFloat(styles.getPropertyValue('--sticker-width'));
      const height = parseFloat(styles.getPropertyValue('--sticker-height'));
      setSize({ width, height });
    }
  }, [ref]);

  return size;
};
