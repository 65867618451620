import { useRef } from 'react';

import { EditDockField } from '@/components/EditDock/components/EditDockField';
import { useUrlFieldMode } from '@/components/EditDock/WebEditDock/hooks/useUrlFieldMode';
import { useWebviewLoadAnimation } from '@/components/EditDock/WebEditDock/hooks/useWebviewLoadAnimation';
import {
  EditableUrl,
  SimplifiedUrl,
} from '@/components/EditDock/WebEditDock/Url';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';
import { getFaviconUrl, simplifyUrl } from '@/utils/url/url';

import styles from './UrlField.module.css';

export type UrlFieldProps = {
  handleNewUrl: (url: string) => void;
  url: KosmikWebSticker['attributes']['url'];
};

export const UrlField = ({ handleNewUrl, url }: UrlFieldProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const editableUrlRef = useRef<HTMLDivElement>(null);
  const simplifiedUrlRef = useRef<HTMLDivElement>(null);
  const simplifiedUrl = simplifyUrl(url);
  const faviconUrl = getFaviconUrl(simplifiedUrl);
  const setMode = useUrlFieldMode(
    editableUrlRef,
    simplifiedUrlRef,
    url,
    simplifiedUrl
  );

  const isPinned = usePinPanel();

  useWebviewLoadAnimation(containerRef, isPinned);

  return (
    <EditDockField
      ref={containerRef}
      className={styles.urlField}
      data-testid={'editdock-urlfield'}
    >
      <EditableUrl
        ref={editableUrlRef}
        {...{ url, faviconUrl, setMode, handleNewUrl }}
      />
      <SimplifiedUrl
        ref={simplifiedUrlRef}
        {...{ simplifiedUrl, faviconUrl }}
      />
    </EditDockField>
  );
};
