import { Palette } from '@lithium/spectrum';

import { useCreateDefaultPromptSticker } from '@/hooks/sticker/create/useCreateDefaultPromptSticker';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const CreatePromptStickerMenuItem = ({ type }: { type: MenuType }) => {
  const { handleCreateDefaultPrompt } = useCreateDefaultPromptSticker();
  const { Item } = getPolymorphicMenuComponents(type);

  return (
    <Item
      onClick={(event) => handleCreateDefaultPrompt(event.shiftKey)}
      prefix={<Palette />}
    >
      Prompt
    </Item>
  );
};
