/**
 * Type to represent a CSS variable
 */
export type CSSVar = `--${string}`;

/**
 * Type guard, returns if the given value is a CSS variable
 * @param what
 */
export const isCSSVar = (what: unknown): what is CSSVar => {
  return (
    typeof what === 'string' &&
    what.startsWith('--') &&
    what.length > 2 &&
    !what.match(/\s/)
  );
};

/**
 * Wraps a css variable starting with `--` within a var(...) or returns the string as is
 * @param value
 */
export const wrapCSSVar = (value: string) =>
  isCSSVar(value) ? `var(${value})` : value;

/**
 * Resolves a CSS variable string '--label-primary' to the current value.
 * If it fails it fallback to returning the css var() call.
 * N.B. It's important to resolve the style against the element that has
 * the light-scheme / dark-scheme applied, or below in the tree, so that
 * forced colors are properly resolved
 *
 * @param input the variable to resolve for example `--label-white`
 * @param contextElement the element on which to resolve the variable
 */
export const resolveCSSVariable = (
  input: CSSVar,
  contextElement = document.body
): string => {
  const resolvedColor =
    getComputedStyle(contextElement).getPropertyValue(input);
  return resolvedColor ?? wrapCSSVar(input);
};
