import { useEffect, useLayoutEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { followingAtom } from '@/atoms/multiplayer';
import { useRoom } from '@/context/Room/useRoom';
import { useCamera } from '@/hooks/camera/useCamera';
import { Size } from '@/utils/geometry/size';
import { getUiVisibleDimensions } from '@/utils/ui/ui';

export const useFollowingCamera = () => {
  const { room } = useRoom();
  const { setCamera, animateCamera } = useCamera();
  const following = useRecoilValue(followingAtom);
  const { peers } = room.usePresence({
    user: false,
    keys: ['camera', 'viewport', 'canvas', 'user'],
    peers: following ? [following.instantPeerId] : [],
  });
  const followingRef = useRef<string>('');

  useLayoutEffect(() => {
    followingRef.current = '';
  }, [following]);

  useEffect(() => {
    if (following) {
      const followedPeer = peers[following.instantPeerId];
      const {
        camera: followedCamera,
        viewport: followedViewport,
        canvas: followedCanvas,
      } = followedPeer ?? {};
      if (
        followedPeer &&
        followedCanvas &&
        followedCamera &&
        followedViewport
      ) {
        const visibleUiDimensions = getUiVisibleDimensions();
        const fittingDimension: keyof Size =
          followedCanvas.width / followedCanvas.height >=
          visibleUiDimensions.canvas.width / visibleUiDimensions.canvas.height
            ? 'width'
            : 'height';
        const viewport: Size = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
        const ratio =
          followedCanvas[fittingDimension] /
          visibleUiDimensions.canvas[fittingDimension];
        const newZ = followedCamera.z / ratio;
        const deltaScaledViewport: Size = {
          width: viewport.width - followedViewport.width / ratio,
          height: viewport.height - followedViewport.height / ratio,
        };
        const followedSidePanelWidth =
          followedViewport.width - followedCanvas.width;
        const followedToolbarHeight =
          followedViewport.height - followedCanvas.height;
        const sidePanelDelta =
          visibleUiDimensions.sidePanelWidth - followedSidePanelWidth / ratio;
        const toolbarDelta =
          visibleUiDimensions.toolbarHeight - followedToolbarHeight / ratio;
        const newCamera = {
          z: newZ,
          x:
            followedCamera.x +
            (fittingDimension === 'height'
              ? deltaScaledViewport.width / 2 / newZ
              : 0) +
            sidePanelDelta / (fittingDimension === 'width' ? 1 : 2) / newZ,
          y:
            followedCamera.y +
            (fittingDimension === 'width'
              ? deltaScaledViewport.height / 2 / newZ
              : 0) +
            toolbarDelta / (fittingDimension === 'height' ? 1 : 2) / newZ,
        };
        if (followingRef.current !== following.instantPeerId) {
          animateCamera(newCamera);
          followingRef.current = following.instantPeerId;
        } else {
          setCamera(newCamera);
        }
      }
    }
  }, [animateCamera, following, peers, setCamera]);
};
