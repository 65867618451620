import { tx } from '@instantdb/react';

import { db } from '@/db/databaseInit';
import { batchTransact } from '@/utils/db/db';
import { KosmikUser } from '@/utils/kosmik/user';

export interface UserFromInstant {
  id: string;
  email: string;
  created_at?: string;
}

export const createNewUser = async (
  instantUser: UserFromInstant,
  name?: string
) => {
  const { data } = await db.queryOnce({
    users: {
      preferences: {},
      $: {
        where: {
          email: instantUser.email,
        },
      },
    },
  });
  const user = data?.users?.[0];

  const databaseUser: Partial<KosmikUser> = {
    email: instantUser.email,
    created_at: instantUser?.created_at ?? new Date().toISOString(),
  };

  if (name) {
    databaseUser.name = name;
  }

  const txs = [];

  // Create the user if it doesn't exist yet, or update it if a name was given
  if (!user || name) {
    txs.push(tx.users?.[instantUser.id]?.update(databaseUser));
  }

  // Create the preferences if not existing yet
  if (!user?.preferences?.[0]) {
    txs.push(
      tx.preferences?.[instantUser.id]
        ?.update({ created_at: new Date().toISOString() })
        .link({ users: [instantUser.id] })
    );
  }

  await batchTransact(txs);
};
