/**
 * Determines if an element is input-like (can receive text input).
 *
 * @param element - The element to check.
 * @returns True if the element is an HTMLInputElement, HTMLTextAreaElement, or has contentEditable set to 'true'.
 *
 * @example
 * const inputElement = document.createElement('input');
 * console.log(isInputLike(inputElement)); // true
 *
 * const divElement = document.createElement('div');
 * console.log(isInputLike(divElement)); // false
 *
 * const editableDiv = document.createElement('div');
 * editableDiv.contentEditable = 'true';
 * console.log(isInputLike(editableDiv)); // true
 */
export const isInputLike = (
  element: Element | EventTarget | null
): element is HTMLElement => {
  return (
    element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    (element as HTMLElement).contentEditable === 'true'
  );
};
