import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';

import { DropdownSubContent } from '@/components/ui/Dropdown/DropdownSubContent';
import { DropdownSubTrigger } from '@/ui/Dropdown/DropdownSubTrigger';

export interface ContextMenuSubProps
  extends DropdownPrimitive.DropdownMenuProps,
    React.PropsWithChildren<{
      trigger: React.ReactNode;
      triggerAsChild?: boolean;
      contentProps?: DropdownPrimitive.DropdownMenuSubContentProps;
    }> {}

export const DropdownSub = ({
  contentProps,
  trigger,
  triggerAsChild = true,
  children,
  ...props
}: ContextMenuSubProps) => (
  <DropdownPrimitive.Sub {...props}>
    <DropdownSubTrigger asChild={triggerAsChild}>{trigger}</DropdownSubTrigger>

    <DropdownPrimitive.Portal>
      <DropdownSubContent
        alignOffset={-5.5}
        collisionPadding={4}
        {...contentProps}
      >
        {children}
      </DropdownSubContent>
    </DropdownPrimitive.Portal>
  </DropdownPrimitive.Sub>
);
