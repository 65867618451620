import * as Progress from '@radix-ui/react-progress';

import styles from './ProgressBar.module.css';

interface ProgressBarProps {
  progress: number;
}

export const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <Progress.Root className={styles.root} value={progress}>
      <Progress.Indicator
        className={styles.indicator}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  );
};
