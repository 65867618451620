import { Button } from '@lithium/spectrum';
import { useCallback, useState } from 'react';

import styles from '@/components/ShareMenu/PublishMenu/PublishMenu.module.css';
import { useCurrentUniverse } from '@/hooks/useCurrentUniverse';
import { LabelSwitch } from '@/ui/Switch/LabelSwitch';
import { updatePublishUniverse } from '@/utils/universe/universe';

export const UnpublishedState = () => {
  const [isPublished, setIsPublished] = useState(false);
  const [isDuplicable, setIsDuplicable] = useState(false);
  const { universe } = useCurrentUniverse();

  const handlePublish = useCallback(() => {
    if (universe) {
      updatePublishUniverse(universe, isPublished, isDuplicable);
    }
  }, [isDuplicable, isPublished, universe]);

  return (
    <>
      <LabelSwitch checked={isPublished} onCheckedChange={setIsPublished}>
        Publish to web
      </LabelSwitch>
      <LabelSwitch checked={isDuplicable} onCheckedChange={setIsDuplicable}>
        Allow duplicate
      </LabelSwitch>
      <div className={styles.buttonContainer}>
        <Button disabled={!isPublished} onAction={handlePublish}>
          Publish
        </Button>
      </div>
    </>
  );
};
