import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';

import { Text } from '@/components/fontSystem/Text';

export const TextSection = forwardRef(
  (
    props: ComponentPropsWithoutRef<'span'>,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    return <Text ref={ref} variant={'section'} {...props} />;
  }
);

TextSection.displayName = 'TextSection';
