import equal from 'fast-deep-equal/react';
import {
  ComponentProps,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useRecoilValue } from 'recoil';

import { stickerSelectionStateFamily } from '@/atoms/sticker';
import { StickerStyle } from '@/components/HTMLCanvas/StickerStyle';
import { useStickerSelection } from '@/components/selection/useStickerSelection';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { KosmikSticker } from '@/utils/kosmik/sticker';

interface StickerWrapperProps extends ComponentProps<'div'> {
  sticker: KosmikSticker;
}

export const StickerWrapper = memo(
  ({ sticker, children, ...rest }: StickerWrapperProps) => {
    const user = useContext(MultiplayerUserContext);
    const ref = useRef<HTMLDivElement>(null);
    const selection = useRecoilValue(stickerSelectionStateFamily(sticker.id));
    const { select } = useStickerSelection();

    const handleContextMenu = useCallback(() => {
      if (user && !selection.has(user)) {
        select(sticker.id);
      }
    }, [select, selection, sticker.id, user]);

    return useMemo(
      () => (
        <StickerStyle ref={ref} onContextMenu={handleContextMenu} {...rest}>
          {children}
        </StickerStyle>
      ),
      [children, handleContextMenu, rest]
    );
  },
  (prevProps, nextProps) => {
    const { sticker, ...rest } = prevProps;
    const {
      x: _x,
      y: _y,
      width: _width,
      height: _height,
      ...stickerProps
    } = sticker;
    const { sticker: nextSticker, ...nextRest } = nextProps;
    const {
      x: _nx,
      y: _ny,
      width: _nw,
      height: _nh,
      ...nextStickerProps
    } = nextSticker;

    return equal(rest, nextRest) && equal(stickerProps, nextStickerProps);
  }
);

StickerWrapper.displayName = 'StickerWrapper';
