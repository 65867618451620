import { Bold, Italic, Strike, Underline } from '@lithium/spectrum';
import { useCallback, useRef } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { stickerQuillAttributesFamily } from '@/atoms/sticker';
import { EditDockColorItem } from '@/components/EditDock/components/EditDockColorItem';
import { EditDockColorPickerItem } from '@/components/EditDock/components/EditDockColorPickerItem';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { getCurrentTextColor } from '@/components/EditDock/TextEditDock/utils/textEditDock';
import { TextEditorFormat } from '@/components/Quill/QuillEditor';
import { STICKY_COLORS } from '@/constants/colors';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { MenuSeparator } from '@/ui/MenuPrimitive/MenuSeparator';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikStickySticker } from '@/utils/kosmik/stickers/sticky';
import { MaybeNull } from '@/utils/types';

export const StickyEditDock = ({
  stickerId,
  attributes,
  setSticker,
}: {
  stickerId: string;
  attributes: KosmikStickySticker['attributes'];
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}) => {
  const [quillAttributes, setQuillAttributes] = useRecoilState(
    stickerQuillAttributesFamily(stickerId)
  );
  const setAttributes = useSetStickerAttributes<typeof attributes>(setSticker);
  const universe = useUniverseContext();
  const { addUndoRedoAction } = useUndoRedo(universe.id);
  const currentFillColorRef = useRef(attributes.fill_color);

  const handleColorChange = useCallback(
    (color: string) => setAttributes({ fill_color: color }, false),
    [setAttributes]
  );

  const handleColorCommit = useCallback(
    (color: string) => {
      const preCommit = currentFillColorRef.current;
      const fillColorChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ fill_color: color }, true);
        },
        undo: () => {
          setAttributes({ fill_color: preCommit }, true);
        },
      };
      fillColorChangeAction.do();
      addUndoRedoAction(fillColorChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  const handleTextColorChange = useCallback(
    (color: string) =>
      setQuillAttributes((prev) => {
        return { ...prev, color };
      }),
    [setQuillAttributes]
  );

  const changeSelectedStyle = (format: TextEditorFormat) => {
    setQuillAttributes((prev) => {
      return { ...prev, [format]: !prev[format] };
    });
  };

  const currentColor = attributes.fill_color ?? '--tag-yellow-saturated';
  const currentTextColor =
    getCurrentTextColor(quillAttributes) ?? '--label-black';

  return (
    <>
      <EditDockItem
        active={quillAttributes?.bold}
        onClick={() => changeSelectedStyle('bold')}
      >
        <Bold />
      </EditDockItem>
      <EditDockItem
        active={quillAttributes?.italic}
        onClick={() => changeSelectedStyle('italic')}
      >
        <Italic />
      </EditDockItem>
      <EditDockItem
        active={quillAttributes?.underline}
        onClick={() => changeSelectedStyle('underline')}
      >
        <Underline />
      </EditDockItem>
      <EditDockItem
        active={quillAttributes?.strike}
        onClick={() => changeSelectedStyle('strike')}
      >
        <Strike />
      </EditDockItem>
      <EditDockColorPickerItem
        colorWheel={false}
        currentColor={currentTextColor}
        onColorChange={handleTextColorChange}
        onColorCommit={() => undefined}
      />
      <MenuSeparator orientation={'vertical'} />
      {STICKY_COLORS.map((color) => (
        <EditDockColorItem
          key={color}
          color={color}
          currentColor={currentColor}
          onColorChange={handleColorChange}
          onColorCommit={handleColorCommit}
        />
      ))}
      <EditDockColorPickerItem
        colorWheel={true}
        colors={STICKY_COLORS}
        currentColor={currentColor}
        onColorChange={handleColorChange}
        onColorCommit={handleColorCommit}
      />
    </>
  );
};
