import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import React from 'react';

import { MenuLabel } from '@/components/ui/MenuPrimitive/MenuLabel';

export const ContextMenuLabel = React.forwardRef<
  HTMLDivElement,
  ContextMenuPrimitive.ContextMenuLabelProps
>(({ children, ...props }, ref) => {
  return (
    <ContextMenuPrimitive.Label asChild {...props}>
      <MenuLabel ref={ref}>{children}</MenuLabel>
    </ContextMenuPrimitive.Label>
  );
});

ContextMenuLabel.displayName = 'ContextMenuLabel';
