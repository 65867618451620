import { ChevronDown } from '@lithium/spectrum';
import { useCallback, useState } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { TextTag } from '@/components/fontSystem/TextTag';
import { CommonModalProps } from '@/components/modal/Modals';
import styles from '@/components/SidePanel/TeamDropdown/TeamDropdown.module.css';
import { useTeam } from '@/context/Team/useTeam';
import { useUser } from '@/context/User/useUser';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { DropdownItem } from '@/ui/Dropdown/DropdownItem';
import { leaveFromTeam } from '@/utils/team/team';

export type TeamRoleDropdownProps = {
  userId: string;
} & Pick<CommonModalProps, 'closeModal'>;

export const TeamRoleDropdown = ({
  userId,
  closeModal,
}: TeamRoleDropdownProps) => {
  const user = useUser();
  const { team } = useTeam();
  const [open, setOpen] = useState(false);

  const handleRemove = useCallback(() => {
    leaveFromTeam(userId, team.id);
    if (userId === user?.id) {
      closeModal();
    }
  }, [closeModal, team.id, user?.id, userId]);

  const trigger = (
    <div className={styles.button} key={userId}>
      <TextTag>Admin</TextTag>
      <ChevronDown
        style={{
          transition: '.2s transform',
          transform: open ? 'rotate(-180deg)' : 'rotate(0deg)',
        }}
      />
    </div>
  );

  return (
    <Dropdown
      key={userId}
      trigger={trigger}
      onOpenChange={(open) => setOpen(open)}
    >
      <DropdownItem onClick={handleRemove}>
        <TextLabelRegular>Remove</TextLabelRegular>
      </DropdownItem>
    </Dropdown>
  );
};
