// Import the generated route tree
import {
  createBrowserHistory,
  createHashHistory,
  createRouter,
} from '@tanstack/react-router';

import { ErrorPage } from '@/components/ErrorPage/ErrorPage';
import { LoadingView } from '@/components/loading/LoadingView';

import { routeTree } from './routeTree.gen';

// Create a hash history instance for electron builds, create a browser history instance for web builds
// source: https://github.com/TanStack/router/discussions/835
const isWeb = window?.electron === undefined;

// Create a new router instance
export const router = createRouter({
  basepath: import.meta.env.BASE_URL || '/',
  routeTree,
  history: isWeb ? createBrowserHistory() : createHashHistory(),
  defaultPendingComponent: () => <LoadingView />,
  defaultErrorComponent: ({ error }) => (
    <ErrorPage
      variant={'custom'}
      title={error.name}
      subtitle={error.message}
      description={error.stack}
    />
  ),
  defaultNotFoundComponent: () => <ErrorPage variant={'404'} />,
  // defaultViewTransition: false,
  context: { authenticated: false },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
