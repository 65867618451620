import { tx } from '@instantdb/react';

import { batchTransact } from '@/utils/db/db';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { deleteDatabaseStickers } from '@/utils/sticker/sticker';

export const deleteUser = async (
  userId: string,
  teams: string[],
  preferences: string[],
  universes: string[],
  stickers: KosmikSticker[]
) => {
  // delete user and all their content
  // Important: The default user's team has the same ID as the user id
  deleteDatabaseStickers(stickers);
  await batchTransact([
    tx.users?.[userId]?.delete(),
    ...teams.map((id) => tx.teams?.[id]?.delete()),
    ...universes.map((id) => tx.universes?.[id]?.delete()),
    ...preferences.map((id) => tx.preferences?.[id]?.delete()),
  ]);
};
