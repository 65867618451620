import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { useSetDefaultFileResizable } from '@/components/HTMLCanvas/stickers/hooks/useSetDefaultResizable';
import { HtmlImageExtractSticker } from '@/components/HTMLCanvas/stickers/HtmlImageExtractSticker';
import { HtmlLoadingSticker } from '@/components/HTMLCanvas/stickers/HtmlLoadingSticker';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { useSetStickerProperties } from '@/hooks/sticker/useSetStickerProperties';
import { normalizeImageSize } from '@/utils/image';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';

import styles from './HtmlImageSticker.module.css';

export const HtmlImageSticker = ({
  sticker,
  refreshCachedUrl,
}: {
  sticker: KosmikFileSticker;
  refreshCachedUrl: () => void;
}) => {
  const [error, setError] = useState<Error | MediaError | null>(null);
  const file = sticker.files?.[0];
  const { cached_url, file_name } = file ?? sticker.attributes;
  const { extract } = sticker.attributes;
  const { ratio } = sticker.attributes;
  const imgRef = useRef<HTMLImageElement>(null);
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerProperties =
    useSetStickerProperties<KosmikFileSticker>(setSticker);
  const setStickerAttributes =
    useSetStickerAttributes<FileStickerAttributes>(setSticker);
  useSetDefaultFileResizable(sticker);

  useEffect(() => {
    if (error && cached_url) {
      refreshCachedUrl();
    }
  }, [cached_url, error, refreshCachedUrl]);

  useEffect(() => {
    if (cached_url) {
      setError(null);
    }
  }, [cached_url]);

  if (extract) {
    return <HtmlImageExtractSticker sticker={sticker} extract={extract} />;
  }

  return (
    <HtmlLoadingSticker stickerId={sticker.id} isLoading={!cached_url}>
      {cached_url ? (
        <img
          ref={imgRef}
          src={cached_url}
          alt={file_name}
          data-testid={'image-sticker'}
          className={styles.imageSticker}
          style={{
            opacity: error ? 0 : 1,
          }}
          onPointerDown={(event) => event.preventDefault()}
          onLoad={() => {
            const image = imgRef.current;
            if (!image) {
              return;
            }
            const imageWidth = image.naturalWidth;
            const imageHeight = image.naturalHeight;
            if (!imageWidth || !imageHeight) {
              return;
            }
            const imageRatio = imageWidth / imageHeight;

            if (imageRatio !== ratio) {
              const normalizedDimensions = normalizeImageSize(image);
              setStickerProperties(
                {
                  width: normalizedDimensions.width,
                  height: normalizedDimensions.height,
                },
                true
              );
              setStickerAttributes({ ratio: imageRatio }, true);
            }
          }}
          onError={() => {
            setError((prev) => prev ?? new Error('Undefined image error'));
          }}
        />
      ) : null}
    </HtmlLoadingSticker>
  );
};
