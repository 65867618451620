import { useRecoilValue } from 'recoil';

import { sidePanelOpenAtom } from '@/atoms/sidePanel';
import { ShareMenu } from '@/components/ShareMenu/ShareMenu';
import { CreateUniverseButton } from '@/components/SidePanel/CreateUniverseButton';
import { DuplicateButton } from '@/components/Toolbar/DuplicateButton';
import { LoginButton } from '@/components/Toolbar/LoginButton';
import { SidebarToggleButton } from '@/components/Toolbar/SidebarToggleButton';
import { UniverseMenu } from '@/components/Toolbar/UniverseMenu/UniverseMenu';
import { UserBubbles } from '@/components/UserBubbles';
import { useUser } from '@/context/User/useUser';
import { useCurrentUniverse } from '@/hooks/useCurrentUniverse';
import { useIsUniverseMember } from '@/hooks/useIsUniverseMember';
import { NotificationsButton } from '@/ui/Notifications/NotificationsButton';
import { isMacOS } from '@/utils/platform/isMacOS';

import styles from './Toolbar.module.css';

const Spacer = ({ display }: { display: boolean }) => {
  return (
    <div
      className={styles.spacer}
      style={{ width: display ? '128px' : '0px' }}
    ></div>
  );
};

export const Toolbar = () => {
  const isOpen = useRecoilValue(sidePanelOpenAtom);
  const { universe } = useCurrentUniverse();
  const isUniverseMember = useIsUniverseMember(universe);
  const contextUser = useUser();

  const isLoggedIn = !!contextUser;
  const displaySpacer = isMacOS && !isOpen;

  return (
    <div className={styles.toolbarWrapper} id={'toolbar'}>
      <Spacer display={displaySpacer} />
      <div className={styles.toolbar}>
        <div className={styles.buttongroup}>
          {!isMacOS && isLoggedIn ? <SidebarToggleButton /> : null}
          {!isLoggedIn ? <LoginButton /> : null}
          {universe ? (
            <UniverseMenu
              universe={universe}
              isMember={isUniverseMember && isLoggedIn}
            />
          ) : null}
          {isLoggedIn ? <CreateUniverseButton /> : null}
        </div>
        <div className={styles.trailing}>
          {isUniverseMember ? <UserBubbles /> : null}
          {universe?.isDuplicable && !isUniverseMember ? (
            <DuplicateButton />
          ) : null}
          {universe && isUniverseMember ? <ShareMenu /> : null}
          {isLoggedIn ? <NotificationsButton /> : null}
        </div>
      </div>
    </div>
  );
};
