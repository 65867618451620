import { MAX_ZOOM, MIN_ZOOM } from '@/constants/zoom';
import { Dimension } from '@/utils/geometry/dimension';
import { Point } from '@/utils/geometry/point';
import { Position } from '@/utils/geometry/position';
import { clamp } from '@/utils/number/number';
import { getUiVisibleDimensions } from '@/utils/ui/ui';

export interface Camera {
  x: number;
  y: number;
  z: number;
}

export interface Box {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  width: number;
  height: number;
}

/**
 * Convert a screen position to canvas coordinates.
 * @param point - The screen position.
 * @param camera - The current camera state.
 * @returns The converted canvas position.
 */
export const screenToCanvas = (point: Position, camera: Camera): Position => ({
  x: point.x / camera.z - camera.x,
  y: point.y / camera.z - camera.y,
});

/**
 * Convert position from universe to the canvas window.
 * @param point
 * @param camera
 */
export const canvasToScreen = (point: Position, camera: Camera): Position => {
  const uiDimensions = getUiVisibleDimensions();
  return {
    x: (point.x + camera.x) * camera.z - uiDimensions.sidePanelWidth,
    y: (point.y + camera.y) * camera.z - uiDimensions.toolbarHeight,
  };
};

/**
 * Return true if the position given is visible on the canvas.
 * @param position
 * @param camera
 */
export const isPositionInVisibleCanvas = (
  position: Position,
  camera: Camera
) => {
  const uiDimensions = getUiVisibleDimensions();
  const convertedPosition = canvasToScreen(position, camera);

  return (
    convertedPosition.x >= 0 &&
    convertedPosition.x <= uiDimensions.canvas.width &&
    convertedPosition.y >= 0 &&
    convertedPosition.y <= uiDimensions.canvas.height
  );
};

/**
 * Pan the camera by a given delta.
 * @param camera - The current camera state.
 * @param dx - The horizontal delta.
 * @param dy - The vertical delta.
 * @returns The new camera state after panning.
 */
export const panCamera = (camera: Camera, dx: number, dy: number): Camera => ({
  x: camera.x - (dx / camera.z) * 1.5,
  y: camera.y - (dy / camera.z) * 1.5,
  z: camera.z,
});

/**
 * Constrain the zoom value to upper and lower bounds
 * @param zoom
 */
export const clampZoom = (zoom: number) => {
  return clamp(MIN_ZOOM, zoom, MAX_ZOOM);
};

/**
 * Zoom Camera to point with ZoomDelta
 */
export const zoomCameraTo = (
  camera: Camera,
  point: Position,
  dz: number
): Camera => {
  const zoom = clampZoom(camera.z - dz * camera.z);
  const p1 = screenToCanvas(point, camera);
  const p2 = screenToCanvas(point, { ...camera, z: zoom });

  return {
    x: camera.x + (p2.x - p1.x),
    y: camera.y + (p2.y - p1.y),
    z: zoom,
  };
};

/**
 * Zoom Camera to a specific Zoom level
 */
export const zoomCameraToZoom = (
  camera: Camera,
  point: Position,
  zoom: number
): Camera => {
  const p1 = screenToCanvas(point, camera);
  const p2 = screenToCanvas(point, { ...camera, z: zoom });

  return {
    x: camera.x + (p2.x - p1.x),
    y: camera.y + (p2.y - p1.y),
    z: zoom,
  };
};

/**
 * Returns the current camera values for the given element, this is helpful to
 * avoid using useCamera which triggers a re-render at every camera value change
 */
export const getCurrentCameraValues = (): Camera => {
  const cameraContainer = document.querySelector('[data-camera]');
  if (cameraContainer) {
    return {
      x: Number(cameraContainer.getAttribute('data-camera-x') ?? 1),
      y: Number(cameraContainer.getAttribute('data-camera-y') ?? 1),
      z: Number(cameraContainer.getAttribute('data-camera-z') ?? 1),
    };
  }
  return { x: 1, y: 1, z: 1 };
};

/**
 * Computes the new camera position in order to center camera on given bounds
 * @param bounds the bounds to center on
 * @param fitRatio the percentage that the centered bounds should occupy
 * @param maxZoom the maximum zoom to apply
 */
const computeCenterCameraPosition = (
  bounds: Dimension,
  fitRatio: number,
  maxZoom?: number
) => {
  const uiDimensions = getUiVisibleDimensions();
  const newZoom = clamp(
    0,
    clampZoom(
      Math.min(
        uiDimensions.canvas.width / bounds.width,
        uiDimensions.canvas.height / bounds.height
      ) * fitRatio
    ),
    maxZoom ?? MAX_ZOOM
  );

  return {
    x:
      -bounds.x -
      bounds.width / 2 +
      uiDimensions.sidePanelWidth / newZoom +
      uiDimensions.canvas.width / 2 / newZoom,
    y:
      -bounds.y -
      bounds.height / 2 +
      uiDimensions.toolbarHeight / newZoom +
      uiDimensions.canvas.height / 2 / newZoom,
    z: newZoom,
  };
};

/**
 * Center the camera on the given bounds
 * @param camera the current camera
 * @param bounds the bound to center the camera on
 * @param alternateBounds the alternate bounds when the camera is already centered
 * @param fitRatio the percentage the centered bounds should occupy
 * @param maxZoom the maximum zoom to apply
 */
export const getZoomToFitTargetCamera = (
  camera: Camera,
  bounds: Dimension,
  alternateBounds?: Dimension,
  fitRatio = 0.9,
  maxZoom?: number
) => {
  const newCameraPosition = computeCenterCameraPosition(
    bounds,
    fitRatio,
    maxZoom
  );

  const newCameraAlternatePosition = alternateBounds
    ? computeCenterCameraPosition(alternateBounds, fitRatio, maxZoom)
    : newCameraPosition;

  const isDifferentEnoughCameraValues =
    Math.abs(camera.z - newCameraPosition.z) > 0.1 ||
    Math.abs(camera.x - newCameraPosition.x) > 10 ||
    Math.abs(camera.y - newCameraPosition.y) > 10;

  return isDifferentEnoughCameraValues
    ? newCameraPosition
    : newCameraAlternatePosition;
};

/**
 * Returns the viewport center point for zoom
 */
export const getZoomCenterPoint = (): Point => {
  const uiDimensions = getUiVisibleDimensions();
  return {
    x: uiDimensions.canvas.width / 2 + uiDimensions.sidePanelWidth,
    y: uiDimensions.canvas.height / 2 + uiDimensions.toolbarHeight,
  };
};

/**
 * Returns the center point in canvas coordinates for the current viewport
 */
export const getCenterPoint = (camera: Camera) => {
  const uiDimensions = getUiVisibleDimensions();
  return {
    x:
      -camera.x +
      (uiDimensions.sidePanelWidth + uiDimensions.canvas.width / 2) / camera.z,
    y:
      -camera.y +
      (uiDimensions.toolbarHeight + uiDimensions.canvas.height / 2) / camera.z,
  };
};
