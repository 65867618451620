import styles from './UrlIcon.module.css';

export const UrlIcon = ({ imageUrl }: { imageUrl?: string }) => {
  return (
    <img
      alt={'Icon'}
      className={styles.urlIcon}
      src={imageUrl || 'assets/images/kosmik/art/saturn.svg'}
    />
  );
};
