import { RouterProvider } from '@tanstack/react-router';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import { InternalToolbar } from '@/components/internal/InternalToolbar';
import { LoadingView } from '@/components/loading/LoadingView';
import { db } from '@/db/databaseInit';
import { router } from '@/router';
import { useSyncColorScheme } from '@/utils/css/hooks/useSyncColorScheme';

export const App = () => {
  useSyncColorScheme();
  const auth = db.useAuth();

  if (auth.isLoading) {
    return <LoadingView />;
  }

  if (auth.error) {
    return <div>Uh oh error! {auth?.error?.message}</div>;
  }

  return (
    <RecoilRoot>
      <RecoilNexus />
      <RouterProvider
        router={router}
        context={{ authenticated: !!auth.user }}
      />
      <InternalToolbar />
    </RecoilRoot>
  );
};
