import { Dimension } from '@/utils/geometry/dimension';
import { Maybe } from '@/utils/types';

interface SelectionAreaProps {
  area?: Maybe<Dimension>;
  color?: string;
}

export const SelectionArea = ({ area, color }: SelectionAreaProps) => {
  return (
    <div
      key={color}
      data-testid={'selectionArea'}
      style={{
        transform: `matrix(1, 0, 0, 1, ${area?.x ?? 0}, ${area?.y ?? 0})`,
        width: area?.width,
        height: area?.height,
        position: 'absolute',
        backgroundColor: color,
        opacity: 0.2,
        borderRadius: 4,
        zIndex: 'var(--zindex-important-element)',
      }}
    ></div>
  );
};
