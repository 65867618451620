import { atom } from 'recoil';

import { KosmikUniverse } from '@/utils/kosmik/universe';

/**
 * Currently active universe tabs
 */
export const universeTabsAtom = atom<KosmikUniverse[]>({
  key: 'universeTabsAtom',
  default: [],
});
