import { useEffect, useMemo, useState } from 'react';

import { PublishState } from '@/components/ShareMenu/PublishMenu/PublishState';
import { UnpublishedState } from '@/components/ShareMenu/PublishMenu/UnpublishState';
import { useCurrentUniverse } from '@/hooks/useCurrentUniverse';
import { MaybeUndefined } from '@/utils/types';

import styles from './PublishMenu.module.css';

enum PublishStatus {
  published = 'published',
  unpublished = 'unpublished',
}

export const PublishMenu = () => {
  const { universe } = useCurrentUniverse();
  const [publishState, setPublishState] =
    useState<MaybeUndefined<PublishStatus>>(undefined);

  useEffect(() => {
    setPublishState(
      universe?.isPublished
        ? PublishStatus.published
        : PublishStatus.unpublished
    );
  }, [universe?.isPublished]);

  const content = useMemo(() => {
    switch (publishState) {
      case PublishStatus.unpublished:
        return <UnpublishedState />;
      case PublishStatus.published:
        return <PublishState />;
      default:
        return null;
    }
  }, [publishState]);

  return <div className={styles.menu}>{content}</div>;
};
