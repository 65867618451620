import { Button } from '@lithium/spectrum';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { FormEvent } from 'react';

import { TextHeader3 } from '@/components/fontSystem/Header3';
import { CommonModalProps } from '@/components/modal/Modals';
import { DialogDescription, DialogTitle } from '@/components/ui/Dialog/Dialog';
import { DialogFooter } from '@/components/ui/Dialog/DialogFooter';
import { useUser } from '@/context/User/useUser';
import { db } from '@/db/databaseInit';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import { inviteToUniverse } from '@/utils/universe/universe';

export type InviteToUniverseProps = CommonModalProps & {
  universe: KosmikUniverse;
};

export const InviteToUniverse = ({
  universe,
  closeModal,
}: InviteToUniverseProps) => {
  const currentUser = useUser();
  const { isLoading, error, data } = db.useQuery({
    users: {},
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!currentUser) {
      return;
    }

    // get email from form
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    if (!email) {
      return;
    }

    // find user id by email
    const invitedUser = data?.users.find((user) => user.email === email);
    if (!invitedUser) {
      console.error('User not found in data');
      return;
    }
    inviteToUniverse(universe, currentUser.id, invitedUser.id);
    closeModal();
  };

  if (isLoading) {
    return (
      <>
        <VisuallyHidden>
          <DialogTitle>
            <TextHeader3>Loading...</TextHeader3>
          </DialogTitle>
        </VisuallyHidden>
        <DialogDescription>Loading...</DialogDescription>
      </>
    );
  }

  if (error) {
    return (
      <>
        <VisuallyHidden>
          <DialogTitle>
            <TextHeader3>Error</TextHeader3>
          </DialogTitle>
        </VisuallyHidden>
        <DialogDescription>Uh oh! {error.message}</DialogDescription>
      </>
    );
  }

  if (!currentUser) {
    return (
      <>
        <VisuallyHidden>
          <DialogTitle>
            <TextHeader3>Error</TextHeader3>
          </DialogTitle>
        </VisuallyHidden>
        <DialogDescription>You are not logged in</DialogDescription>
      </>
    );
  }

  // filter out the current user from the suggestions
  const filteredUsers = data?.users.filter((user) => {
    return user.id !== currentUser.id;
  });

  return (
    <div style={{ minWidth: '300px' }}>
      <DialogTitle asChild>
        <TextHeader3>Invite to {universe.name}</TextHeader3>
      </DialogTitle>
      <DialogDescription>
        Invite someone to collaborate on this universe
      </DialogDescription>
      <form onSubmit={handleSubmit}>
        <input
          list={'users'}
          name={'email'}
          placeholder={'Type in the email you want to invite'}
          autoComplete={'off'}
        />
        <datalist id={'users'}>
          {filteredUsers.map((user) => (
            <option key={user.id} value={user.email} />
          ))}
        </datalist>
        <DialogFooter>
          <Button variant={'positive'}>Send invite</Button>
        </DialogFooter>
      </form>
    </div>
  );
};
