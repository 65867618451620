import 'pdfjs-dist/web/pdf_viewer.css';

import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { useEffect } from 'react';
import useSWR from 'swr';

import { Maybe } from '@/utils/types';

// MARGIN_PDF: corresponds to the space between each page of the extract if the extract is on more than one page
export const MARGIN_PDF = 4;

// this the indexes of page width/height properties in page view array
export enum PAGEVIEW {
  width = 2,
  height = 3,
}

const src = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url);
GlobalWorkerOptions.workerSrc = src.toString();

const fetcher = (url: string) => {
  return getDocument(url).promise.then((newPdf) => {
    return newPdf;
  });
};

const swrFileOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateIfStale: false,
  dedupingInterval: 3600 * 24,
};

export const usePdf = (url: Maybe<string>, refreshCachedUrl?: () => void) => {
  const { data, error, isLoading } = useSWR(
    url ?? null,
    fetcher,
    swrFileOptions
  );

  useEffect(() => {
    if (error) {
      refreshCachedUrl?.();
    }
  }, [error, refreshCachedUrl]);

  return { pdf: data, loading: isLoading, error };
};
