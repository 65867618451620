import { id } from '@instantdb/react';
import { Capture, StickerFile, StickerSource } from '@lithium/spectrum';
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilTransaction_UNSTABLE,
} from 'recoil';
import { z } from 'zod';

import { ActiveTool, pinPanelActiveToolAtom } from '@/atoms/pinpanel';
import { localStickerFamily } from '@/atoms/sticker';
import { EditDockDownloadItem } from '@/components/EditDock/components/EditDockDownloadItem';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { EditDockReplaceFileItem } from '@/components/EditDock/components/EditDockReplaceFileItem';
import { PdfEditDock } from '@/components/EditDock/PdfEditDock/PdfEditDock';
import { UrlRow } from '@/components/EditDock/WebEditDock/Url';
import styles from '@/components/EditDock/WebEditDock/Url.module.css';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { useSetPinnedSticker } from '@/hooks/pinPanel/useSetPinnedSticker';
import { Tooltip } from '@/ui/Tooltip/Tooltip';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';
import { isWeb } from '@/utils/platform/isWeb';
import { MaybeNull } from '@/utils/types';
import { getFaviconUrl, simplifyUrl } from '@/utils/url/url';

interface FileEditDockProps {
  sticker: KosmikFileSticker;
  attributes: FileStickerAttributes;
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}

export const StickerSourceEditDockItem = ({
  attributes,
}: Pick<FileEditDockProps, 'attributes'>) => {
  const { setPinnedSticker } = useSetPinnedSticker();
  const setWebStickerInPinPanel = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (sticker: KosmikWebSticker) => {
        set(localStickerFamily(sticker.id), sticker);
        setPinnedSticker(sticker.id);
      },
    [setPinnedSticker]
  );

  const source = attributes.source;

  if (!source) {
    return null;
  }

  const isUrl = z.string().url().safeParse(source).success;
  const isId = z.string().uuid().safeParse(source).success;

  const handleOpenSource = () => {
    if (isUrl) {
      if (isWeb) {
        window.open(source);
      } else {
        const sticker: KosmikWebSticker = {
          id: id(),
          v: 0,
          x: 0,
          y: 0,
          width: DEFAULT_STICKER_SIZE.web.width,
          height: DEFAULT_STICKER_SIZE.web.height,
          type: 'web',
          created_at: new Date().toISOString(),
          attributes: {
            url: source,
          },
        };
        setWebStickerInPinPanel(sticker);
      }
    } else if (isId) {
      setPinnedSticker(source);
    }
  };

  let toolTipContent;
  if (isUrl) {
    const simplifiedUrl = simplifyUrl(source);
    const faviconUrl = getFaviconUrl(simplifiedUrl);
    toolTipContent = (
      <UrlRow simplifiedUrl={simplifiedUrl} faviconUrl={faviconUrl} />
    );
  } else if (isId) {
    toolTipContent = (
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <StickerFile />
        <TextLabelRegular className={styles.urlText}>
          Display source
        </TextLabelRegular>
      </div>
    );
  }

  return (
    <Tooltip content={toolTipContent}>
      <EditDockItem onClick={handleOpenSource}>
        <StickerSource />
      </EditDockItem>
    </Tooltip>
  );
};

export const FileEditDock = ({
  sticker,
  attributes,
  setSticker,
}: FileEditDockProps) => {
  return (
    <>
      <StickerSourceEditDockItem attributes={attributes} />
      <FileSpecificEditDock
        sticker={sticker}
        attributes={attributes}
        setSticker={setSticker}
      />
    </>
  );
};

const FileSpecificEditDock = ({
  sticker,
  attributes,
  setSticker,
}: FileEditDockProps) => {
  const file = sticker.files?.[0];
  const { type } = file ?? attributes;

  if (type === 'application/pdf') {
    return (
      <PdfEditDock
        sticker={sticker}
        attributes={attributes}
        setSticker={setSticker}
      />
    );
  }

  if (type?.startsWith('video')) {
    return (
      <VideoEditDock
        sticker={sticker}
        attributes={attributes}
        setSticker={setSticker}
      />
    );
  }

  if (type?.startsWith('audio')) {
    return (
      <AudioEditDock
        sticker={sticker}
        attributes={attributes}
        setSticker={setSticker}
      />
    );
  }

  if (type?.startsWith('image')) {
    return (
      <ImageEditDock
        sticker={sticker}
        attributes={attributes}
        setSticker={setSticker}
      />
    );
  }

  return (
    <GenericFileEditDock
      sticker={sticker}
      attributes={attributes}
      setSticker={setSticker}
    />
  );
};

const VideoEditDock = ({ sticker, attributes }: FileEditDockProps) => {
  return <EditDockDownloadItem sticker={sticker} attributes={attributes} />;
};

const AudioEditDock = ({ sticker, attributes }: FileEditDockProps) => {
  return <EditDockDownloadItem sticker={sticker} attributes={attributes} />;
};

const ImageEditDock = ({ sticker, attributes }: FileEditDockProps) => {
  const isPinPanel = usePinPanel();
  const [pinPanelActiveTool, setPinPanelActiveTool] = useRecoilState(
    pinPanelActiveToolAtom
  );

  const toggleTool = (toolName: ActiveTool) => {
    setPinPanelActiveTool((prev) => {
      return prev === toolName ? null : toolName;
    });
  };

  return (
    <>
      {isPinPanel ? (
        <EditDockItem
          data-testid={'pin-panel-edit-dock-extract'}
          active={pinPanelActiveTool === 'extract'}
          onClick={() => toggleTool('extract')}
        >
          <Capture />
        </EditDockItem>
      ) : null}
      <EditDockReplaceFileItem sticker={sticker} />
      <EditDockDownloadItem sticker={sticker} attributes={attributes} />
    </>
  );
};

const GenericFileEditDock = ({ sticker, attributes }: FileEditDockProps) => {
  return <EditDockDownloadItem sticker={sticker} attributes={attributes} />;
};
