import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

import { isInputLike } from '@/utils/element/isInputLike';
import { Position } from '@/utils/geometry/position';

export const usePanNavigation = (
  containerRef: RefObject<HTMLDivElement>,
  callback: (delta: Position) => void
) => {
  const [spacebarDown, setSpacebarDown] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [previousPos, setPreviousPos] = useState({ x: 0, y: 0 });

  const pointerDownHandler = useCallback(
    (event: PointerEvent) => {
      const containerElement = containerRef.current;
      if (containerElement === null) {
        return;
      }
      setPreviousPos({
        x: event.clientX,
        y: event.clientY,
      });
      // Heads-up! This prevents the context menu from working properly when
      // right-clicking on the canvas itself multiple times
      // event.stopPropagation();
      // containerElement.setPointerCapture(event.pointerId);
      setMouseDown(true);
    },
    [containerRef]
  );

  const pointerMoveHandler = useCallback(
    (event: PointerEvent) => {
      if (spacebarDown && mouseDown) {
        event.stopPropagation();
        // How far the pointer has been moved
        const dx = event.clientX - previousPos.x;
        const dy = event.clientY - previousPos.y;
        callback({ x: dx, y: dy });
        setPreviousPos({ x: event.clientX, y: event.clientY });
      }
    },
    [callback, mouseDown, previousPos.x, previousPos.y, spacebarDown]
  );

  const pointerUpHandler = useCallback(() => {
    setMouseDown(false);
  }, []);

  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.repeat) {
        return;
      }
      if (event.key === ' ' && !isInputLike(document.activeElement)) {
        if (!spacebarDown) {
          // Prevent scrolling with spacebar
          event.stopPropagation();
          setSpacebarDown(true);
          document.body.style.cursor = 'grab';
        }
      }
    },
    [spacebarDown]
  );

  const keyUpHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.repeat) {
        return;
      }
      if (event.key === ' ') {
        if (spacebarDown) {
          // Prevent scrolling with spacebar
          event.stopPropagation();
          setSpacebarDown(false);
          document.body.style.cursor = '';
        }
      }
    },
    [spacebarDown]
  );

  useLayoutEffect(() => {
    const containerElement = containerRef.current;

    const opt: AddEventListenerOptions = { passive: true };
    window.addEventListener('keydown', keyDownHandler, opt);
    window.addEventListener('keyup', keyUpHandler, opt);
    containerElement?.addEventListener('pointermove', pointerMoveHandler, opt);
    containerElement?.addEventListener('pointerup', pointerUpHandler, opt);
    containerElement?.addEventListener('pointerdown', pointerDownHandler, opt);
    return () => {
      window.removeEventListener('keydown', keyDownHandler, opt);
      window.removeEventListener('keyup', keyUpHandler, opt);
      containerElement?.removeEventListener(
        'pointermove',
        pointerMoveHandler,
        opt
      );
      containerElement?.removeEventListener('pointerup', pointerUpHandler, opt);
      containerElement?.removeEventListener(
        'pointerdown',
        pointerDownHandler,
        opt
      );
    };
  }, [
    containerRef,
    keyDownHandler,
    keyUpHandler,
    pointerDownHandler,
    pointerMoveHandler,
    pointerUpHandler,
  ]);
};
