import { useMemo, useRef } from 'react';

import { useAutoSizeSticker } from '@/components/HTMLCanvas/stickers/hooks/useAutoSizeSticker';
import { getOffsetPosition } from '@/utils/geometry/position';
import { createRegularStar } from '@/utils/geometry/star';
import {
  KosmikShapeSticker,
  StarShapeAttributes,
} from '@/utils/kosmik/stickers/shape';
import { getNumber } from '@/utils/number/number';
import { cssToSvgDasharray, roundSvgPolygon } from '@/utils/svg';

export const HtmlStarShapeSticker = ({
  sticker,
}: {
  sticker: KosmikShapeSticker;
}) => {
  const attributes = sticker.attributes;
  const starAttributes = StarShapeAttributes.safeParse(attributes);

  if (!starAttributes.success) {
    console.log('Invalid attributes for star sticker', sticker);
    return null;
  }

  return <StarShape attributes={starAttributes.data} />;
};

export type StarShapeProps = {
  attributes: StarShapeAttributes;
};

const StarShape = ({ attributes }: StarShapeProps) => {
  const ref = useRef<SVGSVGElement>(null);
  const size = useAutoSizeSticker(ref);
  const width = getNumber(size?.width, 0);
  const height = getNumber(size?.height, 0);
  const numPoints = attributes.num_points;
  const cornerRadius = attributes.corner_radius_ratio;
  const innerRadiusRatio = attributes.inner_radius_ratio;

  // Compute svg path
  const { svgData, offset } = useMemo(() => {
    if (!width || !height) {
      return { svgData: '', offset: { x: 0, y: 0 } };
    }
    const starPoints = createRegularStar(
      numPoints,
      width,
      height,
      innerRadiusRatio
    );
    const offset = getOffsetPosition(starPoints, width, height);
    let svgData: string;
    if (!cornerRadius) {
      const commands = starPoints.map(([x, y], i) => {
        return `${i === 0 ? 'M' : 'L'} ${x}, ${y}`;
      });
      commands.push('Z');
      svgData = commands.join(' ');
    } else {
      svgData = starPoints
        .flatMap((coordinates) => coordinates.join(','))
        .join(' ');
      svgData = roundSvgPolygon(
        svgData,
        (cornerRadius * Math.min(width, height)) / numPoints
      );
    }
    return { svgData, offset };
  }, [cornerRadius, height, innerRadiusRatio, numPoints, width]);

  return (
    <svg
      ref={ref}
      data-testid={'shape-star'}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
    >
      <svg x={offset.x} y={offset.y}>
        <path
          d={svgData}
          data-testid={'star'}
          fill={attributes.fill_color}
          strokeWidth={attributes.stroke_width}
          stroke={attributes.stroke_color}
          {...cssToSvgDasharray(attributes)}
        />
      </svg>
    </svg>
  );
};
