import { Replace } from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';
import { ChangeEvent, useCallback, useRef } from 'react';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useUploadFiles } from '@/hooks/universe/useUploadFiles';
import utils from '@/utils/css/cssUtils.module.css';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';

export const EditDockReplaceFileItem = ({
  sticker,
}: {
  sticker: KosmikFileSticker;
}) => {
  const { universeId } = useParams({ strict: false });
  const { uploadStickerFile } = useUploadFiles(universeId ?? '');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      if (files) {
        const file = files[0];
        if (file) {
          uploadStickerFile(sticker, file);
        }
      }
    },
    [sticker, uploadStickerFile]
  );

  return (
    <EditDockItem
      onClick={() => {
        inputRef.current?.click();
      }}
      data-testid={'edit-dock-replace-file-item'}
    >
      <Replace />
      <TextLabelRegular>Replace</TextLabelRegular>
      <input
        ref={inputRef}
        type={'file'}
        className={utils.hideVisually}
        onChange={handleOnChange}
        data-testid={'edit-dock-replace-file-item-input'}
      />
    </EditDockItem>
  );
};
