import { DragEventHandler, useCallback } from 'react';

import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { useUndoableUploadNewFiles } from '@/hooks/useUndoableUploadNewFiles';
import { getCurrentCameraValues, screenToCanvas } from '@/utils/camera/camera';

export const useDropFiles = (universeId: string) => {
  const { handleUndoableUploadNewFiles } = useUndoableUploadNewFiles();

  const pushKPI = usePushKPI();

  const handleDrop: DragEventHandler<HTMLDivElement> = useCallback(
    async (event) => {
      event.preventDefault();
      const point = { x: event.clientX, y: event.clientY };
      const canvasCoordinates = screenToCanvas(point, getCurrentCameraValues());
      const files = event.dataTransfer.files;

      handleUndoableUploadNewFiles(files, canvasCoordinates);

      for (const file of files) {
        pushKPI({
          type: 'create',
          properties: {
            interaction: 'drag',
            coType: 'file',
            coFormat: file.type,
            coSize: file.size,
            coUniverse: universeId,
          },
        });
      }
    },
    [handleUndoableUploadNewFiles, pushKPI, universeId]
  );

  return { handleDrop };
};
