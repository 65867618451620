export type Direction =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'topRight'
  | 'bottomRight'
  | 'bottomLeft'
  | 'topLeft';

export type CornerDirection = Extract<
  Direction,
  'topLeft' | 'topRight' | 'bottomRight' | 'bottomLeft'
>;

export const cornerDirections: Readonly<CornerDirection[]> = [
  'topLeft',
  'topRight',
  'bottomRight',
  'bottomLeft',
];

// anchor size 3
export const cornerAnchors = {
  topLeft:
    'M -3 6 L -3 1.5 C -3 -0.985, -0.985 -3, 1.5 -3 L 6 -3 C 7.657 -3, 9 -1.657, 9 0 L 9 0.5 C 9 1.881, 7.881 3, 6.5 3 L 4.5 3 C 4.5 3, 3.5 3, 3.5 3 L 3.5 3 C 3.224 3, 3 3.224, 3 3.5 L 3 3.5 C 3 3.5, 3 4.5, 3 4.5 L 3 6.5 C 3 7.881, 1.881 9, 0.5 9 L 0 9 C -1.657 9, -3 7.657, -3 6 Z',
  topRight:
    'M 3 6 L 3 1.5 C 3 -0.985, 0.985 -3, -1.5 -3 L -6 -3 C -7.657 -3, -9 -1.657, -9 0 L -9 0.5 C -9 1.881, -7.881 3, -6.5 3 L -4.5 3 C -4.5 3, -3.5 3, -3.5 3 L -3.5 3 C -3.224 3, -3 3.224, -3 3.5 L -3 3.5 C -3 3.5, -3 4.5, -3 4.5 L -3 6.5 C -3 7.881, -1.881 9, -0.5 9 L 0 9 C 1.657 9, 3 7.657, 3 6 Z',
  bottomRight:
    'M 3 -6 L 3 -1.5 C 3 0.985, 0.985 3, -1.5 3 L -6 3 C -7.657 3, -9 1.657, -9 0 L -9 -0.5 C -9 -1.881, -7.881 -3, -6.5 -3 L -4.5 -3 C -4.5 -3, -3.5 -3, -3.5 -3 L -3.5 -3 C -3.224 -3, -3 -3.224, -3 -3.5 L -3 -3.5 C -3 -3.5, -3 -4.5, -3 -4.5 L -3 -6.5 C -3 -7.881, -1.881 -9, -0.5 -9 L 0 -9 C 1.657 -9, 3 -7.657, 3 -6 Z',
  bottomLeft:
    'M -3 -6 L -3 -1.5 C -3 0.985, -0.985 3, 1.5 3 L 6 3 C 7.657 3, 9 1.657, 9 0 L 9 -0.5 C 9 -1.881, 7.881 -3, 6.5 -3 L 4.5 -3 C 4.5 -3, 3.5 -3, 3.5 -3 L 3.5 -3 C 3.224 -3, 3 -3.224, 3 -3.5 L 3 -3.5 C 3 -3.5, 3 -4.5, 3 -4.5 L 3 -6.5 C 3 -7.881, 1.881 -9, 0.5 -9 L 0 -9 C -1.657 -9, -3 -7.657, -3 -6 Z',
};
