import { useLocalStorage } from 'usehooks-ts';

import { useIsDev } from '@/hooks/env/useIsDev';
import { FeatureFlags } from '@/utils/features';

export const useFeature = () => {
  const [flags, setFlags] = useLocalStorage<FeatureFlags>('featureflags', {});
  const isDev = useIsDev();
  const setFlag = (flag: keyof FeatureFlags, value: boolean) => {
    setFlags((old) => {
      return { ...old, [`${flag}`]: value };
    });
  };

  if (!isDev) {
    return {
      flags: {} as FeatureFlags,
      setFlag: (flag: keyof FeatureFlags, value: boolean) => {
        console.warn(
          `not allowed to update ${flag} to ${value} because you are on ${import.meta.env.VITE_STAGE} (${import.meta.env.MODE} mode)`
        );
      },
    };
  }

  return { flags, setFlag };
};
