import {
  AutolayoutFitFirstDecreasing,
  AutolayoutOrbital,
  AutolayoutPotpack,
} from '@lithium/spectrum';

import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';
import { ButtonGroup } from '@/ui/ButtonGroup/ButtonGroup';
import { IconButton } from '@/ui/IconButton/IconButton';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const AutoLayoutMenuOptions = ({ type }: { type: MenuType }) => {
  const { Label } = getPolymorphicMenuComponents(type);
  const handlers = useUniverseContextActions();

  return (
    <Label style={{ padding: '4px 6px' }}>
      <ButtonGroup
        buttons={[
          {
            id: 'potpack',
            button: (
              <IconButton
                onClick={() => {
                  handlers.autolayoutSelected('potpack');
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                <AutolayoutPotpack />
              </IconButton>
            ),
          },
          {
            id: 'orbital',
            button: (
              <IconButton
                onClick={() => handlers.autolayoutSelected('orbital')}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                <AutolayoutOrbital />
              </IconButton>
            ),
          },
          {
            id: 'masonry',
            button: (
              <IconButton
                onClick={() => handlers.autolayoutSelected('masonry')}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                <AutolayoutFitFirstDecreasing />
              </IconButton>
            ),
          },
        ]}
      />
    </Label>
  );
};
