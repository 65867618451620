import { PDFPageProxy } from 'pdfjs-dist';
import React, { HTMLAttributes, KeyboardEvent, useRef } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useSetPdfCanvasSize } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useSetPdfCanvasSize';
import {
  DATA_ATTRIBUTE_PAGE,
  DATA_ATTRIBUTE_PAGE_WRAPPER,
} from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';
import { getScrollInfo } from '@/utils/scroll/getScrollInfo';

import styles from './PdfPreviewPage.module.css';

export const DATA_ATTRIBUTE_SELECTED = 'selected';

interface PdfPreviewPageProps extends HTMLAttributes<HTMLButtonElement> {
  pageProxy: PDFPageProxy;
}

export const PdfPreviewPage = React.forwardRef<
  HTMLButtonElement,
  PdfPreviewPageProps
>(({ pageProxy, ...props }, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useSetPdfCanvasSize(canvasRef, pageProxy);

  const handleKeyDown = (event: KeyboardEvent) => {
    let element: Element | null = null;
    if (event.key === 'ArrowDown') {
      element = event.currentTarget.nextElementSibling;
    } else if (event.key === 'ArrowUp') {
      element = event.currentTarget.previousElementSibling;
    }
    if (element instanceof HTMLElement) {
      const scrollArea = element?.closest('[data-radix-scroll-area-viewport]');
      if (element && scrollArea instanceof HTMLElement) {
        event.preventDefault();
        // get the scroll info before focusing, otherwise the element will
        // have scrolled into view with default params
        const { isBefore, isAfter } = getScrollInfo(element, scrollArea);
        element.click();
        element.focus();
        if (isBefore || isAfter) {
          element.scrollIntoView(isBefore);
        }
      }
    }
  };

  return (
    <button
      className={styles.previewPageContainer}
      ref={ref}
      {...props}
      {...{ [`data-${DATA_ATTRIBUTE_PAGE}`]: pageProxy.pageNumber }}
      onKeyDown={handleKeyDown}
    >
      <div
        className={styles.pageCanvasWrapper}
        {...{ [`data-${DATA_ATTRIBUTE_PAGE_WRAPPER}`]: '' }}
      >
        <canvas className={styles.pageCanvas} ref={canvasRef} />
      </div>

      <div className={styles.pageNumber}>
        <TextLabelRegular>{pageProxy.pageNumber}</TextLabelRegular>
      </div>
    </button>
  );
});

PdfPreviewPage.displayName = 'PdfPreviewPage';
