import { ReactNode } from 'react';

import { ContextMenu } from '@/components/ui/ContextMenu/ContextMenu';
import { UniverseMenuContent } from '@/components/UniverseMenuContent';
import { KosmikUniverse } from '@/utils/kosmik/universe';

interface UniverseContextMenuProps {
  universe: KosmikUniverse;
  children: ReactNode;
}

export const UniverseContextMenu = ({
  universe,
  children,
}: UniverseContextMenuProps) => {
  return (
    <ContextMenu
      modal={false}
      trigger={children}
      contentProps={{
        style: { minWidth: 200 },
      }}
    >
      <UniverseMenuContent universe={universe} type={'contextmenu'} />
    </ContextMenu>
  );
};
