import * as Form from '@radix-ui/react-form';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/components/ui/Form/FormField.module.css';
import { FormFieldProps } from '@/ui/Form/FormFieldProps';
import { FormStack } from '@/ui/Form/FormStack';

export const NameFormField = ({
  serverError,
  onChange,
  value,
  showLabel,
}: FormFieldProps) => {
  return (
    <Form.Field name={'name'} serverInvalid={serverError} asChild>
      <FormStack>
        {showLabel ? (
          <Form.Label>
            <TextLabelRegular style={{ fontWeight: '600' }}>
              Name
            </TextLabelRegular>
          </Form.Label>
        ) : null}

        <Form.Message className={styles.message} match={'valueMissing'} asChild>
          <TextLabelRegular>Please enter your name</TextLabelRegular>
        </Form.Message>

        <Form.Control asChild>
          <input
            value={value ?? ''}
            type={'text'}
            required
            placeholder={'John Doe'}
            onChange={onChange}
          />
        </Form.Control>
      </FormStack>
    </Form.Field>
  );
};
