import { atom } from 'recoil';

import { ModalKey, ModalProps } from '@/components/modal/specs/MODAL_SPECS';

export interface Modal {
  type: ModalKey;
  props?: ModalProps<ModalKey>;
}

/**
 * Holds the current stack of open modals
 */
export const modalStackAtom = atom<Modal[]>({
  key: 'modalStack',
  default: [],
});
