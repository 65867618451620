import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeNull } from '@/utils/types';

/**
 * Determines a comparable type for a given sticker.
 *
 * @param sticker - The sticker to determine the comparable type for.
 * @returns The comparable type of the sticker.
 *   - For file type stickers, returns the first part of the MIME type (e.g., 'image' from 'image/png').
 *   - For non-file type stickers, returns the sticker's type as is.
 *   - If the sticker is null or undefined, returns undefined.
 */
export const comparableStickerType = (sticker: MaybeNull<KosmikSticker>) => {
  if (sticker?.type === 'file') {
    const file = sticker.files?.[0];
    const attributes = sticker.attributes;
    const { type } = file ?? attributes;
    if (type?.includes('/')) {
      const fileType = sticker?.attributes?.type?.split('/')?.[0];
      if (fileType) {
        return fileType;
      }
    }
  }
  return sticker?.type;
};
