import { Download } from '@lithium/spectrum';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';

export const EditDockDownloadItem = ({
  sticker,
  attributes,
}: {
  sticker: KosmikFileSticker;
  attributes: FileStickerAttributes;
}) => {
  const file = sticker.files?.[0];
  const { cached_url, file_name } = file ?? attributes;

  return (
    <EditDockItem
      data-testid={'editdock-downloaditem'}
      onClick={() => {
        // This will open a new tab until Content-Disposition header
        // is set to `attachment` by instant's S3
        // https://macarthur.me/posts/trigger-cross-origin-download/
        const anchor = document.createElement('a');
        anchor.download = file_name ?? '';
        anchor.href = cached_url ?? '';
        anchor.hidden = true;
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        anchor.remove();
      }}
    >
      <Download />
      <TextLabelRegular>Download</TextLabelRegular>
    </EditDockItem>
  );
};
