import { PDFPageProxy } from 'pdfjs-dist';
import { useEffect, useRef } from 'react';

import { IterableRefsMapKey } from '@/hooks/useIterableRefs';
import { Maybe, MaybeUndefined } from '@/utils/types';

/**
 * Scrolls to the persisted selected page when the page has loaded
 */
export const useScrollToRemoteCurrentPage = (
  remoteCurrentPage: number,
  handlePageChange: (newPage: number) => void,
  pagesRefs: Map<IterableRefsMapKey, HTMLDivElement>,
  pageProxys: PDFPageProxy[],
  url: Maybe<string>
) => {
  const remoteCurrentPageRef = useRef<MaybeUndefined<number>>(undefined);

  useEffect(() => {
    remoteCurrentPageRef.current = undefined;
  }, [url]);

  useEffect(() => {
    if (!remoteCurrentPageRef.current && remoteCurrentPage) {
      remoteCurrentPageRef.current = remoteCurrentPage;
      handlePageChange(remoteCurrentPage);
    }
  }, [handlePageChange, remoteCurrentPage]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { target } = entry;
        if (target instanceof HTMLElement) {
          const pageNumber = Number(target.dataset.page);
          if (
            entry.contentRect.height &&
            pageNumber === remoteCurrentPageRef.current
          ) {
            handlePageChange(remoteCurrentPageRef.current);
            resizeObserver.disconnect();
          }
        }
      });
    });

    const values = [...pagesRefs.values()];
    values.forEach((entry) => {
      resizeObserver.observe(entry);
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [handlePageChange, pagesRefs, pageProxys]);
};
