import { ChevronDown } from '@lithium/spectrum';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react';

import styles from './ItemButton.module.css';

interface ItemButtonProps extends ComponentPropsWithoutRef<'button'> {
  children?: ReactNode;
  isDropDownMenu?: boolean;
  isDropDownMenuOpened?: boolean;
}

export const ItemButton = forwardRef<HTMLButtonElement, ItemButtonProps>(
  (
    {
      children,
      isDropDownMenu = false,
      isDropDownMenuOpened = false,
      style,
      ...props
    }: ItemButtonProps,
    ref
  ): ReactElement => {
    return (
      <button
        ref={ref}
        className={styles.itemButton}
        style={{
          background: isDropDownMenuOpened ? 'var(--shadow-shade-10)' : 'none',
          ...style,
        }}
        {...props}
      >
        {children}
        {isDropDownMenu && <ChevronDown />}
      </button>
    );
  }
);

ItemButton.displayName = 'ItemButton';
