import { Checkmark } from '@lithium/spectrum';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { ItemIndicator } from '@radix-ui/react-dropdown-menu';
import React from 'react';

import {
  MenuItem,
  MenuItemProps,
} from '@/components/ui/MenuPrimitive/MenuItem';

export interface DropdownRadioItemProps
  extends Omit<
      DropdownPrimitive.DropdownMenuRadioItemProps,
      'prefix' | 'suffix'
    >,
    MenuItemProps {}

export const DropdownRadioItem = React.forwardRef<
  HTMLDivElement,
  DropdownRadioItemProps
>(({ prefix, children, ...props }, ref) => {
  const suffix = (
    <ItemIndicator asChild>
      <Checkmark />
    </ItemIndicator>
  );
  return (
    <DropdownPrimitive.RadioItem asChild {...props}>
      <MenuItem ref={ref} {...{ prefix, suffix }}>
        {children}
      </MenuItem>
    </DropdownPrimitive.RadioItem>
  );
});

DropdownRadioItem.displayName = 'DropdownRadioItem';
