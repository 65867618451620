import { StickerFile } from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useFileImport } from '@/hooks/useFileImport';
import { Tooltip } from '@/ui/Tooltip/Tooltip';

export const CreateFileStickerButton = () => {
  const { universeId } = useParams({ strict: false });
  const { openFilePicker } = useFileImport();

  return (
    <Tooltip content={'Import files'}>
      <EditDockItem
        onClick={openFilePicker}
        disabled={universeId === null}
        data-testid={'create-file-sticker-button'}
      >
        <StickerFile />
      </EditDockItem>
    </Tooltip>
  );
};
