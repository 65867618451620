import { useUser } from '@/context/User/useUser';
import { db } from '@/db/databaseInit';
import { TeamInvite } from '@/utils/kosmik/team';

export const useTeamInvites = () => {
  const user = useUser();

  const { data } = db.useQuery(
    user?.id
      ? {
          team_invite: {
            $: {
              where: {
                'receiver.id': user.id,
              },
            },
            teams: {},
            receiver: {},
            sender: {},
          },
        }
      : null
  );

  const teamInvites: TeamInvite[] = data ? data.team_invite : [];

  return { teamInvites };
};
