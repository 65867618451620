import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

const universeIdSearch = z.object({
  sticker: z.string().uuid().optional(),
});

export const Route = createFileRoute('/_authenticated/$universeId')({
  validateSearch: zodSearchValidator(universeIdSearch),
});
