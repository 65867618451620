import { id, tx } from '@instantdb/react';
import { ReactNode } from 'react';

import { PreferencesContext } from '@/context/Preferences/PreferencesContext';
import { useUser } from '@/context/User/useUser';
import { batchTransact } from '@/utils/db/db';
import { getDefaultPreferences } from '@/utils/preferences';

export const PreferencesProvider = ({ children }: { children: ReactNode }) => {
  const user = useUser();
  const preferences = user?.preferences?.[0];

  // Create preferences entity if none existing yet and link it to current user
  if (user && !preferences) {
    setTimeout(() => {
      batchTransact([
        tx.preferences?.[id()]
          ?.update({ created_at: new Date().toISOString() })
          .link({ users: [user.id] }),
      ]);
    });
  }

  return (
    <PreferencesContext.Provider
      value={{ ...getDefaultPreferences(), ...preferences }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};
