import { KosmikUniverse } from '@/utils/kosmik/universe';
import { KosmikUser } from '@/utils/kosmik/user';
import { AnyStringWithAutocomplete } from '@/utils/types';

export interface TeamInvite {
  id: string;
  created_at: string;
  sender: KosmikTeam[];
  receiver: KosmikUser[];
  teams: KosmikTeam[];
}

export interface KosmikTeam {
  id: string;
  created_at: string;
  name: string;
  color?: AnyStringWithAutocomplete<'auto'>;
  universes: KosmikUniverse[];
  users: KosmikUser[];
  team_ownerships?: KosmikTeamOwnership[];
  sent_invitations?: TeamInvite[];
  invites?: TeamInvite[];
}

export interface KosmikTeamOwnership {
  id: string;
  users: KosmikUser[];
  teams: KosmikTeam[];
}

export enum TeamRole {
  'owner' = 'owner',
  'admin' = 'admin',
  'editor' = 'editor',
  'viewer' = 'viewer',
}
