import { RefObject, useCallback, useEffect, useRef } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { useRecoilValue } from 'recoil';
import { z } from 'zod';

import { cameraAtom } from '@/atoms/camera';
import { Position } from '@/utils/geometry/position';
import {
  cornerAnchors,
  CornerDirection,
  cornerDirections,
  Direction,
} from '@/utils/handle';
import { MaybeUndefined } from '@/utils/types';

import styles from './TransformHandle.module.css';

export const TransformPayload = z.object({
  left: z.number(),
  top: z.number(),
  right: z.number(),
  bottom: z.number(),
});

export type TransformPayload = z.infer<typeof TransformPayload>;

export type TransformHandleProps = {
  direction: Direction;
  onTransformStart: () => void;
  onTransform: (payload: TransformPayload) => void;
  onTransformEnd: (payload: TransformPayload) => void;
  ratio: {
    horizontal: number;
    vertical: number;
  };
  scaleFactor?: number;
};

export const TransformHandle = ({
  direction,
  onTransformStart,
  onTransform,
  onTransformEnd,
  ratio,
  scaleFactor,
}: TransformHandleProps) => {
  const camera = useRecoilValue(cameraAtom);
  const ref = useRef<HTMLDivElement | SVGSVGElement>(null);
  const isCorner = cornerDirections.includes(direction as CornerDirection);
  const cornerAnchorSize = 3;
  const transformState = useRef<{ dragging: boolean; startPosition: Position }>(
    { dragging: false, startPosition: { x: 0, y: 0 } }
  );

  const handlePointerDown = useCallback(
    (event: PointerEvent) => {
      const { currentTarget } = event;
      if (currentTarget instanceof Element) {
        currentTarget.setPointerCapture(event.pointerId);
      }
      event.stopImmediatePropagation();
      transformState.current.dragging = true;
      transformState.current.startPosition = {
        x: event.clientX,
        y: event.clientY,
      };
      onTransformStart();
    },
    [onTransformStart]
  );

  const getTransformPayload = useCallback(
    (pointerPosition: { x: number; y: number }, startPosition: Position) => {
      const delta = {
        x: pointerPosition.x - startPosition.x,
        y: pointerPosition.y - startPosition.y,
      };

      // Transform proportionally?
      if (
        isHotkeyPressed('shift') &&
        ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'].includes(direction)
      ) {
        delta.y =
          delta.x *
          (['topRight', 'bottomLeft'].includes(direction) ? -1 : 1) *
          ratio.vertical;
      }

      // Scale delta to zoom 1
      const scaledDelta = {
        x: delta.x / (scaleFactor ?? camera.z),
        y: delta.y / (scaleFactor ?? camera.z),
      };

      let payload: MaybeUndefined<TransformPayload> = undefined;

      if (direction === 'right') {
        payload = {
          left: isHotkeyPressed('alt') ? -scaledDelta.x : 0,
          right: scaledDelta.x,
          top: 0,
          bottom: 0,
        };
      } else if (direction === 'left') {
        payload = {
          left: scaledDelta.x,
          right: isHotkeyPressed('alt') ? -scaledDelta.x : 0,
          top: 0,
          bottom: 0,
        };
      } else if (direction === 'top') {
        payload = {
          left: 0,
          right: 0,
          top: scaledDelta.y,
          bottom: isHotkeyPressed('alt') ? -scaledDelta.y : 0,
        };
      } else if (direction === 'bottom') {
        payload = {
          left: 0,
          right: 0,
          top: isHotkeyPressed('alt') ? -scaledDelta.y : 0,
          bottom: scaledDelta.y,
        };
      } else if (direction === 'topLeft') {
        payload = {
          left: scaledDelta.x,
          right: isHotkeyPressed('alt') ? -scaledDelta.x : 0,
          top: scaledDelta.y,
          bottom: isHotkeyPressed('alt') ? -scaledDelta.y : 0,
        };
      } else if (direction === 'bottomLeft') {
        payload = {
          left: scaledDelta.x,
          right: isHotkeyPressed('alt') ? -scaledDelta.x : 0,
          top: isHotkeyPressed('alt') ? -scaledDelta.y : 0,
          bottom: scaledDelta.y,
        };
      } else if (direction === 'topRight') {
        payload = {
          left: isHotkeyPressed('alt') ? -scaledDelta.x : 0,
          right: scaledDelta.x,
          top: scaledDelta.y,
          bottom: isHotkeyPressed('alt') ? -scaledDelta.y : 0,
        };
      } else if (direction === 'bottomRight') {
        payload = {
          left: isHotkeyPressed('alt') ? -scaledDelta.x : 0,
          right: scaledDelta.x,
          top: isHotkeyPressed('alt') ? -scaledDelta.y : 0,
          bottom: scaledDelta.y,
        };
      }
      return payload;
    },
    [camera.z, direction, ratio.vertical, scaleFactor]
  );

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      const { startPosition, dragging } = transformState.current;
      if (!dragging) {
        return;
      }
      event.stopImmediatePropagation();
      const pointerPosition = {
        x: event.clientX,
        y: event.clientY,
      };
      const payload = getTransformPayload(pointerPosition, startPosition);

      if (payload) {
        onTransform(payload);
      }
    },
    [getTransformPayload, onTransform]
  );

  const handlePointerUp = useCallback(
    (event: PointerEvent) => {
      const { startPosition, dragging } = transformState.current;
      if (dragging) {
        const { currentTarget } = event;
        if (currentTarget instanceof Element) {
          currentTarget.releasePointerCapture(event.pointerId);
        }
        event.stopImmediatePropagation();
        transformState.current.dragging = false;
        const pointerPosition = {
          x: event.clientX,
          y: event.clientY,
        };
        const payload = getTransformPayload(pointerPosition, startPosition);
        if (payload) {
          onTransformEnd(payload);
        }
      }
    },
    [getTransformPayload, onTransformEnd]
  );

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener(
      'pointerdown',
      handlePointerDown as EventListener
    );
    element?.addEventListener(
      'pointermove',
      handlePointerMove as EventListener
    );
    window.addEventListener('pointerup', handlePointerUp as EventListener);

    return () => {
      element?.removeEventListener(
        'pointerdown',
        handlePointerDown as EventListener
      );
      element?.removeEventListener(
        'pointermove',
        handlePointerMove as EventListener
      );
      window.removeEventListener('pointerup', handlePointerUp as EventListener);
    };
  }, [handlePointerDown, handlePointerMove, handlePointerUp]);

  return isCorner ? (
    <svg
      ref={ref as RefObject<SVGSVGElement>}
      data-direction={direction}
      className={`${styles.handle} ${styles.svgHandle}`}
      viewBox={`${-3 * cornerAnchorSize} ${-3 * cornerAnchorSize} ${6 * cornerAnchorSize} ${6 * cornerAnchorSize}`}
    >
      <path d={cornerAnchors[direction as CornerDirection]} />
    </svg>
  ) : (
    <div
      ref={ref as RefObject<HTMLDivElement>}
      data-direction={direction}
      className={`${styles.handle}`}
    />
  );
};
