import { ChevronDown, ChevronUp } from '@lithium/spectrum';
import * as SelectPrimitive from '@radix-ui/react-select';
import React from 'react';

import styles from '@/components/ui/Select/Select.module.css';
import { SelectContent } from '@/ui/Select/SelectContent/SelectContent';

export interface SelectProps
  extends SelectPrimitive.SelectProps,
    React.PropsWithChildren<{
      placeholder: React.ReactNode;
      contentProps?: SelectPrimitive.SelectContentProps;
    }> {}

export const Select = ({
  contentProps,
  placeholder,
  children,
  ...props
}: SelectProps) => (
  <SelectPrimitive.Root {...props}>
    <SelectPrimitive.Trigger
      className={styles.styledSelectTrigger}
      data-testid={'select-trigger'}
    >
      <SelectPrimitive.Value placeholder={placeholder} />
      <SelectPrimitive.Icon>
        <ChevronDown style={{ display: 'flex', alignItems: 'center' }} />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
    <SelectPrimitive.Portal>
      <SelectContent {...contentProps}>
        <SelectPrimitive.ScrollUpButton>
          <ChevronUp className={styles.chevron} />
        </SelectPrimitive.ScrollUpButton>
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
        <SelectPrimitive.ScrollDownButton>
          <ChevronDown className={styles.chevron} />
        </SelectPrimitive.ScrollDownButton>
      </SelectContent>
    </SelectPrimitive.Portal>
  </SelectPrimitive.Root>
);
