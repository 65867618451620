import { PropsWithChildren, ReactNode } from 'react';

import { TextHeader3 } from '@/components/fontSystem/Header3';
import { ScrollArea, ScrollAreaProps } from '@/ui/ScrollArea/ScrollArea';

import styles from './DialogSection.module.css';

export type BaseDialogSectionProps = PropsWithChildren<{
  title?: ReactNode;
}>;

export type NonScrollableDialogSectionProps = BaseDialogSectionProps & {
  scrollable?: false;
  scrollAreaProps?: never;
};

export type ScrollableDialogSectionProps = BaseDialogSectionProps & {
  scrollable: true;
  scrollAreaProps?: ScrollAreaProps;
};

export type DialogSectionProps =
  | NonScrollableDialogSectionProps
  | ScrollableDialogSectionProps;

export const DialogSection = ({
  title,
  scrollable,
  scrollAreaProps,
  children,
}: DialogSectionProps) => {
  const content = <div className={styles.content}>{children}</div>;
  return (
    <section className={styles.dialogSection}>
      {title ? <TextHeader3>{title}</TextHeader3> : null}
      <div className={styles.contentWrapper}>
        {scrollable ? (
          <ScrollArea {...scrollAreaProps}>{content}</ScrollArea>
        ) : (
          content
        )}
      </div>
    </section>
  );
};
