import { useNavigate } from '@tanstack/react-router';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { v4 as uuidv4 } from 'uuid';

import { selectedStickerIdsAtom } from '@/atoms/selection';
import { localStickerFamily } from '@/atoms/sticker';
import {
  duplicateOffsetAtom,
  isMovingAtom,
  isPressingAtom,
} from '@/atoms/transform';
import { useStickerSelection } from '@/components/selection/useStickerSelection';
import { BoundingBox } from '@/components/Universe/Selection/BoundingBox';
import { useDraggingState } from '@/components/Universe/Selection/hooks/useDraggingState';
import { useMoveTransformHooks } from '@/components/Universe/Selection/hooks/useMoveTransformHooks';
import { useSelectedAndTemporaryStickerIdsRef } from '@/components/Universe/Selection/hooks/useSelectedAndTemporaryStickerIdsRef';
import { useRoom } from '@/context/Room/useRoom';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { usePointerPositionRef } from '@/hooks/pointer/usePointerPositionRef';
import { useAddRemoveTemporaryStickers } from '@/hooks/sticker/useAddRemoveTemporaryStickers';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { Dimension, isIntersecting } from '@/utils/geometry/dimension';
import { Point } from '@/utils/geometry/point';
import { Position } from '@/utils/geometry/position';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { invertObjectValues } from '@/utils/math/invertObjectValues';
import {
  duplicateStickers,
  getNotUploadingStickers,
  undoableDuplicateStickers,
} from '@/utils/sticker/sticker';
import { Maybe, MaybeUndefined } from '@/utils/types';

import styles from './SelectionLayer.module.css';

export const SelectionLayer = ({
  stickers,
  universeId,
}: {
  stickers: KosmikSticker[];
  universeId: string;
}) => {
  const user = useContext(MultiplayerUserContext);
  const { room } = useRoom();
  const publishMove = room.usePublishTopic('move');
  const publishCommit = room.usePublishTopic('commit');
  const publishTemporaryStickers = room.usePublishTopic('addTemporaryStickers');
  const publishRemoveTemporaryStickers = room.usePublishTopic(
    'removeTemporaryStickers'
  );
  const ref = useRef<HTMLDivElement>(null);
  const setIsPressing = useSetRecoilState(isPressingAtom);
  const setIsMoving = useSetRecoilState(isMovingAtom);
  const { isUniverseMember } = useContext(UniverseContext);
  const [boundingBox, setBoundingBox] = useState<MaybeUndefined<Dimension>>();
  const pointerPositionRef = usePointerPositionRef();
  const { moveLocalStickers, persist } = useMoveTransformHooks();
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const { addTemporaryStickers, removeTemporaryStickers } =
    useAddRemoveTemporaryStickers(universeId);
  const { setNewSelection, unselect } = useStickerSelection();
  const selectedStickerIdsBeforeAlt = useRef(selectedStickerIds);
  const setDuplicateOffset = useSetRecoilState(duplicateOffsetAtom);
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const { deleteStickers } = useDeleteStickers();
  const navigate = useNavigate();

  useEffect(() => {
    selectedStickerIdsRef.current = selectedStickerIds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStickerIds]);

  const {
    draggingState,
    getPointerMovement,
    getMovementOffset,
    resetModifierState,
  } = useDraggingState();
  const { selectedStickerIdsRef, temporaryStickerIdsRef } =
    useSelectedAndTemporaryStickerIdsRef(universeId);

  useEffect(() => {
    const wrapper = ref.current;
    const cameraElement = wrapper?.closest('[data-camera]');

    if (
      !wrapper ||
      !(cameraElement instanceof HTMLElement) ||
      !isUniverseMember
    ) {
      return;
    }

    /**
     * Duplicate alt-dragged stickers and publish the relevant temporary stickers
     */
    const duplicateAltDraggedStickers = () => {
      const stickers = [...selectedStickerIdsRef.current]
        .map((id) => getRecoil(localStickerFamily(id)))
        .filter(Boolean) as KosmikSticker[];
      const { newIds, newStickers } = duplicateStickers(stickers, universeId, {
        offset: { x: 0, y: 0 },
        persist: false,
      });
      addTemporaryStickers(newStickers);
      publishTemporaryStickers(getNotUploadingStickers(newStickers));
      selectedStickerIdsBeforeAlt.current = selectedStickerIdsRef.current;
      setNewSelection(new Set(newIds));
    };

    /**
     * We ignore the pointer down event when it was triggered outside the
     * selected stickers bounding box, or when the user clicked on a sticker
     * that isn't in the current selection
     */
    const shouldIgnorePointerDown = (element: Element, id: Maybe<string>) => {
      const isCanvasOrCanvasAncestor = element.querySelector('[data-camera]');
      // For the pointer to be considered in the bounding box, the element has
      // to be the canvas or its ancestors. Indeed, it is possible for the
      // pointer to be within the bounding box, but the user might have clicked
      // within a context menu, in which case we should also ignore.
      const isPointerWithinBoundingBox =
        isCanvasOrCanvasAncestor && boundingBox
          ? isIntersecting(boundingBox, {
              ...pointerPositionRef.current.camera,
              width: 0,
              height: 0,
            })
          : false;
      const noIdAndOutsideBoundingBox = !id && !isPointerWithinBoundingBox;
      const idNotAlreadySelected = id && !selectedStickerIdsRef.current.has(id);
      return noIdAndOutsideBoundingBox || idNotAlreadySelected;
    };

    /**
     * Allow dragging as soon as the user clicks on an unselected sticker
     * instead of needing to first click to select, then click to drag
     */
    const handlePointerDown = (event: PointerEvent) => {
      navigate({ search: undefined });
      if (event.button !== 0) {
        return;
      }
      const element = document.elementFromPoint(
        pointerPositionRef.current.x,
        pointerPositionRef.current.y
      );
      if (element) {
        const idElement = element.closest('[data-id]');
        const id =
          idElement instanceof HTMLElement ? idElement.dataset.id : null;
        if (shouldIgnorePointerDown(element, id)) {
          return;
        }
        const { currentTarget } = event;
        if (currentTarget instanceof Element) {
          currentTarget.setPointerCapture(event.pointerId);
        }
        // This makes the text sticker non-focusable
        // event.preventDefault();
        // event.stopImmediatePropagation();
        setIsPressing(true);
        draggingState.current.dragging = true;
        draggingState.current.startPosition = { ...pointerPositionRef.current };
        draggingState.current.commitId = uuidv4();
        draggingState.current.pointedDownAtStickerId = id;
        if (draggingState.current.alt) {
          duplicateAltDraggedStickers();
        }
      }
    };

    /**
     * We use css variables to move the stickers until we persist them to the db
     * As this is way more performant than updating the localStickers, or worse,
     * transacting to the db each time
     */
    const handlePointerMove = (event: PointerEvent | KeyboardEvent) => {
      draggingState.current.shift = event.shiftKey;
      draggingState.current.alt = event.altKey;
      if (draggingState.current.dragging) {
        const movement = getPointerMovement();
        const offset = getMovementOffset(movement);

        if (!(offset.x == 0 && offset.y == 0)) {
          setIsMoving(true);
        }

        cameraElement.style.setProperty(
          `--drag-${user?.peerId}-offset-x`,
          `${offset.x}px`
        );
        cameraElement.style.setProperty(
          `--drag-${user?.peerId}-offset-y`,
          `${offset.y}px`
        );
        publishMove({ ...offset, commitId: draggingState.current.commitId });
      }
    };

    /**
     * Perform cleanup, persist stickers to the db and publish commit
     */
    const handlePointerUp = (event: PointerEvent) => {
      if (event.button !== 0) {
        return;
      }
      if (draggingState.current.dragging) {
        const movement = getPointerMovement();
        const offset = getMovementOffset(movement);
        cleanupCSSVariables(offset);
        if (Math.abs(movement.x) < 1 && Math.abs(movement.y) < 1) {
          handleInsignificantMove();
          return;
        }
        const ids = [...selectedStickerIdsRef.current.values()];
        const { alt } = draggingState.current;
        setDuplicateOffset(alt ? offset : undefined);
        moveLocalStickers(ids, offset);
        const notUploadingIds = getNotUploadingStickers(ids);
        persist(notUploadingIds, alt, alt ? universeId : '');
        addActionToUndoRedoStack(ids, offset);
        publishCommit({ ...offset, commitId: draggingState.current.commitId });
        setBoundingBox((prev) =>
          prev
            ? { ...prev, ...{ x: prev.x + offset.x, y: prev.y + offset.y } }
            : prev
        );
      }
      setIsPressing(false);
      setIsMoving(false);
      draggingState.current.dragging = false;
      selectedStickerIdsBeforeAlt.current = new Set();
    };

    /**
     * Create and add action to undo / redo stack
     */
    const addActionToUndoRedoStack = (ids: string[], offset: Point) => {
      const { alt } = draggingState.current;
      // Memoize the alt drag duplicated stickers to their final position
      let altDragDuplicatedStickers = [...selectedStickerIdsRef.current]
        .map((id) => {
          const sticker = getRecoil(localStickerFamily(id));
          if (!sticker) {
            return undefined;
          }
          return {
            ...sticker,
            x: sticker.x + offset.x,
            y: sticker.y + offset.y,
          };
        })
        .filter(Boolean) as KosmikSticker[];
      // Create the action
      const moveAction: UndoRedoActionType = {
        do: () => {
          if (alt) {
            altDragDuplicatedStickers = undoableDuplicateStickers(
              altDragDuplicatedStickers,
              universeId
            );
          } else {
            moveLocalStickers(ids, offset);
            const notUploadingIds = getNotUploadingStickers(ids);
            persist(notUploadingIds, alt);
          }
        },
        undo: () => {
          if (alt) {
            deleteStickers(altDragDuplicatedStickers);
          } else {
            moveLocalStickers(ids, invertObjectValues(offset));
            persist(ids, alt);
          }
        },
      };
      addUndoRedoAction(moveAction);
    };

    /**
     * If the user hasn't moved enough since the pointerdown, update the
     * selection, 2 options: either the user has clicked on a sticker, in which
     * case we select it, or they have clicked on the canvas, within the
     * selection bounding box, in which case we deselect everything
     */
    const handleInsignificantMove = () => {
      setIsPressing(false);
      setIsMoving(false);
      draggingState.current.dragging = false;
      if (draggingState.current.pointedDownAtStickerId) {
        if (!draggingState.current.shift) {
          setNewSelection(
            new Set([draggingState.current.pointedDownAtStickerId])
          );
        }
      } else {
        unselect();
      }
    };

    /**
     * After a move was done, we keep the bounding-box-related CSS variables
     * matching the move offset, and we clear the user-drag-related ones
     * @param offset
     */
    const cleanupCSSVariables = (offset: Position) => {
      cameraElement.style.setProperty(
        '--bounding-box-offset-x',
        `${offset.x}px`
      );
      cameraElement.style.setProperty(
        '--bounding-box-offset-y',
        `${offset.y}px`
      );
      cameraElement.style.removeProperty(`--drag-${user?.peerId}-offset-x`);
      cameraElement.style.removeProperty(`--drag-${user?.peerId}-offset-y`);
    };

    const handleKeydown = (event: KeyboardEvent) => {
      draggingState.current.shift = event.shiftKey;
      if (event.key === 'Alt') {
        refreshAltState();
        if (!draggingState.current.alt) {
          draggingState.current.alt = event.altKey;
        }
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      draggingState.current.shift = event.shiftKey;
      if (event.key === 'Alt') {
        refreshAltState();
        draggingState.current.alt = event.altKey;
      } else if (event.key === 'Shift') {
        handlePointerMove(event);
      }
    };

    const refreshAltState = () => {
      // if dragging
      if (draggingState.current.dragging) {
        // with ALT
        if (draggingState.current.alt) {
          const ids = [...temporaryStickerIdsRef.current];
          removeTemporaryStickers(ids);
          publishRemoveTemporaryStickers(ids);
          if (selectedStickerIdsBeforeAlt.current.size) {
            setNewSelection(selectedStickerIdsBeforeAlt.current);
          }
        } else {
          // without ALT
          if (draggingState.current.dragging) {
            duplicateAltDraggedStickers();
          }
        }
      }
    };

    const options = { passive: true };
    document.addEventListener('pointerdown', handlePointerDown, options);
    document.addEventListener('pointermove', handlePointerMove, options);
    document.addEventListener('pointerup', handlePointerUp, options);
    document.addEventListener('keydown', handleKeydown, options);
    document.addEventListener('keyup', handleKeyUp, options);
    document.addEventListener('blur', resetModifierState, options);
    document.addEventListener('focus', resetModifierState, options);

    return () => {
      document.removeEventListener('pointerdown', handlePointerDown);
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('blur', resetModifierState);
      document.removeEventListener('focus', resetModifierState);
    };
  }, [
    addTemporaryStickers,
    moveLocalStickers,
    persist,
    pointerPositionRef,
    publishCommit,
    publishMove,
    publishRemoveTemporaryStickers,
    publishTemporaryStickers,
    removeTemporaryStickers,
    setBoundingBox,
    setDuplicateOffset,
    setIsMoving,
    setNewSelection,
    universeId,
    user?.peerId,
    setIsPressing,
    addUndoRedoAction,
    deleteStickers,
    boundingBox,
    unselect,
    navigate,
    draggingState,
    getPointerMovement,
    getMovementOffset,
    resetModifierState,
    isUniverseMember,
    selectedStickerIdsRef,
    temporaryStickerIdsRef,
  ]);

  return (
    <div ref={ref} className={styles.selectionLayer}>
      <BoundingBox
        stickers={stickers}
        universeId={universeId}
        boundingBox={boundingBox}
        setBoundingBox={setBoundingBox}
      />
    </div>
  );
};
