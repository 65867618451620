import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { DropdownContent } from '@/ui/Dropdown/DropdownContent';
import { DropdownItem } from '@/ui/Dropdown/DropdownItem';
import { DropdownLabel } from '@/ui/Dropdown/DropdownLabel';
import { DropdownSeparator } from '@/ui/Dropdown/DropdownSeparator';
import { DropdownSub } from '@/ui/Dropdown/DropdownSub';
import { DropdownSubContent } from '@/ui/Dropdown/DropdownSubContent';
import { DropdownSubTrigger } from '@/ui/Dropdown/DropdownSubTrigger';

export const DropdownMenuComponentMap = {
  Menu: Dropdown,
  Content: DropdownContent,
  Item: DropdownItem,
  Label: DropdownLabel,
  Separator: DropdownSeparator,
  Sub: DropdownSub,
  SubContent: DropdownSubContent,
  SubTrigger: DropdownSubTrigger,
} as const;
