import { ReactElement } from 'react';

import { UniverseContextMenu } from '@/components/SidePanel/UniverseContextMenu';
import { UniverseButton } from '@/components/Toolbar/UniverseButton';
import { UniversePublishStatusLabel } from '@/components/UniversePublishStatusLabel';
import { Tooltip } from '@/ui/Tooltip/Tooltip';
import { KosmikUniverse } from '@/utils/kosmik/universe';

export interface UniverseMenuProps {
  universe: KosmikUniverse;
  isMember: boolean;
}

export const UniverseMenu = ({
  universe,
  isMember,
}: UniverseMenuProps): ReactElement => {
  if (isMember) {
    return (
      <Tooltip content={'Right click to show universe menu'}>
        <span>
          <UniverseContextMenu universe={universe}>
            <UniverseButton universe={universe} tooltip={'Universe menu'} />
          </UniverseContextMenu>
        </span>
      </Tooltip>
    );
  }
  return (
    <UniverseButton
      universe={universe}
      tooltip={'Universe menu'}
      suffix={<UniversePublishStatusLabel />}
    />
  );
};

UniverseMenu.displayName = 'UniverseMenu';
