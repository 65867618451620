import { DocumentDescription } from '@/utils/ai/files.ts';
import { MaybeNull } from '@/utils/types';

export type SearchResult = {
  id: string;
  score: number;
  universe: string;
};

export type SearchResponse = {
  success: boolean;
  data: {
    results: SearchResult[];
    scroll_id: string;
    timed_out: boolean;
    total: {
      relation: 'eq' | 'gte';
      value: number;
    };
  };
  error: MaybeNull<string>;
};

const baseUrl: string = import.meta.env.VITE_WATSON_URL;

export async function ingest(
  id: string,
  universe: string,
  author: string,
  content: string,
  aiDescription: DocumentDescription,
  refreshToken: string
) {
  const response = await fetch(`${baseUrl}/api/ingest`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${refreshToken}`,
    },
    method: 'POST',
    body: JSON.stringify({
      id,
      universe,
      author,
      content: `${content} ${aiDescription.summary} ${aiDescription.tags.map((v) => v.tag_name).join(' ')}`,
    }),
  });

  return response.json();
}

export async function deleteEntry(id: string, refreshToken: string) {
  const response = await fetch(`${baseUrl}/api/delete`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${refreshToken}`,
    },
    method: 'POST',
    body: JSON.stringify({
      id,
    }),
  });

  return response.json();
}

export async function search(
  content: string,
  universes: string[],
  refreshToken: string
): Promise<SearchResponse> {
  const response = await fetch(`${baseUrl}/api/search`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${refreshToken}`,
    },
    method: 'POST',
    body: JSON.stringify({
      content: content,
      universes,
      scroll_size: 10,
      scroll_time: '10m',
    }),
  });

  return response.json();
}

export async function scroll(
  scrollId: string,
  refreshToken: string
): Promise<SearchResponse> {
  const response = await fetch(`${baseUrl}/api/search/scroll`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${refreshToken}`,
    },
    method: 'POST',
    body: JSON.stringify({
      scroll_id: scrollId,
      scroll_time: '10m',
    }),
  });

  return response.json();
}
