import { useCallback, useMemo, useRef, useState } from 'react';

import { ExtractTransformer } from '@/components/HTMLCanvas/PinPanel/Extract/ExtractTransformer';
import { useOnExtract } from '@/components/HTMLCanvas/PinPanel/Extract/hooks/useOnExtract';
import { Dimension } from '@/utils/geometry/dimension';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeUndefined } from '@/utils/types';

import styles from './PinPanelExtract.module.css';

export type PinPanelExtractProps = {
  contentRef: React.RefObject<HTMLDivElement>;
  sticker: KosmikSticker;
};

export const PinPanelExtract = ({
  contentRef,
  sticker,
}: PinPanelExtractProps) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [extractDimension, setExtractDimension] =
    useState<MaybeUndefined<Dimension>>(undefined);
  const normalizedExtractDimension = useMemo(() => {
    if (!extractDimension) {
      return undefined;
    }

    let x = extractDimension.x;
    let y = extractDimension.y;

    if (extractDimension.width < 0) {
      x = extractDimension.x + extractDimension.width;
    }

    if (extractDimension.height < 0) {
      y = extractDimension.y + extractDimension.height;
    }

    return {
      x,
      y,
      width: Math.abs(extractDimension.width),
      height: Math.abs(extractDimension.height),
    };
  }, [extractDimension]);
  const ref = useRef<HTMLDivElement>(null);
  const onExtract = useOnExtract(contentRef, sticker);

  const handlePointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (e.target !== e.currentTarget) {
        return;
      }
      const extractRect = ref.current?.getBoundingClientRect();
      if (!extractRect) {
        return;
      }
      setIsDrawing(true);
      setExtractDimension({
        x: e.clientX - extractRect.x,
        y: e.clientY - extractRect.y,
        width: 0,
        height: 0,
      });
    },
    []
  );

  const handlePointerMove = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (isDrawing) {
        const extractRect = ref.current?.getBoundingClientRect();
        if (!extractRect) {
          return;
        }
        setExtractDimension((prev) => {
          if (!prev) {
            return undefined;
          }
          return {
            ...prev,
            width: e.clientX - extractRect.x - prev.x,
            height: e.clientY - extractRect.y - prev.y,
          };
        });
      }
    },
    [isDrawing]
  );

  const handlePointerUp = useCallback(() => {
    setIsDrawing(false);
  }, []);

  return (
    <div
      ref={ref}
      className={styles.pinpanelExtract}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
    >
      {normalizedExtractDimension &&
      normalizedExtractDimension.width > 0 &&
      normalizedExtractDimension.height > 0 ? (
        <ExtractTransformer
          boundingBox={normalizedExtractDimension}
          isDrawing={isDrawing}
          setBoundingBox={setExtractDimension}
          onExtract={() => onExtract(normalizedExtractDimension)}
        />
      ) : null}
    </div>
  );
};
