import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { stickerUploadStateFamily } from '@/atoms/sticker';
import { TextTag } from '@/components/fontSystem/TextTag';
import styles from '@/components/HTMLCanvas/stickers/HtmlLoadingSticker.module.css';

interface HtmlLoadingStickerProp {
  children?: ReactNode;
  stickerId: string;
  isLoading: boolean;
}

export interface SpinnerProps {
  size?: number | string;
}

export const Spinner = ({ size }: SpinnerProps) => {
  const resolvedSize = size ?? 32;
  return (
    <svg
      className={styles.spinner}
      viewBox={'0 0 32 32'}
      style={{ width: resolvedSize, height: resolvedSize }}
    >
      <circle
        className={styles.path}
        cx={'16'}
        cy={'16'}
        r={'10'}
        fill={'none'}
        strokeWidth={'2'}
      ></circle>
      <circle
        className={styles.track}
        cx={'16'}
        cy={'16'}
        r={'10'}
        fill={'none'}
        strokeWidth={'2'}
      ></circle>
    </svg>
  );
};

export const HtmlLoadingSticker = React.forwardRef<
  HTMLDivElement,
  HtmlLoadingStickerProp
>(({ stickerId, children, isLoading }, ref) => {
  const loadingState = useRecoilValue(stickerUploadStateFamily(stickerId));
  return (
    <div
      ref={ref}
      className={`${styles.loadingSticker} ${isLoading ? '' : styles.loaded}`}
    >
      {isLoading ? (
        <div className={styles.loadingStatus}>
          <Spinner />
        </div>
      ) : null}

      {children}
      {loadingState?.isUploading && (
        <div className={styles.uploadStatus}>
          <Spinner size={21} /> <TextTag>Uploading</TextTag>
        </div>
      )}
    </div>
  );
});

HtmlLoadingSticker.displayName = 'HtmlLoadingSticker';
