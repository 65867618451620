import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { isSingleSelectionAtom } from '@/atoms/selection';
import { stickerSelectionStateFamily } from '@/atoms/sticker';
import { EditDock } from '@/components/EditDock/EditDock';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { KosmikSticker } from '@/utils/kosmik/sticker';

export const CanvasEditDock = ({ sticker }: { sticker: KosmikSticker }) => {
  const user = useContext(MultiplayerUserContext);
  const selection = useRecoilValue(stickerSelectionStateFamily(sticker.id));
  const isSingleSelection = useRecoilValue(isSingleSelectionAtom);
  const isLocalSelection = user ? selection.has(user) : false;
  const isTheOnlySelected = selection && isLocalSelection && isSingleSelection;
  const { isUniverseMember } = useContext(UniverseContext);

  if (!isTheOnlySelected || !isUniverseMember) {
    return null;
  }

  return <EditDock sticker={sticker} />;
};
