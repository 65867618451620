import * as Form from '@radix-ui/react-form';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/components/ui/Form/FormField.module.css';
import { FormFieldProps } from '@/ui/Form/FormFieldProps';
import { FormStack } from '@/ui/Form/FormStack';

interface MagicCodeFormFieldProps extends FormFieldProps {
  error: boolean;
}

export const MagicCodeFormField = ({
  serverError,
  onChange,
  value,
  showLabel,
  error,
}: MagicCodeFormFieldProps) => (
  <Form.Field name={'magicCode'} serverInvalid={serverError} asChild>
    <FormStack>
      {showLabel ? (
        <Form.Label>
          <TextLabelRegular style={{ fontWeight: '600' }}>
            Magic Code
          </TextLabelRegular>
        </Form.Label>
      ) : null}

      {error ? (
        <Form.Message className={styles.message} asChild>
          <TextLabelRegular>The code is invalid</TextLabelRegular>
        </Form.Message>
      ) : null}

      <Form.Message className={styles.message} match={'valueMissing'} asChild>
        <TextLabelRegular data-testid={'magiccode-value-missing'}>
          Please enter your code
        </TextLabelRegular>
      </Form.Message>

      <Form.Control asChild>
        <input
          value={value ?? ''}
          style={{ textTransform: 'uppercase' }}
          type={'text'}
          required
          placeholder={'123456'}
          onChange={onChange}
        />
      </Form.Control>
    </FormStack>
  </Form.Field>
);
