import { atomFamily, selectorFamily } from 'recoil';

import { UndoRedoActionType } from '@/hooks/useUndoRedo';

export const undoStackFamily = atomFamily<UndoRedoActionType[], string>({
  key: 'undoStack',
  default: [],
});

export const redoStackFamily = atomFamily<UndoRedoActionType[], string>({
  key: 'redoStack',
  default: [],
});

/**
 * A selector that indicates whether an undo operation can be performed for a given universe.
 *
 * This selector family takes a `universeId` and returns `true` if the undo stack for the specified
 * universe contains any actions, meaning an undo operation is possible. It returns `false` if the
 * undo stack is empty, meaning no actions are available to undo.
 *
 * @param universeId - The unique identifier for the universe in question.
 * @return {boolean} True if there are actions in the undo stack, false otherwise.
 */
export const canUndoSelector = selectorFamily<boolean, string>({
  key: 'canUndo',
  get:
    (universeId) =>
    ({ get }) => {
      const undoStack = get(undoStackFamily(universeId));
      return undoStack.length > 0;
    },
});

/**
 * A selector that indicates whether a redo operation can be performed for a given universe.
 *
 * This selector family takes a `universeId` and returns `true` if the redo stack for the specified
 * universe contains any actions, meaning a redo operation is possible. It returns `false` if the
 * redo stack is empty, meaning no actions are available to redo.
 *
 * @param universeId - The unique identifier for the universe in question.
 * @return {boolean} True if there are actions in the undo stack, false otherwise.
 */
export const canRedoSelector = selectorFamily<boolean, string>({
  key: 'canRedo',
  get:
    (universeId) =>
    ({ get }) => {
      const redoStack = get(redoStackFamily(universeId));
      return redoStack.length > 0;
    },
});
