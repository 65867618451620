import { Button } from '@lithium/spectrum';
import { useCallback } from 'react';

import { TextBodySemibold } from '@/components/fontSystem/BodySemibold';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { useTeam } from '@/context/Team/useTeam';
import { useUser } from '@/context/User/useUser';
import { DialogSection } from '@/ui/Dialog/DialogSection';
import { deleteTeam, leaveFromTeam } from '@/utils/team/team';

export type LeaveTeamSectionProps = {
  closeModal: () => void;
};

export const LeaveTeamSection = ({ closeModal }: LeaveTeamSectionProps) => {
  const user = useUser();
  const { team } = useTeam();

  const handleLeave = useCallback(async () => {
    if (user && team) {
      const isLastUser = team.users.length === 1;
      if (isLastUser && team.users[0]?.id === user.id) {
        deleteTeam(team);
      } else {
        leaveFromTeam(user.id, team.id);
      }
    }
    closeModal();
  }, [closeModal, team, user]);

  return (
    <DialogSection title={<TextBodySemibold>Leave Workspace</TextBodySemibold>}>
      <div className={styles.centerRow}>
        <TextLabelRegular>
          Doing so will remove your access to any Objects and Universes
          of&nbsp;this&nbsp; workspace.
        </TextLabelRegular>
        <Button onAction={handleLeave}>Leave</Button>
      </div>
    </DialogSection>
  );
};
