import { useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { useNoControlsFocus } from '@/components/HTMLCanvas/stickers/hooks/useNoControlsFocus';
import { useSetDefaultFileResizable } from '@/components/HTMLCanvas/stickers/hooks/useSetDefaultResizable';
import { HtmlGenericFileSticker } from '@/components/HTMLCanvas/stickers/HtmlGenericFileSticker';
import { HtmlLoadingSticker } from '@/components/HTMLCanvas/stickers/HtmlLoadingSticker';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { useSetStickerProperties } from '@/hooks/sticker/useSetStickerProperties';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';
import { offscreenVideo } from '@/utils/media/offscreen';

import styles from './HtmlVideoSticker.module.css';

export const HtmlVideoSticker = ({
  sticker,
  refreshCachedUrl,
}: {
  sticker: KosmikFileSticker;
  refreshCachedUrl: () => void;
}) => {
  const [error, setError] = useState<Error | MediaError | null>(null);
  const file = sticker.files?.[0];
  const { type, cached_url } = file ?? sticker.attributes;
  const { ratio } = sticker.attributes;
  const videoRef = useRef<HTMLVideoElement>(null);
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerProperties =
    useSetStickerProperties<KosmikFileSticker>(setSticker);
  const setStickerAttributes =
    useSetStickerAttributes<FileStickerAttributes>(setSticker);
  const spaceRef = useNoControlsFocus({ ref: videoRef });
  const refs = mergeRefs([videoRef, spaceRef]);
  const isPinPanel = usePinPanel();
  useSetDefaultFileResizable(sticker);

  useEffect(() => {
    if (error && cached_url) {
      refreshCachedUrl();
    }
  }, [cached_url, error, refreshCachedUrl]);

  useEffect(() => {
    setError(null);
  }, [cached_url]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    const handleFullscreenChange = () => {
      if (isPinPanel || document.fullscreenElement === video) {
        video.style.objectFit = 'contain';
      } else {
        video.style.objectFit = 'cover';
      }
    };
    video.addEventListener('fullscreenchange', handleFullscreenChange);
    handleFullscreenChange();
    return () => {
      video.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [cached_url, isPinPanel]);

  return offscreenVideo.canPlayType(type ?? '') ? (
    <HtmlLoadingSticker stickerId={sticker.id} isLoading={!cached_url}>
      {cached_url ? (
        <video
          /* The key has to change so the video properly picks new sources */
          key={cached_url}
          ref={refs}
          data-testid={'video'}
          controls={true}
          className={styles.videoSticker}
          onLoadedMetadata={(event) => {
            const video = event.currentTarget;
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;
            if (!videoWidth || !videoHeight) {
              return;
            }
            const videoRatio = videoWidth / videoHeight;

            if (videoRatio !== ratio) {
              setStickerProperties(
                {
                  width: videoWidth,
                  height: videoHeight,
                },
                true
              );
              setStickerAttributes({ ratio: videoRatio }, true);
            }
          }}
        >
          <source
            src={cached_url}
            data-testid={'source'}
            type={type}
            onError={() => {
              const error = videoRef.current?.error;
              setError(error ?? new Error('Undefined video error'));
            }}
          />
        </video>
      ) : null}
    </HtmlLoadingSticker>
  ) : (
    <HtmlGenericFileSticker
      sticker={sticker}
      refreshCachedUrl={refreshCachedUrl}
    />
  );
};
