import { Position } from '@/utils/geometry/position';
import { rotate } from '@/utils/geometry/rotate';

export type Vector = {
  // the x component of the vector
  dx: number;
  // the y component of the vector
  dy: number;
  // the magnitude (length) of the vector
  mag: number;
  // the components of the unit vector (normalised vector of length 1)
  unit: { x: number; y: number };
  // the components of the normal vector (normalised perpendicular vector of length 1)
  normal: { x: number; y: number };
  // the first point of the vector
  p1: Position;
  // the last point of the vector
  p2: Position;
  // the angle info of the vector
  angle: {
    // the angle in radians from the horizontal axis (counterclockwise)
    radians: number;
    // the angle in radians from the horizontal axis (clockwise)
    normalized: number;
    // the angle in degrees from the horizontal axis (counterclockwise)
    degrees: number;
    // the angle in degrees from the horizontal axis (clockwise)
    degreesNormalized: number;
  };
};

/**
 * Compute a ( p1 o-> p2 ) vector and its related information given p1 and p2
 * position objects with the shape {x: number, y: number}
 * @param p1
 * @param p2
 */
export const vector = (p1: Position, p2: Position): Vector => {
  // horizontal and vertical vector components
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  // magnitude of the vector (distance)
  const mag = Math.hypot(dx, dy);
  // unit vector
  const unit = mag !== 0 ? { x: dx / mag, y: dy / mag } : { x: 0, y: 0 };
  // normal vector
  const normal = rotate(unit, { x: 0, y: 0 }, Math.PI / 2);
  // Angle in radians
  const radians = Math.atan2(dy, dx);
  // Normalize to clock-wise circle
  const normalized = 2 * Math.PI + (radians % (2 * Math.PI));
  // Angle in degrees
  const degrees = (180 * radians) / Math.PI;
  const degreesNormalized = (360 + degrees) % 360;

  return {
    dx,
    dy,
    mag,
    unit,
    normal,
    p1: { ...p1 },
    p2: { ...p2 },
    angle: {
      radians,
      normalized,
      degrees,
      degreesNormalized,
    },
  };
};
