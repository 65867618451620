import { tx } from '@instantdb/react';
import { useCallback } from 'react';
import { SetterOrUpdater } from 'recoil';

import { batchTransact } from '@/utils/db/db';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeNull } from '@/utils/types';

export const useSetStickerProperties = <Sticker extends KosmikSticker>(
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>
) => {
  return useCallback(
    (properties: Partial<Omit<Sticker, 'v'>>, persist: boolean) => {
      setSticker((prevSticker) => {
        if (!prevSticker) {
          return prevSticker;
        }

        const newSticker = {
          ...prevSticker,
          ...properties,
          v: (prevSticker.v ?? 0) + 1,
        };

        if (persist) {
          batchTransact(
            tx.stickers?.[newSticker.id]?.update({
              v: newSticker.v,
              ...properties,
            })
          );
        }
        return newSticker;
      });
    },
    [setSticker]
  );
};
