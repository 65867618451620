import { CornerRadius } from '@lithium/spectrum';
import { useCallback, useRef } from 'react';
import { SetterOrUpdater } from 'recoil';

import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { Slider } from '@/ui/Slider/Slider';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { RectangleShapeAttributes } from '@/utils/kosmik/stickers/shape';
import { MaybeNull } from '@/utils/types';

export const RectangleShapeEditDock = ({
  attributes,
  setSticker,
}: {
  attributes: RectangleShapeAttributes;
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}) => {
  const setAttributes =
    useSetStickerAttributes<RectangleShapeAttributes>(setSticker);
  const universe = useUniverseContext();
  const { addUndoRedoAction } = useUndoRedo(universe.id);
  const currentCornerRadiusRef = useRef<number | undefined>(
    attributes.corner_radius_ratio
  );

  const handleCornerRadiusCommit = useCallback(
    (corner_radius_ratio: number | undefined) => {
      const preCommitValue = currentCornerRadiusRef.current;
      const cornerRadiusChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ corner_radius_ratio }, true);
          currentCornerRadiusRef.current = corner_radius_ratio;
        },
        undo: () => {
          setAttributes({ corner_radius_ratio: preCommitValue }, true);
        },
      };
      cornerRadiusChangeAction.do();
      addUndoRedoAction(cornerRadiusChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  return (
    <>
      <CornerRadius />
      <Slider
        orientation={'horizontal'}
        value={[attributes.corner_radius_ratio]}
        min={0}
        max={1}
        step={0.01}
        onValueChange={(newValue) => {
          setAttributes({ corner_radius_ratio: newValue[0] }, false);
        }}
        onValueCommit={(newValue) => {
          handleCornerRadiusCommit(newValue[0]);
        }}
      />
    </>
  );
};
