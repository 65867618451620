import { TransactionChunk } from '@instantdb/core';

import { db } from '@/db/databaseInit';
import { MaybeUndefined } from '@/utils/types';

const BATCH_SIZE = 500;

// We allow any TransactionChunk for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TxChunk = TransactionChunk<any, any>;

/**
 * Batch transact the given Transaction Chunks
 * about batching see: https://discord.com/channels/1031957483243188235/1252314570626695298/1252328034585808936* > I found that 500ms between batches and 100 transactions per batch was my sweet spot
 * @param chunks
 */
export const batchTransact = async (
  chunks: MaybeUndefined<TxChunk> | MaybeUndefined<TxChunk>[]
) => {
  if (Array.isArray(chunks)) {
    const batches: Array<TxChunk[]> = [];
    let batch: TxChunk[] = [];
    for (const chunk of chunks) {
      if (chunk) {
        batch.push(chunk);
        if (batch.length > BATCH_SIZE) {
          batches.push(batch);
          batch = [];
        }
      }
    }
    if (batch.length) {
      batches.push(batch);
    }
    for (const batch of batches) {
      await db.transact(batch);
    }
  } else {
    if (chunks) {
      await db.transact(chunks);
    }
  }
};
