import { atom } from 'recoil';

import { MaybeNull } from '@/utils/types';

/**
 * Holds the instant peer id and the kosmik peer id of the user being followed
 */
export const followingAtom = atom<
  MaybeNull<{ instantPeerId: string; kosmikPeerId: string }>
>({
  key: 'followingAtom',
  default: null,
});
