import { forwardRef, ReactElement } from 'react';

import { TextTag } from '@/components/fontSystem/TextTag';
import styles from '@/components/modal/modals/Settings/components/SettingsTabsItem/SettingsTabs.module.css';

interface SettingsTabsItemProps {
  name: string;
  icon: ReactElement;
}

export const SettingsTabsItem = forwardRef<
  HTMLDivElement,
  SettingsTabsItemProps
>(({ name, icon, ...props }, ref) => {
  return (
    <div ref={ref} className={styles.tabsTriggerItem} {...props}>
      <div className={styles.iconContainer}>{icon}</div>
      <TextTag className={styles.name}>{name}</TextTag>
    </div>
  );
});

SettingsTabsItem.displayName = 'SettingsTabsItem';
