import { useCallback, useState } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { CommonModalProps } from '@/components/modal/Modals';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { SettingsPageTitle } from '@/components/modal/modals/Settings/TeamSettings/contents/SettingsPageTitle';
import { DeleteTeamSection } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamGeneralSettings/DeleteTeamSection';
import { LeaveTeamSection } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamGeneralSettings/LeaveTeamSection';
import { TeamGeneralSettingsFooter } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamGeneralSettings/TeamGeneralSettingsFooter';
import { TeamNameSection } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamGeneralSettings/TeamNameSection';
import { useTeam } from '@/context/Team/useTeam';
import { DialogDescription } from '@/ui/Dialog/Dialog';
import { TeamRole } from '@/utils/kosmik/team';
import { renameTeam } from '@/utils/team/team';

export const TeamGeneralSettings = ({ closeModal }: CommonModalProps) => {
  const { team, role } = useTeam();
  const [teamName, setTeamName] = useState(team.name);

  const handleSave = useCallback(() => {
    renameTeam(team.id, teamName);
    closeModal();
  }, [closeModal, team.id, teamName]);

  return (
    <>
      <section className={styles.tabsContentContainer}>
        <div>
          <SettingsPageTitle>Workspace Settings</SettingsPageTitle>
          <DialogDescription style={{ margin: 0 }}>
            <TextLabelRegular>
              Rename, Leave or Delete your Workspace.
            </TextLabelRegular>
          </DialogDescription>
        </div>
        <TeamNameSection
          teamName={teamName}
          setTeamName={setTeamName}
          disabled={![TeamRole.owner, TeamRole.admin].includes(role)}
        />
        {role !== TeamRole.owner ? (
          <LeaveTeamSection closeModal={closeModal} />
        ) : null}
        {role === TeamRole.owner ? <DeleteTeamSection /> : null}
      </section>
      <TeamGeneralSettingsFooter onSave={handleSave} />
    </>
  );
};
