import { z } from 'zod';

import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { KosmikPromptSticker } from '@/utils/kosmik/stickers/prompt';
import { KosmikShapeSticker } from '@/utils/kosmik/stickers/shape';
import { KosmikStickySticker } from '@/utils/kosmik/stickers/sticky';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';

export const KosmikSticker = z.union([
  KosmikShapeSticker,
  KosmikStickySticker,
  KosmikTextSticker,
  KosmikWebSticker,
  KosmikFileSticker,
  KosmikPromptSticker,
]);

export type KosmikSticker = z.infer<typeof KosmikSticker>;
