/**
 * Rounds a number with an optional precision, (number of floating point digits
 * you want to round to) - E.g. round(1.123)
 * @param number
 * @param precision
 */
export const round = (number: number, precision = 0) => {
  const precisionFactpr = Math.pow(10, precision);
  return (
    Math.round((number + Number.EPSILON) * precisionFactpr) / precisionFactpr
  );
};
