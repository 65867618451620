import { RefObject, useLayoutEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { cameraAtom } from '@/atoms/camera';
import { CustomCursor } from '@/components/multiplayer/MultiplayerCursors/CustomCursor';
import { useUpdateCursorPosition } from '@/components/multiplayer/MultiplayerCursors/hooks/useUpdateCursorPosition';
import { UserMultiplayerState } from '@/db/databaseInit';
import { getUserName } from '@/utils/user/user';

export type MultiplayerCursorProps = Pick<
  UserMultiplayerState,
  'x' | 'y' | 'user'
>;

export const MultiplayerCursor = ({ x, y, user }: MultiplayerCursorProps) => {
  const camera = useRecoilValue(cameraAtom);
  const cursorContainerRef = useRef<HTMLDivElement>(null);
  const customCursorRef = useRef<{
    cursorPointerRef: RefObject<HTMLDivElement>;
    cursorLabelRef: RefObject<HTMLDivElement>;
  }>(null);

  const updateCursorPosition = useUpdateCursorPosition(x, y, camera, {
    cursorContainerRef,
    cursorPointerRef: customCursorRef.current?.cursorPointerRef,
    cursorLabelRef: customCursorRef.current?.cursorLabelRef,
  });

  useLayoutEffect(() => {
    updateCursorPosition();

    window.addEventListener('resize', updateCursorPosition);
    return () => {
      window.removeEventListener('resize', updateCursorPosition);
    };
  }, [updateCursorPosition]);

  return user ? (
    <div
      ref={cursorContainerRef}
      data-testid={'multiplayer-cursor'}
      style={{
        transformOrigin: 'top left',
        position: 'absolute',
        zIndex: 'var(--zindex-important-element)',
        pointerEvents: 'none',
      }}
    >
      <CustomCursor
        ref={customCursorRef}
        key={user.color}
        color={user.color}
        contrastColor={user.contrastColor}
        name={getUserName(user)}
      />
    </div>
  ) : null;
};
