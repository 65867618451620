import { useContext, useMemo } from 'react';

import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import { MaybeUndefined } from '@/utils/types';
import { getIsUniverseMember } from '@/utils/universe/universe';

export const useIsUniverseMember = (
  universe: MaybeUndefined<KosmikUniverse>
) => {
  const user = useContext(MultiplayerUserContext);
  const isOwner = useMemo(() => {
    if (!user || !universe) {
      return false;
    }
    return getIsUniverseMember(user, universe);
  }, [universe, user]);
  return !!isOwner;
};
