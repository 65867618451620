import { Maybe } from 'src/utils/types';

import { DATA_ATTRIBUTE_PAGE } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';

/**
 * Returns the html element containing the given page
 */
export const getPdfViewerHtmlPage = (
  container: Maybe<HTMLElement>,
  pageNumber: number
) => {
  const pages = container?.querySelectorAll(`[data-${DATA_ATTRIBUTE_PAGE}]`);
  return pages?.[pageNumber - 1];
};
