import { db } from '@/db/databaseInit';
import { KosmikUser } from '@/utils/kosmik/user';
import { MaybeNull } from '@/utils/types';

export const useUser = (): MaybeNull<KosmikUser> => {
  const { user: authUser } = db.useAuth();

  const { data, isLoading } = db.useQuery(
    authUser?.email
      ? {
          users: {
            preferences: {},
            teams: {
              universes: {},
              users: {},
              team_ownerships: {
                users: {},
              },
              sent_invitations: {
                receiver: {
                  team_invitations: {},
                },
              },
            },
            team_ownerships: {
              teams: {
                universes: {},
                users: {},
                sent_invitations: {
                  receiver: {
                    team_invitations: {},
                  },
                },
                team_ownerships: {
                  users: {},
                },
              },
            },
            $: {
              where: {
                email: authUser.email,
              },
            },
          },
        }
      : null
  );

  const user = data?.users?.[0];

  if (isLoading || !user?.id) {
    return null;
  }

  return user;
};
