/**
 * Retrieves the favicon URL for a given website hostname.
 *
 * @param {string} url - The hostname of the website.
 * @returns {string} The URL of the favicon for the given website.
 */
export const getFaviconUrl = (url: string) => {
  return `https://icons.duckduckgo.com/ip3/${url}.ico`;
};

/**
 * Simplifies a URL by extracting its hostname.
 *
 * @param {string} url - The full URL to simplify.
 * @returns {string} The hostname of the given URL.
 * @throws {Error} If the input is not a valid URL.
 */
export const simplifyUrl = (url: string) => {
  return `${new URL(url).hostname}`;
};

/**
 * Fetches the content type of the given URL.
 * @param url - The URL to check for content type.
 * @returns A Promise that resolves to the content type string or undefined if the request fails.
 */
export const getContentType = async (url: string) => {
  try {
    const response = await fetch(
      `https://metaproxy.kosmik.app/proxy/head?url=${encodeURIComponent(url)}`,
      {
        method: 'HEAD',
      }
    );
    return response.headers.get('content-type');
  } catch (error) {
    console.warn('getContentType failed', error);
    return;
  }
};

/**
 * Checks if a given string is a valid URL.
 * @param url - The string to validate as a URL.
 * @returns A boolean indicating whether the input is a valid URL.
 */
export const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch {
    return false;
  }
};
