import { CSSVar } from '@/utils/css/variable';

export const STICKY_COLORS: CSSVar[] = [
  '--tag-yellow-saturated',
  '--tag-green-saturated',
  '--tag-teal-saturated',
  '--tag-purple-saturated',
  '--tag-red-saturated',
];

export const TEAM_COLORS: CSSVar[] = [
  '--tag-orange-saturated',
  '--tag-yellow-saturated',
  '--tag-green-saturated',
  '--tag-blue-saturated',
  '--tag-teal-saturated',
  '--tag-purple-saturated',
  '--tag-red-saturated',
];
