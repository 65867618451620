import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';

import styles from './UniversePublishStatusLabel.module.css';

// TODO @jeremy discuss this with @mat
export const UniversePublishStatusLabel = () => {
  return <StatusLabel variant={'read only'} />;
};

interface StatusLabelProps {
  variant: 'published' | 'read only';
}

const StatusLabel = ({ variant }: StatusLabelProps) => {
  return (
    <div className={styles.labelContainer} data-variant={variant}>
      <TextLabelRegular>{variant}</TextLabelRegular>
    </div>
  );
};
