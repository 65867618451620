import { CSSProperties } from 'react';

import { useUpdateStickerTextContent } from '@/components/HTMLCanvas/stickers/hooks/useUpdateStickerTextContent';
import { QuillEditor } from '@/components/Quill/QuillEditor';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';

import styles from './HtmlTextSticker.module.css';

export const HtmlTextSticker = ({
  sticker,
}: {
  sticker: KosmikTextSticker;
}) => {
  const { handleDebouncedTextChange } = useUpdateStickerTextContent(sticker);

  return (
    <div
      style={
        {
          ['--bg']: sticker.attributes.fill_color ?? 'var(--color-primary)',
        } as CSSProperties
      }
      className={`${styles.text}`}
    >
      <QuillEditor
        type={'text'}
        key={sticker.id}
        stickerId={sticker.id}
        initialValue={sticker.attributes.text_content}
        onTextChange={handleDebouncedTextChange}
      />
    </div>
  );
};
