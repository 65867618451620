import { id, tx } from '@instantdb/react';

import { Camera, getCenterPoint } from '@/utils/camera/camera';
import { stringToHslColor } from '@/utils/color/colors';
import { batchTransact } from '@/utils/db/db';
import { Point } from '@/utils/geometry/point';
import { KosmikShapeSticker } from '@/utils/kosmik/stickers/shape';

interface StickerParams {
  point: Point;
  size: number;
  created_at: string;
  fill_color: string;
}

const createRectangleSticker = ({
  point,
  size,
  created_at,
  fill_color,
}: StickerParams): Omit<KosmikShapeSticker, 'id'> => {
  return {
    v: 0,
    created_at,
    type: 'shape',
    x: point.x,
    y: point.y,
    width: size,
    height: size,
    resizeable: true,
    attributes: {
      shape: 'rectangle',
      fill_color,
      stroke_color: 'rgba(0, 0, 0, 0.10)',
      stroke_width: 1,
      corner_radius_ratio: 0.25,
    },
  };
};

const createEllipseSticker = ({
  point,
  size,
  created_at,
  fill_color,
}: StickerParams): Omit<KosmikShapeSticker, 'id'> => {
  return {
    v: 0,
    created_at,
    type: 'shape',
    x: point.x,
    y: point.y,
    width: size,
    height: size,
    resizeable: true,
    attributes: {
      shape: 'ellipse',
      fill_color,
      stroke_color: 'rgba(0, 0, 0, 0.10)',
      stroke_width: 1,
    },
  };
};

const createPolygonSticker = ({
  point,
  size,
  created_at,
  fill_color,
}: StickerParams): Omit<KosmikShapeSticker, 'id'> => {
  return {
    v: 0,
    created_at,
    type: 'shape',
    x: point.x,
    y: point.y,
    width: size,
    height: size,
    resizeable: true,
    attributes: {
      shape: 'polygon',
      fill_color,
      stroke_color: 'rgba(0, 0, 0, 0.10)',
      stroke_width: 1,
      corner_radius_ratio: 0.4,
      num_points: Math.round(Math.random() * 3) + 3,
    },
  };
};

const createStarSticker = ({
  point,
  size,
  created_at,
  fill_color,
}: StickerParams): Omit<KosmikShapeSticker, 'id'> => {
  return {
    v: 0,
    created_at,
    type: 'shape',
    x: point.x,
    y: point.y,
    width: size,
    height: size,
    resizeable: true,
    attributes: {
      shape: 'star',
      fill_color,
      stroke_color: 'rgba(0, 0, 0, 0.10)',
      stroke_width: 1,
      corner_radius_ratio: 0.4,
      inner_radius_ratio: 0.5,
      num_points: 5,
    },
  };
};

export interface CreateShapeStickerProps {
  universeId: string;
  camera: Camera;
  shape: KosmikShapeSticker['attributes']['shape'];
  fill_color?: string;
  isShiftKeyPressed?: boolean;
}

export const createShapeSticker = ({
  universeId,
  camera,
  shape,
  fill_color,
  isShiftKeyPressed = false,
}: CreateShapeStickerProps): KosmikShapeSticker[] => {
  if (!universeId) {
    return [];
  }

  const txs = [];
  const size = 200;
  const created_at = new Date().toISOString();
  const amount = isShiftKeyPressed ? 100 : 1;
  const createdShapeStickers: KosmikShapeSticker[] = [];

  for (let i = 0; i < amount; i++) {
    const centerOfScreen = getCenterPoint(camera);
    const position = {
      x: centerOfScreen.x - size / 2,
      y: centerOfScreen.y - size / 2,
    };
    const point = {
      x: position.x + (50 + size) * (i % 10),
      y: position.y + (50 + size) * (Math.floor(i / 10) % 10),
    };
    const stickerId = id();
    let sticker: Omit<KosmikShapeSticker, 'id'>;
    const fillColorValue = fill_color ?? stringToHslColor(stickerId, 85, 75);

    switch (shape) {
      case 'rectangle':
        sticker = createRectangleSticker({
          point,
          size,
          created_at,
          fill_color: fillColorValue,
        });
        break;
      case 'ellipse':
        sticker = createEllipseSticker({
          point,
          size,
          created_at,
          fill_color: fillColorValue,
        });
        break;
      case 'polygon':
        sticker = createPolygonSticker({
          point,
          size,
          created_at,
          fill_color: fillColorValue,
        });
        break;
      case 'star':
        sticker = createStarSticker({
          point,
          size,
          created_at,
          fill_color: fillColorValue,
        });
        break;
      default:
        throw new Error(`Unknown shape: ${shape}`);
    }

    txs.push(
      tx.stickers?.[stickerId]
        ?.update(sticker)
        .link({ universes: [universeId] })
    );
    createdShapeStickers.push({ id: stickerId, ...sticker });
  }

  batchTransact(txs);
  return createdShapeStickers;
};
