import { useEffect } from 'react';

import {
  NotificationContent,
  UpdateState,
  useUpdates,
} from '@/components/Notifications/hooks/useUpdates';
import { useNotifications } from '@/context/Notifications/useNotifications';
import { NotificationBase } from '@/ui/Notifications/NotificationBase';
import { ProgressBar } from '@/ui/ProgressBar/ProgressBar';

export const UpdateNotification = ({ id }: { id: string }) => {
  const { dismissNotification } = useNotifications();
  const {
    status,
    progressInfo,
    setProgressInfo,
    versionInfo,
    updateError,
    checkUpdates,
  } = useUpdates();

  useEffect(() => {
    checkUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content: NotificationContent = {
    cancelText: 'Cancel',
    onCancel: () => {
      dismissNotification(id);
    },
    okText: undefined,
    onOk: undefined,
    title: 'Version Update',
    content: 'Contacting HQ...',
  };

  switch (status) {
    case UpdateState.checking: {
      content = {
        cancelText: 'Cancel',
        onCancel: () => {
          dismissNotification(id);
        },
        okText: undefined,
        onOk: undefined,
        title: 'Version Update',
        content: 'Contacting HQ...',
      };
      break;
    }
    case UpdateState.downloading: {
      content = {
        cancelText: 'Cancel',
        onCancel: () => {
          dismissNotification(id);
        },
        okText: undefined,
        onOk: undefined,
        title: 'Downloading Update',
        content: <ProgressBar progress={progressInfo?.percent ?? 0} />,
      };
      break;
    }
    case UpdateState.updateAvailable: {
      content = {
        cancelText: 'Cancel',
        onCancel: () => {
          dismissNotification(id);
        },
        okText: 'Update',
        onOk: () => {
          setProgressInfo({ percent: 0 });
          window.electron?.ipcRenderer.invoke('start-download');
        },
        title: 'Update Available',
        content: `Update available to v${versionInfo?.version}`,
      };
      break;
    }
    case UpdateState.readyToInstall: {
      content = {
        cancelText: 'Later',
        onCancel: () => {
          dismissNotification(id);
        },
        okText: 'Install now',
        onOk: () => {
          window.electron?.ipcRenderer.invoke('quit-and-install');
        },
        title: 'Update Downloaded',
        content: `Install now to quit and install Kosmik v${versionInfo?.version}`,
      };

      break;
    }
    case UpdateState.error: {
      content = {
        cancelText: 'Cancel',
        onCancel: () => {
          dismissNotification(id);
        },
        okText: undefined,
        onOk: undefined,
        title: 'Update Error',
        content: updateError?.message ?? 'Error trying to update',
      };
      break;
    }
    case UpdateState.upToDate: {
      content = {
        cancelText: 'Cancel',
        onCancel: () => {
          dismissNotification(id);
        },
        okText: undefined,
        onOk: undefined,
        title: 'No updates available',
        content: 'You are on the last version of Kosmik',
      };
    }
  }

  return (
    <NotificationBase
      id={id}
      title={content.title}
      description={content.content}
      action={{
        label: content.okText,
        onClick: content.onOk,
      }}
      cancel={{
        label: content.cancelText,
        onClick: content.onCancel,
      }}
    />
  );
};
