import { Capture, SidebarPdf, TableOfContents } from '@lithium/spectrum';
import { useRecoilState } from 'recoil';

import { ActiveTool, pinPanelActiveToolAtom } from '@/atoms/pinpanel';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';

export const PinPanelPdfEditDockItems = () => {
  const [pinPanelActiveTool, setPinPanelActiveTool] = useRecoilState(
    pinPanelActiveToolAtom
  );

  const toggleTool = (toolName: ActiveTool) => {
    setPinPanelActiveTool((prev) => {
      return prev === toolName ? null : toolName;
    });
  };

  return (
    <>
      <EditDockItem
        data-testid={'pin-panel-edit-dock-preview'}
        active={pinPanelActiveTool === 'preview'}
        onClick={() => toggleTool('preview')}
      >
        <SidebarPdf />
      </EditDockItem>
      <EditDockItem
        data-testid={'pin-panel-edit-dock-table'}
        active={pinPanelActiveTool === 'table'}
        onClick={() => toggleTool('table')}
      >
        <TableOfContents />
      </EditDockItem>
      <EditDockItem
        data-testid={'pin-panel-edit-dock-extract'}
        active={pinPanelActiveTool === 'extract'}
        onClick={() => toggleTool('extract')}
      >
        <Capture />
      </EditDockItem>
    </>
  );
};
