import { Button } from '@lithium/spectrum';
import React, { CSSProperties, HTMLAttributes } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { Tooltip } from '@/ui/Tooltip/Tooltip';

type ShareButtonProps = HTMLAttributes<HTMLButtonElement>;

export const ShareButton = React.forwardRef<
  HTMLButtonElement,
  ShareButtonProps
>(({ style, ...props }, ref) => {
  return (
    <Tooltip content={'Publish & collaborate'}>
      <Button
        ref={ref}
        {...props}
        style={{ ...style, WebkitAppRegion: 'no-drag' } as CSSProperties}
        data-testid={'share-button'}
        variant={'positive'}
      >
        <TextLabelRegular>Share</TextLabelRegular>
      </Button>
    </Tooltip>
  );
});

ShareButton.displayName = 'ShareButton';
