import { atom } from 'recoil';

import { Position } from '@/utils/geometry/position';

/**
 * True if the user is currently pressing on stickers
 */
export const isPressingAtom = atom<boolean>({
  key: 'isPressingAtom',
  default: false,
});

/**
 * True if the user is currently moving stickers
 */
export const isMovingAtom = atom<boolean>({
  key: 'isMovingAtom',
  default: false,
});

/**
 * Stores the last alt-drag duplicate offset for repeating when duplicating again
 */
export const duplicateOffsetAtom = atom<Position | undefined>({
  key: 'duplicateOffsetAtom',
  default: undefined,
});
