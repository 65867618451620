/**
 * Computes the angle (in radians) between two coordinates in a 2D plane.
 * @param p1
 * @param p2
 */
export const getAngleBetweenCoord = (
  p1: { x: number; y: number },
  p2: { x: number; y: number }
) => {
  return Math.atan2(p2.y - p1.y, p2.x - p1.x);
};
