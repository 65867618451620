import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';

export type UrlFieldMode = 'edit' | 'display';

// Update the elements for Display mode
const updateToDisplayMode = (
  simplifiedUrl: HTMLDivElement,
  urlInputContainer: HTMLDivElement
) => {
  const paddingLeft = simplifiedUrl.offsetLeft - urlInputContainer.offsetLeft;
  urlInputContainer.style.paddingLeft = `${paddingLeft}px`;
  urlInputContainer.style.opacity = '0';
  simplifiedUrl.style.transform = 'none';
  simplifiedUrl.style.opacity = '1';
};

// Update the elements for Edit mode
const updateToEditMode = (
  simplifiedUrl: HTMLDivElement,
  urlInputContainer: HTMLDivElement
) => {
  const translateX = urlInputContainer.offsetLeft - simplifiedUrl.offsetLeft;
  urlInputContainer.style.paddingLeft = '0';
  urlInputContainer.style.opacity = '1';
  simplifiedUrl.style.transform = `translateX(${translateX}px)`;
  simplifiedUrl.style.opacity = '0';
  const input = urlInputContainer.querySelector('input');
  input?.focus();
};

/**
 * Manage toggling between `edit` and `display` and update the UI accordingly
 * @param urlInputContainerRef
 * @param simplifiedUrlRef
 * @param url
 * @param simplifiedUrl
 */
export const useUrlFieldMode = (
  urlInputContainerRef: MutableRefObject<HTMLDivElement | null>,
  simplifiedUrlRef: MutableRefObject<HTMLDivElement | null>,
  url?: string,
  simplifiedUrl?: string
) => {
  const [, privateSetMode] = useState<UrlFieldMode>('display');

  const update = useCallback(
    (mode: UrlFieldMode) => {
      const urlInputContainer = urlInputContainerRef.current;
      const simplifiedUrl = simplifiedUrlRef.current;
      if (urlInputContainer && simplifiedUrl) {
        if (mode === 'edit') {
          updateToEditMode(simplifiedUrl, urlInputContainer);
        } else {
          updateToDisplayMode(simplifiedUrl, urlInputContainer);
        }
      }
    },
    [simplifiedUrlRef, urlInputContainerRef]
  );

  const animate = useCallback(
    (mode: UrlFieldMode) => {
      const urlInputContainer = urlInputContainerRef.current;
      const simplifiedUrl = simplifiedUrlRef.current;
      if (urlInputContainer && simplifiedUrl) {
        urlInputContainer.style.transition =
          'padding-left 0.15s ease-in-out, opacity 0.15s ease-out';
        simplifiedUrl.style.transition =
          'transform 0.15s ease-in-out, opacity 0.15s ease-out';
        update(mode);
      }
    },
    [simplifiedUrlRef, update, urlInputContainerRef]
  );

  const setMode = useCallback(
    (mode: UrlFieldMode) => {
      privateSetMode(mode);
      animate(mode);
    },
    [animate]
  );

  useLayoutEffect(() => {
    const urlInputContainer = urlInputContainerRef.current;
    const input = urlInputContainer?.querySelector('input');
    if (document.activeElement !== input) {
      update('display');
    }
  }, [update, url, simplifiedUrl, urlInputContainerRef]);

  return setMode;
};
