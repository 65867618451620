import { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export type UseNoControlsFocusProps = {
  ref: React.RefObject<HTMLVideoElement | HTMLAudioElement>;
};

export const useNoControlsFocus = ({ ref }: UseNoControlsFocusProps) => {
  const spaceRef = useHotkeys('space', (event) => {
    event.stopPropagation();
    event.preventDefault();
  });

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    const reFocus = (event: Event) => {
      (event.currentTarget as HTMLElement).focus();
    };
    const eventList = [
      'play',
      'pause',
      'seeking',
      'volumechange',
      'fullscreenchange',
    ];
    for (const event of eventList) {
      element.addEventListener(event, reFocus);
    }
    return () => {
      for (const event of eventList) {
        element.removeEventListener(event, reFocus);
      }
    };
  }, [ref]);

  return spaceRef;
};
