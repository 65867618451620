import mime from 'mime';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useSetDefaultFileResizable } from '@/components/HTMLCanvas/stickers/hooks/useSetDefaultResizable';
import {
  FILE_EXTENSION_ICONS,
  FileExtensionWithIcon,
  GENERIC_FILE_ICON,
} from '@/constants/extensions';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';

import styles from './HtmlGenericFileSticker.module.css';

export const HtmlGenericFileSticker = ({
  sticker,
  fromError,
}: {
  sticker: KosmikFileSticker;
  refreshCachedUrl: () => void;
  fromError?: boolean;
}) => {
  const file = sticker.files?.[0];
  const { type, file_name: fileName } = file ?? sticker.attributes;
  useSetDefaultFileResizable(sticker, fromError);

  const extension = mime.getExtension(type ?? '');
  const extensionIcon =
    FILE_EXTENSION_ICONS[(extension ?? '') as FileExtensionWithIcon] ??
    GENERIC_FILE_ICON;

  return (
    <div
      className={styles.genericFileSticker}
      data-testid={'generic-file-sticker'}
    >
      <img alt={''} className={styles.icon} src={extensionIcon} />
      <div className={styles.textPart}>
        <TextLabelRegular className={styles.fileName}>
          {fileName}
        </TextLabelRegular>
        <TextLabelRegular className={styles.description}>
          {extension
            ? extension.charAt(0).toUpperCase() + extension.slice(1)
            : 'Unknown'}{' '}
          document
        </TextLabelRegular>
      </div>
    </div>
  );
};
