import { Sidebar } from '@lithium/spectrum';
import { useRecoilState } from 'recoil';

import { sidePanelOpenAtom } from '@/atoms/sidePanel';
import { MinimalButton } from '@/ui/MinimalButton/MinimalButton';
import { Tooltip } from '@/ui/Tooltip/Tooltip';
import styles from '@/ui/TransparentIcon.module.css';

export const SidebarToggleButton = () => {
  const [isOpen, setIsOpen] = useRecoilState(sidePanelOpenAtom);

  const handleSidebarToggle = () => {
    setIsOpen((state) => !state);
  };

  return (
    <Tooltip content={`${isOpen ? 'Close' : 'Open'} side panel`}>
      <MinimalButton
        onClick={handleSidebarToggle}
        data-testid={'sidebar-toggle-button'}
      >
        <Sidebar className={`${styles.transparency} icon icon-transition`} />
      </MinimalButton>
    </Tooltip>
  );
};
