import { CornerRadius, InnerRadius, ShapePoints } from '@lithium/spectrum';
import { useCallback, useRef } from 'react';
import { SetterOrUpdater } from 'recoil';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { MenuSeparator } from '@/ui/MenuPrimitive/MenuSeparator';
import { Slider } from '@/ui/Slider/Slider';
import { SliderPopover } from '@/ui/Slider/SliderPopover';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { StarShapeAttributes } from '@/utils/kosmik/stickers/shape';
import { MaybeNull } from '@/utils/types';

export const StarShapeEditDock = ({
  attributes,
  setSticker,
}: {
  attributes: StarShapeAttributes;
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}) => {
  const setAttributes =
    useSetStickerAttributes<StarShapeAttributes>(setSticker);
  const universe = useUniverseContext();
  const { addUndoRedoAction } = useUndoRedo(universe.id);
  const currentInnerRadiusRef = useRef<number | undefined>(
    attributes.inner_radius_ratio
  );
  const currentNumPointsRef = useRef<number | undefined>(attributes.num_points);
  const currentCornerRadiusRef = useRef<number | undefined>(
    attributes.corner_radius_ratio
  );

  const handleInnerRadiusCommit = useCallback(
    (inner_radius_ratio: number | undefined) => {
      const preCommitValue = currentInnerRadiusRef.current;
      const innerRadiusChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ inner_radius_ratio }, true);
          currentInnerRadiusRef.current = inner_radius_ratio;
        },
        undo: () => {
          setAttributes({ inner_radius_ratio: preCommitValue }, true);
        },
      };
      innerRadiusChangeAction.do();
      addUndoRedoAction(innerRadiusChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  const handleNumPointCommit = useCallback(
    (num_points: number | undefined) => {
      const preCommitValue = currentNumPointsRef.current;
      const numPointChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ num_points }, true);
          currentNumPointsRef.current = num_points;
        },
        undo: () => {
          setAttributes({ num_points: preCommitValue }, true);
        },
      };
      numPointChangeAction.do();
      addUndoRedoAction(numPointChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  const handleCornerRadiusCommit = useCallback(
    (corner_radius_ratio: number | undefined) => {
      const preCommitValue = currentCornerRadiusRef.current;
      const cornerRadiusChangeAction: UndoRedoActionType = {
        do: () => {
          setAttributes({ corner_radius_ratio }, true);
          currentCornerRadiusRef.current = corner_radius_ratio;
        },
        undo: () => {
          setAttributes({ corner_radius_ratio: preCommitValue }, true);
        },
      };
      cornerRadiusChangeAction.do();
      addUndoRedoAction(cornerRadiusChangeAction);
    },
    [addUndoRedoAction, setAttributes]
  );

  return (
    <>
      <InnerRadius />
      <Slider
        orientation={'horizontal'}
        value={[attributes.inner_radius_ratio]}
        min={0}
        max={1}
        step={0.01}
        onValueChange={(newValue) => {
          setAttributes({ inner_radius_ratio: newValue[0] }, false);
        }}
        onValueCommit={(newValue) => {
          handleInnerRadiusCommit(newValue[0]);
        }}
      />
      <MenuSeparator orientation={'vertical'} />
      <SliderPopover
        trigger={
          <EditDockItem>
            <ShapePoints />
          </EditDockItem>
        }
        orientation={'vertical'}
        value={[attributes.num_points]}
        min={3}
        max={16}
        step={1}
        onValueChange={(newValue) => {
          setAttributes({ num_points: newValue[0] }, false);
        }}
        onValueCommit={(newValue) => {
          handleNumPointCommit(newValue[0]);
        }}
      />
      <SliderPopover
        trigger={
          <EditDockItem>
            <CornerRadius />
          </EditDockItem>
        }
        orientation={'vertical'}
        value={[attributes.corner_radius_ratio]}
        min={0}
        max={1}
        step={0.01}
        onValueChange={(newValue) => {
          setAttributes({ corner_radius_ratio: newValue[0] }, false);
        }}
        onValueCommit={(newValue) => {
          handleCornerRadiusCommit(newValue[0]);
        }}
      />
    </>
  );
};
