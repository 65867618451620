import { PDFPageProxy, RenderTask } from 'pdfjs-dist';
import { MutableRefObject, useEffect } from 'react';

import {
  DATA_ATTRIBUTE_PAGE,
  DATA_ATTRIBUTE_VISIBLE,
} from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';
import { renderPageAndTextLayer } from '@/components/HTMLCanvas/PinPanel/PdfViewer/utils/RenderPageAndTextLayer';
import { IterableRefsMapKey } from '@/hooks/useIterableRefs';

export const useRenderPageWhenVisible = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  pdfPromiseRefs: Map<IterableRefsMapKey, RenderTask>,
  getPdfPromiseRef: (id: IterableRefsMapKey) => (node: RenderTask) => void,
  pageProxies: PDFPageProxy[],
  pageRefs: Map<IterableRefsMapKey, HTMLDivElement>
) => {
  // Setup page specific observers
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      // Use IntersectionObserver on the page container to detect when it will
      // overlap with the panel and therefore become visible
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const { target: pageContainer } = entry;
            if (pageContainer instanceof HTMLElement) {
              const pageNumber = Number(
                pageContainer.dataset[DATA_ATTRIBUTE_PAGE]
              );
              const visible = entry.isIntersecting;
              if (pageContainer && pageNumber) {
                if (visible) {
                  pageContainer.dataset[DATA_ATTRIBUTE_VISIBLE] = '';
                  // Render the page (only when it has not been rendered yet)
                  // and it is becoming visible
                  const getPdfPromise = () => pdfPromiseRefs.get(pageNumber);
                  const setPdfPromise = getPdfPromiseRef(pageNumber);
                  const pageProxy = pageProxies[pageNumber - 1];
                  if (pageProxy) {
                    renderPageAndTextLayer({
                      container: pageContainer,
                      pageProxy,
                      getPdfPromise,
                      setPdfPromise,
                    });
                  }
                } else {
                  // TODO we need to find a way to cancel pdf page render: promise.cancel() doesn't do it if the render already started
                  // const promise = pdfPromiseRefs.get(pageNumber);
                  // promise?.cancel();
                  delete pageContainer.dataset[DATA_ATTRIBUTE_VISIBLE];
                }
              }
            }
          });
        },
        {
          root: container,
          rootMargin: '100% 0% 100% 0%',
        }
      );
      // Observe all the pages
      [...pageRefs.values()].forEach((page) => {
        intersectionObserver.observe(page);
      });

      return () => {
        intersectionObserver.disconnect();
        [...pdfPromiseRefs.values()].forEach((pdfPromise) => {
          pdfPromise.cancel();
        });
      };
    }
  }, [containerRef, getPdfPromiseRef, pageProxies, pageRefs, pdfPromiseRefs]);
};
