import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  deletingStickersFamily,
  temporaryStickersFamily,
} from '@/atoms/sticker';
import { KosmikUniverse } from '@/utils/kosmik/universe';

export const useUniverseStickers = (universe?: KosmikUniverse) => {
  const deletingStickers = useRecoilValue(
    deletingStickersFamily(universe?.id ?? '')
  );

  // Get the temporary stickers for the current universe
  const temporaryStickers = useRecoilValue(
    temporaryStickersFamily(universe?.id ?? '')
  );

  // Filter out the stickers that are being deleted
  const persistedStickersMap = useMemo(
    () =>
      new Map(
        universe?.stickers
          ? universe.stickers
              .filter((sticker) => !deletingStickers.has(sticker.id))
              .map((sticker) => [sticker.id, sticker])
          : []
      ),
    [deletingStickers, universe?.stickers]
  );
  const persistedStickers = useMemo(
    () => Array.from(persistedStickersMap.values()),
    [persistedStickersMap]
  );

  // Filter the temporary stickers that are not already persisted nor deleted
  const notPersistedTemporaryStickers = useMemo(
    () =>
      temporaryStickers.filter(
        (temporarySticker) =>
          !persistedStickersMap.has(temporarySticker.id) &&
          !deletingStickers.has(temporarySticker.id)
      ),
    [deletingStickers, persistedStickersMap, temporaryStickers]
  );

  // Patch the persisted stickers with the temporary sticker files if needed.
  // We have to do this because linking a sticker to a file is not instant, and
  // the db will first return the sticker without its file
  temporaryStickers.forEach((temporarySticker) => {
    const persistedSticker = persistedStickersMap.get(temporarySticker.id);
    if (persistedSticker?.type === 'file' && temporarySticker.type === 'file') {
      const { v, files } = temporarySticker;
      const temporaryStickerHasFiles = files?.length;
      const persistedStickerHasFiles = persistedSticker.files?.length;
      const tempVersionHigherOrEqual = (v ?? 0) >= (persistedSticker.v ?? 0);
      if (
        temporaryStickerHasFiles &&
        !persistedStickerHasFiles &&
        tempVersionHigherOrEqual
      ) {
        persistedSticker.files = files;
      }
    }
  });

  // The stickers to display are the persisted (not deleting) plus the temporary
  // stickers that were not persisted yet
  const stickers = useMemo(
    () => [...persistedStickers, ...notPersistedTemporaryStickers],
    [notPersistedTemporaryStickers, persistedStickers]
  );

  return useMemo(
    () => ({
      // The stickers that are being deleted
      deletingStickers,
      // The stickers in the database that are not being deleted
      persistedStickers,
      // The temporary stickers (including those that were already persisted to the db)
      temporaryStickers,
      // The temporary stickers that are not persisted to the db
      notPersistedTemporaryStickers,
      // The displayable stickers
      stickers,
    }),
    [
      deletingStickers,
      notPersistedTemporaryStickers,
      persistedStickers,
      stickers,
      temporaryStickers,
    ]
  );
};
