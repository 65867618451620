import React, { ComponentProps } from 'react';

import styles from '@/components/EditDock/components/EditDockItem.module.css';

export type EditDockItemProps = {
  active?: boolean;
} & ComponentProps<'button'>;

export const EditDockItem = React.forwardRef<
  HTMLButtonElement,
  EditDockItemProps
>(({ children, active, className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={`${styles.editDockItem} ${className ?? ''}`}
      data-state={active ? 'active' : undefined}
      {...props}
    >
      {children}
    </button>
  );
});

EditDockItem.displayName = 'EditDockItem';
