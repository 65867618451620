import { useUser } from '@/context/User/useUser';
import { db } from '@/db/databaseInit';
import { UniverseInvite } from '@/utils/kosmik/universe';

export const useUniverseInvites = () => {
  const user = useUser();
  const { data } = db.useQuery(
    user
      ? {
          universe_invite: {
            $: {
              where: {
                'receiver.id': user.id,
              },
            },
            receiver: {},
            sender: {},
            universes: {},
          },
        }
      : null
  );

  const universeInvites: UniverseInvite[] = data ? data.universe_invite : [];

  return { universeInvites };
};
