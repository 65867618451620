import { Button } from '@lithium/spectrum';
import { useNavigate } from '@tanstack/react-router';
import { CSSProperties } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';

export const LoginButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ to: '/login' });
  };

  return (
    <Button
      style={{ WebkitAppRegion: 'no-drag' } as CSSProperties}
      onAction={handleClick}
    >
      <TextLabelRegular>Login</TextLabelRegular>
    </Button>
  );
};
