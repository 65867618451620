import { z } from 'zod';

export const ExtractData = z.object({
  cropTop: z.number(),
  cropLeft: z.number(),
  cropBottom: z.number(),
  cropRight: z.number(),
  zoomScale: z.number().optional(),
  visiblePages: z.number().array().optional(),
});

export type ExtractData = z.infer<typeof ExtractData>;
