import { MutableRefObject } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { getPdfViewerHtmlPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/utils/getPdfViewerHtmlPage';

/**
 * Selects all the text in the given page when pressing mod+a
 */
export const useSelectPdfText = (
  scrollingAreaRef: MutableRefObject<HTMLDivElement | null>,
  currentPage: number
) => {
  useHotkeys(
    ['mod+a'],
    () => {
      const viewerDiv = scrollingAreaRef.current;
      if (!viewerDiv) {
        return;
      }
      const pageToSelect = getPdfViewerHtmlPage(viewerDiv, currentPage);
      if (pageToSelect) {
        const range = new Range();

        range.setStart(pageToSelect, 0);
        range.setEnd(pageToSelect, pageToSelect.childElementCount);

        document.getSelection()?.removeAllRanges();
        document.getSelection()?.addRange(range);
      }
    },
    { preventDefault: true }
  );
};
