import { useLayoutEffect, useRef } from 'react';

import { useGeneratePdfExtract } from '@/components/HTMLCanvas/PinPanel/Extract/hooks/useGeneratePdfExtract';
import { HtmlLoadingSticker } from '@/components/HTMLCanvas/stickers/HtmlLoadingSticker';
import { useDelayedCameraZ } from '@/hooks/camera/useDelayedCameraZ';
import { usePdf } from '@/hooks/pdf/usePdf';
import { ExtractData } from '@/utils/kosmik/stickers/extract';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';

export const HtmlPdfExtractSticker = ({
  sticker,
  extract,
}: {
  sticker: KosmikFileSticker;
  extract: ExtractData;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cameraZ = useDelayedCameraZ(containerRef);
  const file = sticker.files?.[0];
  const { cached_url } = file ?? sticker.attributes;
  const { pdf } = usePdf(cached_url);
  const canvas = useGeneratePdfExtract({
    pdf,
    extractData: extract,
    width: sticker.width,
    cameraZ,
  });

  useLayoutEffect(() => {
    const container = containerRef.current;

    if (container && canvas) {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      container.appendChild(canvas);

      return () => {
        container.removeChild(canvas);
      };
    }
  }, [canvas]);

  return (
    <HtmlLoadingSticker
      ref={containerRef}
      stickerId={sticker.id}
      isLoading={!cached_url}
    ></HtmlLoadingSticker>
  );
};
