import * as TabsPrimitive from '@radix-ui/react-tabs';
import { forwardRef } from 'react';

import styles from '@/components/ui/Tabs/Trigger/TabsTrigger.module.css';

export const TabsTrigger = forwardRef<
  HTMLButtonElement,
  TabsPrimitive.TabsTriggerProps
>(({ children, ...props }, ref) => {
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={props.className ?? styles.tabsTriggerItem}
      asChild
      {...props}
    >
      {children}
    </TabsPrimitive.Trigger>
  );
});

TabsTrigger.displayName = 'TabsTrigger';
