import equal from 'fast-deep-equal/es6';
import { useCallback, useContext } from 'react';
import { useRecoilCallback } from 'recoil';

import {
  isMultiSelectionAtom,
  isSingleSelectionAtom,
  selectedStickerIdsAtom,
} from '@/atoms/selection';
import {
  stickerPinnedStateFamily,
  stickerSelectionStateFamily,
} from '@/atoms/sticker';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { getIsUniverseMember } from '@/utils/universe/universe';

/**
 * A highly performant collection of Hooks to surgically update client-side sticker selection state.
 *
 * `stickerSelectionStateFamily(id)` is the selection state of a single sticker
 * `selectedStickersAtom` is the overview of all selected stickers
 *
 * For performance reasons we want to be able to atomically update the stickerSelectionStateFamily(id)
 * through recoil. We still need to keep track of all the currently selected stickers which is done
 * with the `selectedStickersAtom`.
 */
export const useStickerSelection = () => {
  const user = useContext(MultiplayerUserContext);
  const universe = useUniverseContext();

  /**
   * Recoil transaction to batch update both sticker state and selectedStickers state
   */
  const setNewSelection = useRecoilCallback(
    ({ transact_UNSTABLE }) => {
      return (
        selected: Set<string> | ((currVal: Set<string>) => Set<string>)
      ) => {
        if (user && universe) {
          transact_UNSTABLE(({ get, set }) => {
            set(selectedStickerIdsAtom, (prev) => {
              // Compute final selection
              const result =
                typeof selected === 'function' ? selected(prev) : selected;

              // Avoid setting a new identity if nothing change
              if (equal(result, prev)) {
                return prev;
              }

              // Exclude multiplayer selected, if selected by any universe member,
              // otherwise, allow multi-selection for read-only users
              if (universe.isUniverseMember) {
                result.forEach((id) => {
                  const selectionState = get(stickerSelectionStateFamily(id));
                  const pinnedState = get(stickerPinnedStateFamily(id));
                  const isRemoteSelected =
                    selectionState.size && !selectionState.has(user);
                  const isRemotePinned =
                    pinnedState.size && !pinnedState.has(user);
                  if (isRemoteSelected || isRemotePinned) {
                    const selectedByUniverseMembers = [
                      ...selectionState,
                      ...pinnedState,
                    ].some((user) => getIsUniverseMember(user, universe));
                    if (selectedByUniverseMembers) {
                      result.delete(id);
                    }
                  }
                });
              }

              // Compute added ids
              const added = new Set<string>();
              result.forEach((id) => {
                if (!prev.has(id)) {
                  added.add(id);
                }
              });

              // Compute removed ids
              const removed = new Set<string>();
              prev.forEach((id) => {
                if (!result.has(id)) {
                  removed.add(id);
                }
              });

              // Update added / removed selection states
              added.forEach((id) => {
                set(stickerSelectionStateFamily(id), (prev) => {
                  if (prev.has(user)) {
                    return prev;
                  } else {
                    const newSelection = new Set(prev);
                    newSelection.add(user);
                    return newSelection;
                  }
                });
              });

              removed.forEach((id) => {
                set(stickerSelectionStateFamily(id), (prev) => {
                  if (prev.has(user)) {
                    const newSelection = new Set(prev);
                    newSelection.delete(user);
                    return newSelection;
                  } else {
                    return prev;
                  }
                });
              });

              // Update single and multi selection atom
              set(isSingleSelectionAtom, result.size === 1);
              set(isMultiSelectionAtom, result.size > 1);

              // Update selected stickers with result
              return result;
            });
          });
        }
      };
    },
    [user, universe]
  );

  /**
   * Utility to select a single sticker
   */
  const select = useCallback(
    (id: string) => {
      setNewSelection(new Set([id]));
    },
    [setNewSelection]
  );

  /**
   * Utility to unselect stickers.
   * If no argument is passed unselects all stickers
   * If argument of ids is passed, unselect only those stickers
   *
   * Note: this callback doesn't use setNewSelection to avoid
   *       changing identity when the universe changes. We need
   *       a stable identity for this function, so it's not executed
   *       when the universe stickers change for example, otherwise
   *       the stickers would be deselected as soon as they are moved
   */
  const unselect = useRecoilCallback(
    ({ set }) =>
      (ids?: Set<string>) => {
        set(selectedStickerIdsAtom, (prev) => {
          let removed: Set<string>;
          let newSelected = new Set<string>();
          if (!ids) {
            removed = prev;
          } else {
            removed = ids;
            newSelected = new Set(prev);
            ids.forEach((id) => newSelected.delete(id));
          }
          // Update multiplayer selection state
          removed.forEach((id) => {
            set(stickerSelectionStateFamily(id), (prev) => {
              if (user && prev.has(user)) {
                const newSelection = new Set(prev);
                newSelection.delete(user);
                return newSelection;
              } else {
                return prev;
              }
            });
          });
          // Return updated selected ids
          return newSelected;
        });
      },
    [user]
  );

  return {
    select,
    unselect,
    setNewSelection,
  };
};
