import * as SliderPrimitive from '@radix-ui/react-slider';

import styles from './Slider.module.css';

export const Slider = ({
  className,
  ...props
}: SliderPrimitive.SliderProps) => {
  return (
    <SliderPrimitive.Root
      className={`${styles.sliderRoot} ${className ?? ''}`}
      {...props}
    >
      <SliderPrimitive.Track className={styles.sliderTrack}>
        <SliderPrimitive.Range className={styles.slideRange} />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className={styles.sliderThumb} />
    </SliderPrimitive.Root>
  );
};
