import { id, tx } from '@instantdb/react';

import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { Camera, getCenterPoint } from '@/utils/camera/camera';
import { batchTransact } from '@/utils/db/db';
import { KosmikPromptSticker } from '@/utils/kosmik/stickers/prompt';
import { MaybeUndefined } from '@/utils/types';

export interface CreatePromptStickerProps {
  universeId: MaybeUndefined<string>;
  isShiftKeyPressed: boolean;
  camera: Camera;
}

export const createPromptSticker = ({
  universeId,
  isShiftKeyPressed,
  camera,
}: CreatePromptStickerProps): KosmikPromptSticker[] => {
  if (!universeId) {
    return [];
  }
  const txs = [];
  const size = DEFAULT_STICKER_SIZE.prompt;
  const created_at = new Date().toISOString();
  const amount = isShiftKeyPressed ? 100 : 1;
  const stickers: KosmikPromptSticker[] = [];

  for (let i = 0; i < amount; i++) {
    const stickerId = id();
    const centerOfScreen = getCenterPoint(camera);

    const position = {
      x: centerOfScreen.x - size.width / 2,
      y: centerOfScreen.y - size.height / 2,
    };

    const point = {
      x: position.x + (50 + size.width) * (i % 10),
      y: position.y + (50 + size.height) * (Math.floor(i / 10) % 10),
    };

    const sticker: Omit<KosmikPromptSticker, 'id'> = {
      v: 0,
      created_at: created_at,
      type: 'prompt',
      x: point.x,
      y: point.y,
      width: size.width,
      height: 10,
      resizeable: false,
      attributes: {},
    };

    txs.push(
      tx.stickers?.[stickerId]
        ?.update(sticker)
        .link({ universes: [universeId] })
    );
    stickers.push({ id: stickerId, ...sticker });
  }

  batchTransact(txs);

  return stickers;
};
