import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { useEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

import { resolveCSSVariable } from '@/utils/css/variable';
import { isDefined } from '@/utils/isDefined';
import { MaybeNull } from '@/utils/types';

import styles from './DialogContent.module.css';

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  DialogPrimitive.DialogContentProps
>(({ children, className, ...props }, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const mergedRef = mergeRefs([innerRef, ref]);
  const lastWidthRef = useRef<MaybeNull<number>>(null);
  const lastHeightRef = useRef<MaybeNull<number>>(null);

  useEffect(() => {
    const element = innerRef.current;
    if (!element) {
      return;
    }
    const observer = new MutationObserver(() => {
      const element = innerRef.current;
      if (element) {
        const width = element.offsetWidth;
        const height = element.offsetHeight;
        const lastWidth = lastWidthRef.current;
        const lastHeight = lastHeightRef.current;
        const keyframes: PropertyIndexedKeyframes = {};
        if (isDefined(lastWidth) && width !== lastWidth) {
          keyframes.width = [`${lastWidth}px`, `${width}px`];
        }
        if (isDefined(lastHeight) && height !== lastHeight) {
          keyframes.height = [`${lastHeight}px`, `${height}px`];
        }
        if (Object.keys(keyframes).length) {
          element.animate(keyframes, {
            duration: 200,
            easing: resolveCSSVariable('--ease-transition'),
          });
        }
        lastWidthRef.current = width;
        lastHeightRef.current = height;
      }
    });
    observer.observe(element, { childList: true });
    lastWidthRef.current = element.offsetWidth;
    lastHeightRef.current = element.offsetHeight;

    return () => {
      observer.disconnect();
    };
  }, [children]);

  useEffect(() => {
    const handleResize = () => {
      const element = innerRef.current;
      if (!element) {
        return;
      }
      lastWidthRef.current = element.offsetWidth;
      lastHeightRef.current = element.offsetHeight;
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DialogPrimitive.Content
      ref={mergedRef}
      className={[styles.dialogContent, className].join(' ')}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  );
});

DialogContent.displayName = 'DialogContent';
