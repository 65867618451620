import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';

import { Text } from '@/components/fontSystem/Text';

export const TextBodySemibold = forwardRef(
  (
    props: ComponentPropsWithoutRef<'span'>,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    return <Text ref={ref} variant={'body-semibold'} {...props} />;
  }
);

TextBodySemibold.displayName = 'TextBodySemibold';
