import { Notification } from '@lithium/spectrum';

import styles from '@/components/ui/Notifications/NotificationsButton.module.css';
import { useNotifications } from '@/context/Notifications/useNotifications';
import { Tooltip } from '@/ui/Tooltip/Tooltip';

import iconStyles from '../TransparentIcon.module.css';

export const NotificationsButton = () => {
  const { visibility, setVisibility, notifications } = useNotifications();

  const handleClick = () => {
    if (notifications.length === 0) {
      return;
    }
    setVisibility(!visibility);
  };

  // TODO: replace with TopBar's ItemButton @mat
  return (
    <Tooltip content={'Notifications'}>
      <div
        onClick={handleClick}
        className={`${styles.wrapper} ${visibility ? styles.active : null}`}
        data-testid={'notifications-button'}
      >
        {notifications.length > 0 ? (
          <div className={styles.counter}>{notifications.length}</div>
        ) : null}
        <Notification
          className={`${iconStyles.transparency} icon icon-transition`}
        />
      </div>
    </Tooltip>
  );
};
