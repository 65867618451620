import * as Watson from '@/utils/research/watson.ts';

interface Tag {
  tag_name: string;
  relevance_score: number;
}

export interface DocumentDescription {
  tags: Tag[];
  summary: string;
  notes: string;
}

interface AnalyzerResponse {
  analyzerResponse: {
    document_description: DocumentDescription;
  };
  usageMetadata: {
    promptTokenCount: number;
    candidatesTokenCount: number;
    totalTokenCount: number;
  };
}

/**
 * Analyze a document with provided tags
 */
export async function analyzeDocument(
  file: File | undefined,
  url: string | undefined,
  refreshToken: string
): Promise<AnalyzerResponse> {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  if (url) {
    formData.append('url', url);
  }

  const response = await fetch(
    `${import.meta.env.VITE_GENERATIVE_URL}/analyze`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any
  );

  return response.json();
}

export const analyzeAndSave = (
  stickerId: string,
  file: File | undefined,
  url: string | undefined,
  universeId: string,
  username: string,
  refreshToken: string
) => {
  return analyzeDocument(file, url, refreshToken).then(async (response) => {
    console.log('response', response);
    await Watson.ingest(
      stickerId,
      universeId,
      username,
      '',
      response.analyzerResponse.document_description,
      refreshToken
    );

    return response;
  });
};
