import * as SwitchPrimitive from '@radix-ui/react-switch';
import { ReactNode } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/ui/Switch/LabelSwitch.module.css';
import { Switch } from '@/ui/Switch/Switch';

interface PublishSwitchProps extends SwitchPrimitive.SwitchProps {
  children: ReactNode;
}

export const LabelSwitch = ({ children, ...props }: PublishSwitchProps) => {
  return (
    <div className={styles.row}>
      <TextLabelRegular>{children}</TextLabelRegular>
      <Switch {...props} />
    </div>
  );
};
