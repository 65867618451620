import { ForwardedRef, forwardRef, HTMLAttributes } from 'react';

import { Text } from '@/components/fontSystem/Text';

export const TextHeader2 = forwardRef(
  (
    props: HTMLAttributes<HTMLHeadingElement>,
    ref: ForwardedRef<HTMLHeadingElement>
  ) => {
    return <Text ref={ref} variant={'header-2'} {...props} />;
  }
);

TextHeader2.displayName = 'TextHeader2';
