import { id, tx } from '@instantdb/react';

import { batchTransact } from '@/utils/db/db';
import { KosmikTeam } from '@/utils/kosmik/team';
import { KosmikUser } from '@/utils/kosmik/user';

export type CreateTeamProps = {
  name: string;
  color?: string;
  owner: KosmikUser;
  users?: KosmikUser[];
};

/**
 * Creates a new team with the specified name and associated users.
 */
export const createTeam = async ({
  name,
  color,
  owner,
  users = [],
}: CreateTeamProps): Promise<KosmikTeam> => {
  const teamId = id();
  const team: Omit<
    KosmikTeam,
    'id' | 'users' | 'universes' | 'team_ownerships'
  > = {
    name: name,
    color: color,
    created_at: new Date().toISOString(),
  };
  const usersWithoutOwner = users.filter((user) => user.id !== owner.id);
  const userIds = usersWithoutOwner.map((user) => user.id);
  await batchTransact([
    tx.teams?.[teamId]?.update(team).link({ users: userIds }),
    tx.team_ownerships?.[teamId]
      ?.update({})
      .link({ users: [owner.id], teams: [teamId] }),
  ]);
  return { id: teamId, ...team, universes: [], users };
};
