import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { EditDockField } from '@/components/EditDock/components/EditDockField';
import styles from '@/components/EditDock/PdfEditDock/PageInfo.module.css';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import cssUtils from '@/utils/css/cssUtils.module.css';

export type PdfPageInfoProps = {
  page: number;
  maxPage?: number;
  onPageChange: (page: number) => void;
};

/**
 * Renders a PDF page information component that allows the user to navigate between pages.
 * The component displays the current page number and the total number of pages, and provides an input field
 * for the user to enter a new page number. When the user submits the new page number, the `onPageChange`
 * callback is called with the new page number.
 *
 * @param props.page - The current page number.
 * @param props.maxPage - The total number of pages.
 * @param props.onPageChange - A callback function that is called when the user changes the page.
 */
export const PdfPageInfo = ({
  page,
  maxPage,
  onPageChange,
}: PdfPageInfoProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputPage, setInputPage] = useState<number>(page);

  useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setInputPage(page);
    }
  }, [page]);

  useLayoutEffect(() => {
    const input = inputRef.current;
    if (!maxPage) {
      return;
    }
    if (input) {
      const hiddenMeasureContainer = document.createElement('span');
      const hiddenMeasureText = document.createElement('span');
      hiddenMeasureContainer.className = cssUtils.hideVisually;
      hiddenMeasureText.textContent = inputPage?.toString() ?? null;
      hiddenMeasureContainer.appendChild(hiddenMeasureText);
      input.insertAdjacentElement('afterend', hiddenMeasureContainer);
      const width = hiddenMeasureText.offsetWidth;
      input.style.width = `${width}px`;
      hiddenMeasureContainer.remove();
    }
  }, [inputPage, maxPage]);

  const isLoading = !maxPage;

  return (
    <EditDockField
      data-testid={'pdf-edit-dock-page-info'}
      style={{ width: 100 }}
      onClick={() => inputRef.current?.focus()}
    >
      <TextLabelRegular
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {isLoading ? (
          'Loading...'
        ) : (
          <form
            className={styles.form}
            onSubmit={(event) => {
              event.preventDefault();
              const form = event.currentTarget;
              const formData = new FormData(form);
              const pageNumber = Number(formData.get('page-number'));
              if (!isNaN(pageNumber)) {
                const newPageNumber = Math.max(
                  1,
                  Math.min(pageNumber, maxPage)
                );
                onPageChange(newPageNumber);
                setTimeout(() => inputRef.current?.blur());
              }
            }}
          >
            <input
              className={styles.pageNumberInput}
              ref={inputRef}
              name={'page-number'}
              type={'number'}
              inputMode={'numeric'}
              value={inputPage ?? ''}
              onChange={(event) => {
                const newValue = event.currentTarget.valueAsNumber;
                setInputPage((prev) => (isNaN(newValue) ? prev : newValue));
              }}
              onBlur={() => {
                setInputPage(page);
              }}
              maxLength={maxPage ? maxPage.toString().length : undefined}
            />
            {'/ '}
            {maxPage}
          </form>
        )}
      </TextLabelRegular>
    </EditDockField>
  );
};
