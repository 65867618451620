import React, { useLayoutEffect } from 'react';

import { DATA_ATTRIBUTE_SELECTED } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPreviewPage';
import { getPdfViewerHtmlPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/utils/getPdfViewerHtmlPage';
import { getScrollInfo } from '@/utils/scroll/getScrollInfo';

/**
 * Mark the current preview page as selected and scroll it into view if necessary
 * @param containerRef
 * @param currentPage
 */
export const useSelectedPreviewPage = (
  containerRef: React.RefObject<HTMLDivElement>,
  currentPage: number
) => {
  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const dataAttribute = `data-${DATA_ATTRIBUTE_SELECTED}`;
    const selectedPages = container.querySelectorAll(`[${dataAttribute}]`);
    selectedPages?.forEach((selectedPage) =>
      selectedPage.removeAttribute(dataAttribute)
    );
    const pageContainer = getPdfViewerHtmlPage(container, currentPage);
    pageContainer?.setAttribute(dataAttribute, '');

    if (pageContainer instanceof HTMLElement) {
      const { isBefore, isAfter } = getScrollInfo(pageContainer, container);

      if (isBefore || isAfter) {
        pageContainer.scrollIntoView(isBefore);
      }
      pageContainer.focus();
    }
  }, [currentPage, containerRef]);
};
