import { CSSProperties, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { followingAtom } from '@/atoms/multiplayer';
import { deletingStickersFamily } from '@/atoms/sticker';
import { UniverseEditDock } from '@/components/EditDock/UniverseEditDock/UniverseEditDock';
import { CanvasContextMenu } from '@/components/HTMLCanvas/CanvasContextMenu/CanvasContextMenu';
import { CanvasView } from '@/components/HTMLCanvas/CanvasView';
import { PinPanel } from '@/components/HTMLCanvas/PinPanel/PinPanel';
import { HtmlAnyStickerView } from '@/components/HTMLCanvas/stickers/HtmlAnyStickerView';
import { EmptyUniverseView } from '@/components/placeholders/EmptyUniverseView';
import { SelectionLayer } from '@/components/Universe/Selection/SelectionLayer';
import { UniverseContextActionsWrapper } from '@/components/Universe/UniverseContextActionsWrapper';
import { RoomContext } from '@/context/Room/RoomContext';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { db } from '@/db/databaseInit';
import { useAddRemoveTemporaryStickers } from '@/hooks/sticker/useAddRemoveTemporaryStickers';
import { useUniverseStickers } from '@/hooks/universe/useUniverseStickers';
import styles from '@/routes/UniverseId.module.css';
import { KosmikUniverse } from '@/utils/kosmik/universe';

export type LoadedUniverseProps = {
  universe: KosmikUniverse;
  isUniverseMember: boolean;
  isPublic?: boolean;
};

export const LoadedUniverseStage = ({
  universe,
  isUniverseMember,
  isPublic,
}: LoadedUniverseProps) => {
  const room = db.room('universe-room', universe.id);
  const following = useRecoilValue(followingAtom);
  const { peers } = room.usePresence({
    user: false,
    keys: ['user'],
    peers: following ? [following.instantPeerId] : [],
  });
  const followingColor =
    following && peers[following.instantPeerId]?.user?.color;

  const { temporaryStickers, notPersistedTemporaryStickers, stickers } =
    useUniverseStickers(universe);
  const setDeletingStickers = useSetRecoilState(
    deletingStickersFamily(universe.id)
  );
  const { addTemporaryStickers, removeTemporaryStickers } =
    useAddRemoveTemporaryStickers(universe.id);

  // Clean up the temporary stickers that were already persisted
  useEffect(() => {
    if (temporaryStickers.length !== notPersistedTemporaryStickers.length) {
      const temporaryStickerIds = temporaryStickers.map(
        (sticker) => sticker.id
      );
      const notPersistedIds = new Set(
        notPersistedTemporaryStickers.map((sticker) => sticker.id)
      );
      const alreadyPersistedIds = temporaryStickerIds.filter(
        (id) => !notPersistedIds.has(id)
      );
      removeTemporaryStickers(alreadyPersistedIds);
    }
  }, [
    notPersistedTemporaryStickers,
    removeTemporaryStickers,
    temporaryStickers,
  ]);

  room.useTopicEffect('addTemporaryStickers', (stickers) => {
    addTemporaryStickers(stickers);
  });

  room.useTopicEffect('removeTemporaryStickers', (stickerIds) => {
    removeTemporaryStickers(stickerIds);
  });

  room.useTopicEffect('deleteStickers', (stickerIds) => {
    setDeletingStickers((prev) => {
      return new Set([...prev, ...stickerIds]);
    });
  });

  const universeContext = useMemo(
    () => ({
      ...universe,
      isUniverseMember: isUniverseMember,
    }),
    [universe, isUniverseMember]
  );

  const universeContent = useMemo(
    () =>
      stickers.length ? (
        stickers.map((sticker) => (
          <HtmlAnyStickerView key={sticker.id} sticker={sticker} />
        ))
      ) : (
        <EmptyUniverseView />
      ),
    [stickers]
  );

  return (
    <UniverseContext.Provider value={universeContext}>
      <RoomContext.Provider value={{ room, universeId: universe.id }}>
        <UniverseContextActionsWrapper isPublic={isPublic}>
          <CanvasContextMenu type={'contextmenu'}>
            <CanvasView universe={universe}>
              {universeContent}
              <SelectionLayer stickers={stickers} universeId={universe.id} />
            </CanvasView>
          </CanvasContextMenu>
        </UniverseContextActionsWrapper>
      </RoomContext.Provider>
      <PinPanel />

      {isUniverseMember ? (
        <div className={styles.dock}>
          <UniverseEditDock />
        </div>
      ) : null}
      <div
        className={styles.followingIndicator}
        style={
          followingColor
            ? ({
                ['--following-color']: followingColor,
              } as CSSProperties)
            : undefined
        }
      />
    </UniverseContext.Provider>
  );
};
