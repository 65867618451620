import { RefObject, useCallback, useEffect, useState } from 'react';

export const usePointerPosition = ({
  ref,
}: {
  ref: RefObject<HTMLElement>;
}) => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const updateMousePosition = useCallback((event: PointerEvent) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  }, []);

  useEffect(() => {
    const refElement = ref.current;

    refElement?.addEventListener('pointermove', updateMousePosition, {
      capture: true,
      passive: true,
    });
    return () => {
      refElement?.removeEventListener('pointermove', updateMousePosition, {
        capture: true,
      });
    };
  }, [ref, updateMousePosition]);
  return mousePosition;
};
