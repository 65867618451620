import { RefObject, useCallback, useLayoutEffect } from 'react';

import { closeOpenMenuAndDialog } from '@/utils/menu/menu';

export const useWheel = (
  ref: RefObject<HTMLDivElement>,
  callback: (event: WheelEvent) => void
) => {
  const handleWheel = useCallback(
    (event: WheelEvent) => {
      callback(event);
      document.dispatchEvent(new Event('scroll'));
      closeOpenMenuAndDialog();
    },
    [callback]
  );

  useLayoutEffect(() => {
    const elm = ref.current;
    if (!elm) {
      return;
    }

    const opt: AddEventListenerOptions = { passive: false };
    elm.addEventListener('wheel', handleWheel, opt);

    return () => {
      elm.removeEventListener('wheel', handleWheel, opt);
    };
  }, [handleWheel, ref]);
};
