import { RefObject, useEffect, useRef } from 'react';

import { getCurrentCameraValues, screenToCanvas } from '@/utils/camera/camera';

export const usePointerPositionRef = ({
  ref,
}: {
  ref?: RefObject<HTMLElement>;
} = {}) => {
  const pointerPosition = useRef({
    x: 0,
    y: 0,
    camera: {
      x: 0,
      y: 0,
    },
  });

  useEffect(() => {
    const refElement = ref ? ref.current : (window as Window);
    const updateMousePosition = (event: PointerEvent) => {
      const point = { x: event.clientX, y: event.clientY };
      pointerPosition.current = {
        ...point,
        camera: screenToCanvas(point, getCurrentCameraValues()),
      };
    };

    refElement?.addEventListener(
      'pointermove',
      updateMousePosition as EventListener,
      { capture: true, passive: true }
    );
    return () => {
      refElement?.removeEventListener(
        'pointermove',
        updateMousePosition as EventListener,
        { capture: true }
      );
    };
  }, [ref]);
  return pointerPosition;
};
