import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { temporaryStickersFamily } from '@/atoms/sticker';
import { KosmikSticker } from '@/utils/kosmik/sticker';

export const useAddRemoveTemporaryStickers = (universeId: string) => {
  const setTemporaryStickers = useSetRecoilState(
    temporaryStickersFamily(universeId)
  );

  /**
   * Add given stickers to the temporary stickers,
   * remember to publish the temporary stickers if needed
   */
  const addTemporaryStickers = useCallback(
    (newStickers: KosmikSticker[]) =>
      setTemporaryStickers((prev) => {
        const map = new Map(prev.map((prevItem) => [prevItem.id, prevItem]));
        newStickers.forEach((sticker) => {
          map.set(sticker.id, sticker);
        });
        return Array.from(map.values());
      }),
    [setTemporaryStickers]
  );

  /**
   * Removes the given stickers from the temporary stickers
   * remember to publish the removed temporary stickers if needed
   */
  const removeTemporaryStickers = useCallback(
    (removedStickerIds: string[]) =>
      setTemporaryStickers((prev) => {
        const map = new Map(prev.map((prevItem) => [prevItem.id, prevItem]));
        removedStickerIds.forEach((stickerId) => {
          map.delete(stickerId);
        });
        return Array.from(map.values());
      }),
    [setTemporaryStickers]
  );

  return { addTemporaryStickers, removeTemporaryStickers };
};
