import { atom } from 'recoil';

import { Dimension } from '@/utils/geometry/dimension';

/**
 * Holds the ids of selected stickers
 */
export const selectedStickerIdsAtom = atom<Set<string>>({
  key: 'selectedStickersAtom',
  default: new Set(),
});

/**
 * Holds the user's selection area rect
 */
export const selectionAreaAtom = atom<Dimension | null>({
  key: 'selectionAreaAtom',
  default: null,
});

/**
 * True if the selection contains only a single sticker
 */
export const isSingleSelectionAtom = atom<boolean>({
  key: 'isSingleSelectionAtom',
  default: false,
});

/**
 * True if the selection contains more than one sticker
 */
export const isMultiSelectionAtom = atom<boolean>({
  key: 'isMultiSelectionAtom',
  default: false,
});

/**
 * True if the user is currently drawing a selection rect
 */
export const isDrawingSelectionAtom = atom<boolean>({
  key: 'isDrawingSelectionAtom',
  default: false,
});
