import { ProgressInfo, UpdateCheckResult, UpdateInfo } from 'electron-updater';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { z } from 'zod';

import { version } from '@/../package.json';
import { useNotifications } from '@/context/Notifications/useNotifications';

export interface VersionInfo {
  update: boolean;
  version: string;
  newVersion?: string;
}

export const ErrorType = z.object({
  message: z.string(),
  error: z.unknown(),
});

export type ErrorType = z.infer<typeof ErrorType>;

// The update modal has 4 Main states
// - Checking for updates
// - Update available
// - Downloading update
// - Currently up to date
// - Update Error
export enum UpdateState {
  checking,
  updateAvailable,
  downloading,
  readyToInstall,
  upToDate,
  error,
}

export interface NotificationContent {
  cancelText?: string;
  okText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  title: string;
  content: ReactNode;
}

export const useUpdates = () => {
  const { dismissNotification } = useNotifications();
  const [status, setStatus] = useState<UpdateState>(UpdateState.checking);
  const [updateInfo, setUpdateInfo] = useState<UpdateInfo>();
  const [updateError, setUpdateError] = useState<ErrorType>();
  const [progressInfo, setProgressInfo] = useState<Partial<ProgressInfo>>();

  const onUpdateError = useCallback(
    (_event: Electron.IpcRendererEvent, arg1: ErrorType) => {
      setUpdateError(arg1);
      setStatus(UpdateState.error);
    },
    []
  );

  const onDownloadProgress = useCallback(
    (_event: Electron.IpcRendererEvent, arg1: ProgressInfo) => {
      setProgressInfo(arg1);
      setStatus(UpdateState.downloading);
    },
    []
  );

  const onUpdateDownloaded = useCallback(
    (_event: Electron.IpcRendererEvent) => {
      setProgressInfo({ percent: 100 });
      setStatus(UpdateState.readyToInstall);
    },
    []
  );

  const checkUpdates = useCallback(async () => {
    setStatus(UpdateState.checking);
    const result: UpdateCheckResult | { message: string; error: unknown } =
      await window.electron?.ipcRenderer.invoke('check-update-and-notify');

    const parsedError = ErrorType.safeParse(result);
    if (parsedError.success) {
      console.error(parsedError.data);
      setUpdateError(parsedError.data);
      setStatus(UpdateState.error);
      return;
    }

    const typedResult = result as UpdateCheckResult;
    const updateVersion = typedResult?.updateInfo?.version;

    // we have version info
    if (updateVersion) {
      // if we are already up-to-date
      if (updateVersion === version) {
        setUpdateInfo(typedResult?.updateInfo);
        setStatus(UpdateState.upToDate);
        return;
      }

      // if we have a version mismatch, we got a new version available
      if (updateVersion !== version) {
        console.info(`Update available from ${version} to ${updateVersion}`);
        setUpdateInfo(typedResult?.updateInfo);
        setStatus(UpdateState.updateAvailable);
        return;
      }
    }
  }, []);

  useEffect(() => {
    // Get version information and whether to update
    window.electron?.ipcRenderer.on('update-error', onUpdateError);
    window.electron?.ipcRenderer.on('download-progress', onDownloadProgress);
    window.electron?.ipcRenderer.on('update-downloaded', onUpdateDownloaded);

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('update-error');
      window.electron?.ipcRenderer.removeAllListeners('download-progress');
      window.electron?.ipcRenderer.removeAllListeners('update-downloaded');
    };
  }, [onDownloadProgress, onUpdateDownloaded, onUpdateError]);

  return {
    checkUpdates,
    status,
    progressInfo,
    setProgressInfo,
    versionInfo: updateInfo,
    updateError,
    dismissNotification,
  };
};
