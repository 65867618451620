import { Ellipse, getEllipsePointAt } from '@/utils/geometry/ellipse';
import { Position } from '@/utils/geometry/position';
import { vector } from '@/utils/geometry/vector';

/**
 * Rotate a given point around a given center by the specified amount of radians
 * @param point
 * @param center
 * @param radians
 * @returns
 */
export const rotate = (point: Position, center: Position, radians: number) => {
  const cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (point.x - center.x) + sin * (point.y - center.y) + center.x,
    ny = cos * (point.y - center.y) - sin * (point.x - center.x) + center.y;
  return { x: nx, y: ny };
};

/**
 * Rotate around an ellipse, the optional point can be outside the ellipse
 * if you don't specify a point, it returns the point on the ellipse at the
 * given radians angle
 * @param ellipse
 * @param radians
 * @param point optional the point to rotate around the ellipse
 */
export const rotateAroundEllipse = (
  ellipse: Ellipse,
  radians: number,
  point: Position | undefined = undefined
) => {
  if (point) {
    const vector1 = vector(ellipse.center, point);
    const t = vector1.angle.radians / (2 * Math.PI);
    const intersectionPoint = getEllipsePointAt(t, ellipse);
    const vector2 = vector(ellipse.center, intersectionPoint);
    const vector3 = vector(intersectionPoint, point);
    const isInsideEllipse = vector1.mag < vector2.mag;
    const sign = isInsideEllipse ? -1 : 1;
    const newEllipse = {
      center: ellipse.center,
      rx: Math.max(0, ellipse.rx + sign * vector3.mag),
      ry: Math.max(0, ellipse.ry + sign * vector3.mag),
      rotation: ellipse.rotation,
    };
    const t2 = t + radians / (2 * Math.PI);
    return getEllipsePointAt(t2, newEllipse);
  } else {
    return getEllipsePointAt(radians / (Math.PI * 2), ellipse);
  }
};
