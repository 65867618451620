import { Box } from '@/utils/camera/camera';
import { Dimension } from '@/utils/geometry/dimension';

export type Rectangle = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

/**
 * Converts a Rectangle to a Dimension object.
 * @param rectangle The input Rectangle
 * @returns A Dimension object representing the same area
 */
export const rectangleToDimension = (rectangle: Rectangle): Dimension => {
  const minX = Math.min(rectangle.x1, rectangle.x2);
  const minY = Math.min(rectangle.y1, rectangle.y2);
  const maxX = Math.max(rectangle.x1, rectangle.x2);
  const maxY = Math.max(rectangle.y1, rectangle.y2);

  return {
    x: minX,
    y: minY,
    width: Math.abs(maxX - minX),
    height: Math.abs(maxY - minY),
  };
};

/**
 * Converts a Rectangle to a Box object.
 * @param rectangle The input Rectangle
 * @returns A Box object representing the same area
 */
export const rectangleToBox = (rectangle: Rectangle): Box => {
  const dimension = rectangleToDimension(rectangle);
  return {
    minX: Math.min(rectangle.x1, rectangle.x2),
    minY: Math.min(rectangle.y1, rectangle.y2),
    maxX: Math.max(rectangle.x1, rectangle.x2),
    maxY: Math.max(rectangle.y1, rectangle.y2),
    width: dimension.width,
    height: dimension.height,
  };
};

/**
 * Normalizes a rectangle by ensuring width and height are positive.
 * @param rect The input Dimension object
 * @returns A new Dimension object with positive width and height
 */
export const normalizeRect = (rect: Dimension) => {
  const newRect = { ...rect };

  if (rect.width < 0) {
    newRect.x = rect.x + rect.width;
    newRect.width = -rect.width;
  }
  if (rect.height < 0) {
    newRect.y = rect.y + rect.height;
    newRect.height = -rect.height;
  }

  return newRect;
};
