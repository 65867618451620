import { Camera } from '@/utils/camera/camera';

/**
 * Return the position relative to the universe of the top left corner of the universe's window.
 * @param camera
 * @param leftOffset
 * @param topOffset
 */
export const getTopLeftPosition = (
  camera: Camera,
  leftOffset: number,
  topOffset: number
) => {
  return {
    x: -camera.x + leftOffset / camera.z,
    y: -camera.y + topOffset / camera.z,
  };
};
