import * as TabsPrimitive from '@radix-ui/react-tabs';
import React from 'react';

import styles from '@/components/ui/Tabs/Content/TabsContent.module.css';

export const TabsContent = React.forwardRef<
  HTMLDivElement,
  TabsPrimitive.TabsContentProps
>(({ children, ...props }, ref) => {
  return (
    <TabsPrimitive.Content
      className={styles.tabsContentItem}
      asChild
      {...props}
    >
      <div className={styles.tabsContentChildren} ref={ref}>
        {children}
      </div>
    </TabsPrimitive.Content>
  );
});

TabsContent.displayName = 'TabsContent';
