import { useEffect, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  isPinPanelFullscreenAtom,
  pinPanelActiveToolAtom,
} from '@/atoms/pinpanel';
import styles from '@/components/HTMLCanvas/PinPanel/PinPanelContent.module.css';
import { StickerContent } from '@/components/HTMLCanvas/PinPanel/StickerContent';
import {
  RESIZE_VARIABLE,
  useHorizontalResize,
} from '@/hooks/useHorizontalResize';
import { DragHandle } from '@/ui/Resizer/DragHandle';
import { ScrollArea } from '@/ui/ScrollArea/ScrollArea';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { isElectron } from '@/utils/platform/isElectron';
import { isPdfSticker, isVideoSticker } from '@/utils/sticker/type';

interface PinPanelContentProps {
  sticker: KosmikSticker;
}

export const PinPanelContent = ({ sticker }: PinPanelContentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleDragHandlePointerDown = useHorizontalResize(
    ref,
    RESIZE_VARIABLE.pinpanel
  );
  const setPinPanelActiveTool = useSetRecoilState(pinPanelActiveToolAtom);
  const [isPinPanelFullscreen, setIsPinPanelFullscreen] = useRecoilState(
    isPinPanelFullscreenAtom
  );

  // reset pin panel size and active tool when sticker changes
  useEffect(() => {
    ref.current?.style.removeProperty(RESIZE_VARIABLE.pinpanel);
    setPinPanelActiveTool(null);
    // effect called when pin panel is opened or when a new sticker is opened in pin panel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sticker.id]);

  // reset pin panel fullscreen mode when pin panel is closed
  useEffect(() => {
    return () => {
      setIsPinPanelFullscreen(false);
    };
    // effect called when pin panel is closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const needScrollArea =
    !isPdfSticker(sticker) &&
    !isVideoSticker(sticker) &&
    !(isElectron && sticker.type === 'web');

  return (
    <div
      ref={ref}
      className={styles.pinpanelContent}
      style={{
        width: isPinPanelFullscreen ? '100%' : undefined,
      }}
    >
      {needScrollArea ? (
        <ScrollArea type={'hover'}>
          <StickerContent sticker={sticker} />
        </ScrollArea>
      ) : (
        <StickerContent sticker={sticker} />
      )}
      <DragHandle onPointerDown={handleDragHandlePointerDown} />
    </div>
  );
};
