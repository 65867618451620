import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';

import styles from './Tag.module.css';

export type TagProps = {
  text: string;
  color?: string;
};

export const Tag = ({ text, color = 'var(--color-quinary)' }: TagProps) => {
  return (
    <div className={styles.badge} style={{ backgroundColor: color }}>
      <TextLabelRegular>{text}</TextLabelRegular>
    </div>
  );
};
