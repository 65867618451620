import { z } from 'zod';

import { KosmikStickerBase } from '@/utils/kosmik/stickers/base';
import { StickerType } from '@/utils/kosmik/stickers/type';

export const StickyStickerAttributes = z.object({
  text_content: z.string().optional(),
  fill_color: z.string().optional(),
  text_color: z.string().optional(),
});

export type StickyStickerAttributes = z.infer<typeof StickyStickerAttributes>;

export const KosmikStickySticker = z.intersection(
  KosmikStickerBase,
  z.object({
    type: z.literal(StickerType.enum.sticky),
    attributes: StickyStickerAttributes,
  })
);

export type KosmikStickySticker = z.infer<typeof KosmikStickySticker>;
