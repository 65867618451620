import { InstantReactRoom } from '@instantdb/react/dist/InstantReact';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { stickerSelectionStateFamily } from '@/atoms/sticker';
import { compare } from '@/components/selection/compare';
import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { AppSchema, RoomsSchema } from '@/db/databaseInit';
import { findUserInMultiplayerUserSet } from '@/utils/multiplayer/multiplayerUserSet';
import { MaybeNull } from '@/utils/types';

interface MultiplayerStickerSelectionProps {
  peerId: string;
  room: InstantReactRoom<AppSchema, RoomsSchema, 'universe-room'>;
}

export const useMultiplayerStickerSelection = ({
  peerId,
  room,
}: MultiplayerStickerSelectionProps) => {
  const [selectedStickers, setSelectedStickers] = useState<string[]>([]);
  const lastPeerUserRef = useRef<MaybeNull<MultiplayerUser>>(null);

  const { peers, isLoading } = room.usePresence({
    keys: ['user', 'selections'],
    peers: [peerId],
    user: false,
  });

  // Sets and removes selection on stickers
  const setSelectionTransact = useRecoilCallback(({ transact_UNSTABLE }) => {
    return (user: MultiplayerUser, added: string[], removed: string[]) => {
      transact_UNSTABLE(({ set }) => {
        removed.forEach((id) => {
          set(stickerSelectionStateFamily(id), (prev) => {
            const selectionUser = findUserInMultiplayerUserSet(prev, user);
            if (selectionUser) {
              const newSelection = new Set(prev);
              newSelection.delete(selectionUser);
              return newSelection;
            } else {
              return prev;
            }
          });
        });

        added.forEach((id) => {
          set(stickerSelectionStateFamily(id), (prev) => {
            const selectionUser = findUserInMultiplayerUserSet(prev, user);
            if (selectionUser) {
              return prev;
            } else {
              const newSelection = new Set(prev);
              newSelection.add(user);
              return newSelection;
            }
          });
        });
      });
    };
  }, []);

  const setNewSelection = useCallback(
    (user: MultiplayerUser, newSelections: string[]) => {
      // avoid re-renders
      if (compare(selectedStickers, newSelections)) {
        return;
      }

      const added: string[] = [];
      const removed: string[] = [];
      selectedStickers.forEach(function (item) {
        if (newSelections.indexOf(item) == -1) {
          removed.push(item);
        }
      });
      newSelections.forEach(function (item) {
        if (selectedStickers.indexOf(item) == -1) {
          added.push(item);
        }
      });
      setSelectedStickers(newSelections);
      setSelectionTransact(user, added, removed);
    },
    [selectedStickers, setSelectionTransact]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const peer = Object.values(peers)[0];

    if (peer?.user && peer?.selections) {
      lastPeerUserRef.current = peer.user;
      setNewSelection(peer.user, peer.selections);
    } else if (lastPeerUserRef.current) {
      setNewSelection(lastPeerUserRef.current, []);
    }
  }, [isLoading, peerId, peers, setNewSelection]);

  return { setNewSelection };
};
