import { useEffect, useState } from 'react';

import { createExtractImage } from '@/components/HTMLCanvas/PinPanel/Extract/utils/createExtractImage';
import { createHtmlImage } from '@/utils/image';
import { ExtractData } from '@/utils/kosmik/stickers/extract';
import { MaybeUndefined } from '@/utils/types';

export type GenerateImageExtractProps = {
  imageUrl: MaybeUndefined<string>;
  extractData: ExtractData;
};

export const useGenerateImageExtract = ({
  imageUrl,
  extractData,
}: GenerateImageExtractProps) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | undefined>();

  useEffect(() => {
    const loadImage = async () => {
      if (!imageUrl) {
        return;
      }
      const canvas = document.createElement('canvas');
      const image = await createHtmlImage(imageUrl, false, true);
      canvas.width = image.data.width;
      canvas.height = image.data.height;
      const context = canvas.getContext('2d');
      context?.drawImage(image.data, 0, 0);
      const newCanvas = createExtractImage(canvas, extractData);
      if (newCanvas && newCanvas.width !== 0 && newCanvas.height !== 0) {
        setCanvas(newCanvas);
      }
    };

    loadImage();
  }, [extractData, imageUrl]);

  return canvas;
};
