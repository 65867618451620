import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { modalStackAtom } from '@/atoms/modal';
import { ModalKey, ModalProps } from '@/components/modal/specs/MODAL_SPECS';

export const useAddModal = () => {
  const setModalsStack = useSetRecoilState(modalStackAtom);

  return useCallback(
    <T extends ModalKey>(
      ...args: keyof ModalProps<T> extends never ? [T] : [T, ModalProps<T>]
    ) => {
      const [type, props] = args;
      setModalsStack((prev) => {
        return [...prev, { type: type, props: props }];
      });
    },
    [setModalsStack]
  );
};
