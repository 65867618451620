import { useParams } from '@tanstack/react-router';

import { db } from '@/db/databaseInit';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import { MaybeUndefined } from '@/utils/types';

/**
 * Hook to fetch the universe data based on an ID.
 */
export const useUniverse = (id: MaybeUndefined<string>) => {
  const { isLoading, error, data } = db.useQuery(
    id
      ? {
          universes: {
            stickers: {
              files: {
                stickers: {},
              },
            },
            users: {},
            teams: {
              users: {},
              team_ownerships: {
                users: {},
              },
            },
            $: {
              where: {
                id: id,
              },
            },
          },
        }
      : null
  );
  const universe: KosmikUniverse | undefined = data?.universes?.[0];

  // When fetching a new universe, we still return the previous universe until
  // the data has loaded, but the isLoading flag is set to false, this flag
  // is designed to help with that. If we have a loaded universe but it's not
  // matching the requested id, then we'll force isLoading to be true.
  const isCurrentUniverseStale = universe && universe.id !== id;
  return { isLoading: isLoading || isCurrentUniverseStale, error, universe };
};

/**
 * Retrieves the current universe based on the universe ID from the URL parameters.
 */
export const useCurrentUniverse = () => {
  const { universeId } = useParams({ strict: false });
  return useUniverse(universeId);
};
