import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import { Toolbar } from '@/components/Toolbar/Toolbar';
import { UniverseStageWrapper } from '@/components/Universe/UniverseStageWrapper';
import {
  MultiplayerUser,
  MultiplayerUserContext,
} from '@/context/User/MultiplayerUserContext';
import styles from '@/routes/UniverseId.module.css';
import { getContrastColor, stringToHslColor } from '@/utils/color/colors';

const PublicUniverse = () => {
  const anonymousMultiplayerUser: MultiplayerUser = useMemo(() => {
    const randomId = Math.round((Math.random() + 1) * 100_000_000).toString(36);
    const anonymousEmail = `anonymous+${randomId}@kosmik.app`;
    const color = stringToHslColor(anonymousEmail);
    const contrastColor = getContrastColor(color);
    return {
      id: uuidv4(),
      email: anonymousEmail,
      name: `Anonymous-${randomId}`,
      color,
      contrastColor,
      peerId: uuidv4(),
      anonymous: true,
    };
  }, []);

  return (
    <MultiplayerUserContext.Provider value={anonymousMultiplayerUser}>
      <div className={styles.windowLayoutGrid}>
        <div className={styles.mainArea}>
          <div className={styles.toolbarArea}>
            <Toolbar />
          </div>
          <div className={styles.canvasArea}>
            <UniverseStageWrapper isPublic={true} />
          </div>
        </div>
      </div>
    </MultiplayerUserContext.Provider>
  );
};

const publicUniverseIdSearch = z.object({
  sticker: z.string().uuid().optional(),
});

export const Route = createFileRoute('/public/$universeId')({
  validateSearch: zodSearchValidator(publicUniverseIdSearch),
  beforeLoad: ({ context, params }) => {
    if (context.authenticated) {
      throw redirect({ to: '/$universeId', params });
    }
  },
  component: PublicUniverse,
});
