import { id } from '@instantdb/react';
import { StickerWeb } from '@lithium/spectrum';
import { useParams } from '@tanstack/react-router';
import { useRecoilTransaction_UNSTABLE } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { usePreferences } from '@/context/Preferences/usePreferences';
import { useSetPinnedSticker } from '@/hooks/pinPanel/useSetPinnedSticker';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';
import { SEARCH_ENGINE_SPEC, SearchEngine } from '@/utils/web/search';

export const CreateWebStickerMenuItem = ({ type }: { type: MenuType }) => {
  const { setPinnedSticker } = useSetPinnedSticker();
  const { universeId } = useParams({ strict: false });
  const preferences = usePreferences();

  const pinNewWebSticker = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (sticker: KosmikWebSticker) => {
        set(localStickerFamily(sticker.id), sticker);
        setPinnedSticker(sticker.id);
      },
    [setPinnedSticker]
  );

  const handleClick = async () => {
    if (!universeId) {
      return;
    }

    const sticker: KosmikWebSticker = {
      id: id(),
      v: 0,
      x: 0,
      y: 0,
      width: DEFAULT_STICKER_SIZE.web.width,
      height: DEFAULT_STICKER_SIZE.web.height,
      type: 'web',
      created_at: new Date().toISOString(),
      attributes: {
        url:
          preferences.homepage ||
          SEARCH_ENGINE_SPEC[
            preferences.search_engine ?? SearchEngine.enum.google
          ].url,
      },
    };

    pinNewWebSticker(sticker);
  };

  const { Item } = getPolymorphicMenuComponents(type);

  return (
    <Item onClick={handleClick} prefix={<StickerWeb />}>
      Web
    </Item>
  );
};
