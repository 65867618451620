import { useMemo } from 'react';

import { Dimension } from '@/utils/geometry/dimension';

import styles from './ExtractButton.module.css';

export type ExtractButtonProps = {
  extractRef: React.RefObject<HTMLDivElement>;
  boundingBox: Dimension;
  onExtract: () => void;
};

export const ExtractButton = ({
  extractRef,
  boundingBox,
  onExtract,
}: ExtractButtonProps) => {
  const style = useMemo(() => {
    const parent = extractRef.current?.parentElement;
    if (!parent) {
      return undefined;
    }

    let top = 'calc(100% + 7px)';
    let left = 'calc(100% - 60px)';

    if (boundingBox.y + boundingBox.height + 37 > parent.clientHeight) {
      if (boundingBox.y - 37 < 0) {
        top = '7px';
        left = '7px';
      } else {
        top = '-37px';
        left = '0px';
      }
    }

    return {
      top,
      left,
    };
  }, [boundingBox.height, boundingBox.y, extractRef]);

  return (
    <button
      className={styles.extractButton}
      style={style}
      onClick={onExtract}
      onPointerDown={(e) => e.stopPropagation()}
      onPointerUp={(e) => e.stopPropagation()}
    >
      Extract
    </button>
  );
};
