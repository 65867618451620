import { Close, StickerUniverse } from '@lithium/spectrum';
import { Link } from '@tanstack/react-router';
import {
  forwardRef,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { useSetRecoilState } from 'recoil';

import { universeTabsAtom } from '@/atoms/universe';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/components/SidePanel/UniverseListItem.module.css';
import { useFeature } from '@/hooks/useFeature';
import { useIsUniverseMember } from '@/hooks/useIsUniverseMember';
import { Tooltip } from '@/ui/Tooltip/Tooltip';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import { MaybeNull } from '@/utils/types';

interface UniverseButtonProps extends HTMLAttributes<HTMLButtonElement> {
  universe: KosmikUniverse;
  tooltip?: string;
  suffix?: ReactNode;
}

export const UniverseButton = forwardRef<
  HTMLAnchorElement,
  UniverseButtonProps
>(({ universe, tooltip, suffix }, ref) => {
  const { flags } = useFeature();
  const setUniverseTabs = useSetRecoilState(universeTabsAtom);
  const isUniverseMember = useIsUniverseMember(universe);

  const closeButton = useRef<MaybeNull<SVGSVGElement>>(null);

  const handleTabClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (event.target === closeButton.current) {
        return;
      }

      setUniverseTabs((old) => {
        const hasUniverseTab = old.find((oldUniverse) => {
          return oldUniverse.id === universe.id;
        });

        if (hasUniverseTab) {
          return old;
        } else {
          return [...old, universe];
        }
      });
    },
    [setUniverseTabs, universe]
  );

  const handleClose = () => {
    setUniverseTabs((old) => {
      return [...old].filter((oldUniverse) => oldUniverse.id !== universe.id);
    });
  };

  const button = (
    <Tooltip content={'Right click to show universe menu'}>
      <span>
        <Link
          ref={ref}
          className={styles.cell}
          role={'button'}
          to={isUniverseMember ? '/$universeId' : '/public/$universeId'}
          data-testid={'universe-menu-button'}
          params={{ universeId: universe.id }}
          onClick={handleTabClick}
        >
          <span className={styles.leading}>
            <StickerUniverse className={styles.icon} />
            <TextLabelRegular className={styles.label}>
              {universe?.name || 'Loading...'}
            </TextLabelRegular>
          </span>
          {suffix}

          {flags?.['Universe tabs'] ? (
            <Close
              ref={closeButton}
              className={styles.closeButton}
              onClick={handleClose}
            />
          ) : null}
        </Link>
      </span>
    </Tooltip>
  );

  return tooltip ? <Tooltip content={tooltip}>{button}</Tooltip> : button;
});

UniverseButton.displayName = 'UniverseButton';
