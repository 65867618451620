import { z } from 'zod';

import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';

export const BaseKosmikFile = z.object({
  id: z.string().uuid(),
  storage_path: z.string(),
  file_name: z.string(),
  cached_url: z.string(),
  expires_at: z.string(),
  type: z.string(),
  file_size: z.number().optional(), // it's only optional for legacy compat, might be removed later on
});

export type KosmikFile = z.infer<typeof BaseKosmikFile> & {
  stickers?: KosmikFileSticker[];
};

export const KosmikFile: z.ZodType<KosmikFile> = BaseKosmikFile.extend({
  stickers: z.lazy(() => KosmikFileSticker.array().optional()),
});
