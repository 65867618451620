import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { isDefined } from '@/utils/isDefined';
import { MaybeUndefined } from '@/utils/types';

export type UsePdfPageProps = {
  pdf: MaybeUndefined<PDFDocumentProxy>;
  pageNumber: number;
};

export const usePdfPage = ({ pdf, pageNumber }: UsePdfPageProps) => {
  const { data } = useSWR<PDFPageProxy[]>(pdf);
  const [pdfPage, setPdfPage] = useState<PDFPageProxy>();

  useEffect(() => {
    if (data) {
      setPdfPage(data[pageNumber - 1]);
    } else if (pdf) {
      pdf.getPage(pageNumber).then((page) => {
        setPdfPage(page);
      });
    }
  }, [data, pageNumber, pdf]);

  return pdfPage;
};

export type UsePdfPagesProps = {
  pdf: MaybeUndefined<PDFDocumentProxy>;
  pages: number[];
};

export const usePdfPages = ({ pdf, pages }: UsePdfPagesProps) => {
  const { data } = useSWR<PDFPageProxy[]>(pdf);
  const [pdfPages, setPdfPages] = useState<PDFPageProxy[]>([]);

  useEffect(() => {
    if (data) {
      setPdfPages(pages.map((pageIndex) => data[pageIndex]).filter(isDefined));
    } else if (pdf) {
      Promise.all(pages.map((pageIndex) => pdf.getPage(pageIndex + 1))).then(
        (pages) => {
          setPdfPages(pages);
        }
      );
    }
  }, [data, pages, pdf]);

  return pdfPages;
};
