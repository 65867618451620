/**
 * Inverts the values of the given object's numeric properties.
 *
 * This function takes an object with string keys and numeric values,
 * and returns a new object with the same keys but with each value negated.
 */
export const invertObjectValues = <T extends { [key: string]: number }>(
  obj: T
): T => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, -value])
  ) as T;
};
