export function getWeekNumber(date: Date): number {
  // Copy the date object to avoid modifying the original date
  const copiedDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );

  // Set the day to the nearest Thursday (current date + 4 - current day number, mod 7)
  copiedDate.setUTCDate(
    copiedDate.getUTCDate() + 4 - (copiedDate.getUTCDay() || 7)
  );

  // Calculate the first day of the year
  const yearStart = new Date(Date.UTC(copiedDate.getUTCFullYear(), 0, 1));

  // Calculate the week number
  return Math.ceil(
    ((copiedDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
}
