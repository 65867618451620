/**
 * Returns a number whose value is limited to the given range.
 *
 * @example: limit the output of this computation to between 0 and 255
 *   `const clampedValue = clamp(0, x * 255, 255)`
 */
export const clamp = (min: number, value: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

/**
 * Map a number from a given range to another given range
 * @param value the value to map
 * @param low1 the minimum value in the original range
 * @param high1 the maximum value in the original range
 * @param low2 the minimum value in the mapped range
 * @param high2 the maximum value in the mapped range
 */
export const mapToRange = (
  value: number,
  low1: number,
  high1: number,
  low2: number,
  high2: number
) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
};

/**
 * Returns a random integer within the given interval
 * @param min
 * @param max
 */
export const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Return number or default value, handling, false, null, undefined and NaN input correctly
 * @param value
 * @param fallback
 */
export const getNumber = (
  value: number | string | boolean | null | undefined,
  fallback: number = 0
): number => {
  if (typeof value === 'number' && !isNaN(value)) {
    return value;
  }

  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    if (!isNaN(parsed)) {
      return parsed;
    }
  }

  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }

  return fallback;
};

/**
 * Check if a given input is a number, or a string number
 */
export const isNumberOrStringNumber = (num: unknown) => {
  if (typeof num === 'number') {
    return num - num === 0;
  }
  if (typeof num === 'string' && num.trim() !== '') {
    return Number.isFinite ? Number.isFinite(+num) : isFinite(+num);
  }
  return false;
};
