import { Options, useHotkeys } from 'react-hotkeys-hook';
import { mergeRefs } from 'react-merge-refs';

import { useSetPinnedSticker } from '@/hooks/pinPanel/useSetPinnedSticker';
import { useCreateLocalWebSticker } from '@/hooks/sticker/create/useCreateLocalWebSticker';
import { isInputLike } from '@/utils/element/isInputLike';
import { isWeb } from '@/utils/platform/isWeb';

export type UseStickerCreateContextActionShortcutsProps = {
  ignoreEventWhen?: Options['ignoreEventWhen'];
};

export const useStickerCreateContextActionShortcuts = ({
  ignoreEventWhen = () => false,
}: UseStickerCreateContextActionShortcutsProps) => {
  const { setPinnedSticker } = useSetPinnedSticker();
  const createLocalWebSticker = useCreateLocalWebSticker();

  const createWebStickerRef = useHotkeys(
    'w',
    () => {
      const newWebStickerId = createLocalWebSticker();
      if (newWebStickerId) {
        setPinnedSticker(newWebStickerId);
      }
    },
    {
      preventDefault: true,
      ignoreEventWhen: (event) =>
        isInputLike(document.activeElement) || isWeb || ignoreEventWhen(event),
    }
  );

  return mergeRefs([createWebStickerRef]);
};
