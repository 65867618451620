import { useParams } from '@tanstack/react-router';
import { useCallback } from 'react';

import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { getCurrentCameraValues } from '@/utils/camera/camera';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikShapeSticker } from '@/utils/kosmik/stickers/shape';
import { createShapeSticker } from '@/utils/sticker/create/createShapeSticker';
import { undoableDuplicateStickers } from '@/utils/sticker/sticker';

interface UseCreateDefaultShapeStickerProps {
  shape: KosmikShapeSticker['attributes']['shape'];
}

export const useCreateDefaultShapeSticker = ({
  shape,
}: UseCreateDefaultShapeStickerProps) => {
  const { universeId } = useParams({ strict: false });
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const { deleteStickers } = useDeleteStickers();
  const pushKPI = usePushKPI();

  const handleCreateDefaultShape = useCallback(
    async (isShiftKeyPressed = false) => {
      if (!universeId) {
        return;
      }
      const camera = getCurrentCameraValues();
      let createdStickers: KosmikSticker[] = createShapeSticker({
        camera,
        universeId,
        shape,
        isShiftKeyPressed,
      });
      pushKPI({
        type: 'create',
        properties: {
          interaction: 'click',
          coType: 'shape',
          coFormat: shape,
          coSize: 0,
          coUniverse: universeId,
        },
      });
      const createAction: UndoRedoActionType = {
        do: () => {
          createdStickers = undoableDuplicateStickers(
            createdStickers,
            universeId
          );
        },
        undo: () => {
          deleteStickers(createdStickers);
        },
      };

      addUndoRedoAction(createAction);
    },
    [addUndoRedoAction, deleteStickers, pushKPI, shape, universeId]
  );

  return { handleCreateDefaultShape };
};
