import { atom } from 'recoil';

import { KPI } from '@/utils/kpi/types';

/**
 * The stack of kpis to be sent when the user is connected (because we need its refresh token)
 */
export const kpiStackAtom = atom<KPI[]>({
  key: 'kpiStack',
  default: [],
});
