import { Button, ButtonShortcut } from '@lithium/spectrum';
import { FormEvent, useState } from 'react';

import { TextHeader3 } from '@/components/fontSystem/Header3';
import { CommonModalProps } from '@/components/modal/Modals';
import { useUser } from '@/context/User/useUser';
import { db } from '@/db/databaseInit';
import {
  DialogClose,
  DialogDescription,
  DialogTitle,
} from '@/ui/Dialog/Dialog';
import { DialogFooter } from '@/ui/Dialog/DialogFooter';
import { KosmikTeam } from '@/utils/kosmik/team';
import { deleteUser } from '@/utils/user/delete';

export type DeleteUserProps = CommonModalProps & {};

const flattenUserData = (data: { teams: KosmikTeam[] }) => {
  const teams = [];
  const universes = [];
  const stickers = [];

  for (const team of data?.teams ?? []) {
    teams.push(team.id);

    for (const universe of team?.universes ?? []) {
      universes.push(universe.id);

      for (const sticker of universe?.stickers ?? []) {
        stickers.push(sticker);
      }
    }
  }
  return { teams, universes, stickers };
};

export const DeleteUser = ({ closeModal }: DeleteUserProps) => {
  const user = useUser();
  const [email, setEmail] = useState('');
  const { data, isLoading, error } = db.useQuery(
    user?.id
      ? {
          teams: {
            $: {
              where: {
                id: user?.id,
              },
            },
            universes: {
              stickers: {
                files: {},
              },
            },
          },
        }
      : null
  );

  const finishedLoading = !!data && !isLoading && !error;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');

    if (finishedLoading && email === user?.email) {
      const { teams, universes, stickers } = flattenUserData(data);

      const preferenceIds =
        user.preferences?.map((preference) => preference.id) ?? [];

      await deleteUser(user.id, teams, preferenceIds, universes, stickers);
      closeModal(true);
      await db.auth.signOut();
    }
  };

  return (
    <div style={{ minWidth: '300px', maxWidth: '400px' }}>
      <DialogTitle asChild>
        <TextHeader3>Delete User</TextHeader3>
      </DialogTitle>
      <DialogDescription>
        Are you sure you want to delete your account?
        <br /> <br />
        All your universes, files and account information will be permanently
        erased. Workspaces, multiplayer universes and published content you own
        will not be available anymore. <br /> <br /> Type in your email address
        to confirm.
      </DialogDescription>
      <form onSubmit={handleSubmit}>
        <input
          type={'email'}
          name={'email'}
          placeholder={'user@example.com...'}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button shortcut={ButtonShortcut.Esc}>Cancel</Button>
          </DialogClose>
          <Button
            variant={'destructive'}
            shortcut={ButtonShortcut.Enter}
            disabled={!(finishedLoading && email === user?.email)}
          >
            Delete
          </Button>
        </DialogFooter>
      </form>
    </div>
  );
};
