import { Dimension, dimensionToRectangle } from '@/utils/geometry/dimension';
import { Rectangle, rectangleToDimension } from '@/utils/geometry/rectangle';

/**
 * Get overall bounding box for a given array of bounding boxes
 * @param boundingBoxes
 */
export const getBoundingBox = (boundingBoxes: Dimension[]) => {
  const boundingRectangle = boundingBoxes.reduce(
    (acc: Rectangle | undefined, val) => {
      return acc
        ? {
            x1: Math.min(acc.x1, val.x),
            y1: Math.min(acc.y1, val.y),
            x2: Math.max(acc.x2, val.x + val.width),
            y2: Math.max(acc.y2, val.y + val.height),
          }
        : dimensionToRectangle(val);
    },
    undefined
  );

  return boundingRectangle
    ? rectangleToDimension(boundingRectangle)
    : undefined;
};
