import { Dimension } from 'src/utils/geometry/dimension';

export const extractPicture = (
  extractSelection: Dimension,
  contentDiv: HTMLDivElement,
  width: number,
  height: number
) => {
  const contentRect = contentDiv.getBoundingClientRect();
  const zoomScale = contentRect.width / width;

  contentRect.height = zoomScale * height;

  return {
    cropTop: extractSelection.y / contentRect.height,
    cropLeft: extractSelection.x / contentRect.width,
    cropBottom:
      1 - (extractSelection.y + extractSelection.height) / contentRect.height,
    cropRight:
      1 - (extractSelection.x + extractSelection.width) / contentRect.width,
    zoomScale,
  };
};
