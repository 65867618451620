import { Button } from '@lithium/spectrum';
import { ChangeEvent, useCallback, useState } from 'react';

import Alert from '@/assets/alert.svg?react';
import { TextBodySemibold } from '@/components/fontSystem/BodySemibold';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { CommonModalProps } from '@/components/modal/Modals';
import styles from '@/components/modal/modals/Settings/TeamSettings/contents/modals/DeleteTeam.module.css';
import { useTeam } from '@/context/Team/useTeam';
import { DialogClose } from '@/ui/Dialog/Dialog';
import { deleteTeam } from '@/utils/team/team';

const BUTTON_WIDTH = '138px';

export const DeleteTeam = ({ closeModal }: CommonModalProps) => {
  const { team } = useTeam();
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsValid(event.target.value === team.name);
    },
    [team.name]
  );

  const handleDelete = useCallback(async () => {
    deleteTeam(team);
    closeModal(true);
  }, [closeModal, team]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <TextBodySemibold>Delete workspace permanently?</TextBodySemibold>
      </div>
      <div className={styles.iconContainer}>
        <Alert />
      </div>
      <TextLabelRegular>
        This action cannot be undone. This will permanently delete the entire
        workspace.
      </TextLabelRegular>
      <TextLabelRegular>
        All universes and objects will be deleted. <br />
        All private universes will be deleted. <br />
        All shared universes will be deleted. <br />
        All members and guests will lose access to the workspace.
      </TextLabelRegular>
      <TextBodySemibold>
        Please type the workspace name to confirm.
      </TextBodySemibold>
      <input
        className={'label'}
        placeholder={team.name}
        onChange={handleChange}
      />
      <div className={styles.footerContainer}>
        <DialogClose asChild>
          <Button variant={'default'} style={{ width: BUTTON_WIDTH }}>
            Cancel
          </Button>
        </DialogClose>
        <Button
          disabled={!isValid}
          variant={'destructive'}
          onAction={handleDelete}
          style={{ width: BUTTON_WIDTH }}
        >
          Delete workspace
        </Button>
      </div>
    </div>
  );
};
