import { RefObject } from 'react';

import { useMultiplayerPresence } from '@/components/useMultiplayerPresence';

/**
 * Register multiplayer presence, we use a null component to isolate
 * the rest of the tree from the re-renders the useMultiplayerPresence
 * triggers when moving the mouse
 */
export const MultiplayerPresence = ({
  containerRef,
}: {
  containerRef: RefObject<HTMLDivElement>;
}) => {
  useMultiplayerPresence(containerRef);

  return null;
};
