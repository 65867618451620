import { PDFPageProxy, RenderTask } from 'pdfjs-dist';
import { MutableRefObject, RefCallback } from 'react';

import { getPageScaledViewport } from '@/hooks/pdf/utils/getPageScaledViewport';

interface PdfPageToCanvasProps {
  page: PDFPageProxy;
  canvas?: HTMLCanvasElement;
  size?: { width?: number; height?: number };
  scale?: number;
  pdfPromiseRef?: MutableRefObject<RenderTask | null> | RefCallback<RenderTask>;
}

export const pdfPageToCanvas = async ({
  page,
  canvas,
  size,
  scale,
  pdfPromiseRef,
}: PdfPageToCanvasProps) => {
  const _canvas = canvas || document.createElement('canvas');
  const { viewport } = getPageScaledViewport({ page, size, scale });

  if (viewport.width === 0 || viewport.height === 0) {
    return null;
  }

  _canvas.width = viewport.width;
  _canvas.height = viewport.height;

  const ctx = _canvas.getContext('2d');
  if (!ctx) {
    return null;
  }
  ctx.imageSmoothingEnabled = false;

  const promise = page.render({
    canvasContext: ctx,
    viewport: viewport,
  });

  if (pdfPromiseRef) {
    if (typeof pdfPromiseRef === 'function') {
      pdfPromiseRef(promise);
    } else {
      pdfPromiseRef.current = promise;
    }
  }

  return promise.promise.then(() => {
    return _canvas;
  });
};
