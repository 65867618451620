import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { Login } from '@/components/Onboarding/Login';

const loginParamsSchema = z.object({
  duplicateId: z.string().optional(),
});

export type loginParams = z.infer<typeof loginParamsSchema>;

export const Route = createFileRoute('/login')({
  validateSearch: loginParamsSchema,
  component: Login,
  beforeLoad: ({ context }) => {
    if (context.authenticated) {
      throw redirect({ to: '/' });
    }
  },
});
