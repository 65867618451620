import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import React from 'react';

import { ContextMenuSubContent } from '@/components/ui/ContextMenu/ContextMenuSubContent';

export interface ContextMenuSubProps
  extends ContextMenuPrimitive.ContextMenuProps,
    React.PropsWithChildren<{
      trigger: React.ReactNode;
      triggerAsChild?: boolean;
      contentProps?: ContextMenuPrimitive.ContextMenuSubContentProps;
    }> {}

export const ContextMenuSub = ({
  contentProps,
  trigger,
  triggerAsChild,
  children,
  ...props
}: ContextMenuSubProps) => (
  <ContextMenuPrimitive.Sub {...props}>
    <ContextMenuPrimitive.SubTrigger asChild={triggerAsChild}>
      {trigger}
    </ContextMenuPrimitive.SubTrigger>

    <ContextMenuPrimitive.Portal>
      <ContextMenuSubContent
        alignOffset={-5.5}
        collisionPadding={4}
        {...contentProps}
      >
        {children}
      </ContextMenuSubContent>
    </ContextMenuPrimitive.Portal>
  </ContextMenuPrimitive.Sub>
);
