import { useRecoilCallback } from 'recoil';

import { redoStackFamily, undoStackFamily } from '@/atoms/undoRedo';
import { useIsDev } from '@/hooks/env/useIsDev';
import { MaybeUndefined } from '@/utils/types';

export interface UndoRedoActionType {
  do: () => void;
  undo: () => void;
}

const MAX_STACK_SIZE = 30;

/**
 * A custom hook that provides undo and redo functionality for state management using Recoil.
 *
 * @param universeId - The unique identifier for the universe (scope) in which the undo/redo stacks are managed.
 * @return An object containing three methods: addUndoRedoAction, undo, and redo.
 *   - `addUndoRedoAction(action)`: Adds an action to the undo stack and clears the redo stack.
 *   - `undo()`: Undoes the last action, moving it from the undo stack to the redo stack.
 *   - `redo()`: Redoes the last undone action, moving it from the redo stack back to the undo stack.
 */
export function useUndoRedo(
  universeId: MaybeUndefined<string> = 'default-uuid-undo-redo'
) {
  const isDev = useIsDev();

  const addUndoRedoAction = useRecoilCallback(
    ({ set }) =>
      (action: UndoRedoActionType) => {
        set(undoStackFamily(universeId), (prevStack) => {
          const newStack = [...prevStack, action];
          if (newStack.length > MAX_STACK_SIZE) {
            return newStack.slice(newStack.length - MAX_STACK_SIZE);
          }
          return newStack;
        });
        set(redoStackFamily(universeId), []);
      },
    [universeId]
  );

  const undo = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const undoStack = snapshot.getLoadable(
          undoStackFamily(universeId)
        ).contents;
        const redoStack = snapshot.getLoadable(
          redoStackFamily(universeId)
        ).contents;

        if (undoStack.length > 0) {
          const lastAction = undoStack[undoStack.length - 1];
          if (isDev) {
            lastAction.undo();
          }

          set(undoStackFamily(universeId), undoStack.slice(0, -1));
          set(redoStackFamily(universeId), [...redoStack, lastAction]);
        }
      },
    [isDev, universeId]
  );

  const redo = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const undoStack = snapshot.getLoadable(
          undoStackFamily(universeId)
        ).contents;
        const redoStack = snapshot.getLoadable(
          redoStackFamily(universeId)
        ).contents;

        if (redoStack.length > 0) {
          const nextAction = redoStack[redoStack.length - 1];
          if (isDev) {
            nextAction.do();
          }

          set(undoStackFamily(universeId), [...undoStack, nextAction]);
          set(redoStackFamily(universeId), redoStack.slice(0, -1));
        }
      },
    [universeId, isDev]
  );

  return { addUndoRedoAction, undo, redo };
}
