import { Button, ButtonShortcut } from '@lithium/spectrum';
import { FormEvent } from 'react';

import { TextHeader3 } from '@/components/fontSystem/Header3';
import { CommonModalProps } from '@/components/modal/Modals';
import {
  DialogClose,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/Dialog/Dialog';
import { DialogFooter } from '@/components/ui/Dialog/DialogFooter';
import { useUniverseActions } from '@/utils/universe/hooks/useUniverseActions';

export type RenameUniverseProps = CommonModalProps & {
  universeId: string;
  universeName: string;
};

export const RenameUniverse = ({
  universeId,
  universeName,
  closeModal,
}: RenameUniverseProps) => {
  const { handleRenameUniverse } = useUniverseActions();
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const newName = formData.get('name');

    if (!newName || typeof newName !== 'string' || newName.length === 0) {
      return;
    }

    handleRenameUniverse(universeId, newName);
    closeModal();
  };

  return (
    <div style={{ minWidth: '300px' }}>
      <DialogTitle asChild>
        <TextHeader3>Rename {universeName}</TextHeader3>
      </DialogTitle>
      <DialogDescription>
        Type in a new name for your universe
      </DialogDescription>
      <form onSubmit={handleSubmit}>
        <input
          type={'text'}
          name={'name'}
          placeholder={'Enter a nice name for your universe'}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button shortcut={ButtonShortcut.Esc}>Cancel</Button>
          </DialogClose>
          <Button variant={'positive'} shortcut={ButtonShortcut.Enter}>
            Rename
          </Button>
        </DialogFooter>
      </form>
    </div>
  );
};
