import { z } from 'zod';

import { Dimension } from '@/utils/geometry/dimension';

export const KosmikStickerBase = z.intersection(
  z.object({
    id: z.string().uuid('The sticker id must be a uuid'),
    v: z.number().optional(),
    created_at: z.string(),
    resizeable: z.boolean().optional(),
    description: z.string().optional().nullable(),
    universes: z
      .array(
        z.object({
          id: z.string(),
          created_at: z.string(),
          name: z.string(),
          color: z.string().optional(),
          isPublished: z.boolean().optional(),
          isDuplicable: z.boolean().optional(),
        })
      )
      .optional(),
  }),
  Dimension
);

export type KosmikStickerBase = z.infer<typeof KosmikStickerBase>;
