import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Handles opening a color input's color picker when clicking on the related
 * opener. This is made so clicking on the opener a second time closes the
 * color picker, which isn't the native behavior
 * @param openerRef the ref for the button that will open the color picker
 * @param colorInputRef the ref of the color input tag
 */
export const useColorInputOpener = (
  openerRef: MutableRefObject<HTMLElement | null>,
  colorInputRef: MutableRefObject<HTMLInputElement | null>
) => {
  const isOpenRef = useRef(false);

  useEffect(() => {
    const opener = openerRef.current;
    const colorInput = colorInputRef.current;

    if (opener && colorInput) {
      const handleClick = () => {
        const isOpen = !isOpenRef.current;
        const input = colorInputRef.current;
        if (isOpen && input) {
          input.click();
          input.focus();
        }
        isOpenRef.current = isOpen;
      };

      const handlePointerDown = (event: MouseEvent) => {
        event.preventDefault();
      };

      const handleColorSwatchBlur = () => {
        isOpenRef.current = false;
      };

      opener?.addEventListener('click', handleClick);
      opener?.addEventListener('pointerdown', handlePointerDown);
      colorInput?.addEventListener('blur', handleColorSwatchBlur);

      return () => {
        opener?.removeEventListener('click', handleClick);
        opener?.removeEventListener('pointerdown', handlePointerDown);
        colorInput?.removeEventListener('blur', handleColorSwatchBlur);
      };
    }
  }, [colorInputRef, openerRef]);
};
