import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setRecoil } from 'recoil-nexus';

import { isPinPanelOpenedAtom, pinnedStickerIdAtom } from '@/atoms/pinpanel';
import { localStickerFamily } from '@/atoms/sticker';

export const useClosePinPanel = () => {
  const setIsPinPanelOpened = useSetRecoilState(isPinPanelOpenedAtom);
  const pinnedStickerId = useRecoilValue(pinnedStickerIdAtom);
  const localSticker = useRecoilValue(
    localStickerFamily(pinnedStickerId ?? '')
  );

  const handleClosePinPanel = useCallback(() => {
    if (localSticker && localSticker.type === 'web') {
      setRecoil(localStickerFamily(localSticker.id), null);
    }
    setIsPinPanelOpened(false);
  }, [localSticker, setIsPinPanelOpened]);

  return { handleClosePinPanel };
};
