import { id } from '@instantdb/react';

import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';

/**
 * Generates a unique storage path for a file.
 *
 * @param file - The file to generate a path for.
 * @param user - The user associated with the file.
 * @returns A string representing the storage path.
 *
 * @example
 * const file = new File(["content"], "example.txt", { type: "text/plain" });
 * const user = { id: "user123" };
 * console.log(getStoragePath(file, user)); // "user123/uniqueId-example.txt"
 */
export const getStoragePath = (file: File, user: MultiplayerUser) => {
  return `${user.id}/${id()}-${file.name.replace(/[\s\W]/g, '-')}`;
};

/**
 * Generates an expiration date 7 days from now.
 *
 * @returns An ISO string representing the expiration date.
 *
 * @example
 * console.log(generateExpiresAt()); // "2023-04-14T10:30:00.000Z" (if current date is 2023-04-07)
 */
export const generateExpiresAt = () => {
  return new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString();
};

/**
 * Downloads a file from a given URL using a proxy.
 *
 * @param url - The URL of the file to download.
 * @returns A Promise that resolves to a Blob of the downloaded file.
 *
 * @example
 * const fileBlob = await downloadFile('https://example.com/file.pdf');
 * // Use the fileBlob...
 */
export const downloadFile = async (url: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_META_PROXY}/proxy?url=${url}`,
    {
      method: 'GET',
    }
  );

  return response.blob();
};

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
