import { id, tx } from '@instantdb/react';
import { setRecoil } from 'recoil-nexus';

import { localStickerFamily } from '@/atoms/sticker';
import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { batchTransact } from '@/utils/db/db';
import { KosmikTeam } from '@/utils/kosmik/team';
import { KosmikUniverse } from '@/utils/kosmik/universe';
import { KosmikUser } from '@/utils/kosmik/user';
import { getCopyName } from '@/utils/name';
import {
  getCurrentFileLink,
  getTransactableStickerProperties,
} from '@/utils/sticker/sticker';
import { MaybeUndefined } from '@/utils/types';

/**
 * Deletes the given universe and all its stickers if present (make sure you
 * provide the result of a query that includes the stickers to actually delete
 * the stickers)
 * @param universe the universe to delete
 */
export const deleteUniverse = async (universe: KosmikUniverse) => {
  await batchTransact([
    ...(universe.stickers?.map((sticker) => {
      // clean up recoil state
      setRecoil(localStickerFamily(sticker.id), null);
      // return an instant delete transaction
      return tx.stickers?.[sticker.id]?.delete();
    }) ?? []),
    tx.universes?.[universe.id]?.delete(),
  ]);
};

/**
 * Update the public status of the given universe.
 * @param universe
 * @param isPublished
 * @param isDuplicable
 */
export const updatePublishUniverse = (
  universe: KosmikUniverse,
  isPublished: boolean,
  isDuplicable: boolean
) => {
  batchTransact(
    tx.universes?.[universe.id]?.update({
      isPublished,
      isDuplicable,
    })
  );
};

/**
 * Renames the universe that matches the given id
 * @param id the id of the universe to be renamed
 * @param newName the new name for the universe
 */
export const renameUniverse = (id: string, newName: string) => {
  batchTransact(tx.universes?.[id]?.update({ name: newName }));
};

/**
 * Create and send an invite for the given universe
 * @param universe the universe to invite to
 * @param invitingUserId the inviting (sender) user's id
 * @param invitedUserId the invited (recipient) user's id
 */
export const inviteToUniverse = (
  universe: KosmikUniverse,
  invitingUserId: string,
  invitedUserId: string
) => {
  // Create invite linking currentUser and invitedUser
  batchTransact(
    tx.universe_invite?.[id()]
      ?.update({
        created_at: new Date().toISOString(),
      })
      .link({ universes: [universe.id] })
      .link({ sender: [invitingUserId] })
      .link({ receiver: [invitedUserId] })
  );
};

/**
 * Updates the universe's color that matches the given id.
 * @param id
 * @param newColor
 */
export const updateColorUniverse = (id: string, newColor: string) => {
  batchTransact(tx.universes?.[id]?.update({ color: newColor }));
};

/**
 * Duplicates the given universe and all its stickers if present (make sure you
 * provide the result of a query that includes the stickers to actually duplicate
 * all the stickers). It will be linked to the team on this universe and will
 * exclude the external users who were invited to the original universe
 *
 * @param universe the universe to duplicate
 * @param teams
 */
export const duplicateUniverse = async (
  universe: KosmikUniverse,
  teams?: KosmikTeam[]
) => {
  const teamsToLink = teams ?? universe.teams;
  const teamsIds = teamsToLink?.map((team) => team.id) ?? [];
  const { name, color } = universe;
  const newUniverseId = id();

  // Create duplicated universe
  await batchTransact([
    tx.universes?.[newUniverseId]
      ?.update(
        color
          ? {
              name: getCopyName(name),
              color,
              created_at: new Date().toISOString(),
            }
          : {
              name: getCopyName(name),
              created_at: new Date().toISOString(),
            }
      )
      .link({ teams: teamsIds }),
  ]);

  // Create duplicated stickers
  const stickers = universe.stickers?.map((sticker) => {
    const { id: _oldId, ...rest } = sticker;
    const fileLink = getCurrentFileLink(sticker);
    const toBePersisted = getTransactableStickerProperties(rest);

    return tx.stickers?.[id()]
      ?.update(toBePersisted)
      .link({ universes: [newUniverseId], files: fileLink });
  });
  await batchTransact([...(stickers ?? [])]);

  return newUniverseId;
};

/**
 * Returns whether the given user is a member of the given universe
 * @param user the user to check on
 * @param universe the universe we're interested in knowing if the user is a member of
 */
export const getIsUniverseMember = (
  user: KosmikUser | MultiplayerUser,
  universe: MaybeUndefined<KosmikUniverse>
) => {
  const teamOwners = universe?.teams?.flatMap((team) =>
    team.team_ownerships?.flatMap((team) => team.users)
  );
  const teamMembers = universe?.teams?.flatMap((team) => team.users);
  const predicate = (member: MaybeUndefined<KosmikUser>) =>
    member?.id === user.id;
  const isTeamOwner = teamOwners?.some(predicate);
  const isTeamMember = teamMembers?.some(predicate);
  const isUniverseUser = universe?.users?.some(predicate);
  return isTeamOwner || isTeamMember || isUniverseUser;
};
