import { ComponentProps, ComponentType } from 'react';

import { CommonModalProps } from '@/components/modal/Modals';
import { DeleteUser } from '@/components/modal/modals/DeleteUser';
import { InviteToUniverse } from '@/components/modal/modals/InviteToUniverse';
import { RenameUniverse } from '@/components/modal/modals/RenameUniverse';
import { CreateTeamModalContent } from '@/components/modal/modals/Settings/CreateTeam/CreateTeamModalContent';
import { DeleteTeam } from '@/components/modal/modals/Settings/TeamSettings/contents/modals/DeleteTeam';
import { TeamSettings } from '@/components/modal/modals/Settings/TeamSettings/TeamSettings';
import { UserSettings } from '@/components/modal/modals/Settings/UserSettings/UserSettings';
import { StickerErrorBoundaryModal } from '@/components/stickerErrorBoundary/StickerErrorBoundaryModal';
import { DialogProps } from '@/components/ui/Dialog/Dialog';

export type ModalProperties = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: ComponentType<any>;
  contentProps?: DialogProps['contentProps'];
  dialogProps?: Omit<DialogProps, 'contentProps'>;
};

export type ModalPropertiesMap = Record<string, ModalProperties>;

export const TOP_FIX_MODAL_STYLE: React.CSSProperties = {
  padding: '0',
  position: 'fixed',
  top: '10%',
  left: '50%',
  transform: 'translateX(-50%)',
  transformOrigin: '0% center',
  maxHeight: '80vh',
  overflowY: 'auto',
};

export const MODAL_SPECS = {
  'invite-to-universe': {
    Component: InviteToUniverse,
  },
  'rename-universe': {
    Component: RenameUniverse,
  },
  'sticker-error-boundary': {
    Component: StickerErrorBoundaryModal,
  },
  'user-settings': {
    Component: UserSettings,
    contentProps: {
      style: TOP_FIX_MODAL_STYLE,
    },
  },
  'delete-user': {
    Component: DeleteUser,
  },
  'team-settings': {
    Component: TeamSettings,
    contentProps: {
      style: TOP_FIX_MODAL_STYLE,
    },
  },
  'delete-team': {
    Component: DeleteTeam,
    contentProps: {
      style: TOP_FIX_MODAL_STYLE,
    },
  },
  'create-team': {
    Component: CreateTeamModalContent,
    contentProps: {
      style: {
        padding: 0,
      },
      onOpenAutoFocus: (event) => {
        event.preventDefault();
        const { target } = event;
        if (target instanceof Element) {
          target.querySelector('input')?.focus();
        }
      },
    },
  },
} as const satisfies ModalPropertiesMap;

/**
 * The key for any given modal
 */
export type ModalKey = keyof typeof MODAL_SPECS;

/**
 * The Component rendered for a given Modal
 */
export type ModalComponent<T extends ModalKey> =
  (typeof MODAL_SPECS)[T]['Component'];

/**
 * The props specific to a given Modal (without the common modal props)
 */
export type ModalProps<T extends ModalKey> = Omit<
  ComponentProps<ModalComponent<T>>,
  keyof CommonModalProps
>;
