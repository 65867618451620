import { ReactNode } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { canvasContextMenuOpenAtom } from '@/atoms/context';
import { selectedStickerIdsAtom } from '@/atoms/selection';
import { EmptySelectionItems } from '@/components/HTMLCanvas/CanvasContextMenu/EmptySelectionItems/EmptySelectionItems';
import { MultiSelectionItems } from '@/components/HTMLCanvas/CanvasContextMenu/MultiSelectionItems';
import { SingleSelectionItems } from '@/components/HTMLCanvas/CanvasContextMenu/SingleSelectionItems';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

interface CanvasContextMenuProps {
  type: MenuType;
  children: ReactNode;
}

export const CanvasContextMenu = ({
  type,
  children,
}: CanvasContextMenuProps) => {
  const setCanvasContextMenuOpen = useSetRecoilState(canvasContextMenuOpenAtom);
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const hasOneSelected = selectedStickerIds.size === 1;
  const hasManySelected = selectedStickerIds.size > 1;

  const { Menu } = getPolymorphicMenuComponents(type);

  return (
    <Menu
      modal={false}
      trigger={children}
      onOpenChange={setCanvasContextMenuOpen}
      contentProps={{
        style: { minWidth: 220 },
      }}
    >
      {hasOneSelected ? (
        <SingleSelectionItems type={type} />
      ) : hasManySelected ? (
        <MultiSelectionItems type={type} />
      ) : (
        <EmptySelectionItems type={type} />
      )}
    </Menu>
  );
};
