import * as PopoverPrimitive from '@radix-ui/react-popover';
import { PopoverPortalProps } from '@radix-ui/react-popover';
import React from 'react';

import { PopoverContent } from '@/ui/Popover/PopoverContent';

export interface PopoverProps
  extends PopoverPrimitive.PopoverProps,
    React.PropsWithChildren<{
      trigger: React.ReactNode;
      contentProps?: PopoverPrimitive.PopoverContentProps;
    }> {
  container?: PopoverPortalProps['container'];
}

export const Popover = ({
  contentProps,
  trigger,
  children,
  container,
  ...props
}: PopoverProps) => (
  <PopoverPrimitive.Root {...props}>
    <PopoverPrimitive.Trigger asChild>{trigger}</PopoverPrimitive.Trigger>
    <PopoverPrimitive.Portal container={container}>
      <PopoverContent
        collisionPadding={4}
        align={'center'}
        sideOffset={4}
        onCloseAutoFocus={(event) => event.preventDefault()}
        {...contentProps}
      >
        {children}
      </PopoverContent>
    </PopoverPrimitive.Portal>
  </PopoverPrimitive.Root>
);
