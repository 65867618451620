import isUrl from 'is-url-superb';

import { KosmikSticker } from '@/utils/kosmik/sticker';
import { parseArrayOfStickers } from '@/utils/sticker/sticker';
import { isFile } from '@/utils/sticker/type';
import { isUriList, parseUriList } from '@/utils/url/uri';

/**
 * Get the file blobs for the given ClipboardItem if it matches the supported
 * file types
 */
export const getFileBlob = async (item: ClipboardItem) => {
  const fileType = item.types.find((type) => isFile(type));
  if (fileType) {
    return await item.getType(fileType);
  }
  return null;
};

/**
 * Get the text for the given ClipboardItem
 */
export const getText = async (item: ClipboardItem) => {
  let blob;
  if (item.types.includes('text/html')) {
    blob = await item.getType('text/html');
  } else if (item.types.includes('text/plain')) {
    blob = await item.getType('text/plain');
  }
  if (blob) {
    const text = await blob.text();
    // We put the html in a template to make sure it actually contains useful text
    // for example, we dismiss the html if it's just markup
    const template = document.createElement('template');
    template.innerHTML = text;
    // eslint-disable-next-line no-control-regex
    return template.content.textContent ? text.replace(/\x00/g, '') : null;
  }
  return null;
};

/**
 * Get all the paste-able items for the given collection of ClipboardItems
 */
export const getPasteableItems = async (clipboard: ClipboardItems) => {
  const files: Blob[] = [];
  const urls: string[] = [];
  const texts: string[] = [];
  const stickers: KosmikSticker[] = [];
  for (const item of clipboard) {
    const file = await getFileBlob(item);
    if (file) {
      files.push(file);
    } else {
      const text = await getText(item);
      if (text) {
        if (isUriList(text)) {
          urls.push(...parseUriList(text));
        } else if (isUrl(text)) {
          urls.push(text);
        } else {
          const parsedStickers = parseArrayOfStickers(text);
          if (parsedStickers) {
            stickers.push(...parsedStickers);
          } else {
            texts.push(text);
          }
        }
      }
    }
  }
  return { files, urls, texts, stickers };
};
