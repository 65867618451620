import { useRef } from 'react';

import { colorPaletteColors } from '@/ui/ColorPalette/ColorPaletteColors';

import styles from './ColorPalette.module.css';

interface ColorPaletteProps {
  currentColor: string;
  onChange: (color: string) => void;
}

const ColorPalette = ({ currentColor, onChange }: ColorPaletteProps) => {
  const colorInputRef = useRef<HTMLInputElement>(null);
  const computedBgStyle = (color: string) => {
    if (color === 'transparent') {
      return { background: color };
    }
    if (color === 'advance') {
      return {};
    }

    return { background: color };
  };

  const onColorChange = (color: string) => {
    if (color !== 'advance') {
      onChange(color);
    }
  };

  return (
    <div className={styles.palette}>
      {colorPaletteColors.map((row) => (
        <div key={JSON.stringify(row)} className={styles.row}>
          {row.map((color) => (
            <div
              key={color}
              className={styles.colorCubeWrapper}
              onClick={() => onColorChange(color)}
            >
              {color === 'advance' ? (
                <div
                  className={`${styles.colorCube} ${styles.advance}`}
                  style={computedBgStyle(color)}
                  data-testid={`color-${color}`}
                >
                  <input
                    type={'color'}
                    ref={colorInputRef}
                    className={styles.colorSwatchInput}
                    onChange={(event) => onColorChange(event.target.value)}
                    value={currentColor}
                    data-testid={'color-input'}
                  />
                </div>
              ) : (
                <div
                  className={`${styles.colorCube} ${color === 'transparent' ? styles.transparent : ''}`}
                  style={computedBgStyle(color)}
                  data-testid={`color-${color}`}
                ></div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ColorPalette;
