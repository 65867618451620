/**
 * Extracts initials from a full name.
 * Takes the first character of the first and last name.
 * @param fullName - The full name to extract initials from.
 * @returns A string containing the initials.
 */
export const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};
