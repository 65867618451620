import { RenderTask } from 'pdfjs-dist';
import React, { useMemo, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useRecoilValue } from 'recoil';

import { pinPanelActiveToolAtom } from '@/atoms/pinpanel';
import { useLocalCurrentPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useLocalCurrentPage';
import { usePageProxies } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/usePageProxies';
import { usePdfContentTable } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/usePdfContentTable';
import { useRenderPageWhenVisible } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useRenderPageWhenVisible';
import { useResizePage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useResizePage';
import { useResizePdf } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useResizePdf';
import { useScrollToRemoteCurrentPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useScrollToRemoteCurrentPage';
import { useSelectPdfText } from '@/components/HTMLCanvas/PinPanel/PdfViewer/hooks/useSelectPdfText';
import { PdfContentTable } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfContentTable';
import { PdfPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';
import { PdfPreview } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPreview';
import { usePdf } from '@/hooks/pdf/usePdf';
import { useIterableRefs } from '@/hooks/useIterableRefs';
import { ScrollArea } from '@/ui/ScrollArea/ScrollArea';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';

import styles from './PdfViewer.module.css';

interface PdfViewerProps {
  sticker: KosmikFileSticker;
}

export const PdfViewer = React.forwardRef<HTMLDivElement, PdfViewerProps>(
  ({ sticker }: PdfViewerProps, parentRef) => {
    const { refs: pageRefs, getRef: getPageRef } =
      useIterableRefs<HTMLDivElement>();
    const file = sticker.files?.[0];
    const { attributes } = sticker;
    const { cached_url: url } = file ?? attributes;
    const { page: remoteCurrentPage = 1 } = attributes;
    const { pdf } = usePdf(url);
    const { refs: pdfPromiseRefs, getRef: getPdfPromiseRef } =
      useIterableRefs<RenderTask>();
    const pageProxies = usePageProxies(pdf);
    const contentTable = usePdfContentTable(pdf);
    const scrollingAreaRef = useRef<HTMLDivElement | null>(null);
    const mergedRef = mergeRefs([parentRef, scrollingAreaRef]);
    const activeTool = useRecoilValue(pinPanelActiveToolAtom);
    const isLeftViewOpened =
      (activeTool === 'table' && contentTable?.length > 0) ||
      activeTool === 'preview';
    const { currentPage, handlePageChange } = useLocalCurrentPage(
      scrollingAreaRef,
      pageRefs,
      pageProxies
    );
    useSelectPdfText(scrollingAreaRef, currentPage);
    useResizePdf(scrollingAreaRef);
    useScrollToRemoteCurrentPage(
      remoteCurrentPage,
      handlePageChange,
      pageRefs,
      pageProxies,
      url
    );

    useRenderPageWhenVisible(
      scrollingAreaRef,
      pdfPromiseRefs,
      getPdfPromiseRef,
      pageProxies,
      pageRefs
    );

    useResizePage(
      scrollingAreaRef,
      pdfPromiseRefs,
      getPdfPromiseRef,
      pageProxies,
      pageRefs
    );

    // Only render the scroll view when necessary, otherwise we potentially
    // re-render thousands of pages if the pdf is big
    const scrollView = useMemo(() => {
      return (
        <ScrollArea ref={mergedRef} type={'hover'} className={'pdfViewport'}>
          {pageProxies.map((pageProxy) => (
            <PdfPage
              key={pageProxy.pageNumber}
              ref={getPageRef(pageProxy.pageNumber)}
              pageProxy={pageProxy}
            />
          ))}
        </ScrollArea>
      );
    }, [getPageRef, mergedRef, pageProxies]);

    return (
      <div className={styles.pdfViewer}>
        <div
          className={`${styles.leftViewWrapper} ${isLeftViewOpened ? '' : styles.hidden}`}
        >
          {activeTool === 'table' && contentTable?.length > 0 && (
            <PdfContentTable
              table={contentTable}
              setCurrentPage={handlePageChange}
            />
          )}
          {activeTool === 'preview' && (
            <PdfPreview
              pageProxies={pageProxies}
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
            />
          )}
        </div>

        <div className={styles.mainViewWrapper}>{scrollView}</div>
      </div>
    );
  }
);

PdfViewer.displayName = 'PdfViewer';
