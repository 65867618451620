import { z } from 'zod';

import { KosmikSticker } from '@/utils/kosmik/sticker';
import { Shape } from '@/utils/kosmik/stickers/shape';

export const StickerType = z.enum([
  'picture',
  'sticky',
  'text',
  'shape',
  'web',
  'file',
  'prompt',
]);
// 'passport' |
// 'arrow' |
// 'card' |
// 'file' |
// 'pdf' |
// 'text' |
// 'universe' |
// 'edge' |
// 'video' |
// 'tag'

export type StickerType = z.infer<typeof StickerType>;

export type RenderableFileType = 'file' | 'image' | 'video' | 'audio' | 'pdf';

export type RenderableWebType = 'web' | 'webEmbed';

export type RenderableStickerType =
  | RenderableFileType
  | RenderableWebType
  | Exclude<KosmikSticker['type'], 'shape' | 'file' | 'web'>
  | Shape;
