import {
  KosmikShapeSticker,
  RectangleShapeAttributes,
} from '@/utils/kosmik/stickers/shape';

export const HtmlRectangleShapeSticker = ({
  sticker,
}: {
  sticker: KosmikShapeSticker;
}) => {
  const attributes = sticker.attributes;
  const starAttributes = RectangleShapeAttributes.safeParse(attributes);

  if (!starAttributes.success) {
    console.log('Invalid attributes for rectangle sticker', sticker);
    return null;
  }

  return <RectangleShape attributes={starAttributes.data} />;
};

export type RectangleShapeProps = {
  attributes: RectangleShapeAttributes;
};

export const RectangleShape = ({ attributes }: RectangleShapeProps) => {
  const cornerRadius = attributes.corner_radius_ratio;

  return (
    <div
      data-testid={'shape-rectangle'}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: `calc(min(var(--sticker-width), var(--sticker-height)) / 1.5 * ${cornerRadius})`,
        backgroundColor: attributes.fill_color,
        borderColor: attributes.stroke_color,
        borderWidth: attributes.stroke_width,
        borderStyle: attributes.stroke_style ?? 'solid',
      }}
    />
  );
};
