import { useParams } from '@tanstack/react-router';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { deletingStickersFamily } from '@/atoms/sticker';
import { useStickerSelection } from '@/components/selection/useStickerSelection';
import { useRoom } from '@/context/Room/useRoom';
import { db } from '@/db/databaseInit.ts';
import { useAddRemoveTemporaryStickers } from '@/hooks/sticker/useAddRemoveTemporaryStickers';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import * as Watson from '@/utils/research/watson.ts';
import {
  deleteDatabaseStickers,
  undoableDuplicateStickers,
} from '@/utils/sticker/sticker';

export const useDeleteStickers = () => {
  const { universeId: maybeUndefinedUniverseId } = useParams({ strict: false });
  const universeId = maybeUndefinedUniverseId ?? '';
  const { room } = useRoom();
  const publishDeleteStickers = room.usePublishTopic('deleteStickers');
  const setDeletingStickers = useSetRecoilState(
    deletingStickersFamily(universeId)
  );
  const { removeTemporaryStickers } = useAddRemoveTemporaryStickers(universeId);
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const { unselect } = useStickerSelection();
  const auth = db.useAuth();
  const refreshToken = auth.user?.refresh_token;

  const deleteStickers = useCallback(
    (targetStickers: KosmikSticker[], keepFiles?: boolean) => {
      const ids = targetStickers.map((sticker) => {
        if (refreshToken) {
          Watson.deleteEntry(sticker.id, refreshToken);
        }
        return sticker.id;
      });
      deleteDatabaseStickers(targetStickers, keepFiles);
      publishDeleteStickers(ids);
      setDeletingStickers((prev) => {
        return new Set([...prev, ...ids]);
      });
      removeTemporaryStickers(ids);
      unselect();
    },
    [
      publishDeleteStickers,
      refreshToken,
      removeTemporaryStickers,
      setDeletingStickers,
      unselect,
    ]
  );

  const undoableDeleteStickers = useCallback(
    (stickers: KosmikSticker[], keepFiles?: boolean) => {
      deleteStickers(stickers, keepFiles);
      let createdStickers: KosmikSticker[] = [];
      const deleteAction: UndoRedoActionType = {
        do: () => {
          deleteStickers(createdStickers, keepFiles);
        },
        undo: () => {
          createdStickers = undoableDuplicateStickers(stickers, universeId);
        },
      };
      addUndoRedoAction(deleteAction);
    },
    [addUndoRedoAction, deleteStickers, universeId]
  );

  return { deleteStickers, undoableDeleteStickers };
};
