import { id, tx } from '@instantdb/react';

import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { batchTransact } from '@/utils/db/db';
import { embedProviders } from '@/utils/embed/provider';
import { Position } from '@/utils/geometry/position';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';

export const createWebSticker = (
  url: string,
  position: Position,
  universeId: string
): KosmikWebSticker => {
  const stickerId = id();
  const matchingEmbedProvider = embedProviders.find((provider) =>
    provider.embedRegExp.test(url)
  );
  const sticker: Omit<KosmikWebSticker, 'id'> = {
    v: 0,
    ...position,
    width: matchingEmbedProvider
      ? DEFAULT_STICKER_SIZE.webEmbed.width
      : DEFAULT_STICKER_SIZE.web.width,
    height: matchingEmbedProvider
      ? DEFAULT_STICKER_SIZE.webEmbed.height
      : DEFAULT_STICKER_SIZE.web.height,
    type: 'web',
    created_at: new Date().toISOString(),
    attributes: {
      url: url,
    },
  };
  batchTransact(
    tx.stickers?.[stickerId]?.update(sticker).link({ universes: [universeId] })
  );

  return { id: stickerId, ...sticker };
};
