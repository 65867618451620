import { getRouteApi } from '@tanstack/react-router';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { canvasContextMenuOpenAtom } from '@/atoms/context';
import { isPinPanelOpenedAtom } from '@/atoms/pinpanel';
import { useZoomToFit } from '@/hooks/contextAction/useZoomToFit';
import { useStickerCreateContextActionShortcuts } from '@/hooks/keyboard/useStickerCreateContextActionShortcuts';
import { useUniverseContextActionShortcuts } from '@/hooks/keyboard/useUniverseContextActionShortcuts';

export interface UniverseContextActionsWrapperProps extends PropsWithChildren {
  isPublic?: boolean;
}

export const UniverseContextActionsWrapper = ({
  children,
  isPublic,
}: UniverseContextActionsWrapperProps) => {
  const isPinPanelOpened = useRecoilValue(isPinPanelOpenedAtom);
  const isCanvasContextMenuOpen = useRecoilValue(canvasContextMenuOpenAtom);
  const ref = useRef<HTMLDivElement>(null);
  const routeApi = getRouteApi(
    isPublic ? '/public/$universeId' : '/_authenticated/$universeId'
  );
  const routeSearch = routeApi.useSearch();
  const { zoomToFit } = useZoomToFit();
  const lastStickerId = useRef('');

  useUniverseContextActionShortcuts({
    ignoreEventWhen: (event) => {
      let isShortcutTriggeredInsideRef = false;
      if (event.target instanceof Node) {
        isShortcutTriggeredInsideRef =
          ref.current?.contains(event.target) ?? false;
      }
      return (
        (!isShortcutTriggeredInsideRef && !isCanvasContextMenuOpen) ||
        isPinPanelOpened
      );
    },
  });

  useStickerCreateContextActionShortcuts({
    ignoreEventWhen: () => {
      return isCanvasContextMenuOpen;
    },
  });

  /**
   * Zoom on linked sticker if any (search param)
   */
  const stickerId = routeSearch.sticker;
  useEffect(() => {
    if (stickerId && stickerId !== lastStickerId.current) {
      zoomToFit(new Set([stickerId]));
      lastStickerId.current = stickerId;
    }
  }, [stickerId, zoomToFit]);

  /**
   * Give focus back to the canvas so the universe context actions shortcuts
   * are made active again when closing the pin panel
   */
  useEffect(() => {
    if (!isPinPanelOpened) {
      ref.current?.focus();
    }
  }, [isPinPanelOpened]);

  return (
    <div tabIndex={-1} ref={ref}>
      {children}
    </div>
  );
};
