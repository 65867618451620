import { CSSProperties } from 'react';

import { useUpdateStickerTextContent } from '@/components/HTMLCanvas/stickers/hooks/useUpdateStickerTextContent';
import { QuillEditor } from '@/components/Quill/QuillEditor';
import { wrapCSSVar } from '@/utils/css/variable';
import { KosmikStickySticker } from '@/utils/kosmik/stickers/sticky';

import styles from './HtmlStickySticker.module.css';

export const HtmlStickySticker = ({
  sticker,
}: {
  sticker: KosmikStickySticker;
}) => {
  const { handleDebouncedTextChange } = useUpdateStickerTextContent(sticker);

  return (
    <div
      style={
        {
          ['--bg']: wrapCSSVar(
            sticker.attributes.fill_color ?? 'var(--tag-yellow-saturated)'
          ),
        } as CSSProperties
      }
      className={styles.sticky}
    >
      <QuillEditor
        type={'sticky'}
        key={sticker.id}
        stickerId={sticker.id}
        initialValue={sticker.attributes.text_content}
        onTextChange={handleDebouncedTextChange}
      />
    </div>
  );
};
