import equal from 'fast-deep-equal';
import Quill from 'quill';
import { Delta, EmitterSource, Range } from 'quill/core';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { stickerQuillAttributesFamily } from '@/atoms/sticker';
import { isNumberOrStringNumber } from '@/utils/number/number';
import { MaybeUndefined } from '@/utils/types';

export type UseQuillMutationsProps = {
  quill: MaybeUndefined<Quill>;
  stickerId: string;
  isSelected: boolean;
  initialValue: MaybeUndefined<string>;
  onTextChange: (content: string) => void;
};

export const useQuillMutations = ({
  stickerId,
  quill,
  isSelected,
  initialValue,
  onTextChange,
}: UseQuillMutationsProps) => {
  const [attributes, setAttributes] = useRecoilState(
    stickerQuillAttributesFamily(stickerId)
  );
  const isInitialized = useRef(false);

  useEffect(() => {
    if (quill) {
      if (initialValue && (!quill.isEnabled() || !isInitialized.current)) {
        const updateHTML = () => {
          if (quill.root.innerHTML !== initialValue) {
            const delta = quill.clipboard.convert({ html: initialValue });
            quill?.updateContents(delta);
          }
        };
        try {
          if (isNumberOrStringNumber(initialValue)) {
            updateHTML();
          } else {
            const maybeObject = JSON.parse(initialValue);
            if (typeof maybeObject === 'object' && maybeObject.ops) {
              const remoteDelta = new Delta(maybeObject);
              const diffDelta = quill.getContents().diff(remoteDelta);
              if (diffDelta.ops.length > 0) {
                quill.updateContents(diffDelta);
              }
            } else {
              updateHTML();
            }
          }
        } catch {
          updateHTML();
        }
        isInitialized.current = true;
      }
    }
  }, [initialValue, quill]);

  useEffect(() => {
    if (quill && isSelected) {
      const syncAttributes = (range: Range) => {
        const currentFormating = quill.getFormat(range);
        setAttributes((prev) => {
          if (equal(prev, currentFormating)) {
            return prev;
          }
          return currentFormating;
        });
      };

      const handleSelectionChange: (
        range: Range,
        oldRange: Range,
        source: EmitterSource
      ) => void = (range) => {
        // Update current attributes
        if (range) {
          syncAttributes(range);
        }
      };

      const handleTextChange: (
        delta: Delta,
        oldContent: Delta,
        source: EmitterSource
      ) => void = (delta, oldDelta) => {
        const newQuillContents = oldDelta.compose(delta);
        onTextChange(JSON.stringify(newQuillContents));
        const quillSelection = quill.getSelection();
        if (quillSelection) {
          syncAttributes(quillSelection);
        }
      };

      quill.on('selection-change', handleSelectionChange);
      quill.on('text-change', handleTextChange);

      return () => {
        quill.off('selection-change', handleSelectionChange);
        quill.off('text-change', handleTextChange);
      };
    }
  }, [quill, onTextChange, setAttributes, isSelected]);

  // When EditDock changes attributes sync attributes with quill
  useEffect(() => {
    if (quill && isSelected) {
      const quillSelection = quill.getSelection();
      const selection = quillSelection ?? {
        index: 0,
        length: quill.getLength(),
      };

      if (!equal(attributes, quill.getFormat(selection))) {
        Object.entries(attributes).forEach(([key, value]) => {
          if (key === 'align' && value === 'left') {
            value = '';
          }
          if (quillSelection) {
            quill.format(key, value, 'user');
          } else {
            // we need to use 'api' as a source to be able to modify disabled editor
            quill.formatText(selection, key, value, 'api');
          }
        });
      }
    }
  }, [attributes, isSelected, quill]);
};
