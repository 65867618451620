import { MoreMenu, Tag } from '@lithium/spectrum';
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  canvasContextMenuOpenAtom,
  contextActionStickerIdsAtom,
} from '@/atoms/context';
import { metadataOpenAtom } from '@/atoms/metadata';
import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { PinPanelDefaultEditDockItems } from '@/components/EditDock/components/PinPanelDefaultEditDockItems';
import { MetadataPanel } from '@/components/Metadata/MetadataPanel.tsx';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';
import { Dropdown } from '@/ui/Dropdown/Dropdown';
import { DropdownItem } from '@/ui/Dropdown/DropdownItem';
import { DropdownSeparator } from '@/ui/Dropdown/DropdownSeparator';
import { Shortcut } from '@/ui/Shortcut/Shortcut';
import { KosmikSticker } from '@/utils/kosmik/sticker';

export const DefaultEditDockItems = ({
  sticker,
}: {
  sticker: KosmikSticker;
}) => {
  const handlers = useUniverseContextActions();
  const { isUniverseMember } = useContext(UniverseContext);
  const setCanvasContextMenuOpen = useSetRecoilState(canvasContextMenuOpenAtom);
  const setContextActionStickerIds = useSetRecoilState(
    contextActionStickerIdsAtom
  );
  const [metadataOpen, setMetadataOpen] = useRecoilState(metadataOpenAtom);

  const handleDropdownOpenChange = (newOpen: boolean) => {
    setCanvasContextMenuOpen(newOpen);
    if (newOpen) {
      setContextActionStickerIds(new Set([sticker.id]));
    } else {
      setContextActionStickerIds(null);
    }
  };
  const isPinPanel = usePinPanel();
  const canvasArea = document.getElementById('canvasArea');

  return (
    <>
      {isUniverseMember ? (
        <>
          <EditDockItem>
            <Tag onClick={() => setMetadataOpen(!metadataOpen)} />

            {canvasArea
              ? createPortal(<MetadataPanel sticker={sticker} />, canvasArea)
              : null}
          </EditDockItem>
          {/* <Popover
            trigger={
              <EditDockItem>
                <Tag />
              </EditDockItem>
            }
            contentProps={{
              sideOffset: 8,
              style: { padding: 10 },
              side: 'top',
              avoidCollisions: true,
            }}
          > */}
          {/* <div>TODO: tag menu</div>
          </Popover> */}

          <Dropdown
            modal={false}
            trigger={
              <EditDockItem>
                <MoreMenu />
              </EditDockItem>
            }
            onOpenChange={handleDropdownOpenChange}
            contentProps={{
              style: { minWidth: 220 },
            }}
          >
            <DropdownItem
              displayAction
              onClick={handlers.copy}
              suffix={<Shortcut keys={'mod+c'} />}
            >
              Copy
            </DropdownItem>
            <DropdownItem
              displayAction
              onClick={handlers.cut}
              suffix={<Shortcut keys={'mod+x'} />}
            >
              Cut
            </DropdownItem>
            <DropdownItem
              displayAction
              onClick={handlers.paste}
              suffix={<Shortcut keys={'mod+v'} />}
            >
              Paste
            </DropdownItem>
            <DropdownSeparator />
            <DropdownItem
              displayAction
              onClick={handlers.pin}
              suffix={<Shortcut keys={'Space'} />}
            >
              Pin
            </DropdownItem>
            <DropdownSeparator />
            <DropdownItem
              displayAction
              onClick={handlers.duplicateSelected}
              suffix={<Shortcut keys={'mod+d'} />}
            >
              Duplicate sticker
            </DropdownItem>
            <DropdownSeparator />
            <DropdownItem
              displayAction
              onClick={handlers.selectAll}
              suffix={<Shortcut keys={'mod+a'} />}
            >
              Select All
            </DropdownItem>
            <DropdownItem
              displayAction
              onClick={handlers.selectAllOfType}
              suffix={<Shortcut keys={'mod+shift+a'} />}
            >
              Select all of type
            </DropdownItem>
            <DropdownSeparator />
            <DropdownItem
              displayAction
              onClick={handlers.zoomToFit}
              suffix={<Shortcut keys={'alt+f'} />}
            >
              Zoom to fit
            </DropdownItem>
            <DropdownSeparator />
            <DropdownItem
              displayAction
              onClick={() => handlers.deleteSelected()}
              suffix={<Shortcut keys={'backspace'} />}
            >
              Delete sticker
            </DropdownItem>
          </Dropdown>
        </>
      ) : null}
      {isPinPanel ? <PinPanelDefaultEditDockItems /> : null}
    </>
  );
};
