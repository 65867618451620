import { ArrowsForwardsInside } from '@lithium/spectrum';

import { useAddModal } from '@/components/modal/hooks/useAddModal';
import styles from '@/components/stickerErrorBoundary/StickerErrorBoundaryUI.module.css';
import { KosmikSticker } from '@/utils/kosmik/sticker';

interface StickerErrorBoundaryUIProps {
  error: Error;
  sticker: KosmikSticker;
}

export const StickerErrorBoundaryUI = ({
  error,
  sticker,
}: StickerErrorBoundaryUIProps) => {
  const addModal = useAddModal();
  return (
    <div
      className={styles.wrapper}
      style={{
        maxHeight: sticker.height,
        maxWidth: sticker.width,
      }}
    >
      <h3> Error: {error.name}</h3>
      {/*<pre>{error.message}</pre>*/}
      <pre className={styles.trace}>{error.stack}</pre>
      <ArrowsForwardsInside
        className={styles.resize}
        onClick={() => addModal('sticker-error-boundary', { error })}
      />
    </div>
  );
};
