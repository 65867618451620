import { ShapeCircle } from '@lithium/spectrum';

import { useCreateDefaultShapeSticker } from '@/hooks/sticker/create/useCreateDefaultShapeSticker';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const CreateEllipseShapeStickerMenuItem = ({
  type,
}: {
  type: MenuType;
}) => {
  const { handleCreateDefaultShape } = useCreateDefaultShapeSticker({
    shape: 'ellipse',
  });

  const { Item } = getPolymorphicMenuComponents(type);

  return (
    <Item
      onClick={(event) => handleCreateDefaultShape(event.shiftKey)}
      prefix={<ShapeCircle />}
    >
      Ellipse
    </Item>
  );
};
