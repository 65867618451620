import { useParams } from '@tanstack/react-router';
import { useContext } from 'react';
import { useSetRecoilState } from 'recoil';

import { followingAtom } from '@/atoms/multiplayer';
import { AvatarRow } from '@/components/AvatarRow';
import {
  MultiplayerUser,
  MultiplayerUserContext,
} from '@/context/User/MultiplayerUserContext';
import { db, UserMultiplayerState } from '@/db/databaseInit';
import { Avatar } from '@/ui/Avatar/Avatar';
import { getUserName } from '@/utils/user/user';

import styles from './UserBubbles.module.css';

export const UserBubbles = () => {
  const user = useContext(MultiplayerUserContext);
  const { universeId } = useParams({ strict: false });
  const setFollowing = useSetRecoilState(followingAtom);

  const room = db.room('universe-room', universeId ?? 'random-uuid');
  const { peers } = room.usePresence({
    user: false,
    keys: ['user', 'following'],
  });

  if (!universeId || !user) {
    return null;
  }

  const mainUser: [string, Pick<UserMultiplayerState, 'user' | 'following'>] = [
    user.peerId,
    {
      user: user as MultiplayerUser,
      following: null,
    },
  ];

  const users = [
    mainUser,
    ...Object.entries(peers).filter(([, peer]) => peer.user),
  ];

  return (
    <div className={styles.userBubbles} data-testid={'user-bubbles'}>
      <AvatarRow<Pick<UserMultiplayerState, 'user' | 'following'>>
        limit={5}
        peers={users}
        avatar={([id, peer]) =>
          peer.user ? (
            <Avatar
              key={id}
              name={getUserName(peer.user)}
              color={peer.user.color}
              contrastColor={peer.user.contrastColor}
              id={peer.user.peerId}
              size={24}
              tooltip={
                peer.following === user.peerId
                  ? `${getUserName(peer.user)} is following you`
                  : getUserName(peer.user) +
                    (peer.user.peerId === user?.peerId ? ' (you)' : '')
              }
              onClick={() => {
                if (peer.user && peer.following !== user.peerId) {
                  setFollowing({
                    instantPeerId: id,
                    kosmikPeerId: peer.user.peerId,
                  });
                }
              }}
            />
          ) : null
        }
      />
    </div>
  );
};
