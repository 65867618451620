import {
  createFileRoute,
  Outlet,
  redirect,
  useNavigate,
} from '@tanstack/react-router';
import { useEffect, useRef } from 'react';
import { z } from 'zod';

import { Modals } from '@/components/modal/Modals';
import { ColorSchemeProvider } from '@/context/ColorScheme/ColorSchemeProvider';
import { NotificationsProvider } from '@/context/Notifications/NotificationsProvider';
import { PreferencesProvider } from '@/context/Preferences/PreferencesProvider';
import { TeamProvider } from '@/context/Team/TeamProvider';
import { MultiplayerUserProvider } from '@/context/User/MultiplayerUserProvider';
import { db } from '@/db/databaseInit';
import { useKPIConsumer } from '@/hooks/kpi/useKPIConsumer';
import { DuplicateUrlParams } from '@/routes/_authenticated/duplicate/$duplicateId.lazy';
import { NotificationsList } from '@/ui/Notifications/NotificationsList';
import { sendKPI } from '@/utils/kpi/sendKPI';
import { createNewUser } from '@/utils/user/create';

const authenticatedSchema = z.object({
  sso: z.boolean().optional(),
});

export type authenticatedParams = z.infer<typeof authenticatedSchema>;

const Authenticated = () => {
  const { sso }: authenticatedParams = Route.useSearch();
  const navigate = useNavigate();
  const { user } = db.useAuth();
  useKPIConsumer();
  const isSignedIn = useRef(false);

  useEffect(() => {
    if (sso && user && !isSignedIn.current) {
      isSignedIn.current = true;
      createNewUser(user);
      sendKPI({ type: 'sign_in' }, user, user.refresh_token);
      // remove the sso param from the url after sign in
      const redirectUrl = window.location.pathname.replace('?sso=true', '');
      navigate({ to: redirectUrl });
    }
  }, [navigate, sso, user]);

  useEffect(() => {
    if (!user) {
      navigate({ to: '/login' });
    }
  }, [navigate, user]);

  return (
    <MultiplayerUserProvider>
      <PreferencesProvider>
        <TeamProvider>
          <ColorSchemeProvider>
            <NotificationsProvider>
              <Outlet />
              <Modals />
              <NotificationsList />
            </NotificationsProvider>
          </ColorSchemeProvider>
        </TeamProvider>
      </PreferencesProvider>
    </MultiplayerUserProvider>
  );
};

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: ({ context, params }) => {
    // TODO see if we can do better to retrieve child route params.
    const { duplicateId } = params as DuplicateUrlParams;
    if (!context.authenticated) {
      throw redirect({ to: '/login', search: { duplicateId } });
    }
  },
  component: Authenticated,
  validateSearch: authenticatedSchema,
});
