import { createContext } from 'react';

import { db } from '@/db/databaseInit';

const mockUniverseId = '';

export const RoomContext = createContext({
  room: db.room('universe-room', mockUniverseId),
  universeId: mockUniverseId,
});
