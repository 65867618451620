import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import React, { ReactNode } from 'react';

import {
  MenuItem,
  MenuItemProps,
} from '@/components/ui/MenuPrimitive/MenuItem';

export interface ContextMenuItemProps
  extends Omit<ContextMenuPrimitive.ContextMenuItemProps, 'prefix'>,
    MenuItemProps {
  children: ReactNode;
}

export const ContextMenuItem = React.forwardRef<
  HTMLDivElement,
  ContextMenuItemProps
>(({ prefix, suffix, children, ...props }, ref) => {
  return (
    <ContextMenuPrimitive.Item asChild {...props}>
      <MenuItem ref={ref} {...{ prefix, suffix }}>
        {children}
      </MenuItem>
    </ContextMenuPrimitive.Item>
  );
});

ContextMenuItem.displayName = 'ContextMenuItem';
