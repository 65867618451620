import { WebviewTag } from 'electron';

/**
 * Returns the webview id for a given sticker
 * @param stickerId
 */
export const getWebviewId = (stickerId: string) => `webview-${stickerId}`;

/**
 * Get the webview for a given sticker
 * @param stickerId
 */
export const getWebview = (stickerId: string) => {
  const webviewId = getWebviewId(stickerId);
  return document.getElementById(webviewId) as WebviewTag | null;
};
