import { z } from 'zod';

import bing from '@/assets/icon/searchEngine/Bing.png';
import duckduckgo from '@/assets/icon/searchEngine/DuckDuckGo.png';
import ecosia from '@/assets/icon/searchEngine/Ecosia.png';
import google from '@/assets/icon/searchEngine/Google.png';
import lilo from '@/assets/icon/searchEngine/Lilo.png';
import qwant from '@/assets/icon/searchEngine/Qwant.png';
import yahoo from '@/assets/icon/searchEngine/Yahoo.png';

export const SearchEngine = z.enum([
  'qwant',
  'lilo',
  'bing',
  'duckduckgo',
  'google',
  'yahoo',
  'ecosia',
]);

export type SearchEngine = z.infer<typeof SearchEngine>;

export type SearchEngineSpec = {
  name: string;
  image: string;
  search: string;
  url: string;
};

export const SEARCH_ENGINE_SPEC: Record<SearchEngine, SearchEngineSpec> = {
  [SearchEngine.enum.qwant]: {
    name: 'Qwant',
    image: qwant,
    search: 'https://www.qwant.com/?&q=',
    url: 'https://www.qwant.com',
  },
  [SearchEngine.enum.lilo]: {
    name: 'Lilo',
    image: lilo,
    search: 'https://search.lilo.org/?q=',
    url: 'https://search.lilo.org',
  },
  [SearchEngine.enum.bing]: {
    name: 'Bing',
    image: bing,
    search: 'https://www.bing.com/search?q=',
    url: 'https://www.bing.com',
  },
  [SearchEngine.enum.duckduckgo]: {
    name: 'DuckDuckGo',
    image: duckduckgo,
    search: 'https://duckduckgo.com/?q=',
    url: 'https://duckduckgo.com',
  },
  [SearchEngine.enum.google]: {
    name: 'Google',
    image: google,
    search: 'https://www.google.com/search?q=',
    url: 'https://www.google.com',
  },
  [SearchEngine.enum.yahoo]: {
    name: 'Yahoo',
    image: yahoo,
    search: 'https://search.yahoo.com/search?p=',
    url: 'https://search.yahoo.com',
  },
  [SearchEngine.enum.ecosia]: {
    name: 'Ecosia',
    image: ecosia,
    search: 'https://www.ecosia.org/search?method=index&q=',
    url: 'https://www.ecosia.org',
  },
};
