import { InputHTMLAttributes } from 'react';

import {
  SelectableCard,
  SelectableCheckboxCard,
  SelectableRadioCard,
} from '@/components/SelectableCards/SelectableCard';
import styles from '@/components/SelectableCards/SelectableCards.module.css';
import { isDefined } from '@/utils/isDefined';

export type SelectableCardsBaseProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'value' | 'defaultValue' | 'onChange'
>;

export type SelectableRadioCardsProps = SelectableCardsBaseProps & {
  name: string;
  type: 'radio';
  cards: SelectableRadioCard[];
};

export type SelectableCheckboxCardsProps = SelectableCardsBaseProps & {
  name?: never;
  type: 'checkbox';
  cards: SelectableCheckboxCard[];
};

export type SelectableCardsProps =
  | SelectableRadioCardsProps
  | SelectableCheckboxCardsProps;

export const SelectableCards = ({
  cards,
  value,
  defaultValue,
  ...props
}: SelectableCardsProps) => {
  const getChecked = (val: typeof value, valueProp: typeof value) =>
    isDefined(valueProp)
      ? Array.isArray(valueProp) && !Array.isArray(val)
        ? valueProp.includes(val)
        : valueProp === val
      : undefined;

  return (
    <div className={styles.selectableCardsContainer}>
      {cards.map((card) => (
        <SelectableCard
          key={card.id}
          data-testid={card.value}
          checked={getChecked(card.value, value)}
          defaultChecked={getChecked(card.value, defaultValue)}
          {...card}
          {...props}
        />
      ))}
    </div>
  );
};
