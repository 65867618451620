import 'quill/dist/quill.snow.css';

import { useContext } from 'react';
import { useQuill } from 'react-quilljs';
import { useRecoilValue } from 'recoil';

import {
  localStickerFamily,
  stickerSelectionStateFamily,
} from '@/atoms/sticker';
import { usePasteCapture } from '@/components/Quill/hooks/usePasteCapture';
import { useQuillActivation } from '@/components/Quill/hooks/useQuillActivation';
import { useQuillMutations } from '@/components/Quill/hooks/useQuillMutations';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { isDefined } from '@/utils/isDefined';

import styles from './QuillEditor.module.css';

interface QuillEditorProps {
  stickerId: string;
  initialValue?: string;
  onTextChange: (content: string) => void;
  type: 'sticky' | 'text';
}

export interface QuillEvent extends Event {
  detail: {
    stickerId: string;
    format?: TextEditorFormat;
  };
}

export interface TextAttributes {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strike?: boolean;
  font?: string;
  align?: string;
  size?: string | Array<string>;
  color?: string | Array<string>;
}

export type TextEditorFormat = keyof TextAttributes;

export const QuillEditor = ({
  stickerId,
  initialValue,
  onTextChange,
}: QuillEditorProps) => {
  const localSticker = useRecoilValue(localStickerFamily(stickerId));
  const selection = useRecoilValue(stickerSelectionStateFamily(stickerId));
  const user = useContext(MultiplayerUserContext);
  const isPinPanel = usePinPanel();
  const isSelected = isPinPanel || (isDefined(user) && selection.has(user));

  const formats = ['bold', 'italic', 'underline', 'strike', 'color'];
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: false,
    },
    theme: 'bubble',
    formats:
      localSticker?.type === 'text' ? [...formats, 'size', 'align'] : formats,
    placeholder: 'Double click to edit',
  });
  const handlePasteCapture = usePasteCapture(quill);
  useQuillActivation({ quill, stickerId, isSelected });
  useQuillMutations({
    quill,
    stickerId,
    isSelected,
    initialValue,
    onTextChange,
  });

  return (
    <div
      ref={quillRef}
      className={styles.quill}
      spellCheck={false}
      onPasteCapture={handlePasteCapture}
    />
  );
};

QuillEditor.displayName = 'QuillEditor';
