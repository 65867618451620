/**
 * Returns the scroll info for the given element within the given scroll
 * container. Info includes if the element top is before the scrollable
 * viewport, if the element bottom is after, or if the element is fully visible
 * @param container
 * @param element
 */
export const getScrollInfo = (element: HTMLElement, container: HTMLElement) => {
  const scrollAreaTop = container.scrollTop;
  const scrollAreaBottom = scrollAreaTop + container.offsetHeight;
  const elementTop = element.offsetTop;
  const elementBottom = elementTop + element.offsetHeight;
  const isBefore = elementTop < scrollAreaTop;
  const isAfter = elementBottom > scrollAreaBottom;
  return { isBefore, isAfter, isFullyVisible: !isBefore && !isAfter };
};
