import { Size } from '@/utils/geometry/size';
import { RenderableStickerType } from '@/utils/kosmik/stickers/type';

export const DEFAULT_STICKER_SIZE = {
  image: { width: 400, height: 242 },
  video: { width: 400, height: 242 },
  audio: { width: 400, height: 37 },
  pdf: { width: 400, height: 400 },
  file: { width: 275, height: 50 },
  sticky: { width: 200, height: 200 },
  text: { width: 300, height: 125 },
  rectangle: { width: 200, height: 200 },
  ellipse: { width: 200, height: 200 },
  polygon: { width: 200, height: 200 },
  star: { width: 200, height: 200 },
  prompt: { width: 500, height: 125 },
  web: { width: 400, height: 48 },
  webEmbed: { width: 200, height: 200 },
} as const satisfies Record<RenderableStickerType, Size>;
