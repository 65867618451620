import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import React, { useCallback, useEffect, useRef } from 'react';

import styles from './ScrollArea.module.css';

export type Orientation =
  | ScrollAreaPrimitive.ScrollAreaScrollbarProps['orientation']
  | 'both';

export type ScrollAreaProps = React.PropsWithChildren<
  ScrollAreaPrimitive.ScrollAreaProps & {
    orientation?: Orientation;
    viewportProps?: ScrollAreaPrimitive.ScrollAreaViewportProps;
    onScrollEnd?: (position: { x: number; y: number }) => void;
    scrollEndDelay?: number;
    onReachBottom?: () => void;
    bottomThreshold?: number;
  }
>;

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    {
      orientation = 'vertical',
      children,
      viewportProps,
      className,
      onScrollEnd,
      onReachBottom,
      bottomThreshold = 20,
      ...props
    },
    ref
  ) => {
    const viewportRef = useRef<HTMLDivElement | null>(null);
    const isScrollingRef = useRef(false);
    const rafIdRef = useRef<number>();
    const hasReachedBottomRef = useRef(false);
    const lastScrollTopRef = useRef(0);

    const handleScrollEnd = useCallback(
      (element: HTMLElement) => {
        const { scrollTop, scrollHeight, clientHeight } = element;

        // Only trigger onScrollEnd if we actually scrolled
        if (onScrollEnd && lastScrollTopRef.current !== scrollTop) {
          onScrollEnd({
            x: element.scrollLeft,
            y: scrollTop,
          });
        }

        if (onReachBottom) {
          const isAtBottom =
            scrollHeight - (scrollTop + clientHeight) <= bottomThreshold;

          // Only trigger if we weren't at bottom before and now we are
          if (isAtBottom && !hasReachedBottomRef.current) {
            hasReachedBottomRef.current = true;
            onReachBottom();
          } else if (!isAtBottom && hasReachedBottomRef.current) {
            // Reset the flag when scrolling away from bottom
            hasReachedBottomRef.current = false;
          }
        }

        // Update last scroll position
        lastScrollTopRef.current = scrollTop;
      },
      [onScrollEnd, onReachBottom, bottomThreshold]
    );

    const handleScroll = useCallback(() => {
      if (!isScrollingRef.current) {
        isScrollingRef.current = true;
      }

      const rafId = rafIdRef.current;

      // Cancel any existing animation frame
      if (rafId) {
        cancelAnimationFrame(rafId);
      }

      // Schedule new check
      rafIdRef.current = requestAnimationFrame(() => {
        const viewport = viewportRef.current;
        if (!viewport) {
          return;
        }

        handleScrollEnd(viewport);
        isScrollingRef.current = false;
      });
    }, [handleScrollEnd]);

    // Reset flags when content changes
    useEffect(() => {
      hasReachedBottomRef.current = false;
      lastScrollTopRef.current = 0;
    }, [children]);

    // Cleanup
    useEffect(() => {
      return () => {
        const rafId = rafIdRef.current;
        if (rafId) {
          cancelAnimationFrame(rafId);
        }
      };
    }, []);

    const setRefs = useCallback(
      (element: HTMLDivElement | null) => {
        // Update internal ref
        viewportRef.current = element;

        // Forward the ref
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      },
      [ref]
    );

    return (
      <ScrollAreaPrimitive.Root
        className={styles.root}
        type={'always'}
        {...props}
      >
        <ScrollAreaPrimitive.Viewport
          className={`${styles.viewport} ${className ?? ''}`}
          ref={setRefs}
          onScroll={handleScroll}
          {...viewportProps}
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        {orientation && ['horizontal', 'both'].includes(orientation) ? (
          <ScrollAreaPrimitive.Scrollbar
            className={styles.scrollbar}
            orientation={'horizontal'}
          >
            <ScrollAreaPrimitive.Thumb className={styles.thumb} />
          </ScrollAreaPrimitive.Scrollbar>
        ) : null}
        {orientation && ['vertical', 'both'].includes(orientation) ? (
          <ScrollAreaPrimitive.Scrollbar
            className={styles.scrollbar}
            orientation={'vertical'}
          >
            <ScrollAreaPrimitive.Thumb className={styles.thumb} />
          </ScrollAreaPrimitive.Scrollbar>
        ) : null}
      </ScrollAreaPrimitive.Root>
    );
  }
);

ScrollArea.displayName = 'ScrollArea';
