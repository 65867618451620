import { Button } from '@lithium/spectrum';
import { useCallback, useState } from 'react';

import { useCurrentUniverse } from '@/hooks/useCurrentUniverse';
import { LabelSwitch } from '@/ui/Switch/LabelSwitch';
import { getAppLocation } from '@/utils/location/getAppLocation';
import { updatePublishUniverse } from '@/utils/universe/universe';

import styles from './PublishMenu.module.css';

export const PublishState = () => {
  const { universe } = useCurrentUniverse();
  const [isDuplicable, setIsDuplicable] = useState(universe?.isDuplicable);
  const publicUrl = `${getAppLocation()}/public/${universe?.id}`;

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(publicUrl);
  }, [publicUrl]);

  const handleUnpublish = useCallback(() => {
    if (universe) {
      updatePublishUniverse(universe, false, false);
    }
  }, [universe]);

  const handleDuplicate = useCallback(
    (value: boolean) => {
      setIsDuplicable(value);
      if (universe) {
        updatePublishUniverse(universe, true, value);
      }
    },
    [universe]
  );

  return (
    <>
      <div className={styles.linkRow}>
        <input className={styles.input} value={publicUrl} readOnly />
        <Button onAction={copyToClipboard}>Copy</Button>
      </div>
      <LabelSwitch checked={isDuplicable} onCheckedChange={handleDuplicate}>
        Allow duplicate
      </LabelSwitch>
      <div className={styles.buttonContainer}>
        <Button onAction={handleUnpublish}>Unpublish</Button>
      </div>
    </>
  );
};
