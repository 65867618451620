import styles from './LoadingView.module.css';

export const LoadingView = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <div className={styles.logo} data-testid={'logo'} />
      </div>
    </div>
  );
};
