import styles from '@/components/stickerErrorBoundary/StickerErrorBoundaryUI.module.css';
import { DialogTitle } from '@/ui/Dialog/Dialog';

interface StickerErrorBoundaryModalProps {
  error: Error;
}

export const StickerErrorBoundaryModal = ({
  error,
}: StickerErrorBoundaryModalProps) => {
  return (
    <div className={styles.wrapper}>
      <DialogTitle> Error: {error.name}</DialogTitle>
      <pre className={styles.trace}>{error.stack}</pre>
    </div>
  );
};
