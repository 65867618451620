import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';

import { Text } from '@/components/fontSystem/Text';

export const TextLabelRegular = forwardRef(
  (
    props: ComponentPropsWithoutRef<'span'>,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    return <Text ref={ref} variant={'label-regular'} {...props} />;
  }
);

TextLabelRegular.displayName = 'TextLabelRegular';
