import Quill, { Delta } from 'quill/core';
import { ClipboardEventHandler, useCallback } from 'react';
import { z } from 'zod';

import { KosmikStickySticker } from '@/utils/kosmik/stickers/sticky';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';
import { parseArrayOfStickers } from '@/utils/sticker/sticker';
import { MaybeUndefined } from '@/utils/types';

export const usePasteCapture = (quill: MaybeUndefined<Quill>) => {
  /**
   * Insert the given text content at the current cursor position
   */
  const insertTextualStickerTextContent = useCallback(
    (textContent: string) => {
      let delta: MaybeUndefined<Delta>;
      let length = 0;
      const selection = quill?.getSelection();
      try {
        // In case the text is valid JSON delta
        const stickerDelta = JSON.parse(textContent);
        delta = new Delta([
          { retain: selection?.index ?? 0 },
          { delete: selection?.length ?? 0 },
          ...stickerDelta.ops,
        ]);
        length = new Delta(stickerDelta).length();
      } catch {
        // Otherwise use as plain text
        delta = new Delta()
          .retain(selection?.index ?? 0)
          .delete(selection?.length ?? 0);
        delta.insert(textContent);
        length = textContent.length;
      }
      if (delta) {
        quill?.updateContents(delta);
        quill?.setSelection((selection?.index ?? 0) + length, 0);
      }
    },
    [quill]
  );

  /**
   * Prevent pasting the JSON of other stickers into the textual stickers
   */
  const handlePasteCapture: ClipboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const types = event.clipboardData.types;
      if (types.includes('text/plain')) {
        const text = event.clipboardData.getData('text/plain');
        const stickers = parseArrayOfStickers(text);
        if (stickers) {
          const [firstSticker] = stickers;
          const isSingleSticker = stickers.length === 1;
          // If we only have one sticker, and it's a textual sticker, insert its
          // text_content instead
          if (firstSticker && isSingleSticker) {
            const parsedTextualSticker = z
              .union([KosmikStickySticker, KosmikTextSticker])
              .safeParse(firstSticker);
            const textContent = parsedTextualSticker.success
              ? parsedTextualSticker.data.attributes.text_content
              : undefined;
            if (textContent) {
              insertTextualStickerTextContent(textContent);
            }
          }
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },
    [insertTextualStickerTextContent]
  );

  return handlePasteCapture;
};
