import { Checkmark } from '@lithium/spectrum';
import * as SelectPrimitive from '@radix-ui/react-select';
import { ItemIndicator } from '@radix-ui/react-select';
import React from 'react';

import { MenuItem, MenuItemProps } from '@/ui/MenuPrimitive/MenuItem';

export interface SelectMenuItemProps
  extends Omit<SelectPrimitive.SelectItemProps, 'prefix'>,
    Omit<MenuItemProps, 'suffix'> {}

export const SelectItem = React.forwardRef<HTMLDivElement, SelectMenuItemProps>(
  ({ prefix, children, ...props }, ref) => {
    const suffix = (
      <ItemIndicator asChild>
        <Checkmark />
      </ItemIndicator>
    );
    return (
      <SelectPrimitive.Item asChild {...props}>
        <MenuItem ref={ref} {...{ prefix, suffix }}>
          <SelectPrimitive.ItemText data-testid={`select-item-${props.value}`}>
            {children}
          </SelectPrimitive.ItemText>
        </MenuItem>
      </SelectPrimitive.Item>
    );
  }
);

SelectItem.displayName = 'SelectMenuItem';
