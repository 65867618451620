/**
 * Closes any open menu or dialog by simulating an Escape key press.
 *
 * This is useful for programmatically closing open menus or dialogs that
 * are designed to close on Escape key press.
 */
export const closeOpenMenuAndDialog = () => {
  const openMenu = document.querySelector(
    ':is([role=menu], [role=dialog])[data-state=open]'
  );
  if (openMenu) {
    const keydown = new KeyboardEvent('keydown', {
      key: 'Escape',
    });
    const keyup = new KeyboardEvent('keyup', {
      key: 'Escape',
    });
    document.dispatchEvent(keydown);
    document.dispatchEvent(keyup);
  }
};
