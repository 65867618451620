import { id } from '@instantdb/react';
import { useParams } from '@tanstack/react-router';
import { useCallback } from 'react';
import { setRecoil } from 'recoil-nexus';

import { localStickerFamily } from '@/atoms/sticker';
import { getUrlOrSearchUrl } from '@/components/EditDock/WebEditDock/utils/search';
import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { usePreferences } from '@/context/Preferences/usePreferences';
import { KosmikWebSticker } from '@/utils/kosmik/stickers/web';
import { SEARCH_ENGINE_SPEC, SearchEngine } from '@/utils/web/search';

export const useCreateLocalWebSticker = () => {
  const { universeId } = useParams({ strict: false });
  const preferences = usePreferences();

  const createLocalWebSticker = useCallback(
    (attributes?: KosmikWebSticker['attributes']) => {
      if (!universeId) {
        return null;
      }

      const searchEngine =
        preferences.search_engine ?? SearchEngine.enum.google;
      const sticker: KosmikWebSticker = {
        id: id(),
        v: 0,
        x: 0,
        y: 0,
        width: DEFAULT_STICKER_SIZE.web.width,
        height: DEFAULT_STICKER_SIZE.web.height,
        type: 'web',
        created_at: new Date().toISOString(),
        attributes: {
          url:
            attributes?.url ??
            (preferences.homepage
              ? getUrlOrSearchUrl({
                  maybeUrl: preferences.homepage,
                  searchEngine,
                })
              : SEARCH_ENGINE_SPEC[searchEngine].url),
          ...attributes,
        },
      };
      setRecoil(localStickerFamily(sticker.id), sticker);

      return sticker.id;
    },
    [preferences.homepage, preferences.search_engine, universeId]
  );

  return createLocalWebSticker;
};
