import { Options, useHotkeys } from 'react-hotkeys-hook';
import { mergeRefs } from 'react-merge-refs';

import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';
import { isInputLike } from '@/utils/element/isInputLike';

export type UseUniverseContextActionShortcutsProps = {
  ignoreEventWhen?: Options['ignoreEventWhen'];
};

export const useUniverseContextActionShortcuts = ({
  ignoreEventWhen = () => false,
}: UseUniverseContextActionShortcutsProps) => {
  const handlers = useUniverseContextActions();

  const selectAllRef = useHotkeys('mod+a', handlers.selectAll, {
    preventDefault: true,
    ignoreEventWhen: (event) => {
      return (
        isInputLike(document.activeElement) ||
        // useHotkeys can incorrectly trigger the shortcut when pressing 'q' or 'a'
        // so we double-check for the event.key property, the original bug
        // we had was that pressing cmd-q in electron would select all stickers
        event.key !== 'a' ||
        ignoreEventWhen(event)
      );
    },
  });

  const selectAllOfTypeRef = useHotkeys(
    'mod+shift+a',
    handlers.selectAllOfType,
    {
      preventDefault: true,
      ignoreEventWhen: (event) =>
        isInputLike(document.activeElement) || ignoreEventWhen(event),
    }
  );

  const deleteStickersRef = useHotkeys(
    ['backspace', 'delete'],
    () => handlers.deleteSelected(),
    {
      preventDefault: true,
      ignoreEventWhen: (event) =>
        isInputLike(document.activeElement) || ignoreEventWhen(event),
    }
  );

  const duplicateStickersRef = useHotkeys('mod+d', handlers.duplicateSelected, {
    preventDefault: true,
    ignoreEventWhen: (event) =>
      isInputLike(document.activeElement) || ignoreEventWhen(event),
  });

  const zoomToFitRef = useHotkeys('alt+f', handlers.zoomToFit, {
    preventDefault: true,
  });

  const deselectAllRef = useHotkeys('escape', handlers.deselectAll, {
    preventDefault: true,
    ignoreEventWhen: (event) =>
      isInputLike(document.activeElement) || ignoreEventWhen(event),
  });

  const undoRef = useHotkeys('mod+z', handlers.undo, {
    preventDefault: true,
    ignoreEventWhen: (event) =>
      isInputLike(document.activeElement) || ignoreEventWhen(event),
  });

  const redoRef = useHotkeys('mod+shift+z', handlers.redo, {
    preventDefault: true,
    ignoreEventWhen: (event) =>
      isInputLike(document.activeElement) || ignoreEventWhen(event),
  });

  return mergeRefs([
    selectAllRef,
    deselectAllRef,
    selectAllOfTypeRef,
    deleteStickersRef,
    duplicateStickersRef,
    zoomToFitRef,
    undoRef,
    redoRef,
  ]);
};
