import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { TextTag } from '@/components/fontSystem/TextTag';
import { useSetStickerProperties } from '@/hooks/sticker/useSetStickerProperties';
import { greeting } from '@/utils/greeting';
import { KosmikPromptSticker } from '@/utils/kosmik/stickers/prompt';

import styles from './HtmlPromptSticker.module.css';

export const HtmlPromptSticker = ({
  sticker,
}: {
  sticker: KosmikPromptSticker;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerAttributes =
    useSetStickerProperties<KosmikPromptSticker>(setSticker);

  const response = sticker.attributes?.response;

  useEffect(() => {
    if (ref.current?.scrollHeight) {
      const payload: Partial<KosmikPromptSticker> = {};
      payload.height = ref.current?.scrollHeight;
      setStickerAttributes(payload, true);
    }
  }, [setStickerAttributes, sticker.attributes.response]);

  return (
    <div ref={ref} className={`${styles.plainWrapper}`}>
      <div className={`${styles.promptWrapper}`}>
        {response ? (
          <div className={`${styles.response}`}>
            <TextTag style={{ fontStyle: 'italic', whiteSpace: 'pre-line' }}>
              <i>{response}</i>
            </TextTag>
          </div>
        ) : (
          <div className={`${styles.response}`}>
            <TextTag style={{ fontStyle: 'italic' }}>
              <i>{greeting()}, how can I help you today?</i>
            </TextTag>
          </div>
        )}
      </div>
    </div>
  );
};
