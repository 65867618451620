import { Fragment } from 'react';

import { TextBodySemibold } from '@/components/fontSystem/BodySemibold';
import { TextTag } from '@/components/fontSystem/TextTag';
import { CommonModalProps } from '@/components/modal/Modals';
import { TeamMemberCell } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamMembersSettings/TeamMemberCell';
import { TeamRoleDropdown } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamMembersSettings/TeamRoleDropdown';
import { useTeam } from '@/context/Team/useTeam';
import { useIsDev } from '@/hooks/env/useIsDev';
import { DialogSection } from '@/ui/Dialog/DialogSection';
import { MenuSeparator } from '@/ui/MenuPrimitive/MenuSeparator';
import { TeamRole } from '@/utils/kosmik/team';
import { KosmikUser } from '@/utils/kosmik/user';
import { getAllTeamUsers, getTeamUserRole } from '@/utils/team/team';

export const TeamMemberList = ({ closeModal }: CommonModalProps) => {
  const { team } = useTeam();
  const isDev = useIsDev();
  const teamUsers = getAllTeamUsers(team);

  const getMemberCellSuffix = (user: KosmikUser) => {
    const role = getTeamUserRole(team, user);
    if (role === TeamRole.owner) {
      return <TextTag style={{ padding: '4px 7px' }}>Owner</TextTag>;
    }
    return isDev ? (
      <TeamRoleDropdown userId={user.id} closeModal={closeModal} />
    ) : null;
  };

  return (
    <DialogSection
      title={<TextBodySemibold>Current members</TextBodySemibold>}
      scrollable
      scrollAreaProps={{
        type: 'hover',
        viewportProps: { style: { maxHeight: '20vh' } },
      }}
    >
      {teamUsers.map((user, index, sourceArray) => {
        const showDivider = index < sourceArray.length - 1;
        return (
          <Fragment key={user.id}>
            <TeamMemberCell user={user} suffix={getMemberCellSuffix(user)} />
            {showDivider ? <MenuSeparator /> : null}
          </Fragment>
        );
      })}
    </DialogSection>
  );
};
