import { tx } from '@instantdb/react';
import { Button } from '@lithium/spectrum';
import { Fragment } from 'react';

import { TextBodySemibold } from '@/components/fontSystem/BodySemibold';
import { TeamMemberCell } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamMembersSettings/TeamMemberCell';
import { useTeam } from '@/context/Team/useTeam';
import { DialogSection } from '@/ui/Dialog/DialogSection';
import { MenuSeparator } from '@/ui/MenuPrimitive/MenuSeparator';
import { batchTransact } from '@/utils/db/db';

export const TeamInvitedMemberList = () => {
  const { team } = useTeam();

  const invitedMembers =
    team.sent_invitations?.flatMap((invite) => invite.receiver) ?? [];

  return invitedMembers.length ? (
    <DialogSection
      title={<TextBodySemibold>Invited users</TextBodySemibold>}
      scrollable
      scrollAreaProps={{
        type: 'hover',
        viewportProps: { style: { maxHeight: '20vh' } },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {[...invitedMembers].map((user, index, sourceArray) => {
          const showDivider = index < sourceArray.length - 1;
          const handleRevokeInvite = () => {
            const invite = user.team_invitations?.[0];
            if (invite) {
              batchTransact(tx.team_invite?.[invite.id]?.delete());
            }
          };
          return (
            <Fragment key={user.id}>
              <TeamMemberCell
                user={user}
                suffix={
                  <Button variant={'destructive'} onAction={handleRevokeInvite}>
                    Revoke invite
                  </Button>
                }
              />
              {showDivider ? <MenuSeparator /> : null}
            </Fragment>
          );
        })}
      </div>
    </DialogSection>
  ) : null;
};
