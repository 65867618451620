import * as Form from '@radix-ui/react-form';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { FormFieldProps } from '@/ui/Form/FormFieldProps';
import { FormStack } from '@/ui/Form/FormStack';

import styles from './FormField.module.css';

export const EmailFormField = ({
  serverError,
  onChange,
  showLabel,
}: FormFieldProps) => (
  <Form.Field name={'email'} serverInvalid={serverError} asChild>
    <FormStack>
      {showLabel ? (
        <Form.Label>
          <TextLabelRegular style={{ fontWeight: '600' }}>
            Email
          </TextLabelRegular>
        </Form.Label>
      ) : null}

      <Form.Message className={styles.message} match={'valueMissing'} asChild>
        <TextLabelRegular data-testid={'email-value-missing'}>
          Please enter your email
        </TextLabelRegular>
      </Form.Message>
      <Form.Message className={styles.message} match={'typeMismatch'} asChild>
        <TextLabelRegular data-testid={'email-type-mismatch'}>
          Please provide a valid email
        </TextLabelRegular>
      </Form.Message>

      <Form.Control asChild>
        <input
          style={{ textTransform: 'lowercase' }}
          autoFocus
          type={'email'}
          required
          placeholder={'Your email address'}
          onChange={onChange}
        />
      </Form.Control>
    </FormStack>
  </Form.Field>
);
