import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

type RouterContext = {
  authenticated: boolean;
};

const RootComponent = () => {
  return <Outlet />;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
});
