import debounce from 'debounce';
import { PDFPageProxy, RenderTask } from 'pdfjs-dist';
import { MutableRefObject, useEffect } from 'react';

import {
  DATA_ATTRIBUTE_PAGE,
  DATA_ATTRIBUTE_RENDERED,
  DATA_ATTRIBUTE_VISIBLE,
} from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';
import { renderPageAndTextLayer } from '@/components/HTMLCanvas/PinPanel/PdfViewer/utils/RenderPageAndTextLayer';
import { IterableRefsMapKey, useIterableRefs } from '@/hooks/useIterableRefs';
import { isDefined } from '@/utils/isDefined';

export const useResizePage = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  pdfPromiseRefs: Map<IterableRefsMapKey, RenderTask>,
  getPdfPromiseRef: (id: IterableRefsMapKey) => (node: RenderTask) => void,
  pageProxies: PDFPageProxy[],
  pageRefs: Map<IterableRefsMapKey, HTMLDivElement>
) => {
  const { refs: pageHeightRefs, getRef: getPageHeightRef } =
    useIterableRefs<number>();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const onResize = (entries: ResizeObserverEntry[]) => {
        entries.forEach((entry) => {
          const { target } = entry;
          if (target instanceof HTMLElement) {
            delete target.dataset[DATA_ATTRIBUTE_RENDERED];
            const pageNumber = Number(target.dataset[DATA_ATTRIBUTE_PAGE]);
            const visible = isDefined(target.dataset[DATA_ATTRIBUTE_VISIBLE]);
            const lastHeight = pageHeightRefs.get(pageNumber);
            const height = entry.contentRect.height;
            if (visible && (!lastHeight || Math.abs(lastHeight - height) > 5)) {
              const getPdfPromise = () => pdfPromiseRefs.get(pageNumber);
              const setPdfPromise = getPdfPromiseRef(pageNumber);
              const pageProxy = pageProxies[pageNumber - 1];
              if (pageProxy) {
                renderPageAndTextLayer({
                  container: target,
                  pageProxy,
                  getPdfPromise,
                  setPdfPromise,
                });
              }
            }
            const pageHeightRef = getPageHeightRef(pageNumber);
            pageHeightRef(height);
          }
        });
      };

      const resizeObserver = new ResizeObserver(debounce(onResize, 100));

      [...pageRefs.values()].forEach((page) => {
        resizeObserver.observe(page);
      });

      return () => {
        resizeObserver.disconnect();
        [...pdfPromiseRefs.values()].forEach((pdfPromise) => {
          pdfPromise.cancel();
        });
      };
    }
  }, [
    containerRef,
    getPageHeightRef,
    getPdfPromiseRef,
    pageHeightRefs,
    pageProxies,
    pageRefs,
    pdfPromiseRefs,
  ]);
};
