import { RefObject, useContext, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { cameraAtom } from '@/atoms/camera';
import { followingAtom } from '@/atoms/multiplayer';
import { pinnedStickerIdAtom } from '@/atoms/pinpanel';
import { selectedStickerIdsAtom, selectionAreaAtom } from '@/atoms/selection';
import { useRoom } from '@/context/Room/useRoom';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { usePointerPosition } from '@/hooks/pointer/usePointerPosition';
import { screenToCanvas } from '@/utils/camera/camera';
import { Size } from '@/utils/geometry/size';
import { getUiVisibleDimensions } from '@/utils/ui/ui';

export const useMultiplayerPresence = (ref: RefObject<HTMLElement>) => {
  const { room, universeId } = useRoom();
  const camera = useRecoilValue(cameraAtom);
  const position = usePointerPosition({ ref });
  const user = useContext(MultiplayerUserContext);
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const selectionArea = useRecoilValue(selectionAreaAtom);
  const following = useRecoilValue(followingAtom);
  const pinnedStickerId = useRecoilValue(pinnedStickerIdAtom);

  // Write only usePresence hook
  const { isLoading, publishPresence } = room.usePresence({
    peers: [],
    user: false,
  });

  // When position updates send updated position to room
  // TODO: only publish changed values?
  useEffect(() => {
    if (!isLoading && user) {
      const point = screenToCanvas(position, camera);
      const viewport: Size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      publishPresence({
        // ...multiplayerUser,
        user,
        x: point.x,
        y: point.y,
        selections: [...selectedStickerIds],
        selectionArea,
        camera,
        viewport,
        canvas: getUiVisibleDimensions().canvas,
        following: following ? following.kosmikPeerId : null,
        pinnedStickerId,
      });
    }
    // publishPresence shouldn't be part of the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    camera,
    pinnedStickerId,
    selectedStickerIds,
    selectionArea,
    user,
    position.x,
    position.y,
    isLoading,
  ]);

  useEffect(() => {
    const resetPresence = () => {
      // Remove user from the universe room presence
      publishPresence({
        user: undefined,
        x: undefined,
        y: undefined,
        selections: [],
        selectionArea: undefined,
        camera: undefined,
        viewport: undefined,
        canvas: undefined,
        following: undefined,
        pinnedStickerId: undefined,
      });
    };

    window.addEventListener('beforeunload', resetPresence);

    return () => {
      window.removeEventListener('beforeunload', resetPresence);
      resetPresence();
    };
    // publishPresence shouldn't be part of the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universeId]);
};
