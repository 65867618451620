import { ChangeEvent, FormEvent, useCallback, useRef, useState } from 'react';

import styles from '@/components/modal/modals/Settings/CreateTeam/CreateTeam.module.css';
import { TeamColorRow } from '@/components/modal/modals/Settings/CreateTeam/TeamColorRow';
import { useTeam } from '@/context/Team/useTeam';
import { useUser } from '@/context/User/useUser';
import { createTeam } from '@/utils/team/create';
import { MaybeUndefined } from '@/utils/types';

export type CreatTeamFormProps = {
  closeModal?: () => void;
  formId: string;
};

export const CreateTeamForm = ({ closeModal, formId }: CreatTeamFormProps) => {
  const user = useUser();
  const { setTeam } = useTeam();
  const [color, setColor] = useState('auto');
  const [name, setName] = useState<MaybeUndefined<string>>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName ? newName : undefined);
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement> | Event) => {
      event.preventDefault();
      if (user && name) {
        const newTeam = await createTeam({
          name,
          color: color !== 'auto' ? color : undefined,
          owner: user,
        });
        setTeam(newTeam);
        closeModal?.();
      }
    },
    [closeModal, color, name, setTeam, user]
  );

  return (
    <>
      <div className={styles.centerColumn}>
        <TeamColorRow name={name} color={color} setColor={setColor} />
      </div>
      <form id={formId} className={styles.formColumn} onSubmit={handleSubmit}>
        <input
          ref={inputRef}
          type={'text'}
          name={'name'}
          onChange={handleChange}
          placeholder={'Workspace Name'}
          className={styles.input}
        />
      </form>
    </>
  );
};
