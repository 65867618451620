import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useOnlineStatus } from '@/hooks/online/useOnlineStatus';
import formFieldStyles from '@/ui/Form/FormField.module.css';

export const OfflineMessage = () => {
  const online = useOnlineStatus();
  return !online ? (
    <div className={formFieldStyles.message}>
      <TextLabelRegular>
        You are offline, please try again later
      </TextLabelRegular>
    </div>
  ) : null;
};
