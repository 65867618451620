import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import React from 'react';

import { ContextMenuContent } from '@/components/ui/ContextMenu/ContextMenuContent';

export interface ContextMenuProps
  extends ContextMenuPrimitive.ContextMenuProps,
    React.PropsWithChildren<{
      trigger: React.ReactNode;
      triggerAsChild?: boolean;
      contentProps?: ContextMenuPrimitive.ContextMenuContentProps;
    }> {}

export const ContextMenu = React.forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ contentProps, trigger, triggerAsChild, children, ...props }, ref) => {
    return (
      <ContextMenuPrimitive.Root {...props}>
        <ContextMenuPrimitive.Trigger asChild={triggerAsChild}>
          {trigger}
        </ContextMenuPrimitive.Trigger>

        <ContextMenuPrimitive.Portal>
          <ContextMenuContent ref={ref} collisionPadding={4} {...contentProps}>
            {children}
          </ContextMenuContent>
        </ContextMenuPrimitive.Portal>
      </ContextMenuPrimitive.Root>
    );
  }
);

ContextMenu.displayName = 'ContextMenu';
