import { useParams } from '@tanstack/react-router';
import { useCallback } from 'react';

import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import {
  autolayout,
  AutoLayoutType,
  handlePostLayoutStickers,
} from '@/utils/autolayout/autolayout';
import { KosmikSticker } from '@/utils/kosmik/sticker';

export const useUndoableAutoLayout = () => {
  const { universeId } = useParams({ strict: false });
  const { addUndoRedoAction } = useUndoRedo(universeId);

  const undoableAutoLayout = useCallback(
    (layoutType: AutoLayoutType, stickers: KosmikSticker[]) => {
      const postLayoutStickers = autolayout(layoutType, stickers);
      const autoLayoutAction: UndoRedoActionType = {
        do: () => {
          handlePostLayoutStickers(postLayoutStickers, stickers);
        },
        undo: () => {
          handlePostLayoutStickers(stickers, postLayoutStickers);
        },
      };

      autoLayoutAction.do();
      addUndoRedoAction(autoLayoutAction);
    },
    [addUndoRedoAction]
  );

  return { undoableAutoLayout };
};
