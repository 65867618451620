import { tx } from '@instantdb/react';
import { ChangeEvent, useMemo } from 'react';

import { TextBodyRegular } from '@/components/fontSystem/BodyRegular';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import browserSettingsStyles from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/InterfaceSettings/BrowserSettings/BrowserSettings.module.css';
import { usePreferences } from '@/context/Preferences/usePreferences';
import { useUser } from '@/context/User/useUser';
import { DialogSection } from '@/ui/Dialog/DialogSection';
import { Select } from '@/ui/Select/Select';
import { SelectItem } from '@/ui/Select/SelectItem/SelectItem';
import { shuffleArray } from '@/utils/array/shuffleArray';
import { batchTransact } from '@/utils/db/db';
import { isValidUrl } from '@/utils/url/url';
import { SEARCH_ENGINE_SPEC, SearchEngine } from '@/utils/web/search';

export const BrowserSettings = () => {
  const preferences = usePreferences();
  const user = useUser();

  const shuffledEngine = useMemo(
    () => shuffleArray(Object.entries(SEARCH_ENGINE_SPEC)),
    []
  );

  const updateSearchEnginePreference = (value: SearchEngine) => {
    if (!user?.id) {
      return;
    }
    batchTransact([
      tx.preferences?.[preferences.id]?.update({ search_engine: value }),
    ]);
  };

  const handleHomepageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (!user?.id) {
      return;
    }

    if (!isValidUrl(value)) {
      event.currentTarget.setCustomValidity('Invalid URL');
    } else {
      event.currentTarget.setCustomValidity('');
    }

    batchTransact([
      tx.preferences?.[preferences.id]?.update({ homepage: value }),
    ]);
  };

  return (
    <DialogSection title={'Browser'}>
      <div className={styles.settingsPageFieldRow}>
        <TextBodyRegular className={styles.settingsPageFieldName}>
          Search Engine
        </TextBodyRegular>
        <div className={styles.settingsPageFieldInput}>
          <Select
            placeholder={undefined}
            value={preferences.search_engine}
            onValueChange={updateSearchEnginePreference}
          >
            {shuffledEngine.map(([key, searchEngine]) => (
              <SelectItem key={key} value={key}>
                <div className={browserSettingsStyles.container}>
                  <img
                    alt={searchEngine.name}
                    src={searchEngine.image}
                    className={browserSettingsStyles.image}
                  />
                  <span className={browserSettingsStyles.text}>
                    {searchEngine.name}
                  </span>
                </div>
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>

      <div className={styles.settingsPageFieldRow}>
        <TextBodyRegular className={styles.settingsPageFieldName}>
          Homepage
        </TextBodyRegular>
        <input
          type={'url'}
          name={'url'}
          id={'url'}
          pattern={'https://.*'}
          value={preferences.homepage}
          className={styles.settingsPageFieldInput}
          onChange={handleHomepageChange}
          placeholder={
            SEARCH_ENGINE_SPEC[preferences.search_engine ?? 'google'].url
          }
        />
      </div>
    </DialogSection>
  );
};
