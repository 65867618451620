import { ContextMenu } from '@/ui/ContextMenu/ContextMenu';
import { ContextMenuContent } from '@/ui/ContextMenu/ContextMenuContent';
import { ContextMenuItem } from '@/ui/ContextMenu/ContextMenuItem';
import { ContextMenuLabel } from '@/ui/ContextMenu/ContextMenuLabel';
import { ContextMenuSeparator } from '@/ui/ContextMenu/ContextMenuSeparator';
import { ContextMenuSub } from '@/ui/ContextMenu/ContextMenuSub';
import { ContextMenuSubContent } from '@/ui/ContextMenu/ContextMenuSubContent';
import { ContextMenuSubTrigger } from '@/ui/ContextMenu/ContextMenuSubTrigger';

export const ContextMenuComponentMap = {
  Menu: ContextMenu,
  Content: ContextMenuContent,
  Item: ContextMenuItem,
  Label: ContextMenuLabel,
  Separator: ContextMenuSeparator,
  Sub: ContextMenuSub,
  SubContent: ContextMenuSubContent,
  SubTrigger: ContextMenuSubTrigger,
} as const;
