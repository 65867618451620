import { MaybeUndefined } from 'src/utils/types';

import { LAYOUT_GUTTER } from '@/constants/layout';
import { SpatialHash } from '@/utils/autolayout/SpatialHash';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import {
  Dimension,
  getBiggestRect,
  getCenter,
  isIntersecting,
} from '@/utils/geometry/dimension';
import { Point } from '@/utils/geometry/point';
import { isDefined } from '@/utils/isDefined';

export type Vertex = [number, number, number];

const getVertexDistanceFromPoint = (vertex: Vertex, point: Point) => {
  const [x, y] = vertex;
  return Math.hypot(point.x - x, point.y - y);
};

const getSortVerticesByDistance = (point: Point) => (a: Vertex, b: Vertex) => {
  const distanceToCenterA = getVertexDistanceFromPoint(a, point);
  const distanceToCenterB = getVertexDistanceFromPoint(b, point);
  return distanceToCenterA - distanceToCenterB;
};

// Function to calculate the coordinates of the vertex based on quadrant and offset
function getVertexCoordinates(
  vertex: Vertex,
  quadrant: number,
  currentBox: Dimension
): [MaybeUndefined<number>, MaybeUndefined<number>] {
  const offset = LAYOUT_GUTTER; // You can adjust the offset as needed
  let x, y;
  switch (vertex[2]) {
    case 0:
      if (quadrant === 0) {
        x = vertex[0] - currentBox.width - offset;
        y = vertex[1] - currentBox.height - offset;
      } else if (quadrant === 1) {
        x = vertex[0] - currentBox.width;
        y = vertex[1] - currentBox.height - offset;
      } else if (quadrant === 3) {
        x = vertex[0] - currentBox.width - offset;
        y = vertex[1] - currentBox.height;
      }
      break;
    case 1:
      if (quadrant === 0) {
        x = vertex[0] - currentBox.width;
        y = vertex[1] - currentBox.height - offset;
      } else if (quadrant === 1) {
        x = vertex[0] + offset;
        y = vertex[1] - currentBox.height - offset;
      } else if (quadrant === 2) {
        x = vertex[0] + offset;
        y = vertex[1];
      }
      break;
    case 2:
      if (quadrant === 1) {
        x = vertex[0] + offset;
        y = vertex[1] - currentBox.height;
      } else if (quadrant === 2) {
        x = vertex[0] + offset;
        y = vertex[1] + offset;
      } else if (quadrant === 3) {
        x = vertex[0] - currentBox.width;
        y = vertex[1] + offset;
      }
      break;
    case 3:
      if (quadrant === 0) {
        x = vertex[0] - currentBox.width - offset;
        y = vertex[1] - currentBox.height;
      } else if (quadrant === 2) {
        x = vertex[0];
        y = vertex[1] + offset;
      } else if (quadrant === 3) {
        x = vertex[0] - currentBox.width - offset;
        y = vertex[1] + offset;
      }
      break;
    default:
      x = undefined;
      y = undefined;
  }
  return [x, y];
}

// Function to implement the layout algorithm
export const runOrbitalLayout = <T extends Dimension>(inputBoxes: T[]): T[] => {
  // duplicate the boxes to remove read-only
  const boxes = structuredClone(inputBoxes);
  const positionedBoxes: Set<T> = new Set();
  const notPositionedBoxes = new Set(boxes);
  const boundingBox = getBoundingBox(boxes);
  if (!boundingBox) {
    return [];
  }
  const center = getCenter(boundingBox);
  const hash = new SpatialHash(100);

  // Step 1: Start with the biggest box
  const firstBox = getBiggestRect(boxes);
  if (!firstBox) {
    return [];
  }
  firstBox.x = center.x - firstBox.width / 2;
  firstBox.y = center.y - firstBox.height / 2;
  positionedBoxes.add(firstBox);
  notPositionedBoxes.delete(firstBox);
  // const center = getCenter(firstBox);

  // Step 2: Place the next box to the right of the first positioned box
  const [secondBox] = notPositionedBoxes;
  if (secondBox) {
    secondBox.x = firstBox.x + firstBox.width + LAYOUT_GUTTER;
    secondBox.y = firstBox.y;
    positionedBoxes.add(secondBox);
    notPositionedBoxes.delete(secondBox);
  }

  // Step 3 and 4: Pick and place boxes at the closest vertex without overlap
  const tryToPlaceBox = (
    currentBox: T,
    vertices: [number, number, number][]
  ) => {
    const [closestVertex, ...remainingVertices] = vertices;
    if (!closestVertex) {
      return;
    }
    let placed = false;
    for (let j = 0; j < 4; j++) {
      const [x, y] = getVertexCoordinates(closestVertex, j, currentBox);
      if (!isDefined(x) || !isDefined(y)) {
        continue;
      }
      currentBox.x = x;
      currentBox.y = y;
      const query = hash.query(
        {
          x: currentBox.x + currentBox.width / 2,
          y: currentBox.y + currentBox.height / 2,
        },
        Math.max(currentBox.width / 2, currentBox.height / 2)
      );
      if (
        query.length === 0 ||
        query.every((box) => !isIntersecting(box, currentBox))
      ) {
        positionedBoxes.add(currentBox);
        notPositionedBoxes.delete(currentBox);
        placed = true;
        break;
      }
    }
    if (!placed) {
      tryToPlaceBox(currentBox, remainingVertices);
    }
  };

  for (const currentBox of notPositionedBoxes) {
    const vertices: [number, number, number][] = [];
    hash.create([...positionedBoxes]);
    positionedBoxes.forEach((box) => {
      vertices.push(
        [box.x, box.y, 0],
        [box.x + box.width, box.y, 1],
        [box.x + box.width, box.y + box.height, 2],
        [box.x, box.y + box.height, 3]
      );
    });
    vertices.sort(getSortVerticesByDistance(center)).sort((a, b) => {
      const queryA = hash.query({ x: a[0], y: a[1] });
      const queryB = hash.query({ x: b[0], y: b[1] });
      const diff = Math.max(queryA.length, 2) - Math.max(queryB.length, 2);
      return diff === 0 ? 1 : diff;
    });

    tryToPlaceBox(currentBox, vertices);
  }

  return [...positionedBoxes];
};
