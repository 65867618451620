import { useIsomorphicLayoutEffect } from 'usehooks-ts';

import { useColorScheme } from '@/utils/css/hooks/useColorScheme';
import { isWeb } from '@/utils/platform/isWeb';

export const useSyncColorScheme = () => {
  const { colorScheme, colorMode } = useColorScheme();
  useIsomorphicLayoutEffect(() => {
    if (!isWeb) {
      window.electron.ipcRenderer.invoke(`set-theme-${colorMode}`);
    }
  }, [colorMode]);

  useIsomorphicLayoutEffect(() => {
    document.body.classList.add(
      colorScheme === 'light' ? 'light-scheme' : 'dark-scheme'
    );
    document.body.classList.remove(
      colorScheme === 'dark' ? 'light-scheme' : 'dark-scheme'
    );
  }, [colorScheme]);

  useIsomorphicLayoutEffect(() => {
    document.body.classList.add(isWeb ? 'web' : 'electron');
  }, []);
};
