import { useRef } from 'react';
import { useRecoilState } from 'recoil';

import { cssVariablesAtom } from '@/atoms/canvas';
import { useUpdateVariables } from '@/components/HTMLCanvas/hooks/useUpdateVariables';
import { useMoveTransformHooks } from '@/components/Universe/Selection/hooks/useMoveTransformHooks';
import { MovePayload } from '@/components/Universe/Selection/movePayload';
import { TransformPayload } from '@/components/Universe/Selection/TransformHandle';
import { useRoom } from '@/context/Room/useRoom';

export const useMultiplayerCSSVariables = () => {
  const { room } = useRoom();
  const commitIds = useRef(new Set<string>());
  const [variables, setVariables] = useRecoilState(cssVariablesAtom);
  const { moveLocalStickers, transformLocalStickers } = useMoveTransformHooks();
  const { updateMoveVariables, updateTransformVariables } =
    useUpdateVariables();

  room.useTopicEffect('move', (event, peer) => {
    if (commitIds.current.has(event.commitId)) {
      return;
    }

    if (peer.user) {
      updateMoveVariables(event, peer.user);
    }
  });

  room.useTopicEffect('transform', (event, peer) => {
    if (commitIds.current.has(event.commitId)) {
      return;
    }

    if (peer.user) {
      updateTransformVariables(peer.selections, event, peer.user);
    }
  });

  room.useTopicEffect('commit', (event, peer) => {
    const parsedMovePayload = MovePayload.safeParse(event);
    const parsedTransformPayload = TransformPayload.safeParse(event);
    commitIds.current.add(event.commitId);

    if (parsedMovePayload.success) {
      moveLocalStickers(peer.selections, parsedMovePayload.data);
    } else if (parsedTransformPayload.success) {
      transformLocalStickers(peer.selections, parsedTransformPayload.data);
    } else {
      console.log('Invalid payload', {
        event,
        parsedMovePayload,
        parsedTransformPayload,
      });
    }

    setVariables((prev) => {
      const newVariables = new Map(prev);
      const id = peer.user?.peerId;
      if (id) {
        newVariables.delete(`--drag-${id}-offset-x`);
        newVariables.delete(`--drag-${id}-offset-y`);
        newVariables.delete(`--transform-${id}-scale-x`);
        newVariables.delete(`--transform-${id}-scale-y`);
        newVariables.delete(`--transform-${id}-new-bbox-x`);
        newVariables.delete(`--transform-${id}-new-bbox-y`);
        newVariables.delete(`--transform-${id}-new-bbox-width`);
        newVariables.delete(`--transform-${id}-new-bbox-height`);
        newVariables.delete(`--transform-${id}-new-bbox-x-no-unit`);
        newVariables.delete(`--transform-${id}-new-bbox-y-no-unit`);
        newVariables.delete(`--transform-${id}-new-bbox-width-no-unit`);
        newVariables.delete(`--transform-${id}-new-bbox-height-no-unit`);
        newVariables.delete(`--transform-${id}-initial-bbox-x`);
        newVariables.delete(`--transform-${id}-initial-bbox-y`);
        newVariables.delete(`--transform-${id}-initial-bbox-width`);
        newVariables.delete(`--transform-${id}-initial-bbox-height`);
        newVariables.delete(`--transform-${id}-initial-bbox-x-no-unit`);
        newVariables.delete(`--transform-${id}-initial-bbox-y-no-unit`);
        newVariables.delete(`--transform-${id}-initial-bbox-width-no-unit`);
        newVariables.delete(`--transform-${id}-initial-bbox-height-no-unit`);
      }

      return newVariables;
    });
  });

  return variables;
};
