import { Size } from '@/utils/geometry/size';

export type EmbedProviderName =
  | 'YouTube'
  | 'Spotify'
  | 'X'
  | 'TikTok'
  | 'Figma';

export type EmbedProviderBase = {
  name: EmbedProviderName;
  embedRegExp: RegExp;
  resizeable: boolean;
};

export type OEmbedProvider = EmbedProviderBase & {
  getOEmbedUrl: (url: string) => string;
};

export type IFrameEmbedProvider = EmbedProviderBase & {
  getIframeHtml: (url: string) => string;
  getSize: () => Size;
};

export type EmbedProvider = OEmbedProvider | IFrameEmbedProvider;

const youtubeProvider: EmbedProvider = {
  name: 'YouTube',
  embedRegExp:
    /https?:\/\/(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)(?:&(.*=.+))*/i,
  getOEmbedUrl: (url) =>
    `https://www.youtube.com/oembed?url=${url}&format=json`,
  resizeable: true,
};

const spotifyProvider: EmbedProvider = {
  name: 'Spotify',
  embedRegExp: new RegExp(
    'https?://(www|open|play)\\.?spotify\\.com/(artist|track|playlist|show|album)/([\\w\\-/]+)',
    'i'
  ),
  getOEmbedUrl: (url) => `https://open.spotify.com/oembed?url=${url}`,
  resizeable: false,
};

const twitterProvider: EmbedProvider = {
  name: 'X',
  embedRegExp:
    /https?:\/\/(?:www\.)?(twitter|x)\.com\/\w+\/status\/[0-9]+(?:\?s=[0-9]+)?/i,
  getOEmbedUrl: (url) => `https://publish.twitter.com/oembed?url=${url}`,
  resizeable: false,
};

const tiktokProvider: EmbedProvider = {
  name: 'TikTok',
  embedRegExp: /https:\/\/(?:www.)?tiktok.com\/@\w+\/video\/(\d+)/i,
  getOEmbedUrl: (url) => `https://www.tiktok.com/oembed?url=${url}`,
  resizeable: false,
};

const figmaProvider: EmbedProvider = {
  name: 'Figma',
  embedRegExp:
    /https:\/\/[\w.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/,
  getIframeHtml: (url) =>
    `<iframe src="https://www.figma.com/embed?embed_host=your-site-name-here&embed_origin=your-domain-name.here&url=${url}"></iframe>`,
  getSize: () => ({ width: 800, height: 450 }),
  resizeable: true,
};

export const embedProviders: EmbedProvider[] = [
  youtubeProvider,
  spotifyProvider,
  twitterProvider,
  tiktokProvider,
  figmaProvider,
];

export const autoSizeProviders: EmbedProvider[] = [
  twitterProvider,
  tiktokProvider,
];
