import { Close } from '@lithium/spectrum';
import { useRecoilState } from 'recoil';

import { metadataOpenAtom } from '@/atoms/metadata';
import { TextLabelMedium } from '@/components/fontSystem/LabelMedium';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { db } from '@/db/databaseInit.ts';
import { KosmikSticker } from '@/utils/kosmik/sticker';

import styles from './MetadataPanel.module.css';
import { Tag } from './Tag';

export type MetadataPanelProps = {
  sticker: KosmikSticker;
};

export const MetadataPanel = ({ sticker }: MetadataPanelProps) => {
  const [metadataOpen, setMetadataOpen] = useRecoilState(metadataOpenAtom);
  const { data } = db.useQuery({
    tags: {
      $: {
        where: {
          'stickers.id': sticker.id,
        },
      },
    },
  });

  return metadataOpen ? (
    <div className={styles.metadataPanel}>
      <Close
        style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}
        onClick={() => setMetadataOpen(false)}
      />
      <section className={styles.section}>
        <TextLabelMedium>Description</TextLabelMedium>
        <TextLabelRegular>{sticker.description}</TextLabelRegular>
      </section>
      {/*<section className={styles.section}>*/}
      {/*  <TextLabelMedium>Tags</TextLabelMedium>*/}
      {/*  <div className={styles.tagRow}>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className={styles.section}>
        <TextLabelMedium>Generated tags</TextLabelMedium>
        <div className={styles.tagRow}>
          {data?.tags.map((tag) => {
            return <Tag text={tag.name} key={tag.id} />;
          })}
        </div>
      </section>
    </div>
  ) : null;
};
