export const MOTD = () => {
  const defaultStyle = 'font-family: monospace; font-weight: normal';
  const bold = `${defaultStyle}; font-weight: bold;`;

  console.log(
    `%c
    ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
 ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
▓▓▓▓       ▓▓▓▓▓▓▓░      ▓▓▓▓
▓▓▓▓       ▓▓▓▓▓▓▓       ▓▓▓▓
▓▓▓▓░      ▓▓▓▓▓▓▓      ░▓▓▓▓
▓▓▓▓▓▒░     ░▒▓▒░     ░▒▓▓▓▓▓
▓▓▓▓▒░             ░▒▓▓▓▓▓▓▓▓    There is only one speed:
▓▓▓▓░             ░▒▓▓▓▓▓▓▓▓▓    %cInstant 🚀 %c
▓▓▓▓▒░             ░▒▓▓▓▓▓▓▓▓
▓▓▓▓▓▒░     ░▒▓▒░     ░▒▓▓▓▓▓
▓▓▓▓░      ▓▓▓▓▓▓▓      ░▓▓▓▓
▓▓▓▓       ▓▓▓▓▓▓▓       ▓▓▓▓
▓▓▓▓       ▓▓▓▓▓▓▓░      ▓▓▓▓
 ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
    ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓
    
`,
    defaultStyle,
    bold,
    defaultStyle
  );
};
