import { PDFPageProxy } from 'pdfjs-dist';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { getPdfViewerHtmlPage } from '@/components/HTMLCanvas/PinPanel/PdfViewer/utils/getPdfViewerHtmlPage';
import { IterableRefsMapKey } from '@/hooks/useIterableRefs';
import { round } from '@/utils/math/round';

export const useLocalCurrentPage = (
  scrollingAreaRef: MutableRefObject<HTMLDivElement | null>,
  pagesRefs: Map<IterableRefsMapKey, HTMLDivElement>,
  pageProxies: PDFPageProxy[]
) => {
  // We keep a ref to the last user-set page, so we can scroll to new pages only
  const lastUserSetPageRef = useRef(1);
  const [currentPage, setCurrentPage] = useState(1);
  const visiblePagesRef = useRef(
    new Map<HTMLElement, IntersectionObserverEntry>()
  );

  /**
   * Scrolls to the given page
   */
  const handlePageChange = useCallback(
    (newPage: number) => {
      const viewer = scrollingAreaRef.current;
      if (!viewer) {
        return;
      }
      const htmlPage = getPdfViewerHtmlPage(viewer, newPage);
      if (htmlPage && htmlPage instanceof HTMLElement) {
        const scrollTo = htmlPage.offsetTop;
        viewer.scrollTo({ top: scrollTo });
        lastUserSetPageRef.current = newPage;
        setCurrentPage(newPage);
      }
    },
    [scrollingAreaRef, setCurrentPage]
  );

  /**
   * Scroll to the new currentPage if applicable
   */
  useEffect(() => {
    if (currentPage !== lastUserSetPageRef.current) {
      handlePageChange(currentPage);
    }
  }, [currentPage, handlePageChange]);

  /**
   * Intersection observer to watch for the current page in scrolling area
   */
  useEffect(() => {
    const scrollArea = scrollingAreaRef.current;
    if (scrollArea) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target instanceof HTMLElement) {
              if (entry.isIntersecting) {
                visiblePagesRef.current.set(entry.target, entry);
              } else {
                visiblePagesRef.current.delete(entry.target);
              }
            }
          });

          const sortedByPageNumberAndRatio = [
            ...visiblePagesRef.current.entries(),
          ]
            .map(
              ([element, entry]) =>
                [element, round(entry.intersectionRatio, 2)] as const
            )
            .sort(([a], [b]) => Number(a.dataset.page) - Number(b.dataset.page))
            .sort(([_A, ratioA], [_B, ratioB]) => ratioB - ratioA);
          const elements = sortedByPageNumberAndRatio.map(
            ([element]) => element
          );
          const [mostVisible] = elements;
          const dataPage = mostVisible?.dataset.page;
          if (dataPage) {
            const pageNumber = parseInt(dataPage);
            lastUserSetPageRef.current = pageNumber;
            setCurrentPage(pageNumber);
          }
        },
        {
          root: scrollArea,
          threshold: [
            0, 0.001, 0.01, 0.1, 0.2, 0.4, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
          ],
        }
      );

      [...pagesRefs.values()].forEach((page) => {
        observer.observe(page);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [pagesRefs, pageProxies, scrollingAreaRef, setCurrentPage]);

  return { currentPage, handlePageChange };
};
