import { PDFDocumentProxy } from 'pdfjs-dist';
import { useCallback, useEffect, useState } from 'react';

import { createExtractImage } from '@/components/HTMLCanvas/PinPanel/Extract/utils/createExtractImage';
import { MARGIN_PDF } from '@/hooks/pdf/usePdf';
import { usePdfPages } from '@/hooks/pdf/usePdfPage';
import { pdfPageToCanvas } from '@/hooks/pdf/utils/pdfPageToCanvas';
import { isDefined } from '@/utils/isDefined';
import { ExtractData } from '@/utils/kosmik/stickers/extract';
import { MaybeUndefined } from '@/utils/types';

export type GeneratePdfExtractProps = {
  pdf: MaybeUndefined<PDFDocumentProxy>;
  extractData: ExtractData;
  width: number;
  cameraZ: number;
};

export const useGeneratePdfExtract = ({
  pdf,
  extractData,
  width,
  cameraZ,
}: GeneratePdfExtractProps) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | undefined>();
  const pdfPages = usePdfPages({ pdf, pages: extractData.visiblePages ?? [] });

  /**
   * getPdfPages : allows you to get the pages of the pdf that are useful to the extract
   */
  const createNewPdfImageExtract = useCallback(
    (
      extractPdfPages: HTMLCanvasElement[],
      totalPageHeight: number
    ): HTMLCanvasElement => {
      const canvas = document.createElement('canvas');
      canvas.width = extractPdfPages.reduce((acc, page) => {
        if (page.width > acc) {
          acc = page.width;
        }
        return acc;
      }, 0);
      canvas.height = totalPageHeight;
      const canvasContext = canvas.getContext('2d');
      let lastY = 0;
      for (let index = 0; index < extractPdfPages.length; index++) {
        const imagePage = extractPdfPages[index];
        if (!imagePage) {
          continue;
        }
        canvasContext?.drawImage(
          imagePage,
          0,
          lastY,
          imagePage.width,
          imagePage.height
        );
        lastY += imagePage.height + MARGIN_PDF;
      }
      return canvas;
    },
    []
  );

  /**
   * getTargetPdfPages : allows you to get the pages of the pdf that are useful to the extract
   */
  const getTargetPdfPages = useCallback(
    async (targetPdfPagesPromise: Promise<HTMLCanvasElement | null>[]) => {
      const targetPdfPages = (await Promise.all(targetPdfPagesPromise)).filter(
        isDefined
      );
      if (targetPdfPages.length === 1) {
        return targetPdfPages[0];
      }
      const totalHeight = targetPdfPages.reduce((acc, page) => {
        return acc + page.height + MARGIN_PDF;
      }, 0);
      return createNewPdfImageExtract(targetPdfPages, totalHeight);
    },
    [createNewPdfImageExtract]
  );

  useEffect(() => {
    const loadImage = async () => {
      if (pdfPages.length === 0) {
        return;
      }
      let canvas: MaybeUndefined<HTMLCanvasElement> = undefined;
      const targetPdfPagesPromise = pdfPages.map((page) => {
        const extractWidthRatio =
          1 - (extractData.cropLeft + extractData.cropRight);
        // we calculate the width at which the pdf will be rendered before croping it
        const pdfWidth = width / extractWidthRatio;
        return pdfPageToCanvas({
          page,
          scale: 2 * cameraZ,
          size: { width: pdfWidth },
        });
      });
      canvas = await getTargetPdfPages(targetPdfPagesPromise);
      if (canvas) {
        const newCanvas = createExtractImage(canvas, extractData);
        if (newCanvas && newCanvas.width !== 0 && newCanvas.height !== 0) {
          setCanvas(newCanvas);
        }
      }
    };

    loadImage();
  }, [cameraZ, extractData, getTargetPdfPages, pdfPages, width]);

  return canvas;
};
