import { ChangeEvent, useCallback } from 'react';

import DarkScheme from '@/assets/themesModeAssets/darkScheme.svg?react';
import LightScheme from '@/assets/themesModeAssets/lightScheme.svg?react';
import SystemScheme from '@/assets/themesModeAssets/systemScheme.svg?react';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { SelectableCards } from '@/components/SelectableCards/SelectableCards';
import { DialogSection } from '@/ui/Dialog/DialogSection';
import { ColorMode, useColorScheme } from '@/utils/css/hooks/useColorScheme';

export const ThemesMode = () => {
  const { colorMode, setColorMode } = useColorScheme();

  const handleColorModeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newMode = event.currentTarget.value as ColorMode;
      if (!newMode) {
        return;
      }
      setColorMode(newMode);
    },
    [setColorMode]
  );

  return (
    <DialogSection title={'Theme'}>
      <div className={styles.settingsPageFieldRow}>
        <SelectableCards
          name={'color-scheme'}
          type={'radio'}
          value={colorMode}
          onChange={handleColorModeChange}
          cards={[
            {
              id: 'light',
              content: <LightScheme />,
              label: 'Light',
              value: 'light',
            },
            {
              id: 'dark',
              content: <DarkScheme />,
              label: 'Dark',
              value: 'dark',
            },
            {
              id: 'system',
              content: <SystemScheme />,
              label: 'System',
              value: 'system',
            },
          ]}
        />
      </div>
    </DialogSection>
  );
};
