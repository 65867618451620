import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { Dimension } from '@/utils/geometry/dimension';
import { Point } from '@/utils/geometry/point';
import { MaybeNull } from '@/utils/types';

/**
 * This class allows you to create a spatial hash for anything that looks
 * like a Dimension. A spatial hash allows you to query for neighbouring
 * items within an optional maximal distance. This allows to limit expensive
 * operations such as checking for intersections to only the neighbouring
 * items instead of looping through all items, no matter if near or far.
 */
export class SpatialHash {
  private cellSize: number;
  private tableWidth: number = 0;
  private table: Dimension[][];
  private boundingBox: MaybeNull<Dimension> = null;

  /**
   * Instantiate a new spatial hash
   * @param cellSize - the cell size, adjust based on your need (100 is a
   * reasonable default)
   */
  constructor(cellSize: number = 100) {
    this.cellSize = cellSize;
    this.table = [];
  }

  /**
   * Creates a new hash (we don't have an update method, since it's pretty cheap
   * to create new hashes). If you need to update the hash, simply recreate one.
   * @param rects - the Dimension-like objects you want to store in the hash
   */
  create(rects: Dimension[]) {
    const boundingBox = getBoundingBox(rects);
    if (boundingBox) {
      this.boundingBox = boundingBox;
      this.table = [];
      const horizontalFit = boundingBox.width / this.cellSize;
      this.tableWidth =
        Math.ceil(horizontalFit) + (horizontalFit === 1 ? 1 : 0);
      for (let i = 0; i < rects.length; i++) {
        const rect = rects[i];
        if (!rect) {
          continue;
        }
        const xMin = this.cellCoord(rect.x - boundingBox.x);
        const yMin = this.cellCoord(rect.y - boundingBox.y);
        const xMax = this.cellCoord(rect.x - boundingBox.x + rect.width);
        const yMax = this.cellCoord(rect.y - boundingBox.y + rect.height);
        for (let x = xMin; x <= xMax; x++) {
          for (let y = yMin; y <= yMax; y++) {
            const index = y * this.tableWidth + x;
            const currentTableValue = this.table[index] ?? [];
            currentTableValue.push(rect);
            this.table[index] = currentTableValue;
          }
        }
      }
    }
  }

  /**
   * Query the spatial hash at a given point. If no maxDistance is specified,
   * this will return all the items in the cell containing the point. If a
   * maxDistance is given, this will query all the neighbouring cells within
   * that distance.
   * @param point - the point at which you want to query the hash
   * @param maxDistance - the maximum radius at which to include other cells
   */
  query(point: Point, maxDistance = 0): Dimension[] {
    const boundingBox = this.boundingBox;
    if (maxDistance && boundingBox) {
      const result = new Set<Dimension>();
      const xMin = this.cellCoord(point.x - boundingBox.x - maxDistance);
      const xMax = this.cellCoord(point.x - boundingBox.x + maxDistance);
      const yMin = this.cellCoord(point.y - boundingBox.y - maxDistance);
      const yMax = this.cellCoord(point.y - boundingBox.y + maxDistance);
      for (let x = xMin; x <= xMax; x++) {
        for (let y = yMin; y <= yMax; y++) {
          const index = y * this.tableWidth + x;
          const currentTableValue = this.table[index] ?? [];
          for (const value of currentTableValue) {
            result.add(value);
          }
        }
      }
      return [...result];
    } else {
      const index = this.hashIndex(point);
      return this.table[index] ?? [];
    }
  }

  private cellCoord(value: number) {
    return Math.floor(value / this.cellSize);
  }

  private hashIndex(point: Point) {
    const x = this.cellCoord(point.x - (this.boundingBox?.x ?? 0));
    const y = this.cellCoord(point.y - (this.boundingBox?.y ?? 0));
    return y * this.tableWidth + x;
  }
}
