import { id, tx } from '@instantdb/react';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { z } from 'zod';

import {
  deletingStickersFamily,
  localStickerFamily,
  stickerUploadStateFamily,
} from '@/atoms/sticker';
import { db } from '@/db/databaseInit';
import { batchTransact, TxChunk } from '@/utils/db/db';
import { Position } from '@/utils/geometry/position';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { MaybeNull, MaybeUndefined } from '@/utils/types';

/**
 * Delete the given stickers from the database
 * @param stickers - the stickers to delete
 * @param keepFiles - whether we should keep the associated files if any (for
 *                    example when cutting a sticker, we should keep the file,
 *                    so that when pasting back we can link again to it)
 */
export const deleteDatabaseStickers = async (
  stickers: KosmikSticker[],
  keepFiles?: boolean
) => {
  if (stickers.length) {
    const txs: MaybeUndefined<TxChunk>[] = [];
    const deletedFileStickers: KosmikFileSticker[] = [];
    stickers.forEach((sticker) => {
      // clean up recoil state
      setRecoil(localStickerFamily(sticker.id), null);
      txs.push(tx.stickers?.[sticker.id]?.delete());
      const parsedFileSticker = KosmikFileSticker.safeParse(sticker);
      if (parsedFileSticker.success && !keepFiles) {
        deletedFileStickers.push(parsedFileSticker.data);
      }
    });
    const orphanedFilesTxs =
      await deleteStickerOrphanedFile(deletedFileStickers);
    await batchTransact([...orphanedFilesTxs, ...txs]);
  }
};

/**
 * Given stickers, delete the file entity in the files namespace as well as
 * the file in storage if the file entity isn't linked to any other sticker
 * than the given ones
 * @param stickers the stickers for which to delete the orphaned file entities
 */
export const deleteStickerOrphanedFile = async (
  stickers: KosmikFileSticker[]
) => {
  const txs: MaybeUndefined<TxChunk>[] = [];
  const stickerIds = new Set(stickers.map((sticker) => sticker.id));
  const alreadyDeletedFileEntityIds = new Set<string>();
  for (const sticker of stickers) {
    const fileEntity = sticker.files?.[0];
    if (fileEntity && !alreadyDeletedFileEntityIds.has(fileEntity.id)) {
      const fileEntityStickers = fileEntity?.stickers;
      if (!fileEntityStickers) {
        continue;
      }
      const isLinkedToOtherStickers = fileEntityStickers?.some(
        (linkedSticker) => !stickerIds.has(linkedSticker.id)
      );
      if (!isLinkedToOtherStickers) {
        try {
          await db.storage.delete(fileEntity.storage_path);
          // Only delete the file entity if deletion was successful,
          // this way we can run backend script to clean up unlinked files
          txs.push(tx.files?.[fileEntity.id]?.delete());
          alreadyDeletedFileEntityIds.add(fileEntity.id);
        } catch {
          console.warn(`Could not delete file, ${fileEntity.storage_path}`);
        }
      }
    }
  }
  return txs;
};

/**
 * Add the given duplicate sticker id to the uploadingDuplicates of the root
 * uploading sticker upload state, and update the upload state of the duplicate
 * to mark it as currently uploading
 * @param duplicateId - the sticker id that needs to be added
 * @param uploadingStickerId - the root uploading sticker (the one for which
 *                             the file is actually uploading)
 */
const addUploadingDuplicate = (
  duplicateId: string,
  uploadingStickerId: string
) => {
  setRecoil(stickerUploadStateFamily(uploadingStickerId), (previous) => ({
    isUploading: previous.isUploading,
    uploadingDuplicates: new Set<string>(previous.uploadingDuplicates).add(
      duplicateId
    ),
  }));
  setRecoil(stickerUploadStateFamily(duplicateId), {
    isUploading: true,
    uploadingSticker: uploadingStickerId,
  });
};

interface DuplicateStickersOptions {
  offset?: Position;
  persist?: boolean;
  keepIds?: boolean;
}

/**
 * Duplicate the given stickers in the specified universe,
 * and offset their position by the specified offset
 * @param stickers the stickers to duplicate
 * @param universeId the id of the universe in which to duplicate the stickers
 * @param offset the position offset
 * @param persist should the transaction be written to the db (note that we
 *                don't persist file stickers that are being uploaded, as this
 *                is handled when the file is done uploading)
 */
export const duplicateStickers = (
  stickers: KosmikSticker[],
  universeId: string,
  {
    offset = { x: 25, y: 25 },
    persist = true,
    keepIds = false,
  }: DuplicateStickersOptions
): { newIds: string[]; newStickers: KosmikSticker[] } => {
  const txs = [];
  const newIds: string[] = [];
  const newStickers: KosmikSticker[] = [];
  for (let i = 0; i < stickers.length; i += 1) {
    const sticker = stickers[i];
    if (sticker) {
      const { id: initialId, ...newSticker } = sticker;
      newSticker.created_at = new Date().toISOString();
      newSticker.x += offset.x;
      newSticker.y += offset.y;
      newSticker.v = 0;
      const newId = keepIds ? initialId : id();
      newIds.push(newId);
      const uploadState = getRecoil(stickerUploadStateFamily(sticker.id));
      // We don't persist files that are currently being uploaded, this is
      // handled after the upload has completed
      if (uploadState.isUploading) {
        const uploadingStickerId = uploadState.uploadingSticker ?? sticker.id;
        addUploadingDuplicate(newId, uploadingStickerId);
      } else {
        if (persist) {
          const fileLink = getCurrentFileLink(sticker);
          const transactableSticker =
            getTransactableStickerProperties(newSticker);
          txs.push(
            tx.stickers?.[newId]
              ?.update(transactableSticker)
              .link({ universes: [universeId], files: fileLink })
          );
        }
      }
      const newStickerWithId: KosmikSticker = { ...newSticker, id: newId };
      if ('files' in sticker) {
        (newStickerWithId as KosmikFileSticker).files = sticker.files;
      }
      newStickers.push(newStickerWithId);
    }
  }
  if (persist) {
    batchTransact(txs);
  }
  return { newIds, newStickers };
};

/**
 * Restores stickers for undo / redo.
 * This re-creates given stickers at the same position and with the same id.
 *
 * @param stickers the stickers to duplicate
 * @param universeId the id of the universe in which to duplicate the stickers
 * @returns stickers An array of new sticker objects that have been duplicated.
 */
export const undoableDuplicateStickers = (
  stickers: KosmikSticker[],
  universeId: string
) => {
  const { newStickers, newIds } = duplicateStickers(stickers, universeId, {
    offset: {
      x: 0,
      y: 0,
    },
    keepIds: true,
  });
  setRecoil(deletingStickersFamily(universeId), (prev) => {
    return new Set([...prev].filter((id) => !newIds.includes(id)));
  });

  return newStickers;
};

/**
 * Get the existing file link if any
 * @param sticker the sticker for which to get the file link
 */
export const getCurrentFileLink = (sticker: KosmikSticker) => {
  const fileLink = [];
  const parsedFiledSticker = KosmikFileSticker.safeParse(sticker);
  if (parsedFiledSticker.success) {
    const file = parsedFiledSticker.data.files?.[0];
    if (file) {
      fileLink.push(file?.id);
    }
  }
  return fileLink;
};

/**
 * For a given partial sticker, or full sticker, returns the properties that
 * are valid to transact to the database. That is, all the properties except
 * the linked objects (relations)
 */
export const getTransactableStickerProperties = <
  T extends Partial<KosmikSticker>,
>(
  sticker: T
) => {
  const transactableSticker = structuredClone(sticker);
  if ('files' in transactableSticker) {
    delete transactableSticker.files;
  }
  if ('universes' in transactableSticker) {
    delete transactableSticker.universes;
  }
  return transactableSticker;
};

/**
 * Given a string, parses it and returns an array of KosmikSticker provided
 * the string represents one, returns null otherwise
 * @param textInput
 */
export const parseArrayOfStickers = (
  textInput: string
): MaybeNull<KosmikSticker[]> => {
  try {
    const potentialArrayOfStickers = JSON.parse(textInput);
    return z.array(KosmikSticker).parse(potentialArrayOfStickers);
  } catch {
    return null;
  }
};

/**
 * Given a list of stickers, or a list of sticker ids, returns the ones that
 * are not currently uploading
 */
export const getNotUploadingStickers = <T extends KosmikSticker[] | string[]>(
  stickers: T
): T => {
  return stickers.filter(
    (sticker) =>
      !getRecoil(
        stickerUploadStateFamily(
          typeof sticker === 'string' ? sticker : sticker.id
        )
      ).isUploading
  ) as T;
};
