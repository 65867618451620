import { z } from 'zod';

export const OEmbed = z
  .discriminatedUnion('type', [
    z.object({
      type: z.literal('photo'),
      url: z.string(),
      width: z.union([z.number(), z.string()]),
      height: z.union([z.number(), z.string()]),
    }),
    z.object({
      type: z.literal('video'),
      html: z.string(),
      width: z.union([z.number(), z.string()]),
      height: z.union([z.number(), z.string()]),
    }),
    z.object({
      type: z.literal('link'),
    }),
    z.object({
      type: z.literal('rich'),
      html: z.string(),
      width: z.union([z.number(), z.string()]).nullable(),
      height: z.union([z.number(), z.string()]).nullable(),
    }),
  ])
  .and(
    z.object({
      version: z.string(),
      title: z.string().optional(),
      author_name: z.string().optional(),
      author_url: z.string().optional(),
      provider_name: z.string().optional(),
      provider_url: z.string().optional(),
      cache_age: z.union([z.number(), z.string()]).optional(),
      thumbnail_url: z.string().optional(),
      thumbnail_width: z.number().optional(),
      thumbnail_height: z.number().optional(),
    })
  );

export type OEmbed = z.infer<typeof OEmbed>;
