import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { CommonModalProps } from '@/components/modal/Modals';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { SettingsPageTitle } from '@/components/modal/modals/Settings/TeamSettings/contents/SettingsPageTitle';
import { InviteTeamMemberForm } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamMembersSettings/InviteTeamMemberForm';
import { TeamInvitedMemberList } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamMembersSettings/TeamInvitedMemberList';
import { TeamMemberList } from '@/components/modal/modals/Settings/TeamSettings/contents/TeamMembersSettings/TeamMemberList';
import { DialogDescription } from '@/ui/Dialog/Dialog';

export const TeamMembersSettings = ({ closeModal }: CommonModalProps) => {
  return (
    <section className={styles.tabsContentContainer}>
      <div>
        <SettingsPageTitle>Members</SettingsPageTitle>
        <DialogDescription style={{ margin: 0 }}>
          <TextLabelRegular>Add, Edit and Remove members.</TextLabelRegular>
        </DialogDescription>
      </div>
      <InviteTeamMemberForm />
      <TeamMemberList closeModal={closeModal} />
      <TeamInvitedMemberList />
    </section>
  );
};
