import { tx } from '@instantdb/react';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useNotifications } from '@/context/Notifications/useNotifications';
import { useTeam } from '@/context/Team/useTeam';
import { useUser } from '@/context/User/useUser';
import { NotificationBase } from '@/ui/Notifications/NotificationBase';
import { batchTransact } from '@/utils/db/db';
import { TeamInvite } from '@/utils/kosmik/team';
import { getRelativeTimeString } from '@/utils/relativeDate';
import { getUniversesFromTeam } from '@/utils/team/team';

interface NotificationProps {
  invite: TeamInvite;
}

export const TeamInviteNotification = ({ invite }: NotificationProps) => {
  const navigate = useNavigate();
  const currentUser = useUser();
  const { setTeam } = useTeam();
  const { dismissNotification } = useNotifications();

  const team = invite.teams?.[0];

  const handleOnClick = useCallback(async () => {
    if (currentUser && team?.id) {
      await batchTransact([
        tx.teams?.[team.id]?.link({ users: [currentUser.id] }),
        tx.team_invite?.[invite.id]?.delete(),
      ]);

      const universes = await getUniversesFromTeam(team.id);
      const universeId = universes?.[0]?.id;
      setTeam(team);
      if (universeId) {
        navigate({ to: `/${universeId}` });
      }
      dismissNotification(invite.id);
    }
  }, [currentUser, team, invite.id, setTeam, navigate, dismissNotification]);

  const handleClose = useCallback(async () => {
    await batchTransact(tx.team_invites?.[invite.id]?.delete());
    dismissNotification(invite.id);
  }, [dismissNotification, invite.id]);

  return (
    <NotificationBase
      id={invite.id}
      title={'Team Invite'}
      description={
        <span>
          You have been invited to join <b>{team?.name}</b>
        </span>
      }
      action={{
        label: 'Join',
        onClick: handleOnClick,
      }}
      cancel={{
        label: 'Dismiss',
        onClick: handleClose,
      }}
      footer={
        <TextLabelRegular
          style={{ color: 'var(--label-tertiary)', fontWeight: 400 }}
        >
          {getRelativeTimeString(new Date(invite.created_at))}
        </TextLabelRegular>
      }
    />
  );
};
