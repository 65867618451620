import { useParams } from '@tanstack/react-router';
import debounce from 'debounce';
import { Delta } from 'quill/core';
import { useMemo, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import {
  KosmikStickySticker,
  StickyStickerAttributes,
} from '@/utils/kosmik/stickers/sticky';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';

export const useUpdateStickerTextContent = (
  sticker: KosmikTextSticker | KosmikStickySticker
) => {
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerAttributes =
    useSetStickerAttributes<StickyStickerAttributes>(setSticker);
  const { universeId } = useParams({ strict: false });
  const { addUndoRedoAction } = useUndoRedo(universeId);
  const preCommitTextRef = useRef(
    sticker.attributes.text_content?.length
      ? sticker.attributes.text_content
      : JSON.stringify(new Delta())
  );

  const handleDebouncedTextChange = useMemo(() => {
    const setTextContent = (text_content: string) => {
      const preCommitValue = preCommitTextRef.current;
      const updateAction: UndoRedoActionType = {
        do: () => {
          setStickerAttributes({ text_content }, true);
          preCommitTextRef.current = text_content;
        },
        undo: () => {
          setStickerAttributes({ text_content: preCommitValue }, true);
          preCommitTextRef.current = preCommitValue;
        },
      };
      addUndoRedoAction(updateAction);
      updateAction.do();
    };

    return debounce(setTextContent, 350);
  }, [addUndoRedoAction, setStickerAttributes]);

  return { handleDebouncedTextChange };
};
