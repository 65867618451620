import { z } from 'zod';

export const Size = z.object({
  width: z.number(),
  height: z.number(),
});

export type Size = z.infer<typeof Size>;

/**
 * Get the area of Size-based objects
 */
export const getArea = (size: Size) => {
  return size.width * size.height;
};
