/**
 * Type guard to check if a value is a readonly array.
 * reference: https://github.com/microsoft/TypeScript/issues/17002
 *
 * @template T - The type of elements in the array.
 * @param {unknown} value - The value to check.
 * @returns {value is readonly T[]} - True if the value is a readonly array, false otherwise.
 *
 * @example
 * const myArray = [1, 2, 3] as const;
 * if (isReadonlyArray(myArray)) {
 *   // myArray is treated as readonly number[]
 * }
 */
export function isReadonlyArray<T>(value: unknown): value is readonly T[] {
  return Array.isArray(value);
}

/**
 * Converts an array of File objects to a FileList.
 */
export const arrayToFileList = (files: File[]): FileList => {
  const dataTransfer = new DataTransfer();
  files.forEach((file) => dataTransfer.items.add(file));
  return dataTransfer.files;
};
