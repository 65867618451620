import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import {
  stickerPinnedStateFamily,
  stickerSelectionStateFamily,
} from '@/atoms/sticker';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { getMultiplayerProperties } from '@/utils/multiplayer/multiplayerUserSet';

/**
 * Returns the selection properties for the given selection
 */
export const useSelectionProperties = (stickerId: string) => {
  const user = useContext(MultiplayerUserContext);
  const selectionState = useRecoilValue(stickerSelectionStateFamily(stickerId));
  const pinnedState = useRecoilValue(stickerPinnedStateFamily(stickerId));

  const {
    isUser: isUserSelected,
    isRemote: isRemoteSelected,
    isExclusivelyRemote: isExclusivelyRemoteSelected,
    activeUser: selectionUser,
  } = getMultiplayerProperties(selectionState, user);

  const {
    isUser: isUserPinned,
    isRemote: isRemotePinned,
    isExclusivelyRemote: isExclusivelyRemotePinned,
    activeUser: pinnedUser,
  } = getMultiplayerProperties(pinnedState, user);

  // Combined states
  const isUserSelectedOrPinned = isUserSelected || isUserPinned;
  const isRemoteSelectedOrPinned = isRemoteSelected || isRemotePinned;
  const isExclusivelyRemoteSelectedOrPinned =
    isExclusivelyRemoteSelected || isExclusivelyRemotePinned;

  // Figure out the outline color
  let outlineColor = undefined;
  if (isRemoteSelectedOrPinned && !isUserSelectedOrPinned) {
    outlineColor = selectionUser?.color ?? pinnedUser?.color;
  }

  return {
    isUserSelected,
    isRemoteSelected,
    isUserSelectedOrPinned,
    isExclusivelyRemoteSelected,
    selectionUser,
    isUserPinned,
    isRemotePinned,
    isExclusivelyRemotePinned,
    isExclusivelyRemoteSelectedOrPinned,
    pinnedUser,
    outlineColor,
  };
};
