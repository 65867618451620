export const hideTextLayers = () => {
  const sheet = document.createElement('style');
  sheet.id = 'hideTextLayer';
  sheet.innerHTML = '.textLayer { display: none }';
  document.body.appendChild(sheet);

  return () => {
    try {
      document.body.removeChild(sheet);
    } catch {
      return;
    }
  };
};
