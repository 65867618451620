import { ReactNode, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { UsernameForm } from '@/components/Onboarding/UsernameForm';
import {
  MultiplayerUser,
  MultiplayerUserContext,
} from '@/context/User/MultiplayerUserContext';
import { useUser } from '@/context/User/useUser';
import { useCurrentUniverse } from '@/hooks/useCurrentUniverse';
import { getContrastColor, stringToHslColor } from '@/utils/color/colors';
import { MaybeUndefined } from '@/utils/types';
import { getIsUniverseMember } from '@/utils/universe/universe';

export const MultiplayerUserProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const user = useUser();
  const { universe } = useCurrentUniverse();
  const isUniverseMember = useMemo(
    () => (user && universe ? getIsUniverseMember(user, universe) : false),
    // We only want to re-run this hook when either the teams or the users change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [universe?.teams, universe?.users, user?.id]
  );
  // Make sure the peerId is stable across re-renders, otherwise this can
  // mess the multiplayer selection states
  const peerId = useMemo(() => uuidv4(), []);
  const { id, email, name } = user ?? {};
  const multiplayerUser: MaybeUndefined<MultiplayerUser> = useMemo(() => {
    if (id && email && name) {
      const color = stringToHslColor(email);
      const contrastColor = getContrastColor(color);
      return {
        id,
        email,
        name,
        color,
        contrastColor,
        peerId,
        isUniverseMember,
      };
    } else {
      return undefined;
    }
  }, [email, id, isUniverseMember, name, peerId]);

  if (user && !user.name) {
    return <UsernameForm user={user} />;
  }

  return (
    <MultiplayerUserContext.Provider value={multiplayerUser}>
      {children}
    </MultiplayerUserContext.Provider>
  );
};
