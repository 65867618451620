import { ArrowLeft, ArrowRight } from '@lithium/spectrum';
import { useCallback } from 'react';
import { SetterOrUpdater } from 'recoil';

import { EditDockItem } from '@/components/EditDock/components/EditDockItem';
import { PinPanelPdfEditDockItems } from '@/components/EditDock/PdfEditDock/components/PinPanelPdfEditDockItems';
import { PdfPageInfo } from '@/components/EditDock/PdfEditDock/PdfPageInfo';
import { usePinPanel } from '@/context/PinPanel/usePinPanel';
import { usePdf } from '@/hooks/pdf/usePdf';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';
import { MaybeNull } from '@/utils/types';

export const PdfEditDock = ({
  sticker,
  attributes,
  setSticker,
}: {
  sticker: KosmikFileSticker;
  attributes: FileStickerAttributes;
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>;
}) => {
  const setStickerAttributes =
    useSetStickerAttributes<FileStickerAttributes>(setSticker);
  const file = sticker.files?.[0];
  const { cached_url: url } = file ?? attributes;
  const { page = 1 } = attributes;
  const { pdf } = usePdf(url);
  const isPinPanel = usePinPanel();

  const handleClickArrow = useCallback(
    (direction: 'prev' | 'next') => {
      setStickerAttributes(
        { page: page + (direction === 'prev' ? -1 : 1) },
        true
      );
    },
    [page, setStickerAttributes]
  );

  return (
    <>
      {isPinPanel ? <PinPanelPdfEditDockItems /> : null}
      <EditDockItem
        data-testid={'pdf-edit-dock-previous-page'}
        onClick={() => handleClickArrow('prev')}
        disabled={page <= 1}
      >
        <ArrowLeft />
      </EditDockItem>
      <PdfPageInfo
        page={page}
        maxPage={pdf?.numPages}
        onPageChange={(page) => {
          setStickerAttributes({ page }, true);
        }}
      />
      <EditDockItem
        data-testid={'pdf-edit-dock-next-page'}
        onClick={() => handleClickArrow('next')}
        disabled={page >= (pdf?.numPages ?? 0)}
      >
        <ArrowRight />
      </EditDockItem>
    </>
  );
};
