import { z } from 'zod';

import { KosmikFile } from '@/utils/kosmik/file';
import { KosmikStickerBase } from '@/utils/kosmik/stickers/base';
import { ExtractData } from '@/utils/kosmik/stickers/extract';
import { StickerType } from '@/utils/kosmik/stickers/type';

export const FileStickerAttributes = z.object({
  // @deprecated to be removed when we create a new instant app, now in `Files` namespace
  storage_path: z.string().optional(),
  // @deprecated to be removed when we create a new instant app, now in `Files` namespace
  file_name: z.string().optional(),
  // @deprecated to be removed when we create a new instant app, now in `Files` namespace
  cached_url: z.string().optional(),
  // @deprecated to be removed when we create a new instant app, now in `Files` namespace
  expires_at: z.string().optional(),
  // @deprecated to be removed when we create a new instant app, now in `Files` namespace
  type: z.string().optional(),
  ratio: z.number().optional(),
  page: z.number().min(1).optional(),
  // page url on which the file was located
  source: z.string().optional(),
  // file url where the file was downloaded from
  source_file_url: z.string().optional(),
  extract: ExtractData.optional(),
});

export type FileStickerAttributes = z.infer<typeof FileStickerAttributes>;

export const KosmikFileSticker = z.intersection(
  KosmikStickerBase,
  z.object({
    type: z.literal(StickerType.enum.file),
    attributes: FileStickerAttributes,
    files: z.array(KosmikFile).optional(),
  })
);

export type KosmikFileSticker = z.infer<typeof KosmikFileSticker>;
