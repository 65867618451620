import { Button } from '@lithium/spectrum';
import * as Form from '@radix-ui/react-form';
import { useRouter } from '@tanstack/react-router';
import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

import { TextHeader2 } from '@/components/fontSystem/Header2';
import { TextTag } from '@/components/fontSystem/TextTag';
import { LoginStepType } from '@/components/Onboarding/Login';
import { OfflineMessage } from '@/components/Onboarding/OfflineMessage';
import styles from '@/components/Onboarding/Onboarding.module.css';
import { db } from '@/db/databaseInit';
import { useOnlineStatus } from '@/hooks/online/useOnlineStatus';
import { ButtonLabel } from '@/ui/ButtonLabel/ButtonLabel';
import { MagicCodeFormField } from '@/ui/Form/MagicCodeFormField';
import { sendKPI } from '@/utils/kpi/sendKPI';
import { createNewUser } from '@/utils/user/create';

export const MagicCode = ({
  sentEmail,
  setStep,
  redirectUrl,
}: {
  sentEmail: string;
  setStep: Dispatch<SetStateAction<LoginStepType>>;
  redirectUrl: string;
}) => {
  const router = useRouter();
  const online = useOnlineStatus();
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (online) {
      setLoading(true);
      try {
        const { user } = await db.auth.signInWithMagicCode({
          email: sentEmail,
          code,
        });
        await createNewUser(user);
        sendKPI({ type: 'sign_in' }, user, user.refresh_token);

        setCode('');
        setLoading(false);
        router.navigate({ to: redirectUrl });
      } catch (error) {
        console.error(error);
        setError(true);
        setLoading(false);
      }
    }
  };

  const handleGoBack = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setStep('email');
    },
    [setStep]
  );

  return (
    <>
      <Form.Root onSubmit={handleSubmit} className={styles.formContainer}>
        <TextHeader2>Paste the magic code</TextHeader2>
        <TextTag>Enter the magic code to verify your email.</TextTag>
        <MagicCodeFormField
          value={code}
          onChange={(e) => {
            setError(false);
            setCode(e.target.value);
          }}
          error={error}
        />
        <OfflineMessage />
        <Button
          variant={'positive'}
          disabled={loading || !online}
          loading={loading}
        >
          Verify
        </Button>
        <div className={styles.rowLeft}>
          <TextTag>Haven&apos;t received the email?</TextTag>
          <ButtonLabel underline onClick={handleGoBack}>
            Go back.
          </ButtonLabel>
        </div>
      </Form.Root>
    </>
  );
};
