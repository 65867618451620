import { forwardRef, RefObject, useImperativeHandle, useRef } from 'react';

import styles from '@/components/multiplayer/MultiplayerCursors/CustomCursor.module.css';

export type CustomCursorProps = {
  color: string;
  name: string;
  contrastColor: string;
};

export const CustomCursor = forwardRef<
  {
    cursorPointerRef: RefObject<HTMLDivElement>;
    cursorLabelRef: RefObject<HTMLDivElement>;
  },
  CustomCursorProps
>(({ color, contrastColor, name }, ref) => {
  const cursorPointerRef = useRef<HTMLDivElement>(null);
  const cursorLabelRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    cursorPointerRef,
    cursorLabelRef,
  }));

  return (
    <div className={styles.cursorWrapper}>
      <div className={styles.pointer} ref={cursorPointerRef}>
        <svg
          width={'18'}
          height={'18'}
          viewBox={'0 0 18 18'}
          fill={'none'}
          xmlns={'http://www.w3.org/2000/svg'}
          style={{ transform: 'translate(-3px, -2px)' }}
        >
          <g clipPath={'url(#clip0_338_387)'}>
            <g filter={'url(#filter0_d_338_387)'}>
              <path
                fillRule={'evenodd'}
                clipRule={'evenodd'}
                d={'M15 7.50674L3 2L5.83819 15L9.30622 9.86534L15 7.50674Z'}
                fill={color}
              />
              <path
                fillRule={'evenodd'}
                clipRule={'evenodd'}
                d={'M15 7.50674L3 2L5.83819 15L9.30622 9.86534L15 7.50674Z'}
                stroke={'white'}
                strokeLinejoin={'round'}
              />
            </g>
          </g>
          <defs>
            <filter
              id={'filter0_d_338_387'}
              x={'-0.5'}
              y={'-0.500008'}
              width={'19'}
              height={'20'}
              filterUnits={'userSpaceOnUse'}
              colorInterpolationFilters={'sRGB'}
            >
              <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
              <feColorMatrix
                in={'SourceAlpha'}
                type={'matrix'}
                values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
                result={'hardAlpha'}
              />
              <feOffset dy={'1'} />
              <feGaussianBlur stdDeviation={'1.5'} />
              <feColorMatrix
                type={'matrix'}
                values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'}
              />
              <feBlend
                mode={'normal'}
                in2={'BackgroundImageFix'}
                result={'effect1_dropShadow_338_387'}
              />
              <feBlend
                mode={'normal'}
                in={'SourceGraphic'}
                in2={'effect1_dropShadow_338_387'}
                result={'shape'}
              />
            </filter>
            <clipPath id={'clip0_338_387'}>
              <rect width={'18'} height={'18'} fill={'white'} />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div
        className={styles.label}
        style={{ backgroundColor: color, color: contrastColor }}
        ref={cursorLabelRef}
      >
        {name}
      </div>
    </div>
  );
});

CustomCursor.displayName = 'CustomCursor';
