import { tx } from '@instantdb/react';
import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { HtmlAudioSticker } from '@/components/HTMLCanvas/stickers/HtmlAudioSticker';
import { HtmlGenericFileSticker } from '@/components/HTMLCanvas/stickers/HtmlGenericFileSticker';
import { HtmlImageSticker } from '@/components/HTMLCanvas/stickers/HtmlImageSticker';
import { HtmlPdfSticker } from '@/components/HTMLCanvas/stickers/HtmlPdfSticker';
import { HtmlVideoSticker } from '@/components/HTMLCanvas/stickers/HtmlVideoSticker';
import { db } from '@/db/databaseInit';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { batchTransact } from '@/utils/db/db';
import { generateExpiresAt } from '@/utils/file/file';
import {
  FileStickerAttributes,
  KosmikFileSticker,
} from '@/utils/kosmik/stickers/file';

export const HtmlFileSticker = ({
  sticker,
}: {
  sticker: KosmikFileSticker;
}) => {
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerAttributes =
    useSetStickerAttributes<FileStickerAttributes>(setSticker);
  const file = sticker.files?.[0];
  const {
    type = '',
    expires_at: expiresAt,
    storage_path: storagePath,
    cached_url: cachedUrl,
  } = file ?? sticker.attributes;

  const refreshCachedUrl = useCallback(() => {
    if (storagePath) {
      const expiresAt = generateExpiresAt();
      db.storage.getDownloadUrl(storagePath).then((url) => {
        const payload = {
          expires_at: expiresAt,
          cached_url: url,
        };
        if (file) {
          batchTransact(tx.files?.[file.id]?.update(payload));
        } else {
          setStickerAttributes(payload, true);
        }
      });
    } else {
      console.error('Trying to refresh a file without storage_path');
    }
  }, [file, setStickerAttributes, storagePath]);

  useEffect(() => {
    if (expiresAt && new Date(expiresAt).getTime() < Date.now()) {
      refreshCachedUrl();
    }
  }, [cachedUrl, expiresAt, refreshCachedUrl]);

  if (type === 'application/pdf') {
    return (
      <HtmlPdfSticker sticker={sticker} refreshCachedUrl={refreshCachedUrl} />
    );
  }

  if (type.startsWith('video')) {
    return (
      <HtmlVideoSticker sticker={sticker} refreshCachedUrl={refreshCachedUrl} />
    );
  }

  if (type.startsWith('audio')) {
    return (
      <HtmlAudioSticker sticker={sticker} refreshCachedUrl={refreshCachedUrl} />
    );
  }

  if (type.startsWith('image')) {
    return (
      <HtmlImageSticker sticker={sticker} refreshCachedUrl={refreshCachedUrl} />
    );
  }

  return (
    <HtmlGenericFileSticker
      sticker={sticker}
      refreshCachedUrl={refreshCachedUrl}
    />
  );
};
