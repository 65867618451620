import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { kpiStackAtom } from '@/atoms/kpi';
import { KPI } from '@/utils/kpi/types';

export const usePushKPI = () => {
  const setKPIStack = useSetRecoilState(kpiStackAtom);

  return useCallback(
    (kpi: KPI) => {
      setKPIStack((prev) => [...prev, kpi]);
    },
    [setKPIStack]
  );
};
