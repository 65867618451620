import React, { ReactNode } from 'react';

import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';

import styles from './MenuItem.module.css';

export type MenuItemProps = React.PropsWithChildren<{
  prefix?: ReactNode;
  suffix?: ReactNode;
  displayAction?: boolean;
}>;

export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  ({ children, prefix, suffix, displayAction, ...props }, ref) => {
    return (
      <div ref={ref} className={styles.menuItem} {...props}>
        {prefix ? (
          <span className={styles.menuItemPrefixSuffix}>{prefix}</span>
        ) : null}
        <span className={styles.menuItemLabel}>
          <TextLabelRegular className={styles.menuItemTextLabel}>
            {children}
          </TextLabelRegular>
        </span>
        {suffix || displayAction ? (
          <span className={styles.menuItemPrefixSuffix}>
            {suffix}
            {displayAction ? <div className={styles.enter} /> : null}
          </span>
        ) : null}
      </div>
    );
  }
);

MenuItem.displayName = 'MenuItem';
