import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { BrowserSettings } from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/InterfaceSettings/BrowserSettings/BrowserSettings';
import { ThemesMode } from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/InterfaceSettings/ThemesMode/ThemesMode';
import { DialogTitle } from '@/ui/Dialog/Dialog';

export const InterfaceSettings = () => {
  return (
    <div className={styles.tabsContentContainer}>
      <VisuallyHidden>
        <DialogTitle>Interface settings</DialogTitle>
      </VisuallyHidden>
      <ThemesMode />
      <BrowserSettings />
    </div>
  );
};
