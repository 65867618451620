import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useEffect, useRef } from 'react';

import { version } from '@/../package.json';
import { TeamInviteNotification } from '@/components/Notifications/TeamInviteNotification';
import { UniverseInviteNotification } from '@/components/Notifications/UniverseInviteNotification';
import { UpdateNotification } from '@/components/Notifications/UpdateNotification';
import styles from '@/components/ui/Notifications/NotificationsList.module.css';
import { useNotifications } from '@/context/Notifications/useNotifications';
import { useTeamInvites } from '@/hooks/useTeamInvites';
import { useUniverseInvites } from '@/hooks/useUniverseInvites';

export const NotificationsList = () => {
  const ref = useRef(true);
  const [parent] = useAutoAnimate();
  const { universeInvites } = useUniverseInvites();
  const { teamInvites } = useTeamInvites();
  const { visibility, notifications, addNotification, dismissNotification } =
    useNotifications();
  const lastUniverseInviteIds = useRef<string[]>([]);
  const lastTeamInviteIds = useRef<string[]>([]);

  // List all universe invites
  useEffect(() => {
    const ids: string[] = [];
    universeInvites.forEach((invite) => {
      ids.push(invite.id);
      addNotification({
        id: invite.id,
        element: <UniverseInviteNotification key={invite.id} invite={invite} />,
      });
    });
    lastUniverseInviteIds.current.forEach((id) => {
      if (!ids.includes(id)) {
        dismissNotification(id);
      }
    });
    lastUniverseInviteIds.current = ids;
  }, [addNotification, dismissNotification, universeInvites]);

  // List all team invites
  useEffect(() => {
    const ids: string[] = [];
    teamInvites.forEach((invite) => {
      ids.push(invite.id);
      addNotification({
        id: invite.id,
        element: <TeamInviteNotification key={invite.id} invite={invite} />,
      });
    });
    lastTeamInviteIds.current.forEach((id) => {
      if (!ids.includes(id)) {
        dismissNotification(id);
      }
    });
    lastTeamInviteIds.current = ids;
  }, [addNotification, dismissNotification, teamInvites, universeInvites]);

  // Check for updates at launch in production
  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      window.electron?.ipcRenderer.invoke('check-update').then((result) => {
        if (
          version !== undefined &&
          result?.updateInfo?.version !== undefined &&
          result?.updateInfo?.version !== version &&
          result?.updateInfo?.version !== result?.versionInfo?.version
        ) {
          addNotification({
            id: 'app-update',
            element: (
              <UpdateNotification key={'app-update'} id={'app-update'} />
            ),
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.listWrapper} data-display={visibility}>
      <div ref={parent} className={styles.list}>
        {notifications.map((notification) => {
          return notification.element;
        })}
      </div>
    </div>
  );
};
