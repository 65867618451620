import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import React from 'react';

import { MenuContent } from '@/components/ui/MenuPrimitive/MenuContent';

export const ContextMenuSubContent = React.forwardRef<
  HTMLDivElement,
  ContextMenuPrimitive.ContextMenuSubContentProps
>(({ children, ...props }, ref) => {
  return (
    <ContextMenuPrimitive.SubContent asChild {...props}>
      <MenuContent
        ref={ref}
        style={{
          transformOrigin: 'var(--radix-context-menu-content-transform-origin)',
        }}
      >
        {children}
      </MenuContent>
    </ContextMenuPrimitive.SubContent>
  );
});

ContextMenuSubContent.displayName = 'ContextMenuSubContent';
