import { MouseEventHandler, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { pinPanelActiveToolAtom } from '@/atoms/pinpanel';
import { PinPanelExtract } from '@/components/HTMLCanvas/PinPanel/Extract/PinPanelExtract';
import { HtmlAnyStickerViewRenderer } from '@/components/HTMLCanvas/stickers/HtmlAnyStickerViewRenderer';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { isElectron } from '@/utils/platform/isElectron';
import { isPdfSticker, isVideoSticker } from '@/utils/sticker/type';

import styles from './StickerContent.module.css';

export type BaseStickerContentProps = {
  sticker: KosmikSticker;
};

export type StaticStickerContentProps = BaseStickerContentProps & {
  inert: true;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export type InteractiveStickerContentProps = BaseStickerContentProps & {
  inert?: false;
  onClick?: never;
};

export type StickerContentProps =
  | InteractiveStickerContentProps
  | StaticStickerContentProps;

export const StickerContent = ({
  sticker,
  inert,
  onClick,
}: StickerContentProps) => {
  const pinPanelActiveTool = useRecoilValue(pinPanelActiveToolAtom);
  const stickerContentRef = useRef<HTMLDivElement>(null);
  const shouldMaximizeHeight =
    isPdfSticker(sticker) ||
    isVideoSticker(sticker) ||
    sticker.type === 'text' ||
    (isElectron && sticker.type === 'web');

  return (
    <div
      ref={stickerContentRef}
      className={styles.stickerContent}
      style={{
        aspectRatio: `${sticker.width} / ${sticker.height}`,
        height: shouldMaximizeHeight ? '100%' : undefined,
        maxHeight: '100%',
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: 'contents',
          pointerEvents: inert ? 'none' : undefined,
        }}
      >
        <HtmlAnyStickerViewRenderer sticker={sticker} />
        {pinPanelActiveTool === 'extract' ? (
          <PinPanelExtract contentRef={stickerContentRef} sticker={sticker} />
        ) : null}
      </div>
    </div>
  );
};
