import React, { useEffect } from 'react';

export const LOAD_ANIMATION_VAR = '--progress-animation';

/**
 * Listen to webview events to animate the url field's progress indicator
 * @param containerRef
 * @param enabled
 */
export const useWebviewLoadAnimation = (
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  enabled: boolean
) => {
  useEffect(() => {
    const handleProgressIndeterminate = () => {
      const container = containerRef.current;
      if (container && enabled) {
        container.setAttribute('data-loading-state', 'indeterminate');
      }
    };

    const handleProgressFull = () => {
      const container = containerRef.current;
      if (container && enabled) {
        container.setAttribute('data-loading-state', 'full');
      }
    };

    const handleProgressFail = () => {
      const container = containerRef.current;
      if (container && enabled) {
        container.removeAttribute('data-loading-state');
      }
    };

    window.addEventListener('did-fail-load', handleProgressFail);
    window.addEventListener('did-start-loading', handleProgressIndeterminate);
    window.addEventListener('did-stop-loading', handleProgressFull);

    return () => {
      window.removeEventListener('did-fail-load', handleProgressFail);
      window.removeEventListener(
        'did-start-loading',
        handleProgressIndeterminate
      );
      window.removeEventListener('did-stop-loading', handleProgressFull);
    };
  });
};
