import React, { useEffect, useRef } from 'react';

import { CSSVar } from '@/utils/css/variable';

export const RESIZE_VARIABLE = {
  sidebar: '--panel-width',
  pinpanel: '--pin-panel-width',
} as const;

/**
 * Handle a panel horizontal resize
 */
export const useHorizontalResize = (
  panelRef: React.RefObject<HTMLDivElement>,
  variable: CSSVar,
  onResize?: (width: number) => void
) => {
  const isDragging = useRef(false);

  const handleDragHandlePointerDown = (event: React.PointerEvent) => {
    event.preventDefault();
    isDragging.current = true;
    const panel = panelRef.current;
    if (panel) {
      panel.style.pointerEvents = 'none';
    }
  };

  useEffect(() => {
    const handlePointerMove = (event: PointerEvent) => {
      if (isDragging.current) {
        event.preventDefault();
        const panel = panelRef.current;
        if (panel) {
          const bbox = panel.getBoundingClientRect();
          const width = Math.max(0, Math.round(event.clientX - bbox.x));
          panel.style.setProperty(`${variable}`, `${width}px`);
          onResize?.(width);
        }
      }
    };

    const handlePointerUp = () => {
      isDragging.current = false;
      const panel = panelRef.current;
      if (panel) {
        panel.style.pointerEvents = '';
      }
    };

    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('pointerup', handlePointerUp);
    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [variable, panelRef, onResize]);

  return handleDragHandlePointerDown;
};
