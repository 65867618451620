import { useLayoutEffect, useRef } from 'react';

import { useGenerateImageExtract } from '@/components/HTMLCanvas/PinPanel/Extract/hooks/useGenerateImageExtract';
import { HtmlLoadingSticker } from '@/components/HTMLCanvas/stickers/HtmlLoadingSticker';
import { ExtractData } from '@/utils/kosmik/stickers/extract';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';

export const HtmlImageExtractSticker = ({
  sticker,
  extract,
}: {
  sticker: KosmikFileSticker;
  extract: ExtractData;
}) => {
  const file = sticker.files?.[0];
  const { cached_url } = file ?? sticker.attributes;
  const containerRef = useRef<HTMLDivElement>(null);
  const canvas = useGenerateImageExtract({
    imageUrl: cached_url,
    extractData: extract,
  });

  useLayoutEffect(() => {
    const container = containerRef.current;

    if (container && canvas) {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      container.appendChild(canvas);

      return () => {
        container.removeChild(canvas);
      };
    }
  }, [canvas]);

  return (
    <HtmlLoadingSticker
      ref={containerRef}
      stickerId={sticker.id}
      isLoading={!cached_url}
    ></HtmlLoadingSticker>
  );
};
