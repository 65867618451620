import { Position } from '@/utils/geometry/position';
import { Size } from '@/utils/geometry/size';

/**
 * Return the position relative to the universe of the middle of the universe's window.
 * @param origin
 * @param size
 */
export const getMiddlePosition = (origin: Position, size: Size) => {
  return {
    x: origin.x + size.width / 2,
    y: origin.y + size.height / 2,
  };
};
