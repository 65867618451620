import { useParams } from '@tanstack/react-router';
import { useEffect } from 'react';

import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { useUndoableUploadNewFiles } from '@/hooks/useUndoableUploadNewFiles';
import { getCenterPoint, getCurrentCameraValues } from '@/utils/camera/camera';
import utils from '@/utils/css/cssUtils.module.css';

export const input = document.createElement('input') as HTMLInputElement;
input.type = 'file';
input.accept = '*';
input.multiple = true;
input.className = utils.hideVisually;

export const useFileImport = () => {
  const { universeId } = useParams({ strict: false });
  const { handleUndoableUploadNewFiles } = useUndoableUploadNewFiles();
  const pushKPI = usePushKPI();

  useEffect(() => {
    input.value = '';
  });

  useEffect(() => {
    if (!input.isConnected) {
      document.documentElement.appendChild(input);
    }
    input.onchange = (inputEvent: Event) => {
      const inputFileList = (inputEvent.currentTarget as HTMLInputElement)
        ?.files;
      if (inputFileList && universeId) {
        const camera = getCurrentCameraValues();
        const position = getCenterPoint(camera);

        handleUndoableUploadNewFiles(inputFileList, position);

        for (const file of inputFileList) {
          pushKPI({
            type: 'create',
            properties: {
              interaction: 'drag',
              coType: 'file',
              coFormat: file.type,
              coSize: file.size,
              coUniverse: universeId,
            },
          });
        }
      }
    };
  }, [handleUndoableUploadNewFiles, pushKPI, universeId]);

  const openFilePicker = () => {
    input.click();
  };

  return { openFilePicker };
};
