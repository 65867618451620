import { useLayoutEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { localStickerFamily } from '@/atoms/sticker';
import { HtmlEmbedSticker } from '@/components/HTMLCanvas/stickers/HtmlEmbedSticker';
import { HtmlWebPreviewSticker } from '@/components/HTMLCanvas/stickers/HtmlWebPreviewSticker';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { embedProviders } from '@/utils/embed/provider';
import {
  KosmikWebSticker,
  WebStickerAttributes,
} from '@/utils/kosmik/stickers/web';

export type FetchedMetas = {
  url?: string;
  title?: string;
  description?: string;
  image?: string | null;
  favicon?: string;
};

export const HtmlWebSticker = ({ sticker }: { sticker: KosmikWebSticker }) => {
  const { url } = sticker.attributes;
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerAttributes =
    useSetStickerAttributes<WebStickerAttributes>(setSticker);
  const matchingEmbedProvider = embedProviders.find((provider) =>
    provider.embedRegExp.test(url)
  );
  const lastUrlRef = useRef<string>(url);

  useLayoutEffect(() => {
    if (url !== lastUrlRef.current) {
      setStickerAttributes({ sized: false, embed: undefined }, true);
      lastUrlRef.current = url;
    }
  }, [setStickerAttributes, url]);

  return matchingEmbedProvider ? (
    <HtmlEmbedSticker sticker={sticker} provider={matchingEmbedProvider} />
  ) : (
    <HtmlWebPreviewSticker sticker={sticker} />
  );
};
