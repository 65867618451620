import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { UserBilling } from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/AccountInfo/Billing/UserBilling';
import { UserProfile } from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/AccountInfo/Profile/UserProfile';
import { useIsDev } from '@/hooks/env/useIsDev';
import { DialogTitle } from '@/ui/Dialog/Dialog';

export const AccountInfo = () => {
  const isDev = useIsDev();

  return (
    <div className={styles.tabsContentContainer}>
      <VisuallyHidden>
        <DialogTitle>User settings</DialogTitle>
      </VisuallyHidden>
      <UserProfile />
      {isDev ? <UserBilling /> : null}
    </div>
  );
};
