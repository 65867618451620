import * as TabsPrimitive from '@radix-ui/react-tabs';

import styles from '@/components/ui/Tabs/List/TabsList.module.css';

export const TabsList = ({
  children,
  className,
  ...props
}: TabsPrimitive.TabsListProps) => {
  return (
    <div className={className ?? styles.tabsListContainer}>
      <TabsPrimitive.List asChild {...props}>
        <div className={styles.tabsListChildren}>{children}</div>
      </TabsPrimitive.List>
    </div>
  );
};
