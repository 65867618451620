import { useLayoutEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useLocalStorage } from 'usehooks-ts';

import { cameraAtom } from '@/atoms/camera';
import { getCameraLocalStorageKey } from '@/hooks/camera/useCamera';
import { Camera } from '@/utils/camera/camera';
import { MaybeNull, MaybeUndefined } from '@/utils/types';

export const useSyncLocalStorageCamera = (universeId?: string) => {
  const privateSetCamera = useSetRecoilState(cameraAtom);
  const [isSynced, setIsSynced] = useState(false);
  const [localStorageCamera, setLocalStorageCamera] = useLocalStorage<
    MaybeNull<Camera>
  >(getCameraLocalStorageKey(universeId), null);
  const universeToSync = useRef<MaybeUndefined<string>>();
  const lastSyncedUniverse = useRef<MaybeUndefined<string>>();

  useLayoutEffect(() => {
    if (lastSyncedUniverse.current !== universeId) {
      universeToSync.current = universeId;
      setIsSynced(false);
    }
  }, [universeId]);

  useLayoutEffect(() => {
    if (
      localStorageCamera &&
      universeToSync.current !== lastSyncedUniverse.current
    ) {
      lastSyncedUniverse.current = universeToSync.current;
      setIsSynced(true);
      privateSetCamera(localStorageCamera);
    }
  }, [localStorageCamera, privateSetCamera]);

  return {
    isSynced,
    hasLocalStorageCamera: !!localStorageCamera,
    setLocalStorageCamera,
  };
};
