import { useCallback, useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

import { isPinPanelOpenedAtom, pinnedStickerIdAtom } from '@/atoms/pinpanel';
import { localStickerFamily, stickerPinnedStateFamily } from '@/atoms/sticker';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { useCreateLocalWebSticker } from '@/hooks/sticker/create/useCreateLocalWebSticker';
import { deleteFromSetWithFallback } from '@/utils/set/set';
import { MaybeNull } from '@/utils/types';

export const useSetPinnedSticker = () => {
  const setPinnedStickerId = useSetRecoilState(pinnedStickerIdAtom);
  const setIsPinPanelOpened = useSetRecoilState(isPinPanelOpenedAtom);
  const user = useContext(MultiplayerUserContext);
  const createLocalWebSticker = useCreateLocalWebSticker();

  const resetPinnedSticker = useCallback(() => {
    const pinnedStickerId = getRecoil(pinnedStickerIdAtom);
    if (pinnedStickerId) {
      setPinnedStickerId(null);
      setRecoil(stickerPinnedStateFamily(pinnedStickerId), (prev) => {
        if (user) {
          return deleteFromSetWithFallback(
            prev,
            user,
            (multiplayerUser) => multiplayerUser.id === user.id
          );
        } else {
          return prev;
        }
      });
    }
  }, [setPinnedStickerId, user]);

  const setPinnedSticker = useCallback(
    (stickerId: MaybeNull<string>, forceUseSameId = false) => {
      if (stickerId) {
        const firstSelectedSticker = getRecoil(localStickerFamily(stickerId));
        if (!forceUseSameId && firstSelectedSticker?.type === 'web') {
          stickerId = createLocalWebSticker(firstSelectedSticker.attributes);
          if (!stickerId) {
            return;
          }
        }

        setPinnedStickerId(stickerId);
        setIsPinPanelOpened(true);
        setRecoil(stickerPinnedStateFamily(stickerId), (prev) => {
          if (user) {
            const newState = new Set(prev);
            newState.add(user);
            return newState;
          } else {
            return prev;
          }
        });
      } else {
        resetPinnedSticker();
      }
    },
    [
      createLocalWebSticker,
      resetPinnedSticker,
      setIsPinPanelOpened,
      setPinnedStickerId,
      user,
    ]
  );

  return { setPinnedSticker, resetPinnedSticker };
};
