import { Button } from '@lithium/spectrum';

import launchPad from '@/assets/subscriptionPlans/launchpad.svg';
import mars from '@/assets/subscriptionPlans/mars.svg';
import saturn from '@/assets/subscriptionPlans/saturn.svg';
import { TextBodySemibold } from '@/components/fontSystem/BodySemibold';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import planStyle from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/AccountInfo/Billing/UserBilling.module.css';
import { DialogSection } from '@/ui/Dialog/DialogSection';

interface SubscriptionPlansType {
  name: 'Launchpad' | 'Mars' | 'Saturn';
  icon: string;
  monthlyPrice: 'Free' | '$7.99' | '$12.99';
  yearlyPrice: 'Free' | '$79.99' | '$129.99';
}

interface CurrentPlan {
  name: SubscriptionPlansType['name'];
  type: 'monthly' | 'yearly';
}

const SubscriptionPlans: SubscriptionPlansType[] = [
  {
    name: 'Launchpad',
    icon: launchPad,
    monthlyPrice: 'Free',
    yearlyPrice: 'Free',
  },
  {
    name: 'Mars',
    icon: mars,
    monthlyPrice: '$7.99',
    yearlyPrice: '$79.99',
  },
  {
    name: 'Saturn',
    icon: saturn,
    monthlyPrice: '$12.99',
    yearlyPrice: '$129.99',
  },
] as const;

export const UserBilling = () => {
  const currentPlan: CurrentPlan = {
    name: 'Mars',
    type: 'monthly',
  };

  return (
    <DialogSection title={'Billing and Plans'}>
      {SubscriptionPlans.map(({ name, monthlyPrice, yearlyPrice, icon }) => {
        if (currentPlan.name !== name) {
          return null;
        }

        return (
          <div className={styles.settingsPageFieldRow} key={name}>
            <div className={planStyle.planContainer}>
              <img
                src={icon}
                alt={`icon of ${name}`}
                className={planStyle.icon}
              />
              <div className={planStyle.infoContainer}>
                <TextBodySemibold>{name}</TextBodySemibold>
                {currentPlan.type === 'monthly' && (
                  <TextLabelRegular>{monthlyPrice}</TextLabelRegular>
                )}
                {currentPlan.type === 'yearly' && (
                  <TextLabelRegular>{yearlyPrice}</TextLabelRegular>
                )}
              </div>
            </div>
            <Button>Manage Plan</Button>
          </div>
        );
      })}
    </DialogSection>
  );
};
