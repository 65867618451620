import { Accounts, Palette, ShapeStar } from '@lithium/spectrum';
import { useState } from 'react';

import styles from '@/components/modal/modals/Settings/components/SettingsTabsItem/SettingsTabs.module.css';
import { SettingsTabsItem } from '@/components/modal/modals/Settings/components/SettingsTabsItem/SettingsTabsItem';
import { UserSettingsFooter } from '@/components/modal/modals/Settings/UserSettings/Footer/UserSettingsFooter';
import { AboutInfo } from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/AboutInfo/AboutInfo';
import { AccountInfo } from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/AccountInfo/AccountInfo';
import { InterfaceSettings } from '@/components/modal/modals/Settings/UserSettings/UserSettingsTabsContent/InterfaceSettings/InterfaceSettings';
import { TabsContent } from '@/ui/Tabs/Content/TabsContent';
import { Tabs } from '@/ui/Tabs/Tabs';

export const UserSettings = () => {
  const [tab, setTab] = useState('account');
  return (
    <div
      key={tab}
      style={{ minWidth: 'clamp(400px, 90vw, 474px)', width: '100%' }}
    >
      <Tabs
        listClassName={styles.tabsList}
        itemClassName={styles.tabsTriggerItem}
        value={tab}
        onValueChange={setTab}
        triggers={[
          {
            id: 'account',
            node: (
              <SettingsTabsItem
                icon={<Accounts />}
                name={'Account'}
                key={'Account'}
              />
            ),
          },
          {
            id: 'interface',
            node: (
              <SettingsTabsItem
                icon={<Palette />}
                name={'Interface'}
                key={'Interface'}
              />
            ),
          },
          {
            id: 'about',
            node: (
              <SettingsTabsItem
                icon={<ShapeStar />}
                name={'About'}
                key={'About'}
              />
            ),
          },
        ]}
      >
        <TabsContent value={'account'}>
          <AccountInfo />
        </TabsContent>
        <TabsContent value={'interface'}>
          <InterfaceSettings />
        </TabsContent>
        <TabsContent value={'about'}>
          <AboutInfo />
        </TabsContent>
      </Tabs>
      <UserSettingsFooter />
    </div>
  );
};
