import { PDFPageProxy } from 'pdfjs-dist';
import React, { useEffect } from 'react';

import {
  DATA_ATTRIBUTE_PAGE,
  DATA_ATTRIBUTE_RENDERED,
} from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfPage';
import { getPdfElements } from '@/components/HTMLCanvas/PinPanel/PdfViewer/utils/RenderPageAndTextLayer';
import { pdfPageToCanvas } from '@/hooks/pdf/utils/pdfPageToCanvas';
import { IterableRefsMapKey } from '@/hooks/useIterableRefs';

/**
 * Renders preview pages as they become visible
 * @param containerRef
 * @param pageRefs
 * @param pageProxies
 */
export const useRenderPreviewPageWhenVisible = (
  containerRef: React.RefObject<HTMLDivElement>,
  pageRefs: Map<IterableRefsMapKey, HTMLButtonElement>,
  pageProxies: PDFPageProxy[]
) => {
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    // Detect when a preview page becomes visible and render it
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { target } = entry;
          if (target instanceof HTMLElement) {
            const pageNumber = Number(target.dataset[DATA_ATTRIBUTE_PAGE]);
            const { pageWrapper, canvas } = getPdfElements(target);
            const isRendered = target.dataset[DATA_ATTRIBUTE_RENDERED];
            const page = pageProxies[pageNumber - 1];
            if (
              pageWrapper &&
              canvas &&
              !isRendered &&
              entry.isIntersecting &&
              page
            ) {
              target.dataset[DATA_ATTRIBUTE_RENDERED] = '';
              pdfPageToCanvas({
                canvas,
                page,
                size: { width: 100 },
              });
            }
          }
        });
      },
      {
        root: containerRef.current,
        rootMargin: '100% 0% 100% 0%',
      }
    );

    // Observe all the pages
    [...pageRefs.values()].forEach((page) => {
      observer.observe(page);
    });

    return () => {
      observer.disconnect();
    };
  }, [containerRef, pageProxies, pageRefs]);
};
