import { MultiplayerCursor } from '@/components/multiplayer/MultiplayerCursors/MultiplayerCursor';
import { useRoom } from '@/context/Room/useRoom';

export const MultiplayerCursors = () => {
  const { room } = useRoom();

  const presence = room.usePresence({
    user: false,
    keys: ['x', 'y', 'user'],
  });

  const peers = Object.entries(presence.peers).filter(([_, val]) => {
    return val.user?.id;
  });

  return peers.map(([id, props]) => {
    const { user, ...position } = props;
    return <MultiplayerCursor key={id} {...position} user={user} />;
  });
};
