import { PDFDocumentProxy } from 'pdfjs-dist';
import { useEffect, useState } from 'react';

import { TableOfContentEntry } from '@/components/HTMLCanvas/PinPanel/PdfViewer/PdfContentTable';
import { MaybeUndefined } from '@/utils/types';

export const usePdfContentTable = (pdf: MaybeUndefined<PDFDocumentProxy>) => {
  const [contentTable, setContentTable] = useState<TableOfContentEntry[]>([]);
  useEffect(() => {
    // Get the tree outline
    pdf
      ?.getOutline()
      .then(async (outline) => {
        const pairs: TableOfContentEntry[] = [];

        if (outline) {
          for (let i = 0; i < outline.length; i++) {
            const dest = outline[i]?.dest;
            const ref = dest?.[0];
            const id = await pdf.getPageIndex(ref);
            pairs.push({
              title: outline[i]?.title ?? 'Empty title',
              pageNumber: id + 1,
            });
          }
        }

        setContentTable(pairs);
      })
      .catch(() => {
        /* no-op */
      });

    return () => setContentTable([]);
  }, [pdf]);
  return contentTable;
};
