import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';

import { DropdownContent } from '@/components/ui/Dropdown/DropdownContent';

export interface DropdownProps
  extends DropdownPrimitive.DropdownMenuProps,
    React.PropsWithChildren<{
      trigger: React.ReactNode;
      contentProps?: DropdownPrimitive.DropdownMenuContentProps;
    }> {}

export const Dropdown = ({
  contentProps,
  trigger,
  children,
  ...props
}: DropdownProps) => (
  <DropdownPrimitive.Root {...props}>
    <DropdownPrimitive.Trigger asChild>{trigger}</DropdownPrimitive.Trigger>
    <DropdownPrimitive.Portal>
      <DropdownContent
        collisionPadding={4}
        align={'start'}
        sideOffset={4}
        onCloseAutoFocus={(event) => event.preventDefault()}
        {...contentProps}
      >
        {children}
      </DropdownContent>
    </DropdownPrimitive.Portal>
  </DropdownPrimitive.Root>
);
