export const FILE_EXTENSION_ICONS = {
  ppt: 'assets/icon/file-extension/ppt.svg',
  pptx: 'assets/icon/file-extension/ppt.svg',
  ai: 'assets/icon/file-extension/ai.svg',
  psd: 'assets/icon/file-extension/psd.svg',
  psb: 'assets/icon/file-extension/psd.svg',
  avi: 'assets/icon/file-extension/avi.svg',
  css: 'assets/icon/file-extension/css.svg',
  csv: 'assets/icon/file-extension/csv.svg',
  dbf: 'assets/icon/file-extension/dbf.svg',
  doc: 'assets/icon/file-extension/doc.svg',
  docx: 'assets/icon/file-extension/doc.svg',
  pages: 'assets/icon/file-extension/doc.svg',
  dwg: 'assets/icon/file-extension/dwg.svg',
  exe: 'assets/icon/file-extension/exe.svg',
  fla: 'assets/icon/file-extension/fla.svg',
  html: 'assets/icon/file-extension/html.svg',
  iso: 'assets/icon/file-extension/iso.svg',
  js: 'assets/icon/file-extension/javascript.svg',
  json: 'assets/icon/file-extension/json-file.svg',
  mp3: 'assets/icon/file-extension/mp3.svg',
  svg: 'assets/icon/file-extension/svg.svg',
  xls: 'assets/icon/file-extension/xls.svg',
  xlsx: 'assets/icon/file-extension/xls.svg',
  xml: 'assets/icon/file-extension/xml.svg',
  zip: 'assets/icon/file-extension/zip.svg',
  rar: 'assets/icon/file-extension/zip.svg',
} as const;

export type FileExtensionWithIcon = keyof typeof FILE_EXTENSION_ICONS;

export const GENERIC_FILE_ICON = 'assets/icon/file-extension/file.svg';
