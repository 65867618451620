import { id, tx } from '@instantdb/react';
import { useCallback, useContext } from 'react';
import { getRecoil, resetRecoil, setRecoil } from 'recoil-nexus';
import { v4 as uuidv4 } from 'uuid';

import { localStickerFamily, stickerUploadStateFamily } from '@/atoms/sticker';
import { UniverseContext } from '@/context/Universe/UniverseContext';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { db } from '@/db/databaseInit';
import { useDocumentAnalyzer } from '@/hooks/ai/useDocumentAnalyzer.ts';
import { useAddRemoveTemporaryStickers } from '@/hooks/sticker/useAddRemoveTemporaryStickers';
import { batchTransact } from '@/utils/db/db';
import { generateExpiresAt, getStoragePath } from '@/utils/file/file';
import { Position } from '@/utils/geometry/position';
import { KosmikFile } from '@/utils/kosmik/file';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { createFileSticker } from '@/utils/sticker/create/createFileSticker';
import {
  deleteStickerOrphanedFile,
  getTransactableStickerProperties,
} from '@/utils/sticker/sticker';
import { MaybeNull } from '@/utils/types';

export const useUploadFiles = (universeId: string) => {
  const user = useContext(MultiplayerUserContext);
  const { isUniverseMember } = useContext(UniverseContext);
  const { addTemporaryStickers } = useAddRemoveTemporaryStickers(universeId);
  const analyzeDocument = useDocumentAnalyzer(universeId);

  /**
   * Upload a given sticker's file
   */
  const uploadStickerFile = useCallback(
    async (sticker: KosmikFileSticker, file: File) => {
      if (!user) {
        return;
      }
      const storagePath = getStoragePath(file, user);
      const prevFileEntity = sticker.files?.[0];
      setRecoil(stickerUploadStateFamily(sticker.id), { isUploading: true });

      /**
       * Guard against performing operations on a deleted sticker
       */
      const isStickerDeleted = (id: string) => {
        const localSticker = getRecoil(localStickerFamily(id));
        return !localSticker;
      };

      /**
       * When uploading a file, we only create a temporary sticker until
       * the file was uploaded. This temporary sticker is then added to the
       * local stickers when rendering. We then persist the full local sticker
       * once the file was uploaded, so that any modification made while the
       * file was uploading (position, size, etc.) are properly persisted
       */
      const persistLocalStickerAndLinkFile = (
        stickerId: string,
        fileEntityId: string
      ) => {
        const localSticker = getRecoil(localStickerFamily(stickerId));
        const updatePayload = getTransactableStickerProperties(
          localSticker
            ? { ...localSticker, v: (localSticker?.v ?? 0) + 1 }
            : sticker
        );
        batchTransact([
          tx.stickers?.[stickerId]
            ?.update(updatePayload)
            .link({ universes: [universeId] }),
          tx.files?.[fileEntityId]?.link({ stickers: [stickerId] }),
        ]);
      };

      /**
       * Create file entity for the uploaded file, and link it to the sticker
       */
      const handleFileUploaded = async () => {
        const cachedUrl = await db.storage.getDownloadUrl(storagePath);
        const expiresAt = generateExpiresAt();
        const fileEntity: Omit<KosmikFile, 'id'> = {
          type: file.type,
          file_name: file.name,
          file_size: file.size,
          storage_path: storagePath,
          expires_at: expiresAt,
          cached_url: cachedUrl,
        };

        // Give S3 some time to propagate, it can error if you access it too fast
        await new Promise((resolve) => setTimeout(resolve, 250));
        const uploadingState = getRecoil(stickerUploadStateFamily(sticker.id));
        const uploadingDuplicateIds = uploadingState?.uploadingDuplicates ?? [];
        const idsToPersist = [sticker.id, ...uploadingDuplicateIds];
        const isBlob = prevFileEntity?.cached_url.startsWith('blob:') ?? false;
        const fileEntityId =
          isBlob && prevFileEntity?.id ? prevFileEntity?.id : id();
        batchTransact(tx.files?.[fileEntityId]?.update(fileEntity));
        for (const stickerId of idsToPersist) {
          // Only persist if the sticker wasn't deleted
          if (!isStickerDeleted(stickerId)) {
            persistLocalStickerAndLinkFile(stickerId, fileEntityId);
            resetRecoil(stickerUploadStateFamily(stickerId));
          }
        }
        // Delete previous file entity if not linked to any stickers anymore
        if (prevFileEntity && !isBlob) {
          deleteStickerOrphanedFile([sticker]);
        }
      };

      /**
       * Store the file and update sticker after
       */
      await db.storage
        .upload(storagePath, file)
        .then((success) => {
          if (success) {
            return handleFileUploaded();
          } else {
            console.error('Failed to upload file');
          }
        })
        .catch((reason) => {
          console.error(reason);
        });
    },
    [universeId, user]
  );

  /**
   * Create a new file sticker and upload the given file
   */
  const uploadNewFile = useCallback(
    (
      file: File,
      position: Position,
      source?: string
    ): MaybeNull<KosmikFileSticker> => {
      if (!user || !isUniverseMember) {
        return null;
      }
      const type = file.type;
      const storagePath = getStoragePath(file, user);

      // Instantly create the sticker, so the placeholder appears, and we can
      // display local file while uploading. At this point, we only show the
      // sticker to the player who created it, so multiplayer users don't see
      // a potentially long loading state and wonder if loading is broken in the
      // case of large file uploads

      const fileSize = file.size;
      const fileUrl = URL.createObjectURL(file);
      const expiresAt = generateExpiresAt();
      const [stickerId, sticker, fileEntityId, fileEntity] = createFileSticker({
        type,
        fileSize,
        storagePath,
        position,
        universeId,
        fileName: file.name,
        cachedUrl: fileUrl,
        source_url: source,
        expiresAt: expiresAt,
        persist: false,
      });

      analyzeDocument(stickerId, file);

      const fileEntityWithId = { id: fileEntityId, ...fileEntity };
      const stickerWithIdAndFile = {
        id: stickerId,
        ...sticker,
        files: [fileEntityWithId],
      };
      addTemporaryStickers([stickerWithIdAndFile]);
      uploadStickerFile(stickerWithIdAndFile, file).then(() => {
        // Release the local blob memory
        URL.revokeObjectURL(fileUrl);
      });

      return stickerWithIdAndFile;
    },
    [
      addTemporaryStickers,
      analyzeDocument,
      isUniverseMember,
      universeId,
      uploadStickerFile,
      user,
    ]
  );

  /**
   * Create a new file sticker and upload the given blob
   */
  const uploadNewFileBlob = useCallback(
    (
      blob: Blob,
      position: Position,
      source?: string
    ): MaybeNull<KosmikFileSticker> => {
      const type = blob.type;
      const file = new File([blob], uuidv4(), { type });
      return uploadNewFile(file, position, source);
    },
    [uploadNewFile]
  );

  /**
   * Batch create new file stickers and upload their files
   */
  const uploadNewFiles = useCallback(
    (files: FileList, position: Position): KosmikFileSticker[] => {
      const stickers: KosmikFileSticker[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file) {
          const { x, y } = position;
          const uploadedSticker = uploadNewFile(file, {
            x: x + i * 40,
            y: y + i * 40,
          });
          if (uploadedSticker) {
            stickers.push(uploadedSticker);
          }
        }
      }
      return stickers;
    },
    [uploadNewFile]
  );

  return {
    uploadNewFile,
    uploadNewFiles,
    uploadStickerFile,
    uploadNewFileBlob,
  };
};
