import { ComponentProps, forwardRef } from 'react';

import styles from '@/components/HTMLCanvas/StickerStyle.module.css';

export const StickerStyle = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  (props, ref) => {
    return <div ref={ref} className={styles.basicStickerStyle} {...props} />;
  }
);

StickerStyle.displayName = 'StickerStyle';
