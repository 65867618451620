import * as TabsPrimitive from '@radix-ui/react-tabs';
import React from 'react';

import { TabsList } from '@/components/ui/Tabs/List/TabsList';
import { TabsTrigger } from '@/components/ui/Tabs/Trigger/TabsTrigger';

import styles from './Tabs.module.css';

export interface TabsProps
  extends TabsPrimitive.TabsProps,
    React.PropsWithChildren<{
      triggers: { id: string; node: React.ReactNode }[];
      itemClassName?: string;
      listClassName?: string;
    }> {}

export const Tabs = ({
  triggers,
  children,
  itemClassName,
  listClassName,
  ...props
}: TabsProps) => {
  return (
    <TabsPrimitive.Root className={styles.tabsRoot} {...props}>
      <TabsList className={listClassName}>
        {triggers.map(({ id, node }) => {
          return (
            <TabsTrigger key={id} value={id} className={itemClassName}>
              {node}
            </TabsTrigger>
          );
        })}
      </TabsList>
      {children}
    </TabsPrimitive.Root>
  );
};
