import {
  MouseEventHandler,
  ReactEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useSetRecoilState } from 'recoil';

import LinkIcon from '@/assets/kosmik/linkIcon.svg?react';
import { localStickerFamily } from '@/atoms/sticker';
import { TextTag } from '@/components/fontSystem/TextTag';
import { FetchedMetas } from '@/components/HTMLCanvas/stickers/HtmlWebSticker';
import { useSetPinnedSticker } from '@/hooks/pinPanel/useSetPinnedSticker';
import { useSetStickerAttributes } from '@/hooks/sticker/useSetStickerAttributes';
import { useSetStickerProperties } from '@/hooks/sticker/useSetStickerProperties';
import {
  KosmikWebSticker,
  WebStickerAttributes,
} from '@/utils/kosmik/stickers/web';
import { isElectron } from '@/utils/platform/isElectron';
import { Maybe } from '@/utils/types';

import styles from './HtmlWebPreview.module.css';

export const HtmlWebPreviewSticker = ({
  sticker,
}: {
  sticker: KosmikWebSticker;
}) => {
  const [fetchedMetas, setFetchedMetas] = useState<Maybe<FetchedMetas>>();
  const resizeOnLoad = useRef(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const lastFetchedUrl = useRef('');
  const setSticker = useSetRecoilState(localStickerFamily(sticker.id));
  const setStickerProperties =
    useSetStickerProperties<KosmikWebSticker>(setSticker);
  const setStickerAttributes =
    useSetStickerAttributes<WebStickerAttributes>(setSticker);
  const { setPinnedSticker } = useSetPinnedSticker();

  const { resizeable, height } = sticker;
  const { url, image, description, title, favicon } = sticker.attributes;

  useLayoutEffect(() => {
    if (lastFetchedUrl.current === url) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;
    fetch(`${import.meta.env.VITE_META_PROXY}/meta?url=${url}`, {
      signal,
      headers: new Headers({
        'accept-language': navigator.languages.join(','),
        'user-agent': navigator.userAgent,
      }),
    })
      .then(async (resp) => {
        const meta: Pick<
          FetchedMetas,
          'title' | 'description' | 'image' | 'favicon'
        > = await resp.json();

        setFetchedMetas((prev) => {
          return {
            title: meta?.title ?? prev?.title,
            image: meta?.image ?? prev?.image,
            description: meta?.description ?? prev?.description,
            favicon:
              meta?.favicon ??
              prev?.favicon ??
              `https://icons.duckduckgo.com/ip3/${new URL(url).hostname}.ico`,
            url: url,
          };
        });
        lastFetchedUrl.current = url;
      })
      .catch(() => undefined);

    return () => {
      controller.abort('Unmounted fetch meta');
    };
  }, [url]);

  useLayoutEffect(() => {
    if (fetchedMetas) {
      const metaImage = fetchedMetas?.image;
      const metaDescription = fetchedMetas?.description;
      const metaTitle = fetchedMetas?.title;
      const metaFavicon = fetchedMetas?.favicon;
      const payload: Partial<WebStickerAttributes> = {};
      if (image !== metaImage) {
        resizeOnLoad.current = true;
        payload.image = metaImage ?? undefined;
      }
      if (description !== metaDescription) {
        payload.description = metaDescription;
      }
      if (title !== metaTitle) {
        payload.title = metaTitle;
      }
      if (favicon !== metaFavicon) {
        payload.favicon = metaFavicon;
      }
      if (Object.keys(payload).length) {
        setStickerAttributes(payload, true);
      }
    }
  }, [description, favicon, fetchedMetas, image, setStickerAttributes, title]);

  useEffect(() => {
    if (!resizeable) {
      setStickerProperties({ resizeable: true }, true);
    }
  }, [resizeable, setStickerProperties]);

  const isDragging = useRef(false);
  const moveDelta = useRef({ x: 0, y: 0 });

  const handleLinkMouseDown: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    isDragging.current = true;
    moveDelta.current = { x: 0, y: 0 };
  };

  const handleLinkMouseMove: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (isDragging.current) {
      const { x, y } = moveDelta.current;
      moveDelta.current = {
        x: x + event.movementX,
        y: y + event.movementX,
      };
    }
  };

  const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    const { x, y } = moveDelta.current;
    isDragging.current = false;
    moveDelta.current = { x: 0, y: 0 };
    if (Math.hypot(x, y) < 5) {
      if (isElectron) {
        event.preventDefault();
        setPinnedSticker(sticker.id);
      }
    } else {
      event.preventDefault();
    }
  };

  const linkProps = {
    href: url,
    target: '_blank',
    rel: 'noreferrer',
    className: styles.link,
    onMouseDown: handleLinkMouseDown,
    onMouseMove: handleLinkMouseMove,
    onClick: handleLinkClick,
  };

  const handleImageLoad: ReactEventHandler<HTMLImageElement> = (event) => {
    if (!resizeOnLoad.current) {
      return;
    }
    const image = event.currentTarget;
    const imageWidth = image.offsetWidth;
    const { naturalWidth, naturalHeight } = image;
    const ratio = naturalHeight / naturalWidth;
    const imageHeight = ratio * imageWidth;
    const footer = footerRef.current;
    const payload: Partial<KosmikWebSticker> = {};
    let newHeight = imageHeight;
    if (footer) {
      const descriptionHeight = footer.offsetHeight;
      newHeight = imageHeight + descriptionHeight;
    }
    if (newHeight !== height) {
      payload.height = newHeight;
    }
    if (Object.keys(payload).length) {
      setStickerProperties(payload, true);
    }
  };

  return (
    <div className={styles.webSticker}>
      {sticker.attributes.image ? (
        <div className={styles.image}>
          <img
            alt={''}
            data-testid={'preview'}
            className={styles.img}
            src={sticker.attributes.image}
            onLoad={handleImageLoad}
            draggable={false}
          />
        </div>
      ) : null}
      <footer ref={footerRef} className={styles.footer}>
        <div className={styles.title}>
          <span className={styles.favicon}>
            <img
              alt={''}
              data-testid={'favicon'}
              src={favicon}
              style={{ width: 16 }}
              draggable={false}
            />
          </span>
          <div className={styles.urlContainer}>
            <a data-testid={'web-preview-title'} {...linkProps}>
              <TextTag>{title || url}</TextTag>
            </a>
          </div>
          <div className={styles.linkIconContainer}>
            <a data-testid={'web-preview-link-icon'} {...linkProps}>
              <LinkIcon />
            </a>
          </div>
        </div>
        {sticker.attributes.description ? (
          <TextTag className={styles.description}>
            {sticker.attributes.description}
          </TextTag>
        ) : null}
      </footer>
    </div>
  );
};
