import { ReactNode, useContext } from 'react';

import { TextSection } from '@/components/fontSystem/Section';
import { TextTag } from '@/components/fontSystem/TextTag';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { Avatar } from '@/ui/Avatar/Avatar';
import { getContrastColor, stringToHslColor } from '@/utils/color/colors';
import { KosmikUser } from '@/utils/kosmik/user';

import styles from './TeamMemberCell.module.css';

export type TeamMemberCellProps = {
  user: KosmikUser;
  suffix?: ReactNode;
};

export const TeamMemberCell = ({ user, suffix }: TeamMemberCellProps) => {
  const contextUser = useContext(MultiplayerUserContext);
  const isYou = contextUser?.id === user.id;

  const you = isYou ? (
    <TextSection style={{ color: 'var(--label-muted)' }}>(you)</TextSection>
  ) : null;

  const color = stringToHslColor(user.email);
  const contrastColor = getContrastColor(color);

  return (
    <div className={styles.cellContainer}>
      <div className={styles.avatarContainer}>
        <Avatar
          name={user.name}
          color={color}
          contrastColor={contrastColor}
          id={user.id}
          size={24}
          style={'round'}
        />
      </div>
      <div className={styles.column}>
        <TextSection>
          {user.name} {you}
        </TextSection>
        <TextTag>{user.email}</TextTag>
      </div>
      {suffix}
    </div>
  );
};
