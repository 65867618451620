import { ComponentProps } from 'react';

import styles from './DragHandle.module.css';

export const DragHandle = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div className={`${styles.dragHandleArea} ${className}`} {...props}>
      <div className={styles.dragHandleGripper} />
    </div>
  );
};
