import { id, tx } from '@instantdb/react';

import { DEFAULT_RESIZABLE_STICKER } from '@/constants/resizable';
import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { batchTransact } from '@/utils/db/db';
import { Position } from '@/utils/geometry/position';
import { Size } from '@/utils/geometry/size';
import { normalizeImageSize } from '@/utils/image';
import { KosmikFile } from '@/utils/kosmik/file';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { offscreenAudio, offscreenVideo } from '@/utils/media/offscreen';
import { getRenderableFileType } from '@/utils/sticker/file';

export type CreateFileStickerProps = {
  storagePath: string;
  fileName: string;
  expiresAt: string;
  cachedUrl: string;
  type: string;
  fileSize: number;
  position: Position;
  universeId: string;
  source_url?: string;
  persist?: boolean;
};

export const createFileSticker = ({
  storagePath,
  fileName,
  expiresAt,
  cachedUrl,
  type,
  fileSize,
  position,
  source_url,
  universeId,
  persist = true,
}: CreateFileStickerProps) => {
  const renderableFileType = getRenderableFileType(type);
  let size: Size = DEFAULT_STICKER_SIZE[renderableFileType];
  if (renderableFileType === 'image' && cachedUrl.startsWith('blob:')) {
    const img = new Image();
    img.src = cachedUrl;
    size = normalizeImageSize(img);
  } else if (
    (renderableFileType === 'video' && !offscreenVideo.canPlayType(type)) ||
    (renderableFileType === 'audio' && !offscreenAudio.canPlayType(type))
  ) {
    size = DEFAULT_STICKER_SIZE.file;
  }

  // Create the file object
  const fileId = id();
  const file: Omit<KosmikFile, 'id'> = {
    type: type,
    file_name: fileName,
    file_size: fileSize,
    storage_path: storagePath,
    expires_at: expiresAt,
    cached_url: cachedUrl,
  };
  // Create the sticker object
  const stickerId = id();
  const sticker: Omit<KosmikFileSticker, 'id'> = {
    v: 0,
    ...position,
    ...size,
    type: 'file',
    created_at: new Date().toISOString(),
    resizeable: DEFAULT_RESIZABLE_STICKER[renderableFileType],
    attributes: {
      source: source_url,
      source_file_url: source_url,
    },
  };
  if (persist) {
    batchTransact([
      tx.stickers?.[stickerId]
        ?.update(sticker)
        .link({ universes: [universeId] }),
      tx.files?.[fileId]?.update(file).link({ stickers: [stickerId] }),
    ]);
  }
  return [stickerId, sticker, fileId, file] as const;
};
