import { useParams } from '@tanstack/react-router';
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { selectedStickerIdsAtom } from '@/atoms/selection';
import { useMoveTransformHooks } from '@/components/Universe/Selection/hooks/useMoveTransformHooks';
import {
  TransformHandle,
  TransformHandleProps,
  TransformPayload,
} from '@/components/Universe/Selection/TransformHandle';
import { useRoom } from '@/context/Room/useRoom';
import { MultiplayerUserContext } from '@/context/User/MultiplayerUserContext';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { Dimension } from '@/utils/geometry/dimension';
import { invertObjectValues } from '@/utils/math/invertObjectValues';
import { removeStyleProperties } from '@/utils/style/style';
import { MaybeUndefined } from '@/utils/types';

export const Transformer = ({
  boundingBox,
}: {
  boundingBox: Dimension;
  setBoundingBox: Dispatch<SetStateAction<MaybeUndefined<Dimension>>>;
}) => {
  const user = useContext(MultiplayerUserContext);
  const ref = useRef<HTMLDivElement>(null);
  const { room } = useRoom();
  const { transformLocalStickers, getTransformCSSVariables, persist } =
    useMoveTransformHooks();
  const selectedStickerIds = useRecoilValue(selectedStickerIdsAtom);
  const selectedStickerIdsRef = useRef(selectedStickerIds);

  const publishTransform = room.usePublishTopic('transform');
  const publishCommit = room.usePublishTopic('commit');
  const commitId = useRef('');
  const { universeId } = useParams({ strict: false });
  const { addUndoRedoAction } = useUndoRedo(universeId);

  // We keep a ref because using the state we get stale ids when transforming
  useEffect(() => {
    selectedStickerIdsRef.current = selectedStickerIds;
  }, [selectedStickerIds]);

  const transformCSS = useCallback(
    (payload: TransformPayload) => {
      const wrapper = ref.current;
      const cameraElement = wrapper?.closest('[data-camera]');
      if (!wrapper || !(cameraElement instanceof HTMLElement)) {
        return;
      }

      const transformVariables = getTransformCSSVariables(
        boundingBox,
        payload,
        user
      );
      if (transformVariables) {
        const { variables, newBoundingBox, initialBoundingBox } =
          transformVariables;
        variables.forEach((value, key) => {
          cameraElement.style.setProperty(key, value);
        });
        wrapper.style.setProperty('width', `${newBoundingBox.width}px`);
        wrapper.style.setProperty('height', `${newBoundingBox.height}px`);
        cameraElement.style.setProperty(
          '--bounding-box-offset-x',
          `${newBoundingBox.x - initialBoundingBox.x}px`
        );
        cameraElement.style.setProperty(
          '--bounding-box-offset-y',
          `${newBoundingBox.y - initialBoundingBox.y}px`
        );
      }
    },
    [boundingBox, getTransformCSSVariables, user]
  );

  const handleOnTransformStart = useCallback(() => {
    commitId.current = uuidv4();
  }, []);

  const handleOnTransform = useCallback(
    (payload: TransformPayload) => {
      transformCSS(payload);
      publishTransform({ ...payload, commitId: commitId.current });
    },
    [publishTransform, transformCSS]
  );

  const handleOnTransformEnd = useCallback(
    (payload: TransformPayload) => {
      const ids = [...selectedStickerIdsRef.current.values()];
      publishTransform({ ...payload, commitId: commitId.current });
      const transformAction: UndoRedoActionType = {
        do: () => {
          transformLocalStickers(ids, payload);
          persist(ids);
        },
        undo: () => {
          transformLocalStickers(ids, invertObjectValues(payload));
          persist(ids);
        },
      };
      transformAction.do();
      addUndoRedoAction(transformAction);

      publishCommit({ ...payload, commitId: commitId.current });

      const wrapper = ref.current;
      const cameraElement = wrapper?.closest('[data-camera]');
      if (!wrapper || !(cameraElement instanceof HTMLElement)) {
        return;
      }

      removeStyleProperties(cameraElement, [
        `--transform-${user?.peerId}-scale-x`,
        `--transform-${user?.peerId}-scale-y`,
        `--transform-${user?.peerId}-new-bbox-x`,
        `--transform-${user?.peerId}-new-bbox-y`,
        `--transform-${user?.peerId}-new-bbox-width`,
        `--transform-${user?.peerId}-new-bbox-height`,
        `--transform-${user?.peerId}-new-bbox-x-no-unit`,
        `--transform-${user?.peerId}-new-bbox-y-no-unit`,
        `--transform-${user?.peerId}-new-bbox-width-no-unit`,
        `--transform-${user?.peerId}-new-bbox-height-no-unit`,
        `--transform-${user?.peerId}-initial-bbox-x`,
        `--transform-${user?.peerId}-initial-bbox-y`,
        `--transform-${user?.peerId}-initial-bbox-width`,
        `--transform-${user?.peerId}-initial-bbox-height`,
        `--transform-${user?.peerId}-initial-bbox-x-no-unit`,
        `--transform-${user?.peerId}-initial-bbox-y-no-unit`,
        `--transform-${user?.peerId}-initial-bbox-width-no-unit`,
        `--transform-${user?.peerId}-initial-bbox-height-no-unit`,
      ]);
    },
    [
      addUndoRedoAction,
      persist,
      publishCommit,
      publishTransform,
      transformLocalStickers,
      user?.peerId,
    ]
  );

  const handleProps: Omit<TransformHandleProps, 'direction'> = {
    onTransformStart: handleOnTransformStart,
    onTransform: handleOnTransform,
    onTransformEnd: handleOnTransformEnd,
    ratio: {
      horizontal: boundingBox.width / boundingBox.height,
      vertical: boundingBox.height / boundingBox.width,
    },
  };

  return (
    <div
      ref={ref}
      data-transformer={''}
      style={
        {
          position: 'absolute',
          pointerEvents: 'none',
          borderRadius: 'calc(6px  / var(--camera-z))',
          outline:
            'calc(var(--transformer-outline-size) / var(--camera-z)) solid var(--accent-blue-primary)',
          width: boundingBox.width,
          height: boundingBox.height,
          transform: `translate3d(
              calc(${boundingBox.x}px + var(--transformer-offset-x, 0px)),
              calc(${boundingBox.y}px + var(--transformer-offset-y, 0px)),
              0
          )`,
          contain: 'layout size',
          ['--transformer-outline-size']: '2px',
          ['--transformer-offset-x']: `calc(var(--drag-${user?.peerId}-offset-x, 0px) + var(--bounding-box-offset-x, 0px))`,
          ['--transformer-offset-y']: `calc(var(--drag-${user?.peerId}-offset-y, 0px) + var(--bounding-box-offset-y, 0px))`,
        } as CSSProperties
      }
    >
      <TransformHandle direction={'left'} {...handleProps} />
      <TransformHandle direction={'topLeft'} {...handleProps} />
      <TransformHandle direction={'top'} {...handleProps} />
      <TransformHandle direction={'topRight'} {...handleProps} />
      <TransformHandle direction={'right'} {...handleProps} />
      <TransformHandle direction={'bottomLeft'} {...handleProps} />
      <TransformHandle direction={'bottom'} {...handleProps} />
      <TransformHandle direction={'bottomRight'} {...handleProps} />
    </div>
  );
};
