import { useCallback } from 'react';

import { useStickerSelection } from '@/components/selection/useStickerSelection';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { useDocumentAnalyzer } from '@/hooks/ai/useDocumentAnalyzer.ts';
import { usePushKPI } from '@/hooks/kpi/usePushKPI';
import { usePointerPositionRef } from '@/hooks/pointer/usePointerPositionRef';
import { useAddRemoveTemporaryStickers } from '@/hooks/sticker/useAddRemoveTemporaryStickers';
import { useDeleteStickers } from '@/hooks/sticker/useDeleteStickers';
import { useUploadFiles } from '@/hooks/universe/useUploadFiles';
import { UndoRedoActionType, useUndoRedo } from '@/hooks/useUndoRedo';
import { getFiles, getText, getUrls } from '@/utils/dataTransfer/dataTransfer';
import { isInputLike } from '@/utils/element/isInputLike';
import { downloadFile } from '@/utils/file/file';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { KosmikFileSticker } from '@/utils/kosmik/stickers/file';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';
import { createTextSticker } from '@/utils/sticker/create/createTextSticker/createTextSticker';
import { createWebSticker } from '@/utils/sticker/create/createWebSticker';
import {
  duplicateStickers,
  parseArrayOfStickers,
  undoableDuplicateStickers,
} from '@/utils/sticker/sticker';
import { getStickerFormat } from '@/utils/sticker/type';
import { getContentType } from '@/utils/url/url';

export const usePaste = () => {
  const universe = useUniverseContext();
  const pointerPosition = usePointerPositionRef();
  const { addTemporaryStickers } = useAddRemoveTemporaryStickers(universe.id);
  const { setNewSelection } = useStickerSelection();
  const { uploadNewFileBlob, uploadNewFile } = useUploadFiles(universe.id);
  const { addUndoRedoAction } = useUndoRedo(universe.id);
  const { deleteStickers } = useDeleteStickers();
  const pushKPI = usePushKPI();
  const analyzeDocument = useDocumentAnalyzer(universe.id);

  /**
   * Handle pasting multiple stickers
   */
  const pasteStickers = useCallback(
    (stickers: KosmikSticker[]): KosmikSticker[] => {
      const boundingBox = getBoundingBox(stickers);
      const offset = boundingBox
        ? {
            x: pointerPosition.current.camera.x - boundingBox.x,
            y: pointerPosition.current.camera.y - boundingBox.y,
          }
        : undefined;
      const { newIds, newStickers } = duplicateStickers(stickers, universe.id, {
        offset,
      });
      newStickers.forEach((sticker) => {
        pushKPI({
          type: 'create',
          properties: {
            interaction: 'paste',
            coType: sticker.type,
            coFormat: getStickerFormat(sticker),
            coSize: 0,
            coUniverse: universe.id,
          },
        });
      });
      addTemporaryStickers(newStickers);
      setNewSelection(new Set(newIds));

      return newStickers;
    },
    [
      addTemporaryStickers,
      pointerPosition,
      pushKPI,
      setNewSelection,
      universe.id,
    ]
  );

  /**
   * Handle pasting plain text
   */
  const pasteText = useCallback(
    async (text: string): Promise<KosmikTextSticker[]> => {
      const position = pointerPosition.current.camera;
      const pastedSticker = createTextSticker(text, position, universe.id);

      const file = new File([text], 'file.txt', { type: 'text/plain' });
      analyzeDocument(pastedSticker.id, file);

      pushKPI({
        type: 'create',
        properties: {
          interaction: 'paste',
          coType: 'text',
          coFormat: 'html',
          coSize: text.length,
          coUniverse: universe.id,
        },
      });

      return [pastedSticker];
    },
    [analyzeDocument, pointerPosition, pushKPI, universe.id]
  );

  /**
   * Handle pasting urls
   */
  const pasteUrls = useCallback(
    async (urls: string[]): Promise<KosmikSticker[]> => {
      const result: KosmikSticker[] = [];

      for (const url of urls) {
        const contentType = await getContentType(url);
        const isFile = contentType && !contentType.startsWith('text/');
        const position = pointerPosition.current.camera;
        if (isFile) {
          const blob = await downloadFile(url);
          const uploadedNewFileBlob = uploadNewFileBlob(blob, position, url);

          if (uploadedNewFileBlob?.id) {
            analyzeDocument(uploadedNewFileBlob.id, undefined, url);
          }

          if (uploadedNewFileBlob) {
            result.push(uploadedNewFileBlob);
            pushKPI({
              type: 'create',
              properties: {
                interaction: 'paste',
                coType: 'file',
                coFormat: blob.type,
                coSize: blob.size,
                coUniverse: universe.id,
              },
            });
          }
        } else {
          const sticker = createWebSticker(url, position, universe.id);
          analyzeDocument(sticker.id, undefined, url);
          result.push(sticker);
          pushKPI({
            type: 'create',
            properties: {
              interaction: 'paste',
              coType: 'web',
              coFormat: 'url',
              coSize: 0,
              coUniverse: universe.id,
            },
          });
        }
      }
      return result;
    },
    [analyzeDocument, pointerPosition, pushKPI, universe.id, uploadNewFileBlob]
  );

  /**
   * Handle pasting files
   */
  const pasteFiles = useCallback(
    (files: (File | Blob)[]) => {
      if (!universe.isUniverseMember) {
        return [];
      }
      const pastedFiles = [];
      for (const file of files) {
        const position = pointerPosition.current.camera;
        if (file instanceof File) {
          pastedFiles.push(uploadNewFile(file, position));
        } else {
          pastedFiles.push(uploadNewFileBlob(file, position));
        }
        pushKPI({
          type: 'create',
          properties: {
            interaction: 'paste',
            coType: 'file',
            coFormat: file.type,
            coSize: file.size,
            coUniverse: universe.id,
          },
        });
      }
      return pastedFiles.filter((file) => !!file) as KosmikFileSticker[];
    },
    [
      pointerPosition,
      pushKPI,
      universe.id,
      universe.isUniverseMember,
      uploadNewFile,
      uploadNewFileBlob,
    ]
  );

  /**
   * Handler for the native paste event
   */
  const handlePaste = useCallback(
    async (event: ClipboardEvent): Promise<KosmikSticker[]> => {
      if (!universe.isUniverseMember || isInputLike(document.activeElement)) {
        return [];
      }
      const result: KosmikSticker[] = [];
      try {
        const files = getFiles(event.clipboardData);
        const urls = getUrls(event.clipboardData);
        const text = getText(event.clipboardData);
        if (files.length) {
          result.push(...pasteFiles(files));
        }
        if (urls && urls.length) {
          const pasteUrlsResult = await pasteUrls(urls);
          result.push(...pasteUrlsResult);
        } else if (text) {
          const stickers = parseArrayOfStickers(text);
          if (stickers) {
            result.push(...pasteStickers(stickers));
          } else {
            const pasteTextResult = await pasteText(text);
            result.push(...pasteTextResult);
          }
        }
      } catch (error) {
        console.error(error);
      }
      return result;
    },
    [universe.isUniverseMember, pasteFiles, pasteUrls, pasteStickers, pasteText]
  );

  /**
   * Handler for the native paste event with the undo / redo system
   */
  const handleUndoablePaste = useCallback(
    async (event: ClipboardEvent) => {
      let pastedItems: KosmikSticker[] = await handlePaste(event);
      const pasteAction: UndoRedoActionType = {
        do: () => {
          if (pastedItems) {
            pastedItems = undoableDuplicateStickers(pastedItems, universe.id);
          }
        },
        undo: () => {
          const pastedItemsValue = pastedItems;
          if (pastedItemsValue) {
            deleteStickers(pastedItemsValue);
          }
        },
      };
      addUndoRedoAction(pasteAction);
    },
    [addUndoRedoAction, deleteStickers, handlePaste, universe.id]
  );

  return {
    pasteStickers,
    pasteText,
    pasteUrls,
    pasteFiles,
    handlePaste,
    handleUndoablePaste,
  };
};
