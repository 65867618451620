import { KebabCSSProperty } from '@/utils/css/css';
import { AnyStringWithAutocomplete } from '@/utils/types';

/**
 * Removes all the given style properties from the given element's style
 */
export const removeStyleProperties = (
  element: HTMLElement,
  props: AnyStringWithAutocomplete<KebabCSSProperty>[]
) => {
  props.forEach((prop) => {
    element.style.removeProperty(prop);
  });
};
