import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { followingAtom } from '@/atoms/multiplayer';
import { useUniverseContext } from '@/context/Universe/useUniverseContext';
import { useCamera } from '@/hooks/camera/useCamera';
import {
  getCurrentCameraValues,
  getZoomToFitTargetCamera,
} from '@/utils/camera/camera';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { Dimension } from '@/utils/geometry/dimension';

export const useZoomToFit = () => {
  const resetFollowing = useResetRecoilState(followingAtom);
  const universe = useUniverseContext();
  const { animateCamera } = useCamera();

  /**
   * Zoom to fit the current html content of the canvas (e.g. placeholders)
   */
  const zoomToFitHtmlContent = useCallback(() => {
    const camera = getCurrentCameraValues();
    const canvas = document.querySelector('[data-camera]');
    if (canvas) {
      const boxes: Dimension[] = [];
      [...canvas.children].forEach((child) => {
        if (child instanceof HTMLElement) {
          const boundingBox = {
            x: child.offsetLeft,
            y: child.offsetTop,
            width: child.offsetWidth,
            height: child.offsetHeight,
          };
          boxes.push(boundingBox);
        }
      });
      const boundingBox = getBoundingBox(boxes);
      if (boundingBox) {
        const targetCamera = getZoomToFitTargetCamera(
          camera,
          boundingBox,
          boundingBox,
          0.7,
          1
        );
        animateCamera(targetCamera);
      }
    }
  }, [animateCamera]);

  /**
   * Zoom to fit the given stickers
   */
  const zoomToFit = useCallback(
    (stickerIds: Set<string>) => {
      resetFollowing();
      const camera = getCurrentCameraValues();
      const selected =
        universe.stickers?.filter((sticker) => stickerIds.has(sticker.id)) ??
        [];
      const allStickers = universe.stickers ?? [];
      const stickers = selected.length ? selected : allStickers;
      const boundingBox = getBoundingBox(stickers);
      const allStickersBoundingBox = getBoundingBox(allStickers);
      if (boundingBox && allStickersBoundingBox) {
        const targetCamera = getZoomToFitTargetCamera(
          camera,
          boundingBox,
          allStickersBoundingBox
        );
        animateCamera(targetCamera);
      } else {
        zoomToFitHtmlContent();
      }
    },
    [animateCamera, resetFollowing, universe.stickers, zoomToFitHtmlContent]
  );

  return { zoomToFit, zoomToFitHtmlContent };
};
