import { useUniverseContextActions } from '@/hooks/contextAction/useUniverseContextActions';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';
import { Shortcut } from '@/ui/Shortcut/Shortcut';

export type SingleSelectionItemsProps = {
  type: MenuType;
};

export const SingleSelectionItems = ({ type }: SingleSelectionItemsProps) => {
  const handlers = useUniverseContextActions();
  const { Item, Separator } = getPolymorphicMenuComponents(type);

  return (
    <>
      <Item
        displayAction
        onClick={handlers.copy}
        suffix={<Shortcut keys={'mod+c'} />}
      >
        Copy
      </Item>
      <Item
        displayAction
        onClick={handlers.cut}
        suffix={<Shortcut keys={'mod+x'} />}
      >
        Cut
      </Item>
      <Item
        displayAction
        onClick={handlers.paste}
        suffix={<Shortcut keys={'mod+v'} />}
      >
        Paste
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.pin}
        suffix={<Shortcut keys={'Space'} />}
      >
        Pin
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.duplicateSelected}
        suffix={<Shortcut keys={'mod+d'} />}
      >
        Duplicate sticker
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.selectAll}
        suffix={<Shortcut keys={'mod+a'} />}
      >
        Select All
      </Item>
      <Item
        displayAction
        onClick={handlers.selectAllOfType}
        suffix={<Shortcut keys={'mod+shift+a'} />}
      >
        Select all of type
      </Item>
      <Item
        displayAction
        onClick={handlers.deselectAll}
        suffix={<Shortcut keys={'esc'} />}
      >
        Deselect
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={handlers.zoomToFit}
        suffix={<Shortcut keys={'alt+f'} />}
      >
        Zoom to fit
      </Item>
      <Separator />
      <Item
        displayAction
        onClick={() => handlers.deleteSelected()}
        suffix={<Shortcut keys={'backspace'} />}
      >
        Delete sticker
      </Item>
    </>
  );
};
