import { Size } from '@/utils/geometry/size';
import { ExtractData } from '@/utils/kosmik/stickers/extract';

/**
 * createExtractImage : allows you to create a new image corresponding to the extract from an image (as a canvas)
 */
export const createExtractImage = (
  _canvas: HTMLCanvasElement,
  extractData: ExtractData
): HTMLCanvasElement | null => {
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');

  if (!extractData) {
    return null;
  }

  const getRectFromSize = (size: Size) => {
    if (!extractData) {
      return;
    }
    return {
      x: size.width * extractData.cropLeft,
      y: size.height * extractData.cropTop,
      width: (1 - (extractData.cropLeft + extractData.cropRight)) * size.width,
      height:
        (1 - (extractData.cropTop + extractData.cropBottom)) * size.height,
    };
  };

  const croppedExtractRect = getRectFromSize({
    width: _canvas.width,
    height: _canvas.height,
  });

  if (!croppedExtractRect) {
    return null;
  }
  canvas.width = croppedExtractRect.width;
  canvas.height = croppedExtractRect.height;

  canvasContext?.drawImage(
    _canvas,
    croppedExtractRect.x,
    croppedExtractRect.y,
    croppedExtractRect.width,
    croppedExtractRect.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return canvas;
};
