import { ShapeRectangle } from '@lithium/spectrum';

import { useCreateDefaultShapeSticker } from '@/hooks/sticker/create/useCreateDefaultShapeSticker';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const CreateRectangleShapeStickerMenuItem = ({
  type,
}: {
  type: MenuType;
}) => {
  const { handleCreateDefaultShape } = useCreateDefaultShapeSticker({
    shape: 'rectangle',
  });
  const { Item } = getPolymorphicMenuComponents(type);

  return (
    <Item
      onClick={(event) => handleCreateDefaultShape(event.shiftKey)}
      prefix={<ShapeRectangle />}
    >
      Rectangle
    </Item>
  );
};
