import { useMemo, useRef } from 'react';

import { useAutoSizeSticker } from '@/components/HTMLCanvas/stickers/hooks/useAutoSizeSticker';
import { createPolygon } from '@/utils/geometry/polygon';
import { getOffsetPosition } from '@/utils/geometry/position';
import {
  KosmikShapeSticker,
  PolygonShapeAttributes,
} from '@/utils/kosmik/stickers/shape';
import { getNumber } from '@/utils/number/number';
import { cssToSvgDasharray, roundSvgPolygon } from '@/utils/svg';

export const HtmlPolygonShapeSticker = ({
  sticker,
}: {
  sticker: KosmikShapeSticker;
}) => {
  const attributes = sticker.attributes;
  const polygonAttributes = PolygonShapeAttributes.safeParse(attributes);

  if (!polygonAttributes.success) {
    console.log('Invalid attributes for polygon sticker', sticker);
    return null;
  }

  return <PolygonShape attributes={polygonAttributes.data} />;
};

export type PolygonShapeProps = {
  attributes: PolygonShapeAttributes;
};

export const PolygonShape = ({ attributes }: PolygonShapeProps) => {
  const ref = useRef<SVGSVGElement>(null);
  const size = useAutoSizeSticker(ref);
  const width = getNumber(size?.width, 0);
  const height = getNumber(size?.height, 0);
  const numPoints = attributes.num_points;
  const cornerRadius = attributes.corner_radius_ratio;

  // Compute svg path
  const { svgData, offset } = useMemo(() => {
    if (!width || !height) {
      return {
        svgData: '',
        offset: { x: 0, y: 0 },
      };
    }
    const minDimension = Math.min(width, height);
    const polygonPoints = createPolygon(numPoints, width, height);
    const offset = getOffsetPosition(polygonPoints, width, height);
    let svgData: string;
    if (cornerRadius === 0) {
      const commands = polygonPoints.map(([x, y], i) => {
        return `${i === 0 ? 'M' : 'L'} ${x}, ${y}`;
      });
      commands.push('Z');
      svgData = commands.join(' ');
    } else {
      svgData = polygonPoints
        .flatMap((coordinates) => coordinates.join(','))
        .join(' ');
      svgData = roundSvgPolygon(
        svgData,
        (cornerRadius * minDimension) / numPoints
      );
    }
    return { svgData, offset };
  }, [cornerRadius, height, numPoints, width]);

  return (
    <svg
      ref={ref}
      data-testid={'shape-polygon'}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
    >
      <svg x={offset.x} y={offset.y}>
        <path
          d={svgData}
          data-testid={'polygon'}
          fill={attributes.fill_color}
          strokeWidth={attributes.stroke_width}
          stroke={attributes.stroke_color}
          {...cssToSvgDasharray(attributes)}
        />
      </svg>
    </svg>
  );
};
