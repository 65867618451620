import { getMimetype } from '@/utils/mime';
import { SortOrder } from '@/utils/sort';
import { Maybe, MaybeUndefined } from '@/utils/types';

/**
 * Draw the given image to a new canvas, with an optional background color
 * @param image
 * @param backgroundColor
 */
export const drawImageToNewCanvas = (
  image: HTMLImageElement,
  backgroundColor: false | string = false
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    if (backgroundColor) {
      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
    context.drawImage(image, 0, 0);
    return canvas;
  }
};

/**
 * Convert an HTMLImageElement to a dataUrl representation
 * @param image the image to convert
 * @param format the format of the image, ex: `image/png`
 * @param backgroundColor the background color to use for transparent images
 */
export const convertToDataUrl = (
  image: HTMLImageElement,
  format = 'image/jpeg',
  backgroundColor: false | string = false
) => {
  const canvas = drawImageToNewCanvas(image, backgroundColor);
  return canvas?.toDataURL(format);
};

/**
 * Get the given image blob
 * @param image
 */
export const getImageBlob = async (image: HTMLImageElement) => {
  let blob: Maybe<Blob>;
  const src = (image as HTMLImageElement).src;
  try {
    await fetch(src)
      .then((response) => {
        return response.blob();
      })
      .then((imageBlob) => {
        blob = imageBlob;
        if (!blob) {
          throw new Error();
        }
      });
  } catch {
    const canvas = drawImageToNewCanvas(image);
    blob = await new Promise((resolve) => {
      canvas?.toBlob(
        resolve,
        getMimetype(src.split('.').reverse()[0]) ?? undefined
      );
    });
  }
  return blob;
};

/**
 * Tries to fetch an image and convert it to a data url, fallbacks on drawing
 * it to a canvas and returning the corresponding data url if fetch doesn't succeed
 * @param image
 */
export const fetchImageDataUrl = (image: HTMLImageElement | string) => {
  const src = image instanceof HTMLImageElement ? image.src : image;
  return fetch(src)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .catch(() => {
      const imageElement = document.createElement('img');
      imageElement.src = src;
      const canvas = drawImageToNewCanvas(
        image instanceof HTMLImageElement ? image : imageElement
      );
      return canvas?.toDataURL(
        getMimetype(src.split('.').reverse()[0]) ?? undefined
      );
    }) as Promise<MaybeUndefined<string>>;
};

/**
 * Check if an image contains transparency by rendering it
 * to a canvas element and parsing the image data
 *
 * @param img {HTMLImageElement} onload event
 * @return {boolean} true if image contains transparency
 */
export const checkImageTransparency = (img: HTMLImageElement): boolean => {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return false;
  }
  ctx.drawImage(img, 0, 0, img.width, img.height);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);

  // Iterate through image data and check if any pixel has an alpha value less than 255
  let isTransparent = false;
  for (let i = 3; i < imageData.data.length; i += 4) {
    if (imageData.data[i] !== 255) {
      isTransparent = true;
      break;
    }
  }
  return isTransparent;
};

export type HTMLImagePayload = {
  data: HTMLImageElement;
  isTransparent: boolean;
};

/**
 * Create a HTMLImageElement. If transparency check is skipped,
 * isTransparent defaults to false
 *
 * @param url any valid image url value as string
 * @param [checkTransparency=true] by default always check transparency
 * @return Promise<HTMLImagePayload>
 */
export const createHtmlImage = (
  url: string,
  checkTransparency = true,
  useProxy = false
): Promise<HTMLImagePayload> => {
  return new Promise<HTMLImagePayload>((resolve, reject) => {
    const img = new Image();
    if (!useProxy) {
      img.crossOrigin = 'anonymous';
    }
    img.onload = (event) => {
      const isTransparent = checkTransparency
        ? checkImageTransparency(event.currentTarget as HTMLImageElement)
        : false;
      resolve({ data: img, isTransparent });
    };
    img.onerror = (event) => {
      reject(event);
    };
    img.src = url;
    /* TODO: make meta proxy work with this kind of request
     img.src = useProxy
       ? `${import.meta.env.VITE_META_PROXY}/proxy?url=${url}`
       : url;
    */
  });
};

/**
 * Fetch the given image urls and return the corresponding images sorted by size
 * @param imageUrls
 * @param order
 */
export const getImagesSortedBySize = async (
  imageUrls: string[],
  order: SortOrder = SortOrder.descending
) => {
  const imagePayloads = await Promise.allSettled(
    imageUrls.map((favicon) => createHtmlImage(favicon))
  );
  const resolvedImagePayloads = imagePayloads.filter(
    (image) => image.status === 'fulfilled'
  ) as PromiseFulfilledResult<HTMLImagePayload>[];
  return resolvedImagePayloads
    .map((resolvedImagePayload) => resolvedImagePayload.value.data)
    .sort((a, b) => (a.width * a.height - b.width * b.height) * order);
};

/**
 * Returns the given image dimensions, normalized to not be excessively large
 */
export const normalizeImageSize = (image: HTMLImageElement) => {
  const imageWidth = image.naturalWidth;
  const imageHeight = image.naturalHeight;
  const cappedWidth = Math.min(imageWidth, 800);
  const imageRatio = imageHeight ? imageWidth / imageHeight : 0;
  return imageRatio
    ? {
        width: cappedWidth,
        height: cappedWidth / imageRatio,
      }
    : {
        width: imageWidth,
        height: imageHeight,
      };
};
