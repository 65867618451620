import { useEffect, useRef } from 'react';

interface CallbackProps {
  time: number;
  delta: number;
}

export const useAnimationFrame = (callback: (props: CallbackProps) => void) => {
  const frame = useRef<number>(0);
  const last = useRef(performance.now());
  const init = useRef(performance.now());

  useEffect(() => {
    const animate = () => {
      const now = performance.now();
      const time = (now - init.current) / 1000;
      const delta = (now - last.current) / 1000;
      // In seconds ~> you can do ms or anything in userland
      callback({ time, delta });
      last.current = now;
      frame.current = requestAnimationFrame(animate);
    };

    frame.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(frame.current);
  }, [callback]);
};
