import { useState } from 'react';

import { PublishMenu } from '@/components/ShareMenu/PublishMenu/PublishMenu';
import { ShareButton } from '@/components/ShareMenu/ShareButton';
import { Popover } from '@/ui/Popover/Popover';

export const ShareMenu = () => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen} trigger={<ShareButton />}>
      <PublishMenu />
    </Popover>
  );
};
