import { browserName } from 'react-device-detect';

import { getAppVersion } from '@/utils/getAppVersion';
import { getDeliveryInfos } from '@/utils/kpi/getDeliveryInfos';
import { detectOS } from '@/utils/navigator/detectOS';

export type KPIUser = {
  id: string;
  email: string;
  name?: string;
  created_at?: string;
};

export const getKPIMetas = (user: KPIUser) => {
  return {
    timestamp: new Date().toISOString(),
    applicationVersion: getAppVersion(),
    browser: browserName,
    env: import.meta.env.MODE,
    email: user.email,
    id: user.id,
    name: user.name ?? '',
    online: true,
    subscriptionPlan: 'premium',
    userAgent: navigator.userAgent,
    platform: detectOS(),
    ...getDeliveryInfos(user.created_at),
  };
};
