import { StickerPostit } from '@lithium/spectrum';

import { useCreateDefaultStickySticker } from '@/hooks/sticker/create/useCreateDefaultStickySticker';
import {
  getPolymorphicMenuComponents,
  MenuType,
} from '@/ui/PolymorphicMenu/PolymorphicMenu';

export const CreateStickyStickerMenuItem = ({ type }: { type: MenuType }) => {
  const { handleCreateDefaultSticky } = useCreateDefaultStickySticker();
  const { Item } = getPolymorphicMenuComponents(type);

  return (
    <Item
      onClick={(event) => handleCreateDefaultSticky(event.shiftKey)}
      prefix={<StickerPostit />}
    >
      Post-it
    </Item>
  );
};
