import { Button } from '@lithium/spectrum';
import { useCallback } from 'react';

import { TextBodySemibold } from '@/components/fontSystem/BodySemibold';
import { TextLabelRegular } from '@/components/fontSystem/LabelRegular';
import { useAddModal } from '@/components/modal/hooks/useAddModal';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { DialogSection } from '@/ui/Dialog/DialogSection';

export const DeleteTeamSection = () => {
  const addModal = useAddModal();

  const handleDelete = useCallback(() => {
    addModal('delete-team');
  }, [addModal]);

  return (
    <DialogSection
      title={<TextBodySemibold>Delete Workspace</TextBodySemibold>}
    >
      <div className={styles.centerRow}>
        <TextLabelRegular style={{ fontWeight: 400 }}>
          Deleting the workspace will destroy all the universes created in the
          workspace and remove every members from it.
        </TextLabelRegular>
        <Button variant={'destructive'} onAction={handleDelete}>
          Delete
        </Button>
      </div>
    </DialogSection>
  );
};
