import { PDFPageProxy } from 'pdfjs-dist';
import { MutableRefObject, useEffect } from 'react';

import { PAGEVIEW } from '@/hooks/pdf/usePdf';

export const useSetPdfCanvasSize = (
  canvasRef: MutableRefObject<HTMLCanvasElement | null>,
  pageProxy: PDFPageProxy
) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = pageProxy.view[PAGEVIEW.width] || 0;
      canvas.height = pageProxy.view[PAGEVIEW.height] || 0;
    }
  }, [canvasRef, pageProxy.view]);
};
