import { useRecoilTransaction_UNSTABLE } from 'recoil';

import { cssVariablesAtom } from '@/atoms/canvas';
import { localStickerFamily } from '@/atoms/sticker';
import { useMoveTransformHooks } from '@/components/Universe/Selection/hooks/useMoveTransformHooks';
import { MovePayload } from '@/components/Universe/Selection/movePayload';
import { TransformPayload } from '@/components/Universe/Selection/TransformHandle';
import { MultiplayerUser } from '@/context/User/MultiplayerUserContext';
import { getBoundingBox } from '@/utils/geometry/boundingBox';
import { KosmikSticker } from '@/utils/kosmik/sticker';

export const useUpdateVariables = () => {
  const { getTransformCSSVariables } = useMoveTransformHooks();

  const updateMoveVariables = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (payload: MovePayload, user: MultiplayerUser) => {
        const offsetXVar = `--drag-${user.peerId}-offset-x`;
        const offsetYVar = `--drag-${user.peerId}-offset-y`;
        set(cssVariablesAtom, (prev) => {
          const newVariables = new Map(prev);
          newVariables.set(offsetXVar, `${payload.x}px`);
          newVariables.set(offsetYVar, `${payload.y}px`);
          return newVariables;
        });
      },
    []
  );

  const updateTransformVariables = useRecoilTransaction_UNSTABLE(
    ({ get, set }) =>
      (ids: string[], payload: TransformPayload, user: MultiplayerUser) => {
        const stickers: KosmikSticker[] = [];
        ids.forEach((id) => {
          const sticker = get(localStickerFamily(id));
          if (sticker) {
            stickers.push(sticker);
          }
        });

        const initialBoundingBox = getBoundingBox(stickers);
        if (initialBoundingBox) {
          const transformVariables = getTransformCSSVariables(
            initialBoundingBox,
            payload,
            user
          );
          if (transformVariables) {
            set(cssVariablesAtom, (prev) => {
              const newVariables = new Map(prev);
              transformVariables.variables.forEach((value, key) => {
                newVariables.set(key, value);
              });
              return newVariables;
            });
          }
        }
      },
    [getTransformCSSVariables]
  );

  return {
    updateMoveVariables,
    updateTransformVariables,
  };
};
