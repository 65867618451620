import { useState } from 'react';

import { MaybeUndefined } from '@/utils/types';

/**
 * Fetch url and stream response. Calling `start(body)` will
 * initiate a post request to the configured url.
 *
 * @param url
 */
export function useFetchStream<Type>(url: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Type[]>([]);

  const start = async (body: string) => {
    setIsLoading(true);
    // reset output
    setData([]);
    try {
      const resp = await fetch(url, {
        body: body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      if (!resp.ok || !resp.body) {
        throw resp.statusText;
      }

      const reader = resp.body.getReader();
      let previousPartial: MaybeUndefined<string> = undefined;
      const read = async () => {
        const { value, done } = await reader.read();
        if (done) {
          return;
        }

        const jsonString = new TextDecoder().decode(value); //Buffer.from(value).toString('utf8');
        const partialResults = jsonString.split('\n').map((line) => {
          try {
            const obj = JSON.parse(line);

            return obj;
          } catch {
            // received a partial object join with previous partial object and re-try
            if (previousPartial) {
              const newLine = previousPartial + line;

              const obj = JSON.parse(newLine);

              previousPartial = undefined;
              return obj;
            } else {
              previousPartial = line;
              return null;
            }
          }
        });

        setData((prev) => {
          return [...(prev ?? []), ...partialResults];
        });

        await read();
      };

      await read();
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return { data, isLoading, start };
}
