export const colorPaletteColors: string[][] = [
  [
    '#F9CD1A',
    '#EF6825',
    '#EF2525',
    '#CA499F',
    '#8A49CA',
    '#3381FF',
    '#3DBDD6',
    '#45B572',
    '#54D63D',
  ],
  [
    '#FAD338',
    '#F17C41',
    '#F14141',
    '#D161AC',
    '#9961D1',
    '#5294FF',
    '#57C5DB',
    '#59C082',
    '#6BDB57',
  ],
  [
    '#FBDA56',
    '#F38F5E',
    '#F35E5E',
    '#D879B8',
    '#A879D8',
    '#70A7FF',
    '#70CEE1',
    '#6FC893',
    '#81E170',
  ],
  [
    '#FBE074',
    '#F5A37A',
    '#F57A7A',
    '#DF90C5',
    '#B890DF',
    '#8FBAFF',
    '#89D7E6',
    '#85D1A3',
    '#97E689',
  ],
  [
    '#FCE792',
    '#F7B797',
    '#F79797',
    '#E6A8D1',
    '#C7A8E6',
    '#ADCDFF',
    '#A2DFEB',
    '#9BD9B4',
    '#ADEBA2',
  ],
  [
    'transparent',
    '#FFFFFF',
    '#DEDEDE',
    '#A9A9A9',
    '#4B4B4B',
    '#353535',
    '#212121',
    '#000000',
    'advance',
  ],
];
