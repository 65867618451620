import React, { ComponentPropsWithoutRef } from 'react';

import styles from './IconButton.module.css';

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<'button'>
>(({ children, className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={[styles.iconButton, className].join(' ')}
      {...props}
    >
      {children}
    </button>
  );
});

IconButton.displayName = 'IconButton';
