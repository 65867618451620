import { InputHTMLAttributes, ReactNode } from 'react';

import CheckPrimitive from '@/assets/themesModeAssets/check.svg?react';
import { TextBodyRegular } from '@/components/fontSystem/BodyRegular';
import styles from '@/components/SelectableCards/SelectableCard.module.css';

export type BaseSelectableCard = {
  id: string;
  value: InputHTMLAttributes<HTMLInputElement>['value'];
  content: ReactNode;
  label?: ReactNode;
};

export type SelectableRadioCard = BaseSelectableCard & {
  name?: never;
};

export type SelectableCheckboxCard = BaseSelectableCard & {
  name: string;
};

export type SelectableCardBaseProps<Type extends 'checkbox' | 'radio'> =
  Type extends 'checkbox' ? SelectableCheckboxCard : SelectableRadioCard;

export type SelectableCardProps<Type extends 'checkbox' | 'radio'> =
  SelectableCardBaseProps<Type> &
    Pick<
      InputHTMLAttributes<HTMLInputElement>,
      'onChange' | 'defaultChecked' | 'checked' | 'name'
    > & {
      type: Type;
    };

export const SelectableCard = <Type extends 'checkbox' | 'radio'>({
  type,
  content,
  label,
  ...props
}: SelectableCardProps<Type>) => {
  return (
    <div className={styles.selectableCardContainer}>
      <input type={type} {...props} />
      <label className={styles.selectableCardCard} htmlFor={props.id}>
        {content}
        <CheckPrimitive className={styles.check} />
      </label>
      {label ? (
        <label htmlFor={props.id}>
          <TextBodyRegular>{label}</TextBodyRegular>
        </label>
      ) : null}
    </div>
  );
};
