import { Button } from '@lithium/spectrum';
import debounce from 'debounce';
import { ChangeEvent, useCallback, useMemo } from 'react';

import { TextBodyRegular } from '@/components/fontSystem/BodyRegular';
import { useAddModal } from '@/components/modal/hooks/useAddModal';
import styles from '@/components/modal/modals/Settings/SettingsTabContent.module.css';
import { useUser } from '@/context/User/useUser';
import { DialogSection } from '@/ui/Dialog/DialogSection';
import { renameUser } from '@/utils/user/user';

export const UserProfile = () => {
  const user = useUser();
  const addModal = useAddModal();

  const handleSaveName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      if (user && newName.length) {
        renameUser(user.id, newName);
      }
    },
    [user]
  );

  const handleNameChange = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement>) => {
        handleSaveName(event);
      }, 250),
    [handleSaveName]
  );
  return (
    <DialogSection title={'Profile'}>
      <div className={styles.settingsPageFieldRow}>
        <TextBodyRegular className={styles.settingsPageFieldName}>
          Name
        </TextBodyRegular>
        <input
          defaultValue={user?.name}
          className={styles.settingsPageFieldInput}
          onChange={handleNameChange}
          onBlur={handleSaveName}
        />
      </div>
      <div className={styles.settingsPageFieldRow}>
        <TextBodyRegular className={styles.settingsPageFieldName}>
          Email Address
        </TextBodyRegular>
        <input
          disabled
          value={user?.email}
          className={styles.settingsPageFieldInput}
        />
      </div>
      <div className={styles.settingsPageFieldRow}>
        <TextBodyRegular
          className={`${styles.settingsPageFieldName} ${styles.destructive}`}
        >
          Delete my account
        </TextBodyRegular>
        <Button
          variant={'destructive'}
          onAction={() => {
            addModal('delete-user');
          }}
        >
          Delete
        </Button>
      </div>
    </DialogSection>
  );
};
