import { DEFAULT_STICKER_SIZE } from '@/constants/size';
import { Camera, getCenterPoint } from '@/utils/camera/camera';
import { KosmikTextSticker } from '@/utils/kosmik/stickers/text';
import { createTextSticker } from '@/utils/sticker/create/createTextSticker/createTextSticker';

export type CreateDefaultTextStickerProps = {
  universeId: string;
  camera: Camera;
  isShiftKeyPressed: boolean;
};

export const createDefaultTextSticker = ({
  universeId,
  camera,
  isShiftKeyPressed,
}: CreateDefaultTextStickerProps): KosmikTextSticker[] => {
  const size = DEFAULT_STICKER_SIZE.text;
  const amount = isShiftKeyPressed ? 100 : 1;
  const stickers: KosmikTextSticker[] = [];

  for (let i = 0; i < amount; i++) {
    const centerOfScreen = getCenterPoint(camera);
    const position = {
      x: centerOfScreen.x - size.width / 2,
      y: centerOfScreen.y - size.height / 2,
    };
    const point = {
      x: position.x + (50 + size.width) * (i % 10),
      y: position.y + (50 + size.height) * (Math.floor(i / 10) % 10),
    };
    stickers.push(createTextSticker('', point, universeId));
  }

  return stickers;
};
