import { tx } from '@instantdb/react';
import { useCallback } from 'react';
import { SetterOrUpdater } from 'recoil';

import { batchTransact } from '@/utils/db/db';
import { KosmikSticker } from '@/utils/kosmik/sticker';
import { MaybeNull } from '@/utils/types';

export const useSetStickerAttributes = <
  Attributes extends KosmikSticker['attributes'],
>(
  setSticker: SetterOrUpdater<MaybeNull<KosmikSticker>>
) => {
  return useCallback(
    (attributes: Partial<Attributes>, persist: boolean) => {
      setSticker((prevSticker) => {
        if (!prevSticker) {
          return prevSticker;
        }

        const newAttributes = {
          ...prevSticker.attributes,
          ...attributes,
        };

        const newSticker = {
          ...prevSticker,
          attributes: newAttributes,
          v: (prevSticker.v ?? 0) + 1,
        } as KosmikSticker;

        if (persist) {
          batchTransact(
            tx.stickers?.[prevSticker.id]?.update({
              v: newSticker.v,
              attributes: newAttributes,
            })
          );
        }
        return newSticker;
      });
    },
    [setSticker]
  );
};
