import { RenderableFileType } from '@/utils/kosmik/stickers/type';

/**
 * Returns the renderable file type from the given mime type
 * @param type
 */
export const getRenderableFileType = (type: string) => {
  let renderableFileType: RenderableFileType = 'file';
  if (type === 'application/pdf') {
    renderableFileType = 'pdf';
  } else if (type.startsWith('video')) {
    renderableFileType = 'video';
  } else if (type.startsWith('audio')) {
    renderableFileType = 'audio';
  } else if (type.startsWith('image')) {
    renderableFileType = 'image';
  }
  return renderableFileType;
};
