import { SliderProps } from '@radix-ui/react-slider';
import { ReactElement } from 'react';

import { Popover, PopoverProps } from '@/ui/Popover/Popover';
import { Slider } from '@/ui/Slider/Slider';

type SliderPopoverProps = {
  trigger: PopoverProps['trigger'];
  contentProps?: PopoverProps['contentProps'];
} & SliderProps;

export const SliderPopover = ({
  trigger,
  contentProps,
  ...props
}: SliderPopoverProps): ReactElement => {
  return (
    <Popover
      trigger={trigger}
      contentProps={{
        sideOffset: 8,
        style: { padding: 10 },
        side: 'top',
        avoidCollisions: true,
        ...contentProps,
      }}
    >
      <Slider orientation={'vertical'} {...props} />
    </Popover>
  );
};
