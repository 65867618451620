import { AiChat } from '@lithium/spectrum';

import { AutoResizingInput } from '@/ui/AutoResizingInput/AutoResizingInput';

import styles from './CreatePromptStickerButton.module.css';

export const CreatePromptStickerButton = () => {
  return (
    <button
      className={styles.button}
      data-testid={'create-prompt-sticker-button'}
    >
      <AiChat />

      <AutoResizingInput
        className={styles.prompt}
        placeholder={'Ask Kosmo...'}
      />
    </button>
  );
};
